import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import XIcon from 'assets/iconsLibrary/XIcon'
import CheckBox from 'components/CheckBox'

const InspectionRadioCell = ({
    id,
    onChange
}) => {  
    return (
        <div className={styles.container}>
            <CheckBox
                id={`check-${id}`}
                onChange={onChange}
                type={`radio`}
                name={`inspectionRadioCell-${id}`}
                className={styles.radioCheck}
                height={16}
                width={16}
            ></CheckBox>
            <CheckBox
                id={`x-${id}`}
                onChange={onChange}
                type={`radio`}
                name={`inspectionRadioCell-${id}`}
                symbol={XIcon}
                className={styles.radioX}
                height={16}
                width={16}
            ></CheckBox>
        </div>
    )
}

InspectionRadioCell.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onChange: PropTypes.func
}

export default InspectionRadioCell