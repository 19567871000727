import React from 'react'
import NoneIcon from '@mui/icons-material/Cancel'
import UnknownIcon from '@mui/icons-material/Help'
import RentalIcon from '@mui/icons-material/DriveEta'
import TowIcon from '@mui/icons-material/LocalShipping'
import ShuttleIcon from '@mui/icons-material/AirportShuttle'
import WaitingIcon from '@mui/icons-material/Weekend'
import ColumnIcon from 'componentsUI/TableView/components/ColumnIcon'
import { Transport } from 'types/repairOrder'

export interface ITransportTypeCell {
    type: Transport
}

function TransportTypeCell({
    type
}: ITransportTypeCell) {
    const Icon = () => {
        switch (type) {
            case Transport.NONE:    
                return <ColumnIcon
                    tooltipText={`None`}
                    Icon={NoneIcon}
                ></ColumnIcon>
            case Transport.RENTAL:
                return <ColumnIcon
                    tooltipText={`Rental`}
                    Icon={RentalIcon}
                ></ColumnIcon>
            case Transport.SHUTTLE:
                return <ColumnIcon
                    tooltipText={`Shuttle`}
                    Icon={ShuttleIcon}
                ></ColumnIcon>
            case Transport.TOW:
                return <ColumnIcon
                    tooltipText={`Tow`}
                    Icon={TowIcon}
                ></ColumnIcon>
            case Transport.WAIT:
                return <ColumnIcon
                    tooltipText={`Waiting`}
                    Icon={WaitingIcon}
                ></ColumnIcon>
            default:
                return <ColumnIcon
                    tooltipText={`Unknown`}
                    Icon={UnknownIcon}
                ></ColumnIcon>
        }
    }

    return (
        <Icon/>
    )
}

export default React.memo(TransportTypeCell)