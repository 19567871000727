import React from "react";
import SummerySectionCell from "../../SummerySectionCell";
import SummerySectionContainer from "../../SummerySectionContainer";

export interface IVideoMpviDashboardSummeryStatsProps {
    totalRepairOrderCount: number,
    totalVideoMpvisRecordedCount: number,
    averageASRsPerMpviCount: number
}

function VideoMpviSummerySection({
    totalRepairOrderCount,
    totalVideoMpvisRecordedCount,
    averageASRsPerMpviCount
}: IVideoMpviDashboardSummeryStatsProps) {
    return (
        <SummerySectionContainer>
            <SummerySectionCell
                header={`Total Repair Orders`}
                value={`${totalRepairOrderCount}`}/>
            <SummerySectionCell
                header={`Videos Recorded`}
                value={`${totalVideoMpvisRecordedCount}`}/>
            <SummerySectionCell
                header={`Avg. ASRs Per MPVI`}
                value={`${averageASRsPerMpviCount}`}/>
        </SummerySectionContainer>
    )
}

export default VideoMpviSummerySection