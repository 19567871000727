import { BaseCustomer } from 'api/types/customer'
import { Errors } from 'errors'
import { ApiUtils } from 'api/utils'
import { BaseRepairOrder } from 'api/types/repairOrder'
import { BaseDealer } from 'api/types/dealer'
import { BaseUser } from './user'

enum endpoint {
    SEND_DEFAULT = `SEND_DEFAULT`,
    MARK_READ = `MARK_READ`,
}

const endpoints: {[key in endpoint]: ApiUtils.Endpoint} = {
    SEND_DEFAULT: () => `/api/messages/send-advisor-text`,
    MARK_READ: ids => `/api/messages/mark-read`,
}

export interface BaseMessage {
    ID: number
    CustomerID: number
    DealerID: number
    MessageTypeID: number
    MessageProviderID: number
    RepairOrderID: number | null
    UserID: number | null

    Body: string | null
    CreatedAt: string | null
    Direction: number
    From: string | null
    HasAdvisorViewed: boolean
    IsDeleted: boolean
    SentAt: string
    To: string | null
}

export interface Message extends BaseMessage {
    Customer: BaseCustomer
    Dealer: BaseDealer
    RepairOrder: BaseRepairOrder | null
    User: BaseUser | null
}

type DefaultSend = {
    CustomerID: number
    DealerID: number
    MessageBody: string
    RepairOrderID: number | null
    RepairOrderAlertID: number | null
}

// TODO: Move these to their own file.
export const sendDefault = (params: DefaultSend) => ApiUtils.send<Message>(endpoints.SEND_DEFAULT, params, Errors.CRM0012)
export const markRead = (ids: number[], dealerID: number) => ApiUtils.send(endpoints.MARK_READ, {
    MessageIDs: ids,
    DealerID: dealerID
}, Errors.CRM0016, `PATCH`) 