import React from 'react'
import styles from './styles.module.scss'
import CalendarLineChart from 'components/CalendarLineChart'
import Overlay from 'components/Overlay'
import { useAppDispatch, useAppSelector } from 'store'
import { setSelectedCalendarTab } from 'modules/bdcDashboard'
import TabBar from 'components/TabBar'
import useSubscribeToCalendarData from 'components/BdcDataview/hooks/useSubscribeToCalendarData'

function TasksCalendar() {
    const dispatch = useAppDispatch()
    const {
        selectedCalendarTab,
        isLoadingCalendarData: isLoading,
        isErrorCalendarData: isError,
        calendarLabels: labels,
        calendarSeries: series,
    } = useAppSelector(
        state => state.bdcDashboard
    )
    const {
        EnableAppointments,
    } = useAppSelector(state => state.dealer)

    useSubscribeToCalendarData()

    const handleTabClick = (val: number) => {
        dispatch(setSelectedCalendarTab(val))
    }

    const tabs = <TabBar
        tabsArray={[
            {
                text: `Daily`
            },
            {
                text: `Weekly`
            },
            {
                text: `Monthly`
            }
        ]}
        width={400}
        activeTabIndex={selectedCalendarTab}
        onClick={handleTabClick}
    ></TabBar>
    
    const modifiedSeries = [...series]
    if (!EnableAppointments) {
        modifiedSeries.pop()
    }

    return (
        <div className={styles.container}>
            {tabs}
            <div className={styles.body}>
                <div className={styles.textWrapper}>
                    <div className={`${styles.dot} ${styles.blue}`}></div>
                    <div className={`${styles.text} ${styles.blue}`}>Total Opportunities</div>
                    <div className={`${styles.dot} ${styles.green}`}></div>
                    <div className={`${styles.text} ${styles.green}`}>Calls Made</div>
                    {EnableAppointments &&
                        <>
                            <div className={`${styles.dot} ${styles.orange}`}></div>
                            <div className={`${styles.text} ${styles.orange}`}>Appointments</div>
                        </>
                    }
                </div>
                <div className={styles.dataViewWrapper}>
                    <CalendarLineChart
                        data={{
                            labels,
                            series: modifiedSeries,
                        }}
                    ></CalendarLineChart>
                </div>
                <Overlay
                    show={isLoading || isError}
                    isLoading={isLoading}
                    isError={isError}
                    text={isError ? `An error has occured...` : `Retrieving calendar data...`}
                ></Overlay>
            </div>
        </div>
    )
}

export default TasksCalendar
