import { DialogContent, DialogContentText } from "@mui/material"
import { Message } from "api/types/message"
import ConversationPane from "components/ConversationPane"
import React from "react"
import styles from './styles.module.scss'

export interface IDialogConversationContent {
    contentText: string
    repairOrderId: number | null
    customerId: number
    messages: Message[]
    repairOrderAlertID?: number | null
}

const DialogConversationContent = ({
    contentText,
    repairOrderId,
    customerId,
    messages,
    repairOrderAlertID = null
} : IDialogConversationContent) => {

    return (
        <DialogContent className={styles.conversationContent}>
            <DialogContentText className={styles.conversationContentText}>{contentText}</DialogContentText>
            <ConversationPane
                repairOrderID={repairOrderId}
                customerID={customerId}
                messages={messages}
                repairOrderAlertID={repairOrderAlertID}
            ></ConversationPane>
        </DialogContent>
    )

}

export default DialogConversationContent