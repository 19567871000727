import React from 'react'
import { Button as MaterialUIButton } from '@mui/material'

function Button({...props}) {
    return (
        <MaterialUIButton
            {...props}
        ></MaterialUIButton>
    )
}

Button.defaultProps = {
    variant: `contained`,
    color: `primary`
}

export default Button