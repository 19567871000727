import { Avatar } from "@mui/material";
import React from "react";

export interface IAvatarIcon {
    name: string,
    color: string
}
export default function AvatarIcon({
    name,
    color
}: IAvatarIcon){

    function stringAvatar(name: string, color: string) {
        return {
            sx: {
                bgcolor: color,
                height: 24,
                width: 24,
                fontSize: `12px`
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <Avatar {...stringAvatar(name, color)} />
    )
}