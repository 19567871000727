import React from 'react'
import {
    Box,
    CardContent,
    TextField,
} from '@mui/material'
import { useAppDispatch } from 'store'
import { textValueUpdated } from 'components/RepairOrderAlertAddEditDialog/modules'
import TimeSelect from '../TimeSelect'
import moment from 'moment'

export interface IAddEditCard {
    time: string
    text: string
}

function AddEditCard({
    time,
    text
}: IAddEditCard) {
    
    const dispatch = useAppDispatch()

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        dispatch(textValueUpdated(e.target.value))
    
    }

    return <>
        <CardContent>
            <Box
                display={`grid`}
                rowGap={2}
            >
                <TimeSelect time={moment(time)}/>
                <TextField
                    label={`Reminder`}
                    defaultValue={text}
                    size={`small`}
                    onChange={handleTextChange}
                ></TextField>
            </Box>
        </CardContent>
    </>;
}

export default AddEditCard