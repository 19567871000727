import React from 'react'
import { Chip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

export interface IFilterChip {
    text: string,
    isActive: boolean
    onClick(): void
}

function FilterChip({
    text,
    isActive,
    onClick,
}: IFilterChip) {
    return (
        <Chip
            size={`small`}
            label={text}
            onClick={onClick}
            color={isActive ? `primary` : `default`}
            onDelete={isActive ? onClick : undefined}
            deleteIcon={<CheckIcon/>}
        ></Chip>
    )
}

export default React.memo(FilterChip)