import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.scss'

const BoxWithHeader = ({
    containerClasses,
    titleClasses,
    contentClasses,
    titleText,
    showTitleBar, 
    content, 
    cornerRadius
}) => {  
    const containerClassNames = classNames(
        styles.container,
        {[containerClasses]: containerClasses}
    )

    const titleClassNames = classNames(
        styles.titleWrapper,
        {[titleClasses]: titleClasses}
    )

    const contentClassNames = classNames(
        styles.contentWrapper,
        {[contentClasses]: contentClasses}
    )

    return (
        <div className={containerClassNames} style={{borderRadius: cornerRadius}}>
            {showTitleBar && 
                <div className={titleClassNames}>{titleText}</div>
            }
            <div className={contentClassNames}>{content}</div>
        </div>
    )
}

BoxWithHeader.propTypes = {
    containerClasses: PropTypes.string,
    titleClasses: PropTypes.string,
    contentClasses: PropTypes.string,
    titleText: PropTypes.string,
    content: PropTypes.object,
    cornerRadius: PropTypes.number
}

BoxWithHeader.defaultProps = {
    showTitleBar: true,
    cornerRadius: 8
}


export default BoxWithHeader