import React from 'react'

import styles from './styles.module.scss'

export interface ITasksLayout {
    overview: any
    calendar: any
    infoBoxes: any[] 
}

function TasksLayout({
    overview,
    calendar,
    infoBoxes
}: ITasksLayout) {
  return (
    <div className={styles.container}>
        <div className={styles.overviewWrapper}>{overview}</div>
        <div className={styles.calendarWrapper}>{calendar}</div>
        <div className={styles.boxesWrapper}>
            {infoBoxes}
        </div>    
    </div>
  )
}

export default TasksLayout
