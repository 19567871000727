import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import CheckIcon from 'assets/iconsLibrary/CheckIcon'

const CheckBox = ({
    id,
    value,
    name,
    labelText,
    symbol,
    symbolColor,
    backgroundColor,
    height,
    width,
    defaultChecked,
    className,
    type,
    onChange
}) => {  
    const containerClasses = classNames(
        styles.container,
        {[className]: className}
    )

    const Icon = symbol ? symbol : CheckIcon

    return (
        <div className={containerClasses}>
            <input
                id={id}
                value={value}
                name={name}
                type={type}
                onChange={onChange}
                defaultChecked={defaultChecked}
            />
            <div className={styles.checkbox} style={{backgroundColor, height, width}}>
                <Icon
                    color={symbolColor}
                    className={styles.icon}
                ></Icon>
            </div>
            <label htmlFor={id} style={{height, minWidth: width}}>
                {labelText &&
                    <span>{labelText}</span>
                }
            </label>
        </div>
    )
}

CheckBox.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    name: PropTypes.string,
    labelText: PropTypes.string,
    showLabel: PropTypes.bool,
    symbol: PropTypes.func,
    symbolColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf([`checkbox`, `radio`]),
    onChange: PropTypes.func
}

CheckBox.defaultProps = {
    symbolColor: `white`,
    height: 14,
    width: 14,
    text: ``,
    type: `checkbox`,
    defaultChecked: false,
    onChange: () => {}
}


export default CheckBox