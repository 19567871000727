import React, { useEffect, useState } from 'react'
import { login } from 'modules/login'
import { useAppDispatch, useAppSelector } from 'store'
import UsernameField from './components/UsernameField'
import PasswordField from './components/PasswordField'
import { Box, Paper, Typography } from '@mui/material'
import SubmitButton from 'componentsUI/SubmitButton'
import sleaIcon from 'assets/images/service-lane-eAdvisor-logo.png'
import styles from './styles.module.scss'
import CopyrightFooter from 'components/CopyrightFooter'
import { SubmitHandler, useForm } from 'react-hook-form'

export interface ILoginFormProps {
    email: string,
    password: string
}

function Login() {

    const [hasEmptyFields, setHasEmptyFields] = useState<boolean>(true)
    
    const dispatch = useAppDispatch()

    const {
        isLoading,
        isError,
        infoText
    } = useAppSelector(
        state => state.login
    )

    const {register, watch, formState: { errors }, handleSubmit} = useForm<ILoginFormProps>({
        defaultValues: {
            email: ``,
            password: ``
        }
    })

    let inputValues = watch()

    const onSubmit: SubmitHandler<ILoginFormProps> = data => {

        dispatch(login(data.email, data.password))

    }

    useEffect(() => {

        setHasEmptyFields(inputValues.email === `` || inputValues.password === ``)

    }, [inputValues])
    
    return (
            <div className={styles.container}>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

                    <Box className={styles.imageWrapper}>
                        <img className={styles.image} src={sleaIcon} alt="Service Lane eAdvisor"/>
                    </Box>
                    <Typography className={styles.text} variant={`body2`} gutterBottom>Sign in to eSS</Typography>

                    <Paper className={styles.formWrapper} elevation={8}>
                        <UsernameField 
                            register={register}
                            error={errors.email}
                        ></UsernameField>
                        <PasswordField 
                            register={register}
                            error={errors.password}
                        ></PasswordField>
                        {isError &&
                            <div className={styles.errorText}>{infoText}</div>
                        }
                        <SubmitButton
                            canSubmit={!hasEmptyFields && Object.keys(errors).length === 0}
                            isLoading={isLoading}
                            text={`Login`}
                        ></SubmitButton>
                    </Paper>

                    <Box className={styles.footer}>
                        <CopyrightFooter/>
                        <Typography variant={`body2`} gutterBottom><strong>Support:</strong> (866) 484-4777</Typography>
                    </Box>
                    
                </form>
            </div>
    )
}

export default Login