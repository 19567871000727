import React from 'react'

import styles from './styles.module.scss'

export interface IBdcPageWrapper {
    children: React.ReactNode
}

function BdcPageWrapper({
    children
}: IBdcPageWrapper) {
    return (
        <div className={styles.container}>{children}</div>
    )
}

export default BdcPageWrapper