import React, { useMemo } from 'react'
import {
    useTable,
    useResizeColumns,
    usePagination,
    useFilters,
    useGlobalFilter,
    useSortBy,
    PluginHook,
    TableState,
    TableOptions,
    Column,
} from 'react-table'
import {
    Fade,
    CircularProgress,
} from '@mui/material'
import Pagination from './components/Pagination'
import NoResults from './components/NoResults'
import ScrollWrapper from './components/ScrollWrapper'
import HeaderCell from './components/HeaderCell'
import useScrollReset from './hooks/useScrollReset'
import useGridLayout from './hooks/useGridLayout'
import { colors } from 'components/RepairOrderListView/helpers/rowColors'
import FilterBar, { filterTypes } from './components/FilterBar'

import styles from './styles.module.scss'

export interface ITableView {
    data: any[]
    columns: Column[]
    isLoading?: boolean
    options?: Partial<TableOptions<{}>>
    className?: string
    paginated?: boolean
    initialState?: Partial<TableState>
    hooks?: PluginHook<{}>[]
    responsive?: boolean
}

function TableView({
    data,
    columns,
    isLoading,
    options = {},
    className,
    paginated = false,
    initialState = {},
    hooks = [],
}: ITableView) {
    const isPaginated: boolean = paginated || data.length >= 50

    const {
        getTableProps,
        getTableContainerProps,
        headerGroups,
        rows,
        columns: processedColumns,
        prepareRow,
        canNextPage,
        canPreviousPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: {
            pageIndex,
            scrollResetCount,
            filters,
        },
        pageCount,
        page,
        gotoPage
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 50,
                pageIndex: 0,
                ...initialState,
            },
            autoResetGlobalFilter: false,
            ...options
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useResizeColumns,
        useGridLayout,
        useScrollReset,
        ...hooks
    )

    const rowData = useMemo(() => isPaginated ? page : rows,[isPaginated, page, rows])

    const headerCells = useMemo(() => headerGroups.map(headerGroup => (
        headerGroup.headers.map((column, i) => (
            <HeaderCell
                key={column.id}
                column={column}
                numColumns={headerGroup.headers.length - 1}
                index={i}
            ></HeaderCell>
        ))
    )), [headerGroups])

    const [
        cells,
    ] = useMemo(() => {
        const cells = rowData.map((row, rowIndex) => {
            prepareRow(row)

            const rowCells = row.cells.map((cell, i) => (
                <div
                    {...cell.getCellProps()}
                    className={`${styles.cell} ${rowIndex % 2 ? styles.odd : styles.even}`}
                    style={{
                        ...cell.getCellProps().style,
                        borderRight: row.cells.length - 1 !== i ? `border-right: 1px solid #aaaaaa` : `none`,
                        padding: cell.column.disableDefaultFormat ? 0 : `0 10px`,
                        justifyContent: !!cell.column.iconColumn ? `center` : `normal`,
                        backgroundColor: colors[row.values.rowColor],
                    }}
                >
                    {cell.render('Cell')}
                </div>
            ))

            return rowCells
        })

        return [
            cells,
        ]
    }, [rowData, prepareRow])

    const content = <div {...getTableContainerProps()} className={`${styles.container} ${className}`} >
        <FilterBar
            filterType={options.disableGlobalFilter ? filterTypes.COLUMN : filterTypes.GLOBAL}
            globalFilter={setGlobalFilter}
            columns={processedColumns}
            filters={filters}
        ></FilterBar>
        <ScrollWrapper
            resetCount={scrollResetCount}
        >
            <div
                {...getTableProps()}
                className={styles.grid}
            >
                {headerCells}
                {cells}
            </div>
            {data.length < 1 &&
                <NoResults></NoResults>
            }
        </ScrollWrapper>
        {isPaginated &&
            <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                onNextPage={nextPage}
                onPreviousPage={previousPage}
                currentPageIndex={pageIndex}
                pageCount={pageCount}
                onGoToPage={gotoPage}
            ></Pagination>
        }
        <Fade
            in={isLoading}
            timeout={500}
        >
            <div className={styles.loadingOverlay}>
                <CircularProgress></CircularProgress>
            </div>
        </Fade>
    </div>

    return (content)
}

export default React.memo(TableView)