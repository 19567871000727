import { WarrantyAlertReportingOverviewViewModel } from "api/types/reporting/WarrantyAlertReportingOverview";
import React from "react";
import { ILoading } from "../../Interfaces/ILoading";
import ReportingContainer from "../../ReportingContainer";
import WarrantyAlertSummerySection from "../WarrantyAlertSummerySection";
import WarrantyAlertUserStatsSection from "../WarrantyAlertUserSection";

export interface IWarrantyAlertOverviewProps extends ILoading {
    data: WarrantyAlertReportingOverviewViewModel
}

export default function WarrantyAlertOverview({
    data,
    isLoading
}: IWarrantyAlertOverviewProps){
    const {
        TotalRepairOrders,
        TotalCuredDollarAmount: TotalDollarAmount,
        TotalUncuredDollarAmount: TotalLostDollarAmount,
        ByUser
    } = data

    return (
        <ReportingContainer
            title={`Warranty Alerts`}
            isLoading={isLoading}
            viewDetails={true}
            viewDetailsEnabled={false}>

            <WarrantyAlertSummerySection
                totalRepairOrderCount={TotalRepairOrders}
                totalDollarAmount={TotalDollarAmount}
                totalLostDollarAmount={TotalLostDollarAmount}/>

            <WarrantyAlertUserStatsSection
                statsByUser={ByUser}/>
        </ReportingContainer>
    )
}