import { Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardHeader,
  IconButton,
} from "@mui/material";
import { WarrantyAlertRecipient } from "api/types/WarrantyAlertRecipient";
import { deleteRecipient } from "modules/dealerForm";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import formatPhone from "utils/formatPhone";

interface IWARecipientsListItemProps {
  waRecipient: WarrantyAlertRecipient;
  openModal: () => void;
  index: number;
}

const WARecipientsListItem = ({
  waRecipient,
  openModal,
  index
}: IWARecipientsListItemProps) => {
  const [showActions, setShowActions] = useState(false);

  const dispatch = useDispatch();

  const handleToggleShowActions = () => {
    setShowActions(!showActions);
  };

  const handleDelete = (i) => {
    dispatch(deleteRecipient(i));
  };

  return (
    <>
      <Box sx={{ width: "270px", mr: 1, mb: 1 }}>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#26387A" }}>
                {`${waRecipient.FirstName.charAt(
                  0
                )}${waRecipient.LastName.charAt(0)}`}
              </Avatar>
            }
            action={
              <IconButton onClick={handleToggleShowActions}>
                <MoreVert />
              </IconButton>
            }
            title={`${waRecipient.FirstName} ${waRecipient.LastName}`}
            subheader={
              formatPhone(waRecipient.CellPhone) || "No cellphone assigned."
            }
            titleTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "155px",
              noWrap: true,
            }}
            subheaderTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "155px",
            }}
          />
          {showActions && (
            <CardActions disableSpacing>
              <IconButton onClick={() => openModal()}>
                <Edit />
              </IconButton>

              <IconButton onClick={() => handleDelete(index)}>
                <Delete />
              </IconButton>
            </CardActions>
          )}
        </Card>
      </Box>
    </>
  );
};

export default WARecipientsListItem;
