import { User } from "api/types/user";
import apiFetch from "api/utils/APIFetch";

const putDealerUser = (dealerID: number, user: User): Promise<void> =>
  apiFetch(`/api/dealers/${dealerID}/users`, {
    method: `PUT`,
    body: JSON.stringify(user),
  });

export default putDealerUser;
