import React from 'react'
import { Box } from '@mui/material'
import { titleCase } from 'voca'

interface IVehicleDescriptionCell {
    description: string
    odometer: number
}

function VehicleDescriptionCell({
    description,
    odometer,
}: IVehicleDescriptionCell) {
    const formattedOdometer = odometer.toLocaleString()

    return (
       <Box>
           <Box>{titleCase(description)}</Box>
           <Box>{formattedOdometer} Miles</Box>
       </Box>
    )
}

export default React.memo(VehicleDescriptionCell)