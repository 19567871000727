import { Message } from 'api/types/message'
import apiFetch from 'api/utils/APIFetch'
import Modes from 'components/CommunicationDashboard/modes'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'

const getCommunicationDashboardMessages = async (
    dealerID: number,
    mode: Modes,
    startDate: string,
    endDate: string, 
): Promise<Message[]> => {
    const startString = moment(startDate, US_DATE_FORMAT).format(US_DATE_FORMAT)
    const endString = moment(endDate, US_DATE_FORMAT).add({ days: 1 }).format(US_DATE_FORMAT)

    return await apiFetch(`/api/communication-dashboard/${dealerID}/${resolveURL(mode)}/${startString}/${endString}`)

}


function resolveURL(mode: Modes) {
    switch (mode) {
        case Modes.RECEIVED:
            return `received`
        case Modes.SENT:
            return `sent`
        case Modes.UNANSWERED:
            return `unanswered`
        case Modes.AUTOMATED:
            return `automated`
        default:
            return `received`
    }
}

export default getCommunicationDashboardMessages