import React from 'react'
import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material'
import { useAppSelector } from 'store'
import Header from '../Header'
import SideBar from '../SideBar'
import MainPanel from '../MainPanel'
import { Modes } from 'components/ChatModule/modules'
import theme from 'assets/theme'
import ErrorIcon from '@mui/icons-material/ErrorOutline'

function BaseView() {
    const {
        mode,
    } = useAppSelector(state => state.chat)

    const showDefault = mode !== Modes.LOADING && mode !== Modes.CRITICAL_ERROR

    const defaultContent = <>
        <Header />
        <Divider />
        <Box
            display={`grid`}
            gridTemplateColumns={`200px min-content 1fr`}
            overflow={`hidden`}
        >
            <SideBar />
            <Divider orientation={`vertical`} />
            <MainPanel />
        </Box>
    </>

    const loadingContent = <Box
        display={`grid`}
        justifyItems={`center`}
        alignContent={`center`}
        rowGap={theme.spacing(1)}
    >
        <CircularProgress/>
        <Typography>Initializing...</Typography>
    </Box>

    const errorContent = <Box
        display={`grid`}
        justifyItems={`center`}
        alignContent={`center`}
        rowGap={theme.spacing(1)}
        p={2}
    >
        <ErrorIcon color={`error`} style={{fontSize: `50px`}}/>
        <Typography variant={`body2`} align={`center`}>A critical error has occured. Please contact support if the problem persists.</Typography>
    </Box>

    const content = () => {
        switch (mode) {
            case Modes.CRITICAL_ERROR:
                return errorContent
            case Modes.LOADING:
                return loadingContent
            default:
                return defaultContent
        }
    }

    return (
        <Paper
            elevation={5}
            style={{
                height: `450px`,
                width: `600px`,
                position: `fixed`,
                bottom: `70px`,
                right: `85px`,
                display: `grid`,
                gridTemplateRows: showDefault ? `min-content min-content 1fr` : `1fr`,
                overflow: `hidden`,
            }}
        >{content()}</Paper>
    )
}

export default BaseView