const authenticateUser = ({email, password}): Promise<Response> => fetch(`https://slea-dev.us.auth0.com/oauth/token`, {
    method: 'POST',
    headers: {
        'Content-Type':'application/json'
    },
    body: JSON.stringify({
        "client_id":"neuMQ9OPwXo0TjvOJIoIagCXzpKkMPyD",
        "client_secret":"A74I-GrAs_BziV9kL7AnrgjucpeNYiE8wmhNuKMnn09sjTef372pfjs_inAgTJYs",
        "grant_type":"password",
        "username": email,
        "password": password,
        "audience": "https://slea-dev.us.auth0.com/api/v2/",
        "scope": "openid profile email address phone"
    })
})

export default authenticateUser