import React, { useCallback } from 'react'
import RepairOrderListView from 'components/RepairOrderListView'
import { Redirect, Route, Switch } from 'react-router'
import AppRoute from 'components/AppRoute'
import pages from 'pages'
import CommunicationDashboard from 'components/CommunicationDashboard'
import commDashModes from 'components/CommunicationDashboard/modes'
import useSubscribeToDashboardData from 'components/CommunicationDashboard/hooks/useSubscribeToData'
import useManageViews from './hooks/useManageViews'
import NavButtons from './components/NavButtons'
import DateSelector from './components/DateSelector'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import styles from './styles.module.scss'
import { ISetDates } from 'types/ISetDates'
import setDates from './thunks/setDates'

function Dashboard() {
    const dispatch = useAppDispatch()
    useSubscribeToDashboardData()
    useManageViews()

    const view = useAppSelector(state => state.dashboard.view)

    const {
        isLoading,
        startDate,
        endDate
    } = useAppSelector(state => state.dashboard)

    const onDateRangeApplied = useCallback((args: ISetDates) => {
        dispatch(setDates(args))
    }, [dispatch])
    
    return (
        <>
            <Box className={styles.pageNavigation}>
                <DateSelector 
                    isLoading={isLoading}
                    startDate={startDate}
                    endDate={endDate}
                    onDateRangeApplied={onDateRangeApplied}/>
                <NavButtons type={view} />
            </Box>
            <Switch>
                <AppRoute exact path={pages.REPAIR_ORDERS.route} page={pages.REPAIR_ORDERS}>
                    <RepairOrderListView/>
                </AppRoute>
                <AppRoute exact path={pages.SENT_MESSAGES.route} page={pages.SENT_MESSAGES}>
                    <CommunicationDashboard
                        mode={commDashModes.SENT}
                    ></CommunicationDashboard>
                </AppRoute>
                <AppRoute exact path={pages.RECIEVED_MESSAGES.route} page={pages.RECIEVED_MESSAGES}>
                    <CommunicationDashboard
                        mode={commDashModes.RECEIVED}
                    ></CommunicationDashboard>
                </AppRoute>
                <AppRoute exact path={pages.UNANSWERED_MESSAGES.route} page={pages.UNANSWERED_MESSAGES}>
                    <CommunicationDashboard
                        mode={commDashModes.UNANSWERED}
                    ></CommunicationDashboard>
                </AppRoute>
                <AppRoute exact path={pages.AUTOMATED_MESSAGES.route} page={pages.AUTOMATED_MESSAGES}>
                    <CommunicationDashboard
                        mode={commDashModes.AUTOMATED}
                    ></CommunicationDashboard>
                </AppRoute>
                <Route path={`*`}>
                    <Redirect to={`/dashboard`}/>
                </Route>
            </Switch>
        </>
        
    );
}

export default Dashboard
