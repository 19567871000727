import React from 'react' 
import { useAppSelector } from 'store'
import { Modes } from 'components/ChatModule/modules'
import PanelListView from '../PanelListView'
import ConversationPanel from '../ConversationPanel'
import { Box } from '@mui/material'

function MainPanel() {
    const {
        mode,
    } = useAppSelector(state => state.chat)

    switch (mode) {
        case Modes.SELECT_RECIPIENT:
        case Modes.SELECT_REPAIR_ORDER:
            return (
                <PanelListView/>
            )
        case Modes.CONVERSATION:
            return (
                <ConversationPanel/>
            )
        default: 
            return (
                <Box></Box>
            )
    }
}

export default MainPanel