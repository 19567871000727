
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import getDealerAppointmentsByDateRangeData from "components/Scheduling/thunks/getDealerAppointmentsByDateRangeData";

export default function useSubscribeToSchedulingOverview() {
    const dispatch = useAppDispatch()
    
    const {
        startDate,
        endDate,
    } = useAppSelector(
        state => state.scheduling
    )

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    useEffect(() => {
        dispatch(getDealerAppointmentsByDateRangeData({dealerID, startDate, endDate}))
    }, [startDate, endDate, dispatch, dealerID])
}