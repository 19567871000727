import React, { useEffect } from 'react'
import { Box, Button, Divider, List } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import { conversationSelected, conversationsSelectors, gotoStepOne, Modes } from 'components/ChatModule/modules'
import ConversationListItem from '../ConversationListItem'

function SideBar() {
    const dispatch = useAppDispatch()

    const {
        filterValue,
        selectedID,
        mode,
    } = useAppSelector(state => state.chat)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const conversations = useAppSelector(conversationsSelectors.selectAll)

    const handleStartChatClick = () => {
        dispatch(gotoStepOne(dealerID))
    }

    useEffect(() => {
        if (selectedID === 0 && mode === Modes.DEFAULT && conversations.length > 0) {
            dispatch(conversationSelected(conversations[0].id))
        }
    }, [selectedID, mode, conversations, dispatch])

    const listItems = conversations.filter(conversation => conversation.repairOrderNumber.toLowerCase().includes(filterValue.toLowerCase())).map(conversation => {
        const {
            id,
            repairOrderNumber,
            lastMessageSentTimeStamp,
            users,
        } = conversation
        return (
            <ConversationListItem
                key={id}
                id={id}
                users={users}
                roNumber={repairOrderNumber}
                lastReadText={lastMessageSentTimeStamp}
                onClick={() => dispatch(conversationSelected(id))}
                selected={id === selectedID}
            ></ConversationListItem>
        )
    })

    return (
        <Box
            display={`grid`}
            gridTemplateRows={`min-content min-content 1fr`}
            overflow={`hidden`}
        >
            <Box px={2} py={1}>
                <Button
                    color={`primary`}
                    variant={`contained`}
                    size={`small`}
                    onClick={handleStartChatClick}
                >Start Chat</Button>
            </Box>
            <Divider/>
            <List 
                dense
                disablePadding
                style={{
                    overflowY: `auto`,
                }}
            >{listItems}</List>
        </Box>
    )
}

export default SideBar