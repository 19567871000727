import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import { green, red, yellow } from '@mui/material/colors'
import { Box } from '@mui/material'
import TimeIcon from '@mui/icons-material/AccessTime'
import useInterval from 'hooks/useInterval'
import { Status } from 'types/repairOrder'

export interface IPromiseTimeCell {
    dateTime: string
    roStatus: Status
}

export enum colors {
    UNKNOWN,
    GREEN,
    YELLOW,
    RED,
}

function PromiseTimeCell({
    dateTime,
    roStatus,
}: IPromiseTimeCell) {
    const [colorType, setColorType] = useState<colors>(colors.UNKNOWN)

    const [dateValue, timeValue] = useMemo(() => {
        if (dateTime === null || dateTime === ``) {
            return [
                "N/A", 
                ""
            ]
        }
        else {
            return [
                moment.utc(dateTime).format(`M/D`), 
                moment.utc(dateTime).format(`h:mma`),
            ]
        }
    }, [dateTime])
    
    const color = useMemo(() => {
        switch (colorType) {
            case colors.GREEN:
                return green[700]
            case colors.YELLOW:
                return yellow[700]
            case colors.RED:
                return red[700]
            default:
                return ``
        }
    }, [colorType])

    const checkColor = useCallback(
        () => {
            if (dateTime === null || dateTime === ``) {
                setColorType(colors.UNKNOWN)
            } else {
                const now = moment()
                const promiseTime = moment(dateTime.slice(0, -4))
                if (promiseTime.isBetween(now, moment().add(15, `minutes`))) {
                    setColorType(colors.YELLOW)
                } else if (promiseTime.isSameOrBefore(now)) {
                    setColorType(colors.RED)
                } else {
                    setColorType(colors.GREEN)
                } 
            }
             
        }, [dateTime],
    )

    useInterval(() => {
        checkColor()
    }, 10000)

    useEffect(() => {
        checkColor()
    }, [checkColor])

    return (
        <Box
            display={`grid`}
            columnGap={10}
            gridAutoFlow={`column`}
            justifySelf={`start`}
            alignItems={`center`}
            justifyContent={`space-between`}
            width={`100%`}
        >
            <Box>   
                <Box>{dateValue}</Box>
                <Box>{timeValue}</Box>
            </Box>
            {roStatus === Status.OPEN &&
                <TimeIcon 
                    fontSize={`small`}
                    htmlColor={color}
                ></TimeIcon>
            }
        </Box>
    );
}

export default React.memo(PromiseTimeCell)