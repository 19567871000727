import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'

const Tab = ({
    text,
    desc,
    height,
    active,
    onClick,
    isClickable,
    customTextStyles
}) => {
    const calcHeight = active ? height + 2 : height
    
    const containerClassNames = classnames(
        styles.container,
        {[styles.clickable]: isClickable}
    )

    const textClassNames = classnames(
        styles.text,
        {active}
    )

    return (
        <div 
            style={{height: calcHeight}}
            className={containerClassNames}
            onClick={onClick ? onClick : null}
        >
           <div style={customTextStyles} className={textClassNames}>{text}</div>
           <div className={styles.desc}>{desc}</div>
        </div>
    )
}

Tab.propTypes = {
    text: PropTypes.string,
    desc: PropTypes.string,
    height: PropTypes.number,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    isClickable: PropTypes.bool,
    customTextStyles: PropTypes.object
}

Tab.defaultProps = {
    text: ``,
    desc: ``,
    active: false,
    customTextStyles: {}
}


export default Tab