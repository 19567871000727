import React from "react";
import { Route, Switch } from "react-router-dom";
import pages from "pages";

import AppRoute from "components/AppRoute";
import DealerListView from "components/DealerListView";
import DealerForm from "components/DealerForm";

function DealerRouter() {
  return (
    <Switch>
      <AppRoute exact path={pages.DEALERS.route} page={pages.DEALERS}>
        <DealerListView />
      </AppRoute>
      <Route path={pages.DEALERS_CREATE_FORM.route} component={DealerForm} />
      <Route path={pages.DEALERS_EDIT_FORM.route} component={DealerForm} />
    </Switch>
  );
}

export default DealerRouter;
