import { SmsReportingOverviewViewModel } from 'api/types/reporting/SmsReportingOverviewViewModel';
import theme from 'assets/theme';
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

export interface IPieChartDisplayProps {
    sentWelcomeMessages: boolean,
    sentMessages: boolean,
    receivedMessages: boolean,
    unansweredMessages: boolean
}

export interface ISmsSummerySectionContainerProps {
    smsData: SmsReportingOverviewViewModel,
    pieChartDisplayProps: IPieChartDisplayProps
}

export default function SmsPieChart({
    smsData,
    pieChartDisplayProps
}: ISmsSummerySectionContainerProps){

    const containsData = 
        (smsData.TotalSentWelcomeMessages +
        smsData.TotalSentMessages +
        smsData.TotalReceivedMessages +
        smsData.TotalUnansweredMessages) > 0

    const data = [
        {name: `Sent Welcome Messages`, value: smsData.TotalSentWelcomeMessages, display: pieChartDisplayProps.sentWelcomeMessages, color: theme.palette.primary.dark},
        {name: `Sent Messages`, value: smsData.TotalSentMessages, display: pieChartDisplayProps.sentMessages, color: theme.palette.primary.light},
        {name: `Received Messages`, value: smsData.TotalReceivedMessages, display: pieChartDisplayProps.receivedMessages, color: theme.palette.secondary.dark},
        {name: `Unanswered Messages`, value: smsData.TotalUnansweredMessages, display: pieChartDisplayProps.unansweredMessages, color: theme.palette.secondary.light},
    ]

    const pieGraph = (
        <ResponsiveContainer width={`100%`} height={150}>
            <PieChart>
                <Pie
                    data={data.filter(item => item.display).map(item => { return { name: item.name, value: item.value }})}
                    cx={`50%`}
                    cy={`85%`}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={75}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    nameKey="name">

                    {data.filter(item => item.display).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )

    return (
        containsData ? 
            pieGraph : 
            null
        
    )
}