import React from 'react'
import styles from './styles.module.scss'
import NumberWithWord from 'components/BdcDataview/components/NumberWithWord'
import Overlay from 'components/Overlay'
import { useAppDispatch, useAppSelector } from 'store'
import PieChart from 'components/PieChart'
import TabBar from 'components/TabBar'
import {
    setSelectedTasksTab,
} from 'modules/bdcDashboard'
import useSubscribeToOverviewData from 'components/BdcDataview/hooks/useSubscribeToOverviewData'

function TasksOverview() {
    const dispatch = useAppDispatch()
    const {
        selectedTasksTab,
        tasksNew,
        tasksCompleted,
        isLoadingOverviewData: isLoading,
        isErrorOverviewData: isError,
        tasksAppointments: appointments,
    } = useAppSelector(
        state => state.bdcDashboard
    )
    const {
        EnableAppointments,
    } = useAppSelector(state => state.dealer)

    useSubscribeToOverviewData()

    const handleOverviewTabOnClick = (val: number) => {
        dispatch(setSelectedTasksTab(val))
    }

    let total = tasksNew + tasksCompleted + appointments

    const slices = [
        {
            amount: tasksNew - tasksCompleted
        },
        {
            amount: tasksCompleted
        },
    ]

    if (EnableAppointments) {
        slices.push({
            amount: appointments
        })
        total = total + appointments
    }

    return (
        <div className={styles.container}>
            <TabBar
                tabsArray={[
                    {
                        text: `Today`
                    },
                    {
                        text: `Yesterday`
                    },
                    {
                        text: `This Week`
                    }
                ]}
                activeTabIndex={selectedTasksTab}
                onClick={handleOverviewTabOnClick}
            ></TabBar>
            <div className={styles.dataWrapper}>
                <div className={styles.topWrapper}>
                    <div className={styles.headline}>{total} Tasks</div>
                    <PieChart
                        slices={slices}
                        options={{
                            donut: true,
                            donutWidth: 40,
                            showLabel: false
                        }}
                    ></PieChart>
                </div>
                <div className={styles.bottomWrapper}>
                    <NumberWithWord
                        number={tasksNew}
                        text={`New Tasks`}
                        color={`#263b77`}
                    ></NumberWithWord>
                    <NumberWithWord
                        number={tasksCompleted}
                        text={`Completed`}
                        color={`#59A651`}
                    ></NumberWithWord>
                    {EnableAppointments &&
                        <NumberWithWord
                            number={appointments}
                            text={`Appointments`}
                            color={`#E38032`}
                        ></NumberWithWord>
                    }
                </div>
                <Overlay
                    show={isLoading || isError}
                    isLoading={isLoading}
                    isError={isError}
                    text={isError ? `An error has occurred retrieving overview data!` : `Retrieving overview data...`}
                ></Overlay>
            </div>
        </div>
    )
}

export default TasksOverview
