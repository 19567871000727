import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import theme from 'assets/theme'
import React from 'react'

export interface IDataTableProps{
    rows: any[],
    columns: GridColDef[],
    hideHeader?: boolean
}

export default function DataTable({
    rows,
    columns,
    hideHeader = false
}: IDataTableProps){
    return (
        rows.length > 0 ? 
          <Box>
            <DataGrid
              sx={{
                fontSize: '13px',
                borderRadius: '0px',
                '& .css-1iyq7zh-MuiDataGrid-columnHeaders': {
                  borderRadius: '0px !important'
                },
                '& .header-cell': {
                  backgroundColor: grey[100],
                  color: theme.palette.primary.light,
                }
              }}
              density={`compact`} 
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              hideFooter={true}
              columnHeaderHeight={hideHeader ? 0 : 60}
              columnVisibilityModel={{
                id: false
              }}
            />
          </Box> : null
    )
}