import {
    createEntityAdapter,
    createSlice, EntityState, PayloadAction, 
} from '@reduxjs/toolkit'
import getChatConversationSummaries from 'api/getChatConversationSummaries'
import getChatRepairOrders from 'api/getChatRepairOrders'
import createChatConversaionViaApi from 'api/createChatConversation'
import getChatUsers from 'api/getChatUsers'
import { mapToChatConversationSummary } from 'api/types/chatConversationSummary'
import { mapToChatRepairOrder } from 'api/types/chatRepairOrder'
import { mapToChatUser } from 'api/types/chatUser'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import { IChatConversationSummary } from 'types/chatConversationSummary'
import { IChatRepairOrder } from 'types/chatRepairOrder'
import { IChatUser } from 'types/chatUser'
import getUnreadMessageCounts, { UnreadMessageCounts } from 'api/getUnreadMessageCounts'

type UnreadMessageCount = {
    id: number
    count: number
}

export enum SortByTypes {
    RECENT,
    RO,
}

export enum Modes {
    LOADING,
    DEFAULT,
    CONVERSATION,
    SELECT_RECIPIENT,
    SELECT_REPAIR_ORDER,
    CRITICAL_ERROR,
}

const usersAdapter = createEntityAdapter<IChatUser>()
const rosAdapter = createEntityAdapter<IChatRepairOrder>()
const conversationsAdapter = createEntityAdapter<IChatConversationSummary>()
const unreadMessageCountAdapter = createEntityAdapter<UnreadMessageCount>()

interface IChat {
    isOpen: boolean
    isError: boolean
    infoText: string
    mode: Modes
    sortBy: SortByTypes
    filterValue: string
    subFilterValue: string
    users: EntityState<IChatUser>
    repairOrders: EntityState<IChatRepairOrder>
    unreadMessageCounts: EntityState<UnreadMessageCount>
    selectedID: number
    selectedUserID: number
}

let initialState: IChat = {
    isOpen: false,
    isError: false,
    infoText: ``,
    mode: Modes.LOADING,
    sortBy: SortByTypes.RECENT,
    filterValue: ``,
    subFilterValue: ``,
    users: usersAdapter.getInitialState(),
    repairOrders: rosAdapter.getInitialState(),
    unreadMessageCounts: unreadMessageCountAdapter.getInitialState(),
    selectedID: 0,
    selectedUserID: 0,
}

const slice = createSlice({
    name: `chat`,
    initialState: conversationsAdapter.getInitialState(initialState),
    reducers: {
        initializeStarted(state) {
            state.mode = Modes.LOADING
        },
        initializeSucceded(state) {
            state.mode = Modes.DEFAULT
        },
        initializeFailed(state) {
            state.mode = Modes.CRITICAL_ERROR
        },
        chatOpened(state) {
            state.isOpen = true
        },
        chatClosed(state) {
            state.isOpen = false
            state.selectedID = 0
            state.mode = Modes.DEFAULT
        },
        sortBySet(state, action: PayloadAction<SortByTypes>) {
            state.sortBy = action.payload
        },
        filterValueSet(state, action: PayloadAction<string>) {
            state.filterValue = action.payload
        },
        subFilterValueSet(state, action: PayloadAction<string>) {
            state.subFilterValue = action.payload
        },
        stepOneStarted(state) {
            state.selectedID = 0
            usersAdapter.removeAll(state.users)
            state.isError = false
            state.infoText = ``
        },
        stepOneSucceeded(state, action: PayloadAction<IChatUser[]>) {
            usersAdapter.setAll(state.users, action.payload)
            state.mode = Modes.SELECT_RECIPIENT
        },
        stepOneFailed(state) {
            state.mode = Modes.CRITICAL_ERROR
        },
        stepTwoStarted(state, action: PayloadAction<number>) {
            state.selectedUserID = action.payload
            rosAdapter.removeAll(state.repairOrders)
            state.isError = false
            state.infoText = ``
        },
        stepTwoSucceeded(state, action: PayloadAction<IChatRepairOrder[]>) {
            rosAdapter.setAll(state.repairOrders, action.payload)
            state.mode = Modes.SELECT_REPAIR_ORDER
        },
        stepTwoFailed(state) {
            state.mode = Modes.CRITICAL_ERROR
        },
        createConversationStarted(state) {
            state.isError = false
            state.infoText = ``
        },
        createConversationSucceeded(state, action: PayloadAction<{
            selectedID: number,
            conversations: IChatConversationSummary[],
        }>) {
            const {
                selectedID,
                conversations,
            } = action.payload

            state.selectedUserID = 0
            conversationsAdapter.setAll(state, conversations)
            state.selectedID = selectedID
            state.mode = Modes.DEFAULT
        },
        createConversationFailed(state) {
            state.mode = Modes.CRITICAL_ERROR
        },
        wentBack(state) {
            switch (state.mode) {
                case Modes.SELECT_RECIPIENT:
                    state.mode = Modes.DEFAULT
                    break
                case Modes.SELECT_REPAIR_ORDER:
                    state.mode = Modes.SELECT_RECIPIENT
                    break
            }
        },
        conversationSelected(state, action: PayloadAction<number>) {
            state.selectedID = action.payload
            unreadMessageCountAdapter.removeOne(state.unreadMessageCounts, action.payload)
            state.mode = Modes.CONVERSATION
        },
        errored(state) {
            state.mode = Modes.CRITICAL_ERROR
        },
        unreadMessageCountUpdated(state, action: PayloadAction<UnreadMessageCounts>) {
            const {
                ConversationIDsWithUnreadMessages: counts,
            } = action.payload

            const countsToUpdate = counts.filter(count => count.ID !== state.selectedID)
            
            unreadMessageCountAdapter.setAll(state.unreadMessageCounts, countsToUpdate.map(countObj => ({
                id: countObj.ID,
                count: countObj.Count,
            })))
        },
        unreadMessageCountRemoved(state, action: PayloadAction<number>) {
            unreadMessageCountAdapter.removeOne(state.unreadMessageCounts, action.payload)
        },
        conversationsUpdated: conversationsAdapter.setAll,
    }
})

export const {
    initializeStarted,
    initializeSucceded,
    initializeFailed,
    chatOpened,
    chatClosed,
    sortBySet,
    filterValueSet,
    subFilterValueSet,
    stepOneStarted,
    stepOneSucceeded,
    stepOneFailed,
    stepTwoStarted,
    stepTwoSucceeded,
    stepTwoFailed,
    createConversationStarted,
    createConversationSucceeded,
    createConversationFailed,
    wentBack,
    conversationSelected,
    errored,
    unreadMessageCountUpdated,
    unreadMessageCountRemoved,
    conversationsUpdated,
} = slice.actions

export const usersSelectors = usersAdapter.getSelectors<RootState>(state => state.chat.users)
export const rosSelectors = rosAdapter.getSelectors<RootState>(state => state.chat.repairOrders)
export const conversationsSelectors = conversationsAdapter.getSelectors<RootState>(state => state.chat)
export const unreadMessageCountSelectors = unreadMessageCountAdapter.getSelectors<RootState>(state => state.chat.unreadMessageCounts)

export default slice.reducer

export const initialize = (): AppThunk => async dispatch => {
    try {

        dispatch(initializeStarted())

        dispatch(updateChatConversation())

        dispatch(updateUnreadChatMessageCounts())

        dispatch(initializeSucceded())
        
    } catch (error) {
        dispatch(initializeFailed())
        throw new Error(`Initialization of chat module failed with the following error: ${error}`)
    }
}

export const updateChatConversation = (): AppThunk => async dispatch => {
    try {

        getChatConversationSummaries().then(conversations => {
            dispatch(conversationsUpdated(conversations.map(mapToChatConversationSummary)))
        })

    } catch (error) {
        dispatch(errored())
        throw new Error(`An error occured while attempting to update a conversation after signalr message: ${error}`)
    }
}

export const updateUnreadChatMessageCounts = (): AppThunk => async dispatch => {
    try {

        getUnreadMessageCounts().then(counts => {
            dispatch(unreadMessageCountUpdated(counts))
        })

    } catch (error) {
        dispatch(errored())
        throw new Error(`An error occured while attempting to update a conversation counts after signalr message: ${error}`)
    }
}

export const gotoStepOne = (dealerID: number): AppThunk => async dispatch => {
    try {
        dispatch(stepOneStarted())
        const users = await getChatUsers(dealerID)
        dispatch(stepOneSucceeded(users.map(mapToChatUser)))
    } catch (error) {
        dispatch(stepOneFailed())
        throw new Error(`An error occured while attempting to retrieve users: ${error}`)
    }
}

export const gotoStepTwo = (dealerID: number, userID: number): AppThunk => async dispatch => {
    try {
        dispatch(stepTwoStarted(userID))
        const repairOrders = await getChatRepairOrders(dealerID)
        dispatch(stepTwoSucceeded(repairOrders.map(mapToChatRepairOrder)))
    } catch (error) {
        dispatch(stepTwoFailed())
        throw new Error(`An error occured while attempting to retrieve chat ros: ${error}`)
    }
}

export const createChatConversation = (dealerID: number, repairOrderID: number, userIDs: number[]): AppThunk => async dispatch => {
    try {
        
        dispatch(createConversationStarted())

        const conversationID = await createChatConversaionViaApi(dealerID, repairOrderID, userIDs)
        const conversations = await getChatConversationSummaries()
        dispatch(createConversationSucceeded({
            selectedID: conversationID, 
            conversations: conversations.map(mapToChatConversationSummary)
        }))
    } catch (error) {
        dispatch(createConversationFailed())
        throw new Error(`An error occured while attempting to create a conversation: ${error}`)
    }
}