import React from 'react'
import { Portal, Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

type Props = {
    open: boolean
    message: string
    onClose(): void
    alertProps?: AlertProps
}

function ErrorSnackbar({
    open,
    message,
    onClose,
    alertProps,
}: Props) {
    return (
        <Portal>
            <Snackbar open={open} autoHideDuration={null} onClose={onClose}>
                <MuiAlert 
                    elevation={6}
                    variant={`filled`}
                    severity={`error`}
                    onClose={onClose}
                    {...alertProps}
                >{message}</MuiAlert>
            </Snackbar>
        </Portal>
    )
}

export default ErrorSnackbar