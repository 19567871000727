import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

//import components
import InspectionCheckboxCell from 'components/InspectionCheckboxCell'
import InspectionRadioCell from 'components/InspectionRadioCell'

const InspectionServicesLine = ({
    id,
    name,
    serviceType,
    hours,
    labor,
    parts,
    total,
    onDMSChange,
    onValidChange,
    onDoneChange,
}) => {  
    return (
        <Fragment>
            <InspectionCheckboxCell
                id={`dms-${id}`}
                onChange={onDMSChange}
            ></InspectionCheckboxCell>
            <InspectionRadioCell
                id={`validCheck-${id}`}
                onChange={onValidChange}
            ></InspectionRadioCell>
            <InspectionCheckboxCell
                id={`done-${id}`}
                onChange={onDoneChange}
            ></InspectionCheckboxCell>
            <div>{name}</div>
            <div>{serviceType}</div>
            <div>{hours}</div>
            <div>{labor}</div>
            <div>{parts}</div>
            <div>{total}</div>
        </Fragment>
    )
}

InspectionServicesLine.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    name: PropTypes.string,
    serviceType: PropTypes.string,
    hours: PropTypes.string,
    labor: PropTypes.string,
    parts: PropTypes.string,
    total: PropTypes.string,
    onDMSChange: PropTypes.func,
    onValidChange: PropTypes.func,
    onDoneChange: PropTypes.func,
}

export default InspectionServicesLine