import { Box, Button } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import React, { useState } from "react";
import { useAppSelector } from "store";
import WARecipientListItem from "./components/WARecipientListItem";
import WARecipientForm, {
  IWARecipientFormProps,
} from "./components/WARecipientForm";
import { Add } from "@mui/icons-material";
import { WarrantyAlertRecipient } from "api/types/WarrantyAlertRecipient";
import EmptyRecipientList from "./components/EmptyRecipientsList";

const WarrantyAlertRecipients = () => {
  const { waRecipients, isLoading } = useAppSelector(
    (state) => state.dealerForm
  );

  const [dialog, setDialog] = useState<IWARecipientFormProps>({
    open: false,
  } as IWARecipientFormProps);

  const toggleDialog = (
    index: number,
    waRecipient?: WarrantyAlertRecipient
  ) => {
    setDialog({
      ...dialog,
      index: index,
      open: !dialog.open,
      waRecipient: waRecipient ?? dialog.waRecipient
    });
  };

  return (
    <>
      <Box sx={{display: `flex`, flexDirection: `row`}}>
        <h1>Warranty Alert Recipients</h1>
        {waRecipients && waRecipients.length > 0 && (
            <Button
              variant="contained"
              sx={{
                margin: `20px`
              }}
              onClick={() => toggleDialog(-1, {} as WarrantyAlertRecipient)}>
                <Add />
          </Button>
        )}
      </Box>

      {isLoading && <LoadingIndicator />}

      {waRecipients && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
          }}
        >
          {waRecipients &&
            waRecipients?.map((waRecipient, i) => (
              <WARecipientListItem
                key={waRecipient.ID}
                waRecipient={waRecipient}
                index={i}
                openModal={() => toggleDialog(i, waRecipient)}
              />
            ))}
        </Box>
      )}

      {!waRecipients.length && (
        <EmptyRecipientList {...dialog} toggleDialog={() => toggleDialog(-1)} />
      )}

      <WARecipientForm {...dialog} toggleDialog={() => toggleDialog(-1)} />
    </>
  );
};

export default WarrantyAlertRecipients;
