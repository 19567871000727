import React, { useMemo } from 'react'
import { StatusTypes } from 'classes/CallGroup'
import classNames from 'classnames'

import styles from './styles.module.scss'

export interface ICallStatusInput {
    id: string
    label: string
    value: any
    isChecked: boolean
    onClick(value: StatusTypes): any
}

function CallStatusInput({
    id,
    label,
    value,
    isChecked,
    onClick,
}: ICallStatusInput) {
    const handleClick = () => {
        onClick(value)
    }

    const check = useMemo(
        () => {
            if (isChecked) {
                return <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={styles.icon}>
                <path fill={`white`} d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path>
            </svg>
            } else {
                return null
            }
        }
    , [isChecked])

    return (
        <div className={styles.container}>
            <button 
                id={id}
                className={classNames(
                    styles.box,
                    {
                        [styles.isChecked] : isChecked
                    }
                )}
                onClick={handleClick}
            >{check}</button>
            <label 
                className={styles.label}
                htmlFor={id}
            >{label}</label>
        </div>
    )
}

export default CallStatusInput