import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const CircleWordWithNumber = ({
    number,
    text,
    color
}) => {
    return (
        <div style={{borderColor: color}} className={styles.container}>
            <div style={{color}} className={styles.number}>{number}</div>
            <div style={{color}} className={styles.text}>{text}</div>
        </div>
    )
}

CircleWordWithNumber.propTypes = {
    number: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string
}

export default CircleWordWithNumber
