import CopyrightFooter from 'components/CopyrightFooter'
import React from 'react'
import { useAppSelector } from 'store'
import { Status } from 'types/signalr'

import styles from './styles.module.scss'

function AppFooter() {
    const status = useAppSelector(state => state.app.signalrStatus)

    const signalrStatusNotice = () => {
        if (process.env.NODE_ENV === `development`) {
            const textColor = (status === Status.CONNECTED) ? 'green' :
                (status === Status.CONNECTING) ? 'orange' :
                /* else */ 'red'
            return (
                <div>SignalR status: {<span style={{ color: textColor }}>{status}</span>}</div>
            )
        }
        return null
    }

    return (
        <div className={styles.container}>
            <CopyrightFooter/>
            {signalrStatusNotice()}
        </div>
    )
}

export default AppFooter