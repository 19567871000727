import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { Appointment } from 'api/types/appointment'
import getDealerAppointmentsByDateRangeData from 'components/Scheduling/thunks/getDealerAppointmentsByDateRangeData'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'
import { ISetDateRangeResponse } from 'types/ISetDateRangeResponse'
import { ISetTimePeriodResponse } from 'types/ISetTimePeriodResponse'
import setDates from '../../thunks/setDates'
import setTimePeriod from 'components/Scheduling/thunks/setTimePeriod'

export interface ISchedulingOverviewState {
    isLoading: boolean,
    isError: boolean,
    startDate: string,
    endDate: string,
    appointments: Appointment[],
    timePeriod: string
}

let initialState: ISchedulingOverviewState = {
    isLoading: false,
    isError: false,
    startDate: moment().format(US_DATE_FORMAT),
    endDate: moment().format(US_DATE_FORMAT),
    appointments: [],
    timePeriod: "day"
}

const slice = createSlice({
    name: `scheduling`,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getDealerAppointmentsByDateRangeData.pending, (state) => {
            state.isLoading = true
            state.isError = false
        })
        builder.addCase(getDealerAppointmentsByDateRangeData.fulfilled, (state, action: PayloadAction<Appointment[]>) => {
            state.isLoading = false
            state.isError = false
            state.appointments = action.payload
        })
        builder.addCase(getDealerAppointmentsByDateRangeData.rejected, (state) => {
            state.isLoading = false
            state.isError = true
        })
        builder.addCase(setDates.pending, (state) => {
            state.isError = false
        })
        builder.addCase(setDates.fulfilled, (state, action: PayloadAction<ISetDateRangeResponse>) => {
            const {
                startDate,
                endDate
            } = action.payload

            state.isError = false
            state.startDate = startDate
            state.endDate = endDate
        })
        builder.addCase(setDates.rejected, (state) => {
            state.isError = true
        })
        builder.addCase(setTimePeriod.pending, (state) => {
            state.isError = false
        })
        builder.addCase(setTimePeriod.fulfilled, (state, action: PayloadAction<ISetTimePeriodResponse>) => {
            const {
                startDate,
                endDate,
                timePeriod
            } = action.payload

            state.isError = false
            state.startDate = startDate
            state.endDate = endDate
            state.timePeriod = timePeriod
        })
        builder.addCase(setTimePeriod.rejected, (state) => {
            state.isError = true
        })
    }
})

export default slice.reducer