import React from 'react'
import { Provider } from 'react-redux'
import MaterialUiProvider from 'providers/MaterialUiProvider'
import { ReactQueryDevtools } from 'react-query-devtools'
import BaseRouter from 'routers/BaseRouter'
import store from 'store'
import SignalrMockTool from 'components/SignalrMockTool'
import MomentAdapter from "@mui/lab/AdapterMoment";
import { LocalizationProvider } from '@mui/x-date-pickers'

const BaseProvider = ({children}) => {
    return (
        //keep these on the same level since it doesnt matter anyway
        <>
            <ReactQueryDevtools initialIsOpen/>
            <SignalrMockTool/>
            <Provider store={store}>
                <MaterialUiProvider>
                    <LocalizationProvider dateAdapter={MomentAdapter}>
                        <BaseRouter>
                            {children}
                        </BaseRouter>
                    </LocalizationProvider>
                </MaterialUiProvider>
            </Provider>
        </>
    )
}

export default BaseProvider