import { Box } from '@mui/material'
import React from 'react'
import { colors } from 'components/RepairOrderListView/helpers/rowColors'
import { Color } from 'components/RepairOrderListView/types/ListRepairOrder'

export interface IColorPickerButton {
    color: Color
    onClick(color: Color): any
    currentColor: Color
}

function ColorPickerButton({
    color,
    onClick,
    currentColor,
}: IColorPickerButton) {
    const handleClick = () => {
        onClick(color)
    }

    return (
        <Box
            onClick={handleClick}
            bgcolor={colors[color]}
            style={{
                cursor: `pointer`,
            }}
            border={color === currentColor ? `1px solid gray` : `1px solid white`}
        ></Box>
    )
}

export default React.memo(ColorPickerButton)