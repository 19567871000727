import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import BoxWithHeader from 'components/BoxWithHeader'
import InspectionDetailsGridLayout from 'components/InspectionDetailsGridLayout'

const InspectionDetails = ({
    tagNumber,
    advisors,
    technicians,
    partsEMP,
    promiseDate,
    promiseTime,
    flags
}) => {  
    return (
        <BoxWithHeader
            titleText={`Service Details`}
            content={
                <div className={styles.container}>
                    <InspectionDetailsGridLayout>
                        <div className={`labelClean`}>Tag Number</div>
                        <div>{tagNumber}</div>
                        <label className={`labelClean`}>Adivsor</label>
                        <div>George Washington</div>
                        <label className={`labelClean`}>Technician</label>
                        <div>George Washington</div>
                        <div className={`labelClean`}>Parts EMP</div>
                        <div>XXX</div>
                        <div className={`labelClean`}>Promise Date</div>
                        <div>10/16/2019</div>
                        <div className={`labelClean`}>Promise Time</div>
                        <div>12:00 AM</div>
                        <div className={`labelClean`}>Flags</div>
                        <div>{``}</div>
                    </InspectionDetailsGridLayout>
                </div>
            }
        ></BoxWithHeader>
    )
}

InspectionDetails.propTypes = {
    tagNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    advisors: PropTypes.array,
    technicians: PropTypes.array,
    partsEMP: PropTypes.string,
    promiseDate: PropTypes.string,
    promiseTime: PropTypes.string,
    flags: PropTypes.string
}

export default InspectionDetails