import { Box, Paper, Tooltip, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import styles from './styles.module.scss'
import { IconButton } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { ILoading } from "../Interfaces/ILoading";

export interface IReportingContainerProps extends ILoading {
    title: string,
    children: ReactNode,
    viewDetails: boolean,
    viewDetailsEnabled?: boolean
}

export default function ReportingContainer({
    title,
    children,
    isLoading,
    viewDetails,
    viewDetailsEnabled = false
}: IReportingContainerProps){

    const loadingContent = (
        <Box sx={{height: `250px`, width: `100%`}}>
            <LoadingIndicator message={``}/>
        </Box>
    )

    const detailsView = (
        viewDetails && 
        <Box className={styles.headerDetailsSection}>
            <Tooltip title={`Coming soon!`}>
                <span>
                    <IconButton disabled={!viewDetailsEnabled} >
                        <RemoveRedEyeIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )

    return (
        <Paper 
            elevation={2}
            className={styles.container}>
            
            <Box className={styles.headerContainer}>
                <Box className={styles.headerDescriptionContainer}>
                    <Typography className={styles.headerDescription} variant="subtitle2">{title.toUpperCase()}</Typography>
                </Box>
                {detailsView}
            </Box>

            {isLoading ?  loadingContent : children}

      </Paper>
    )
}