import React from 'react'

import styles from './styles.module.scss'

export interface IToggleSort {
    canSort: boolean,
    isSorted: boolean,
    isSortedDesc: boolean,
    toggleSortBy(descending: boolean): void,
    clearSortBy(): void
}

function ToggleSort({
    canSort,
    isSorted,
    isSortedDesc,
    toggleSortBy,
    clearSortBy
}: IToggleSort) {

    function handleUpClick() {
        isSorted && !isSortedDesc ? clearSortBy() : toggleSortBy(false)
    }

    function handleDownClick() {
        isSortedDesc ? clearSortBy() : toggleSortBy(true)
    }

    return (
        <div className={`${styles.container} ${canSort ? styles.enable : ``}`}>
            <div onClick={handleUpClick} className={`${styles.upArrow} ${isSorted && !isSortedDesc ? styles.active : ``}`}></div>
            <div onClick={handleDownClick} className={`${styles.downArrow} ${isSorted && isSortedDesc ? styles.active : ``}`}></div>
        </div>
    )
}

export default ToggleSort