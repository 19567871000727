import moment from 'moment'
import Base from 'classes/Base'
import CallGroup from 'classes/CallGroup'

export default class BdcLineItem extends Base {
    id: number | null = null
    customerID: number | null = null
    customer: string = ``
    address: string = ``
    phone: string = ``
    vehicle: string = ``
    lastVisit: string = ``
    readonly lastVisitRaw: string = ``
    callGroup: CallGroup = new CallGroup().toPlainObj()

    constructor(init?: Partial<BdcLineItem>) {
        super()
        Object.assign(this, init)
        this.lastVisitRaw = this.formatRawDateTime(this.lastVisit)
    }

    mapFromAPI(raw) {
        return new BdcLineItem({
            id: raw.ID,
            customerID: raw.CustomerID,
            customer: raw.FullName,
            address: raw.Address,
            phone: raw.Phone,
            vehicle: raw.VehicleDescription,
            lastVisit: this.formatLastVisit(raw.LastVisit),
            callGroup: new CallGroup().mapFromAPI(raw)
        }).toPlainObj()
    }

    formatLastVisit(dateTime: string) {
        return moment(dateTime).format(`M/D/YYYY`)
    }
}