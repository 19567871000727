import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { Card, CardHeader, IconButton } from "@mui/material";
import { Dealer } from "api/types/dealer";
import { retrieveDealer } from "modules/dealer";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";

interface IDealerCardProps {
  data: Dealer;
  default: boolean;
  toggleMain: () => void;
}

function DealerListItem(props: IDealerCardProps) {
  const { dealer } = useAppSelector((state) => state);

  const [isHovering, setIsHovering] = React.useState(false);

  const dispatch = useDispatch();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleItemClick = (dealerId: number) => {
    localStorage.setItem(`crmDealerId`, dealerId.toString());

    dispatch(retrieveDealer(dealerId));

    props.toggleMain();
  };

  return (
    <div>
      {dealer.ID === props.data.ID ? (
        <Card style={{ margin: 5 }} sx={{ bgcolor: "#26387A" }}>
          <CardHeader
            action={
              <IconButton>
                <CheckCircle sx={{ color: "white" }} />
              </IconButton>
            }
            title={dealer.Name}
            subheader={dealer.ExternalID}
            titleTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "230px",
              fontSize: "16px",
              noWrap: true,
              color: "white",
            }}
            subheaderTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "300px",
              fontSize: "16px",
              color: "white",
            }}
          />
        </Card>
      ) : (
        <Card
          style={{ margin: 5 }}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
        >
          <CardHeader
            action={
              isHovering && (
                <IconButton onClick={() => handleItemClick(props.data.ID)}>
                  <RadioButtonUnchecked />
                </IconButton>
              )
            }
            title={props.data.Name}
            subheader={props.data.ExternalID}
            titleTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "230px",
              fontSize: "16px",
              noWrap: true,
            }}
            subheaderTypographyProps={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "300px",
              fontSize: "16px",
            }}
          />
        </Card>
      )}
    </div>
  );
}

export default DealerListItem;
