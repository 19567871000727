import React from 'react'
import Button from 'componentsUI/Button'

import styles from './styles.module.scss'
import { ButtonProps, CircularProgress } from '@mui/material'

export interface ISubmitButton extends ButtonProps {
    isLoading: boolean
    canSubmit: boolean
    text: string
    type?: `button` | `submit`
    style?: any
}

function SubmitButton({
    canSubmit,
    isLoading,
    text,
    type = `submit`,
    style,
    ...props
}: ISubmitButton) {

    return (
        <div className={styles.container} style={style}>
            <Button
                disabled={!canSubmit || isLoading}
                type={type}
                fullWidth
                {...props}
            >{text}</Button>
            {isLoading &&
                <div className={styles.loaderWrapper}>
                    <CircularProgress
                        size={24}
                    ></CircularProgress>
                </div>
            }
        </div>
    )
}

export default SubmitButton