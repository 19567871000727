import { Typography } from "@mui/material"
import React from "react"
import styles from './styles.module.scss'

function CopyrightFooter() {

    return (
        <Typography variant={`body2`} gutterBottom>v{process.env.REACT_APP_VERSION} © {new Date().getFullYear()} <a className={styles.link} href={`http://sleadvisor.com`}>Service Lane eAdvisor</a></Typography>
    )
}

export default CopyrightFooter