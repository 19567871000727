import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDealersBySearch from "api/getDealersBySearch";
import { Dealer } from "api/types/dealer";
import { IDealerSearch } from "modules/dealersSearchView";
import { AppThunk } from "store";
import { IPaginatedResponse } from "types/paginatedResponse";

let initialState: IDealerSearch = {
  isLoading: true,
  isError: false,
  infoText: `Initializing...`,
  dealers: [],
  searchTerm: ``,
  pageNumber: 0,
  pageSize: 10,
  totalPages: 0,
  totalRecords: 0,
};

const slice = createSlice({
  name: `dealerPirckerSearch`,
  initialState: initialState,
  reducers: {
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload - 1 < 0 ? 0 : action.payload + 1;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload || ``;
    },
    getDataStart(state) {
      state.isLoading = true;
      state.isError = false;
      state.infoText =
        state.dealers === undefined
          ? `Retrieving dealers...`
          : `Refreshing Data...`;
    },
    getDataSuccess(state, action: PayloadAction<IPaginatedResponse<Dealer>>) {
      const dealersPayload = action.payload;

      state.dealers = dealersPayload.Data;
      state.pageNumber = dealersPayload.PageNumber;
      state.pageSize = dealersPayload.PageSize;
      state.totalPages = dealersPayload.TotalPages;
      state.totalRecords = dealersPayload.TotalRecords;
      state.isLoading = false;
      state.isError = false;
      state.infoText = ``;
    },
    getDataFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.infoText = action.payload;
    },
    reset(state) {
      state.dealers = initialState.dealers;
      state.isLoading = initialState.isLoading;
      state.isError = initialState.isError;
      state.infoText = initialState.infoText;
      state.pageNumber = initialState.pageNumber;
      state.pageSize = initialState.pageSize;
      state.totalPages = initialState.totalPages;
      state.totalRecords = initialState.totalRecords;
      state.searchTerm = initialState.searchTerm;
    },
  },
});

export const {
  getDataStart,
  getDataSuccess,
  getDataFailure,
  reset,
  setPageSize,
  setPageNumber,
  setSearchTerm,
} = slice.actions;

export default slice.reducer;

export const getData =
  (searchCriteria: string, pageNumber: number, pageSize: number): AppThunk =>
  async (dispatch) => {
    try {
      try {
        dispatch(getDataStart());
        const data = await getDealersBySearch(
          searchCriteria,
          pageNumber,
          pageSize
        );
        dispatch(getDataSuccess(data));
      } catch (err: any) {
        dispatch(getDataFailure(err));
      }
    } catch (error) {
      dispatch(
        getDataFailure(`There was an error retrieving dealers: ${error}`)
      );
    }
  };
