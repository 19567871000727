import CommunicationDashboardStats from 'api/types/communicationDashboardStats'
import apiFetch from 'api/utils/APIFetch'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'

const getCommunicationDashboardStats = async (
    dealerID: number,
    startDate: string,
    endDate: string,
): Promise<CommunicationDashboardStats> => {

    const startString = moment(startDate, US_DATE_FORMAT).format(US_DATE_FORMAT)
    const endString = moment(endDate, US_DATE_FORMAT).add({ days: 1 }).format(US_DATE_FORMAT)

    return await apiFetch(`/api/communication-dashboard/${dealerID}/${startString}/${endString}`)
}

export default getCommunicationDashboardStats