import React, { useState, useEffect } from 'react'
import { queryCache } from 'react-query'
import generateBdcCategorySchema from 'utils/generateBdcCategorySchema'
import TasksLayout from 'components/TasksLayout'
import TasksOverview from 'components/BdcDataview/components/TasksOverview'
import CircleWordWithNumber from 'components/CircleWordWithNumber'
import Tab from 'components/Tab'
import TasksInfoBox from 'components/TasksInfoBox'
import TasksCalendar from 'components/BdcDataview/components/TasksCalendar'
import {
    useAppSelector,
    useAppDispatch
} from 'store'
import {
    reset,
} from 'modules/bdcDashboard'
import useSubsrcibeToCategoryData from './hooks/useSubsrcibeToCategoryData'

function BdcDataview() {
    const dispatch = useAppDispatch()
    const {
        isLoadingCategoryData,
        isErrorCategoryData,
    } = useAppSelector(
        state => state.bdcDashboard
    )
    const {
        EnableAppointments,
    } = useAppSelector(state => state.dealer)

    const [categoriesData, setCategoriesData] = useState(generateBdcCategorySchema())

    useSubsrcibeToCategoryData(categoriesData, setCategoriesData)

    useEffect(() => {
        return () => {
            queryCache.clear()
            dispatch(reset())
        }
    }, [dispatch])

    const infoBoxesArray = categoriesData.map(obj => {
        const infoArray = [
            <CircleWordWithNumber
                key={`unique1`}
                number={obj.total}
                text={`Total`}
                color={`#263b77`}
            ></CircleWordWithNumber>,
            <CircleWordWithNumber
                key={`unique2`}
                number={obj.called}
                text={`Called`}
                color={`#59A651`}
            ></CircleWordWithNumber>
        ]

        if (obj.showAppts && EnableAppointments) infoArray.push(
            <CircleWordWithNumber
                key={`unique3`}
                number={obj.appts}
                text={`Appts`}
                color={`#E38032`}
            ></CircleWordWithNumber>
        )

        return <TasksInfoBox
            key={obj.id}
            id={obj.id}
            tab={
                <Tab
                    text={obj.title}
                    desc={obj.desc}
                    height={35}
                    customTextStyles={{
                        fontSize: 12,
                        color: `black`
                    }}
                ></Tab>
            }
            infoArray={infoArray}
            isLoading={isLoadingCategoryData}
            isError={isErrorCategoryData}
        ></TasksInfoBox>
    })

    return (
        <TasksLayout
            overview={<TasksOverview/>}
            calendar={<TasksCalendar/>}
            infoBoxes={infoBoxesArray}
        ></TasksLayout>
    )
}

export default BdcDataview