import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const Button = ({
    text,
    symbol,
    className,
    style,
    onClick
}) => {  
    const containerClasses = classNames(
        styles.container,
        {
            [className]: className
        }
    )

    const handleClick = () => {
        onClick(text)
    }

    return (
        <button className={containerClasses} style={style} onClick={handleClick}>{symbol}{text}</button>
    )
}

Button.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    symbol: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
}

export default Button