import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { updateRepairOrderAlert } from 'modules/roAlerts'
import RepairOrderAlert from 'services/RepairOrderAlert'
import { AppThunk } from 'store'
import { IError } from 'types/IError'

interface IRepairOrderAlertDialog extends IError {
    alert?: RepairOrderAlert
    isOpen: boolean
}

let initialState: IRepairOrderAlertDialog = {
    alert: undefined,
    isOpen: false,
    hasError: false,
    error: ``,
}

const slice = createSlice({
    name: `repairOrderAlertDialog`,
    initialState,
    reducers: {
        setAlert(state, action: PayloadAction<RepairOrderAlert>) {
            state.alert = action.payload
            state.isOpen = true
        },
        removeAlert() {
            return initialState
        },
        setError(state, action: PayloadAction<IError>) {
        
            const {
                hasError,
                error,
            } = action.payload

            state.hasError = hasError
            state.error = error
        
        },
        acknowledgeRoAlertDialogError(state) {

            state.hasError = false
            state.error = ``

        }
    }
})

export const {
    setAlert,
    setError,
    removeAlert,
    acknowledgeRoAlertDialogError
} = slice.actions

export default slice.reducer

export const dismissAlert = (): AppThunk => async (dispatch, getState) => {

    try {

        const alert = getState().roAlertDialog.alert

        if (alert === undefined) {

            throw new Error(`Alert is undefined!`)

        }

        dispatch(updateRepairOrderAlert(alert.id, {
            acknowledged: true,
        }))

        dispatch(removeAlert())

    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error has occured while attempting to dismiss the alert.`
        }))

    }

}

export const snoozeAlert = (time: string): AppThunk => async (dispatch, getState) => {

    try {

        const alert = getState().roAlertDialog.alert

        if (alert === undefined) {

            throw new Error(`Alert is undefined!`)

        }

        dispatch(updateRepairOrderAlert(alert.id, {
            time,
        }))

        dispatch(removeAlert())

    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error has occured while attempting to snooze the alert.`
        }))

    }

}