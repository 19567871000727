import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { setIsOpenInToolbar, setIsToolbarSvsEnabled } from 'modules/app'

declare global {
    interface Window {
        svsEnabled: boolean | undefined,
        closeAlertReminder(): void,
        openAlertReminder(): void,
    }
}

function useToolbar() {

    const dispatch = useAppDispatch()

    const {
        isOpen: roAlertDialogOpen,
    } = useAppSelector(state => state.roAlertDialog)

    const {
        isOpen: unreadMessageDialogOpen
    } = useAppSelector(state => state.customersWithUnreadMessages)

    const {
        isOpenInToolbar
    } = useAppSelector(state => state.app)

    useEffect(() => {

        const params = new URLSearchParams(window.location.search)
        
        const sessionVar = sessionStorage.getItem(`isRunningInToolbar`)

        dispatch(setIsOpenInToolbar(!!params.get(`toolbar`) || sessionVar === `true`))
        
        dispatch(setIsToolbarSvsEnabled(window.svsEnabled ? window.svsEnabled : false))

    }, [dispatch])

    useEffect(() => {
        const timer = setInterval(() => {

            const toolbarMethodsDefined = window.openAlertReminder !== undefined && window.closeAlertReminder !== undefined

            if(isOpenInToolbar && toolbarMethodsDefined) {

                const alertIsOpen = roAlertDialogOpen || unreadMessageDialogOpen

                alertIsOpen ? 
                    window.openAlertReminder() :
                    window.closeAlertReminder()

            }

        }, 500)

        return () => {

            clearInterval(timer)

        }

    }, [isOpenInToolbar, roAlertDialogOpen, unreadMessageDialogOpen])

}

export default useToolbar