import { SmsReportingOverviewViewModel } from "api/types/reporting/SmsReportingOverviewViewModel";
import React, { useState } from "react";
import { ILoading } from "../../Interfaces/ILoading";
import ReportingContainer from "../../ReportingContainer";
import SmsOverviewDataGrid from "../SmsOverviewDataGrid";
import SmsPieChart, { IPieChartDisplayProps } from "../SmsPieChart";

export interface ISmsOverviewProps extends ILoading {
    data: SmsReportingOverviewViewModel
}

export default function SmsOverview({
    data,
    isLoading
}: ISmsOverviewProps){
    const [pieChartDisplayProps, setPieChartDisplayProps] = useState<IPieChartDisplayProps>({
        sentWelcomeMessages: true,
        sentMessages: true,
        receivedMessages: true,
        unansweredMessages: true
    })


    const checkedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPieChartDisplayProps({
            ...pieChartDisplayProps,
            [event.target.name]: event.target.checked
        })
    }

    return (
        <ReportingContainer
            title={`SMS`}
            isLoading={isLoading}
            viewDetails={false}>

            <SmsPieChart
                smsData={data}
                pieChartDisplayProps={pieChartDisplayProps}/>

            <SmsOverviewDataGrid
                data={data}
                checkedChangedCallback={checkedChanged} />

        </ReportingContainer>
    )
}