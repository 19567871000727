import { useAppDispatch, useAppSelector } from 'store'
import { getRepairOrderAlerts } from 'modules/roAlerts'
import { useCallback, useEffect } from 'react'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { Signal } from 'types/signalr'

function useSubscribeToRoAlerts() {
    const dispatch = useAppDispatch()

    const {
        ID: userID
    } = useAppSelector(state => state.user)

    useEffect(() => {

        dispatch(getRepairOrderAlerts(userID))

    }, [dispatch, userID])

    const repairOrderAlertUpdated = useCallback(() => {

        dispatch(getRepairOrderAlerts(userID))

    }, [dispatch, userID])

    useSubscribeToSignal(Signal.REPAIR_ORDER_ALERT_UPDATED, repairOrderAlertUpdated)
}

export default useSubscribeToRoAlerts