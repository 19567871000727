import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import InspectionLine from 'components/InspectionLine'

const InspectionBoxLayout = ({
    sectionID,
    nodes
}) => {  
    const content = nodes.map((node, index) => 
        <InspectionLine
            key={index}
            id={`${sectionID}-${index}`}
            label={node.title}
            showButton={node.hasButton}
            showInput={node.hasInput}
            showScale={node.hasScale}
        ></InspectionLine>
    )

    return (
        <div className={styles.container}>
            {content}
        </div>
    )
}

InspectionBoxLayout.propTypes = {
    sectionID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    nodes: PropTypes.array
}

export default InspectionBoxLayout