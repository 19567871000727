export const timezones = [
  ["Pacific Standard Time", "America/Los_Angeles"],
  ["Eastern Standard Time", "America/New_York"],
  ["Central Standard Time", "America/Chicago"],
  ["Mountain Standard Time", "America/Denver"],
];

export function getTimezone(timezone: string): string {
  for (let i = 0; i < timezones.length; i++) {
    const timezoneFound = timezones[i];
    if (timezoneFound[0] === timezone) {
      return timezoneFound[1];
    }
  }

  return "America/Chicago";
}
