import React from 'react'
import { useAppSelector } from 'store'
import { 
    AppBar,
    Toolbar,
    Typography,
} from '@mui/material'
import MainMenu from '../MainMenu'
import UserMenu from '../UserMenu'
import BackButton from './components/BackButton'

import styles from './styles.module.scss'

export interface IAppNavBar {
    history: any
}

function AppNavbar() {
    const {
        userIsLoggedIn,
    } = useAppSelector(state => state.auth)   
    const {
        title,
        isPrimaryRoute
    } = useAppSelector(state => state.appNavBar)

    const mainContent = isPrimaryRoute ? <MainMenu/> : <BackButton/>

    const content = userIsLoggedIn ? 
        <>
            {mainContent}
            <Typography variant={`h6`}>
                {title}
            </Typography>
            <UserMenu/>
        </> : null

    return (
        <AppBar className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.innerWrapper}>
                    {content}
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default AppNavbar