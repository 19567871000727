import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { Signal } from 'types/signalr'
import { Message } from 'api/types/message'
import { getCustomerData } from '../modules'

function useSubscribeToUnreadMessages() {
    const dispatch = useAppDispatch()

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const getCustomersWithUnreadMessages = useCallback(() => {

        dispatch(getCustomerData(dealerID))

    }, [dispatch, dealerID])

    useEffect(() => {

        getCustomersWithUnreadMessages()

    }, [getCustomersWithUnreadMessages])

    const upsertMessage = useCallback((message: Message) => {

        getCustomersWithUnreadMessages()
        
    }, [getCustomersWithUnreadMessages])
    useSubscribeToSignal(Signal.MESSAGE_RECEIVED, upsertMessage)

    const updateMessages = useCallback((messages: Message[]) => {

        getCustomersWithUnreadMessages()

    }, [getCustomersWithUnreadMessages])    
    useSubscribeToSignal(Signal.MESSAGES_UPDATED, updateMessages)
}

export default useSubscribeToUnreadMessages