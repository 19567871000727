import { Box } from "@mui/material"
import React from "react"
import ClearIcon from '@mui/icons-material/Clear';
import styles from './styles.module.scss'
import { Color } from 'components/RepairOrderListView/types/ListRepairOrder'

export interface IClearColorPickerOption {
    onClick(color: Color): any
}

function ClearColorPickerOption({
    onClick
}: IClearColorPickerOption) {

    var handleClick = () => {
       onClick(Color.NONE) 
    }

    return (
        
        <Box 
            className={styles.clearContainer}
            onClick={handleClick}>
            <Box className={styles.clearContents}>
                <ClearIcon color={`error`} fontSize="inherit"/>
                <span>clear color</span>
            </Box>
        </Box>
        
    )
}

export default React.memo(ClearColorPickerOption)