import { createAsyncThunk } from '@reduxjs/toolkit'
import getWarrantyAlertReportingOverview from 'api/reporting/warrantyAlert/getWarrantyAlertReportingOverview'
import { WarrantyAlertReportingOverviewViewModel } from 'api/types/reporting/WarrantyAlertReportingOverview'
import { IPayload } from '../interfaces/IPayload'

const getWarrantyAlertReportingOverviewData = createAsyncThunk(
    `reporting/getWarrantyAlertReportingOverviewData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<WarrantyAlertReportingOverviewViewModel> => {
        return await getWarrantyAlertReportingOverview(dealerID, startDate, endDate)
    }
)

export default getWarrantyAlertReportingOverviewData