import { IChatMessage } from 'types/chatMessage'

export default interface ChatMessage {
    ID: number
    ChatConversationID: number
    CreatedAt: string
    FormatMessageAge: string
    Message: string
    UpdatedAt: string
    UserID: number
    Email: string
}

export function mapToChatMessage({
    ID,
    ChatConversationID,
    CreatedAt,
    FormatMessageAge,
    Message,
    UpdatedAt,
    UserID,
    Email,
}: ChatMessage): IChatMessage {
    return {
        id: ID,
        chatConversationID: ChatConversationID,
        createdAt: CreatedAt,
        updatedAt: UpdatedAt,
        ageText: FormatMessageAge,
        text: Message,
        userID: UserID,
        email: Email,
    }
}