import apiFetch from "api/utils/APIFetch";
import {Make} from "api/types/make"

const addPreferredMake = (make: Make) : Promise<void> => apiFetch(`/api/web-scheduler/preferred-oems`, {
    method: `POST`,
    body: JSON.stringify(make)
}).catch(error => {
    throw new Error(error.Message)
})

export default addPreferredMake