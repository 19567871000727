import { createAsyncThunk } from '@reduxjs/toolkit'
import getVideoMpviReportingOverview from 'api/reporting/videoMpvi/getVideoMpviReportingOverview'
import { VideoMpviReportingOverviewViewModel } from 'api/types/reporting/VideoMpviReportingOverviewViewModel'
import { IPayload } from '../interfaces/IPayload'

const getVideoMpviReportingOverviewData = createAsyncThunk(
    `reporting/getVideoMpviReportingOverviewData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<VideoMpviReportingOverviewViewModel> => {
        return await getVideoMpviReportingOverview(dealerID, startDate, endDate)
    }
)

export default getVideoMpviReportingOverviewData