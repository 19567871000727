import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@mui/material'
import { Message } from 'api/types/message'
import { Customer, getDisplayPhone } from 'api/types/customer'
import DialogConversationContent from 'components/DialogConversationContent'

export interface IConversationDialog {
    onClose(): void
    customer: Customer
    repairOrderID: number | null
    messages: Message[]
}

function ConversationDialog({
    onClose,
    customer,
    repairOrderID,
    messages
}: IConversationDialog) {

    const ro = messages.find(msg => msg.RepairOrder?.ID === repairOrderID)?.RepairOrder || null

    const roText = ro !== null ? `, RO#: ${ro.RONumber}` : ``

    const contentText = `${customer.FullName} ${getDisplayPhone(customer)}${roText}`

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>Customer Conversation</DialogTitle>
            <DialogConversationContent
                contentText={contentText}
                repairOrderId={repairOrderID}
                customerId={customer.ID}
                messages={messages}
            ></DialogConversationContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color={`primary`}
                >Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(ConversationDialog)