import React from 'react'
import moment from 'moment'
import { Box, Divider, BoxProps, Typography } from '@mui/material'
import theme from 'assets/theme'
import { DirectionTypes } from 'types/Message'
import { Message } from 'api/types/message'
import { titleCase } from 'voca'
import { getFirstInitialLast } from 'utils/getFirstInitialLast'

export interface IMessageBox {
    message: Message
}

function MessageBox({
    message,
}: IMessageBox) {    
    const boxProps = (): BoxProps => {
        switch (message.Direction) {
            case DirectionTypes.INCOMING:
                return {
                    justifySelf: `start`,
                    color: theme.palette.text.primary,
                    bgcolor: `#ebebeb`,
                    border: `1px solid ${theme.palette.divider}`,
                }
                case DirectionTypes.OUTGOING:
                    return {
                    color: `white`,
                    bgcolor: theme.palette.primary.main,
                    justifySelf: `end`,
                }
            default: 
                return {}
        }
    }

    const sentAt = moment(message.SentAt).format(`MMM Do, h:mm a`)

    const defaultName = `Unknown`
    const advisor = {
        FirstName: message.User?.FirstName || defaultName,
        LastName: message.User?.LastName || defaultName
    }

    const fromText = message.Direction === DirectionTypes.INCOMING ? 
        message.From : 
        ((advisor.LastName !== defaultName) ?
            getFirstInitialLast(advisor.FirstName, advisor.LastName) :
            titleCase(advisor.LastName))

    return (
        <Box
            width={`80%`}
            borderRadius="7px"
            color={`white`}
            {...boxProps()}
        >
            <Box padding={1}>
                <Typography variant={`body2`}>{message.Body}</Typography>
            </Box>
            <Divider/>
            <Box 
                padding={1}
                textAlign={message.Direction === DirectionTypes.OUTGOING? `right` : `left`}
            >
                <Typography variant={`caption`}>From: {fromText} on {sentAt}</Typography>
            </Box>
        </Box>
    );
}

export default MessageBox