import { createAsyncThunk } from '@reduxjs/toolkit'
import { ISetReportingDateRange } from 'components/Reporting/thunks/interfaces/ISetReportingDateRange'

const setDates = createAsyncThunk(
    `reporting/setDates`,
    async ({
        startDate, 
        endDate,
    }: ISetReportingDateRange) => {

        return {
            startDate,
            endDate,
        }
    }
)

export default setDates