import React, { useMemo } from 'react'
import { withRouter } from 'react-router'
import { useAppSelector } from 'store'
import TableView from 'componentsUI/TableView'
import useSubscribeToData from './hooks/useSubscribeToData'
import CallStatusCell from './components/CallStatusCell'
import NotesCell from './components/NotesCell'
import Overlay from 'components/Overlay'
import { bdcListViewLineItemsSelectors } from 'modules/bdcListView'
import BdcLineItem from 'classes/BdcLineItem'
import CallGroup from 'classes/CallGroup'

import styles from './styles.module.scss'

export interface IBdcTableView {
    match?: {
        params: {
            id: number
        }
    } | undefined
}

function BdcTableView({
    match
}: IBdcTableView) {
    const {
        isError,
        isLoading,
        infoText
    } = useAppSelector(state => state.bdcListView)
    const categoryID = match !== undefined ? match.params.id : null
    const data = useAppSelector(bdcListViewLineItemsSelectors.selectAll)
    useSubscribeToData(categoryID)

    const columns = useMemo(() => [
        {
            Header: 'Customer',
            accessor: 'customer',
            width: 1,
        },
        {
            Header: 'Address',
            accessor: 'address',
            width: 2,
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            width: 1,
        },
        {
            Header: 'Vehicle',
            accessor: 'vehicle',
            width: 1,
        },
        {
            Header: 'Last Visit Raw',
            accessor: 'lastVisitRaw',
        },
        {
            Header: 'Last Visit',
            id: `lastVisit`,
            accessor: row => new Date(row.lastVisitRaw),
            width: 1,
            disableFilters: true,
            sortType: `datetime`,
            Cell: ({ value }) => new BdcLineItem().formatLastVisit(value),
        },
        {
            Header: 'Last Call',
            id: `lastCall`,
            accessor: row => new Date(row.callGroup.lastCall),
            width: 1,
            disableFilters: true,
            sortType: `datetime`,
            Cell: ({ value, row }) => {
                if (row.original.callGroup.lastCall === ``) {
                    return ``
                } else {
                    return new CallGroup().formatLastCall(value)
                }
            }
        },
        {
            Header: 'Attempts',
            accessor: 'callGroup.numberOfCallAttempts',
            width: 1,
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: 'Status',
            accessor: 'callGroup',
            width: 1,
            Cell: ({ value }) => <CallStatusCell
                callGroup={value}
            ></CallStatusCell>,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Notes',
            accessor: 'callGroup.notes',
            width: 2,
            Cell: ({ value, row }) => <NotesCell
                text={value}
                callGroup={row.values.callGroup}
            ></NotesCell>
        },
    ], [])

    const initialState = useMemo(() => ({
        pageSize: 50,
        pageIndex: 0,
        hiddenColumns: [`lastVisitRaw`]
    }), [])

    return (
        <div className={styles.container}>
            <TableView
                data={data}
                columns={columns}
                initialState={initialState}
                paginated
            ></TableView>
            <Overlay
                show={isLoading || isError}
                isLoading={isLoading}
                isError={isError}
                text={infoText}
            ></Overlay>
        </div>
    )
}

export default withRouter(BdcTableView)