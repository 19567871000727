import getCsiAlertReportingOverviewData from "components/Reporting/thunks/getCsiAlertReportingOverviewData";
import getInteractiveMaintenanceReportingOverviewData from "components/Reporting/thunks/getInteractiveMaintenanceOverviewData";
import getSmsReportingOverviewData from "components/Reporting/thunks/getSmsReportingOverviewData";
import getVideoMpviReportingOverviewData from "components/Reporting/thunks/getVideoMpviReportingOverviewData";
import getWarrantyAlertReportingOverviewData from "components/Reporting/thunks/getWarrantyAlertReportingOverviewData";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";

export default function useSubscribeToReportingOverview() {
    const dispatch = useAppDispatch()
    
    const {
        startDate,
        endDate,
    } = useAppSelector(
        state => state.reporting
    )

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    useEffect(() => {
        dispatch(getWarrantyAlertReportingOverviewData({dealerID, startDate, endDate}))
        dispatch(getVideoMpviReportingOverviewData({dealerID, startDate, endDate}))
        dispatch(getSmsReportingOverviewData({dealerID, startDate, endDate}))
        dispatch(getCsiAlertReportingOverviewData({dealerID, startDate, endDate}))
        dispatch(getInteractiveMaintenanceReportingOverviewData({dealerID, startDate, endDate}))
    }, [startDate, endDate, dispatch, dealerID])
}