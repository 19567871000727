import { IChatConversationSummary } from 'types/chatConversationSummary'
import ChatUser, { mapToChatUser } from './chatUser'

export default interface ChatConversationSummary {
    ChatConversationID: number
    CreatedAt: string
    LastMessageSentTimeStamp: string
    RepairOrderName: string
    UpdatedAt: string
    UserID: number
    Username: string
    Users: ChatUser[]
}

export function mapToChatConversationSummary({
    ChatConversationID,
    CreatedAt,
    LastMessageSentTimeStamp,
    RepairOrderName,
    UpdatedAt,
    UserID,
    Username,
    Users,
}: ChatConversationSummary): IChatConversationSummary {
    return {
        id: ChatConversationID,
        createdAt: CreatedAt,
        lastMessageSentTimeStamp: LastMessageSentTimeStamp,
        repairOrderNumber: RepairOrderName,
        updatedAt: UpdatedAt,
        userID: UserID,
        username: Username,
        users: Users.map(mapToChatUser)
    }
}