import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const PictureIcon = ({
    color,
    width,
    className
}) => {  
    const containerClassNames = classNames(
        `icon-container`,
        {[className]: className}
    )

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={containerClassNames} style={{
            width,
            height: width
        }}>
            <path fill={color} d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"></path>
        </svg>
    )
}

PictureIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    className: PropTypes.string
}

PictureIcon.defaultProps = {
    color: `white`,
    width: 18
}

export default PictureIcon