import React, { useCallback, useMemo } from 'react'
import 'chartist/dist/chartist.min.css'
import 'assets/styles/base.scss'
import AppNavbar from 'components/AppNavbar'
import AppFooter from 'components/AppFooter'
import useSignalr from 'hooks/useSignalr'
import useToolbar from 'hooks/useToolbar'
import useSubscribeToRoAlerts from 'hooks/useSubscribeToRoAlerts'
import useRepairOrderAlertManager from 'hooks/useRepairOrderAlertManager'
import useSentry from 'hooks/useSentry'
import useInitialization from 'hooks/useInitialization'
import useSubscribeToCustomerMessages from 'hooks/useSubscribeToCustomerMessages'
import styles from './styles.module.scss'
import ErrorSnackbar from 'components/ErrorSnackbar'
import { useAppSelector } from 'store'
import { acknowledgeRoAlertError } from 'modules/roAlerts'
import { useDispatch } from 'react-redux'
import { acknowledgeRoAlertDialogError } from 'components/RepairOrderAlertDialog/module'
import { Modes } from 'modules/app'
import Login from 'components/Login'
import RepairOrderAlertDialog from 'components/RepairOrderAlertDialog'
import UnreadConversationsDialog from 'components/UnreadConversationsDialog'
import ChatModule from 'components/ChatModule'
import AppRouter from 'routers/AppRouter'
import { Box } from '@mui/material'
import LoadingIndicator from 'components/LoadingIndicator'

function App() {
    const dispatch = useDispatch()

    const {
        mode,
    } = useAppSelector(state => state.app)

    const {
        hasError: roAlertsHasError,
        error: roAlertsError
    } = useAppSelector(state => state.roAlerts)

    const {
        hasError: roAlertDialogHasError,
        error: roAlertDialogError
    } = useAppSelector(state => state.roAlertDialog)

    useInitialization()
    useToolbar()
    useSignalr()
    useSubscribeToRoAlerts()
    useRepairOrderAlertManager()
    useSentry()
    useSubscribeToCustomerMessages()

    const errorContent = useCallback(() => {

        return [
            {
                key: 0,
                hasError: roAlertsHasError,
                error: roAlertsError,
                handleError: acknowledgeRoAlertError
            },
            {
                key: 1,
                hasError: roAlertDialogHasError,
                error: roAlertDialogError,
                handleError: acknowledgeRoAlertDialogError
            }
        ].map(state => (
            <ErrorSnackbar
                key={state.key}
                open={state.hasError}
                message={state.error}
                onClose={() => dispatch(state.handleError())}
            ></ErrorSnackbar>
        ))

    }, [dispatch, roAlertDialogError, roAlertDialogHasError, roAlertsError, roAlertsHasError])

    const getContent = useCallback(() => {
        switch (mode) {
            case Modes.INITIALIZING:
                return <LoadingIndicator message={`Initializing...`}/>
            case Modes.DEFAULT:
                return <AppRouter/>
        }
    }, [mode])

    const page = useMemo(() => {
        if(mode === Modes.LOGIN) return <Login/>

        return (
            <React.Fragment>
                <Box className={styles.contentWrapper}>
                    {getContent()}
                </Box>
            </React.Fragment>
        )
    }, [getContent, mode])

    return (
        <Box className={styles.container}>
            <AppNavbar/>
            <Box className={styles.pageContainer}>
                {page}
            </Box>
            <RepairOrderAlertDialog />
            <UnreadConversationsDialog />
            <ChatModule />
            
            {errorContent()}
            <AppFooter/>
        </Box>
    )
}

export default App
