import { green } from "@mui/material/colors";
import { InteractiveMaintenanceReportingOverviewViewModel } from "api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel";
import React from "react";
import formatPrice from "utils/formatPrice";
import SummerySectionCell from "../../SummerySectionCell";
import SummerySectionContainer from "../../SummerySectionContainer";

export interface IInteractiveMaintenanceSummerySectionProps {
    data: InteractiveMaintenanceReportingOverviewViewModel
}

export default function InteractiveMaintenanceSummerySection({
    data
}: IInteractiveMaintenanceSummerySectionProps) {
    return (
        <SummerySectionContainer>
            <SummerySectionCell
                statusColor={green[500]}
                header={`Total Dollars Sold`}
                value={`${formatPrice(data.TotalDollarsSold)}`}/>
            <SummerySectionCell
                header={`Total Services Sold`}
                value={`${data.TotalItemsSold}`}/>
        </SummerySectionContainer>
    )
}