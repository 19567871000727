import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { VideoMpviReportingByUserOverviewViewModel } from "api/types/reporting/VideoMpviReportingOverviewViewModel";
import React from "react";
import { stringToColor } from "utils/stringToColor";
import AvatarIcon from "../../AvatarIcon";
import DataTable from "components/DataTable";

export interface IVideoMpviUserDataGridProps {
    data: VideoMpviReportingByUserOverviewViewModel[]
}

export default function VideoMpviUserDataGrid({
    data
}: IVideoMpviUserDataGridProps) {
    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          headerName: 'ID',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'avatar',
          headerName: '',
          sortable: false,
          editable: false,
          flex: .1,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            const columnData = params.formattedValue
            return (
                <AvatarIcon 
                    name={columnData.name} 
                    color={columnData.color}/>
            )
          }
        },
        {
          headerClassName: `header-cell`,
          field: 'advisorName',
          headerName: 'Advisor',
          sortable: false,
          editable: false,
          flex: .5,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'videoMpviCount',
          headerName: 'Total Video MPVIs',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true
        },
      ];

    const rows = data.map((userData, index) => {
      const advisorName = userData.Name
      const color = stringToColor(advisorName)

      return {
        id: index,
        avatar: {
          name: advisorName,
          color: color
        },
        advisorName: advisorName,
        videoMpviCount: userData.TotalVideoMPVIs,
      }
    })

    return (
        <DataTable
          rows={rows}
          columns={columns} />
    )
}