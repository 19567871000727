import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import Button from 'components/Button'

const CheckBoxScale = ({
    onClick
}) => {  
    const schema = [
        {
            text: 1,
            color: `#D2452E`
        },
        {
            text: 2,
            color: `#D2452E`
        },
        {
            text: 3,
            color: `#D2452E`
        },
        {
            text: 4,
            color: `#DDB92E`
        },
        {
            text: 5,
            color: `#DDB92E`
        },
        {
            text: 6,
            color: `#DDB92E`
        },
        {
            text: 7,
            color: `#DDB92E`
        },
        {
            text: 8,
            color: `#53A73E`
        },
        {
            text: 9,
            color: `#53A73E`
        },
        {
            text: 10,
            color: `#53A73E`
        },
        {
            text: 11,
            color: `#53A73E`
        },
        {
            text: 12,
            color: `#53A73E`
        },
    ]

    const buttons = schema.map((button, index) => 
        <Button
            key={index}
            className={styles.button}
            style={{backgroundColor: button.color}}
            text={button.text}
            onClick={() => onClick(button.text)}
        ></Button>
    )

    return (
        <div className={styles.container}>
            {buttons}
        </div>
    )
}

CheckBoxScale.propTypes = {
    onChange: PropTypes.func
}

export default CheckBoxScale