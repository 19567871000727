import React, { useState } from 'react'
import { Snackbar, Tooltip } from '@mui/material'

import styles from './styles.module.scss'
import trimVin from 'utils/trimVin'

export interface IVinCell {
    vin: string
}

function VinCell({
    vin
}: IVinCell) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const trimmedVin = trimVin(vin)

    const handleClick = () => {
        navigator.clipboard.writeText(vin).then(
            () => {
                setIsOpen(true)
            }
        )
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Tooltip
                title={trimmedVin}
                onClick={handleClick}
                className={styles.tooltip}
            >
                <div>{trimmedVin}</div>
            </Tooltip>
            <Snackbar
                open={isOpen}
                onClose={handleClose}
                autoHideDuration={1500}
                message={`${vin} copied to clipboard`}
            ></Snackbar>
        </>
    )
}

export default React.memo(VinCell)