import React from "react";
import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataTable from "components/DataTable";
import { IBaseDmsDealerSetting } from "api/types/dmsDealerSetting";
import moment from "moment";

export interface IDmsSyncTaskSection {
    dmsDealerSettings: IBaseDmsDealerSetting
}

export default function DmsSyncTaskSection({
    dmsDealerSettings
}: IDmsSyncTaskSection){
    const getSyncStatusIcon = (params: GridRenderCellParams) => {
        const isSyncComplete = params.value

        return isSyncComplete ? 
            <Chip size="small" label="Complete" color="success" variant="outlined" /> :
            <Chip size="small" label="Incomplete" color="warning" variant="outlined" />
    }

    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          headerName: 'ID',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'bulkExtractToDate',
          headerName: 'Bulk Extract to Date',
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
        },
        {
          headerClassName: `header-cell`,
          field: 'lastSyncDate',
          headerName: 'Last Sync Date',
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
        },
        {
          headerClassName: `header-cell`,
          field: 'allBulkSync',
          headerName: 'All Syncs',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
        {
          headerClassName: `header-cell`,
          field: 'advisorSync',
          headerName: 'Advisor Sync',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
        {
          headerClassName: `header-cell`,
          field: 'customerVehicleSync',
          headerName: 'Customer Veh. Sync',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
        {
          headerClassName: `header-cell`,
          field: 'customerSync',
          headerName: 'Customer Sync',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
        {
          headerClassName: `header-cell`,
          field: 'dmsVehicleTypesSync',
          headerName: 'Vehicle Types Sync',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
        {
          headerClassName: `header-cell`,
          field: 'repairOrderSync',
          headerName: 'Repair Order Sync',
          type: `boolean`,
          sortable: false,
          editable: false,
          flex: .33,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => getSyncStatusIcon(params)
        },
      ];

    const {
        BulkExtractToDate,
        LastSyncDate,
        IsAllBulkSyncCompleted,
        IsBulkAdvisorsSyncCompleted,
        IsBulkCustomerVehiclesSyncCompleted,
        IsBulkCustomersSyncCompleted,
        IsBulkDmsVehicleTypesSyncCompleted,
        IsBulkRepairOrdersSyncCompleted
    } = dmsDealerSettings
    
    const syncStatusRow = [
        {
            id: 0,
            bulkExtractToDate: BulkExtractToDate != null ? moment(BulkExtractToDate.slice(0, -1)).format(`M/D/Y`) : `N/A`,
            lastSyncDate: LastSyncDate != null ? moment(LastSyncDate.slice(0, -1)).format(`M/D/Y h:mma`) : `N/A`,
            allBulkSync: IsAllBulkSyncCompleted,
            advisorSync: IsBulkAdvisorsSyncCompleted,
            customerVehicleSync: IsBulkCustomerVehiclesSyncCompleted,
            customerSync: IsBulkCustomersSyncCompleted,
            dmsVehicleTypesSync: IsBulkDmsVehicleTypesSyncCompleted,
            repairOrderSync: IsBulkRepairOrdersSyncCompleted
        }
    ]

    return (
        <DataTable
          rows={syncStatusRow}
          columns={columns} />
    )
}