import { createAsyncThunk } from '@reduxjs/toolkit'
import { ISetTimePeriodPayload } from '../interfaces/ISetTimePeriodPayload'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'

const setTimePeriod = createAsyncThunk(
    `scheduling/setTimePeriod`,
    async ({
        startDate,
        endDate,
        timePeriod
    }: ISetTimePeriodPayload, _) => {

        var startMoment = moment(startDate)

        switch (timePeriod) {
            case "day":
                endDate = `${startDate}`
                break;
            case "week":
                var startDayOfWeek = startMoment.day(0)

                startDate = startDayOfWeek.format(US_DATE_FORMAT)
                endDate = startDayOfWeek.add(6, 'day').format(US_DATE_FORMAT)

                break;
            case "month":
                var startDayOfMonth = startMoment.date(1)

                startDate = startDayOfMonth.format(US_DATE_FORMAT)
                endDate = startDayOfMonth.add(1, 'month').subtract(1, 'day').format(US_DATE_FORMAT)

                break;
        }

        return {
            startDate,
            endDate,
            timePeriod
        }
    }
)

export default setTimePeriod