import APIFetch from 'api/utils/APIFetch'

function getBdcTimelineData(key, unitOfTime) {
    return APIFetch(`/api/bdc/calendar/${unitOfTime}`).then(res => res.map(entry => ({
            date: entry.Date,
            opportunities: entry.OpportunitiesCount,
            calls: entry.CallsCount,
            appointments: entry.AppointmentsCount
        })
    ))
}

export default getBdcTimelineData