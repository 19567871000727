import React from 'react'

//import components
import InspectionLayout from 'components/InspectionLayout'
import BoxWithHeader from 'components/BoxWithHeader'
import InspectionBoxLayout from 'components/InspectionBoxLayout'
import InspectionTypeCheckboxes from 'components/InspectionTypeCheckboxes'
import InspectionDetails from 'components/InspectionDetails'
import InspectionInfo from 'components/InspectionInfo'
import InspectionServices from 'components/InspectionServices'

export class MPVIContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            poNumber: null,
            tagNumber: null,
            typeOptions: [
                {
                    id: 0,
                    text: `Waiter`
                },
                {
                    id: 1,
                    text: `Express`
                },
                {
                    id: 2,
                    text: `Internal`
                }
            ],
            typeID: null,
            advisors: [],
            technicians: [],
            firstName: null,
            lastName: null,
            email: null,
            cellPhone: null,
            vin: null,
            vehicle: null,
            mileage: null,
            customerConcerns: [],
            customerRecommendations: [],
            schema: {
                sections: [
                    {
                        id: 1,
                        title: `Interior/Exterior`,
                        nodes: [
                            {
                                title: `Engine Oil`
                            },
                            {
                                title: `Brake Fluid Reservoir`
                            },
                            {
                                title: `Transmission (if equipped with a dipstick)`
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: `Onstar Diagnostics`,
                        nodes: [
                            {
                                title: `Onstar Active*`
                            },
                            {
                                title: `Enrolled in Advanced Diagnostics Report`
                            },
                            {
                                title: `Dealer Maintenance Notification`
                            },
                            {
                                title: `Service History/Recall Check`
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: `Engine Oil & Filter`,
                        nodes: [
                            {
                                title: `Engine Oil`
                            },
                            {
                                title: `Brake Fluid Reservoir`
                            },
                            {
                                title: `Transmission (if equipped with a dipstick)`
                            }
                        ]
                    },
                    {
                        id: 4,
                        title: `Lights`,
                        nodes: [
                            {
                                title: `Exterior Lights`
                            }
                        ]
                    },
                    {
                        id: 5,
                        title: `Windshield & Wipers`,
                        nodes: [
                            {
                                title: `Wiper Blade-Driver`
                            },
                            {
                                title: `Wiper Blade-Passenger`
                            },
                            {
                                title: `Windshield Condition`
                            },
                            {
                                title: `Cracks`
                            },
                            {
                                title: `Chips`
                            }
                        ]
                    },
                    {
                        id: 6,
                        title: `Battery`,
                        nodes: [
                            {
                                title: `Battery Condition`
                            },
                            {
                                title: `Battery Cables & Connections`
                            },
                            {
                                title: `Battery Voltage`,
                                hasInput: true
                            }
                        ]
                    },
                    {
                        id: 7,
                        title: `Visible Leak Inspection`,
                        nodes: [
                            {
                                title: `Engine Oil`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Transmission`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Engine Cooling System`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Power Steering`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Brake Fluid Reservoir`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Windshield Washer Fluid`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Transfer Case`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Fuel System`
                            },
                            {
                                title: `Driver Axel`,
                                hasInput: true,
                                hasButton: true
                            },
                            {
                                title: `Diesel Exhaust Fluid`,
                                hasInput: true,
                                hasButton: true
                            }
                        ]
                    },
                    {
                        id: 8,
                        title: `Driver Side Front`,
                        nodes: [
                            {
                                title: `psi before`,
                                hasInput: true
                            },
                            {
                                title: `psi set tp`,
                                hasInput: true
                            },
                            {
                                title: `Tread Depth 32nds`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `Wear Pattern/Damage`
                            }
                        ]
                    },
                    {
                        id: 9,
                        title: `Passenger Side Front`,
                        nodes: [
                            {
                                title: `psi before`,
                                hasInput: true
                            },
                            {
                                title: `psi set tp`,
                                hasInput: true
                            },
                            {
                                title: `Tread Depth 32nds`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `Wear Pattern/Damage`
                            }
                        ]
                    },
                    {
                        id: 10,
                        title: `Driver Side Rear`,
                        nodes: [
                            {
                                title: `psi before`,
                                hasInput: true
                            },
                            {
                                title: `psi set tp`,
                                hasInput: true
                            },
                            {
                                title: `Tread Depth 32nds`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `Wear Pattern/Damage`
                            }
                        ]
                    },
                    {
                        id: 11,
                        title: `Passenger Side Rear`,
                        nodes: [
                            {
                                title: `psi before`,
                                hasInput: true
                            },
                            {
                                title: `psi set tp`,
                                hasInput: true
                            },
                            {
                                title: `Tread Depth 32nds`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `Wear Pattern/Damage`
                            }
                        ]
                    },
                    {
                        id: 12,
                        title: `Rotation Needed`,
                        nodes: [
                            {
                                title: `Rotation Needed`,
                            }
                        ]
                    },
                    {
                        id: 13,
                        title: `Alignment Needed`,
                        nodes: [
                            {
                                title: `Alignment Needed`,
                            }
                        ]
                    },
                    {
                        id: 14,
                        title: `Balance Needed`,
                        nodes: [
                            {
                                title: `Balance Needed`,
                            }
                        ]
                    },
                    {
                        id: 15,
                        title: `Reset Tire Pressure Monitor`,
                        nodes: [
                            {
                                title: `Reset Tire Pressure Monitor`,
                            }
                        ]
                    },
                    {
                        id: 16,
                        title: `Tire Sealant Expiration Date`,
                        nodes: [
                            {
                                title: `Tire Sealant Expiration Date`,
                                hasInput: true
                            }
                        ]
                    },
                    {
                        id: 17,
                        title: `Tire Sealant Expiration Date`,
                        nodes: [
                            {
                                title: `Tire Sealant Expiration Date`,
                                hasInput: true
                            }
                        ]
                    },
                    {
                        id: 18,
                        title: `Front Brakes`,
                        nodes: [
                            {
                                title: `(mm) Driver Front`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `(mm) Passenger Front`,
                                hasInput: true,
                                hasScale: true
                            }
                        ]
                    },
                    {
                        id: 19,
                        title: `Rear Brakes`,
                        nodes: [
                            {
                                title: `(mm) Driver Front`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `(mm) Passenger Front`,
                                hasInput: true,
                                hasScale: true
                            }
                        ]
                    },
                    {
                        id: 20,
                        title: `Rear Brakes`,
                        nodes: [
                            {
                                title: `(mm) Driver Front`,
                                hasInput: true,
                                hasScale: true
                            },
                            {
                                title: `(mm) Passenger Front`,
                                hasInput: true,
                                hasScale: true
                            }
                        ]
                    },
                    {
                        id: 21,
                        title: `Brake System`,
                        nodes: [
                            {
                                title: `Brake System`
                            }
                        ]
                    },
                    {
                        id: 22,
                        title: `Parking Brake`,
                        nodes: [
                            {
                                title: `Parking Brake`
                            }
                        ]
                    },
                    {
                        id: 23,
                        title: `Inspect (Fit, Form & Function)`,
                        nodes: [
                            {
                                title: `Safety Belt Components`
                            },
                            {
                                title: `Accelerator Pedal`
                            },
                            {
                                title: `Passenger Compartment Air Filter`
                            },
                            {
                                title: `Floor Mat Fit & Wear`
                            },
                            {
                                title: `Compartment Lift Struts`
                            },
                            {
                                title: `Engine Air Filter`
                            },
                            {
                                title: `Hoses`
                            },
                            {
                                title: `Belts`
                            },
                            {
                                title: `Shocks and Struts`
                            },
                            {
                                title: `Steering Components`
                            },
                            {
                                title: `Axel Boots or DriveShaft & U-Joints`
                            },
                            {
                                title: `Exhaust System`
                            },
                        ]
                    },
                    {
                        id: 24,
                        title: `Check Operation`,
                        nodes: [
                            {
                                title: `Horn`
                            },
                            {
                                title: `Ignition Lock`
                            },
                            {
                                title: `Starter Switch`
                            },
                            {
                                title: `Evaporative Control System`
                            }
                        ]
                    },
                    {
                        id: 25,
                        title: `Lubricate`,
                        nodes: [
                            {
                                title: `Chassis Components*`
                            }
                        ]
                    },
                ]
            }
        }
    }

    componentDidMount() {
        //temporarily load dummy data
        this.setState({
            poNumber: 1234556,
            tagNumber: 234254,
            advisors: [
                {
                    id: 0,
                    name: `Karen`
                },
                {
                    id: 1,
                    name: `Ken`
                },
                {
                    id: 2,
                    name: `Dana`
                }
            ],
            technicians: [
                {
                    id: 0,
                    name: `Bo`
                },
                {
                    id: 1,
                    name: `Dude`
                },
                {
                    id: 2,
                    name: `Mary`
                }
            ],
            firstName: `Anne`,
            lastName: `Horn`,
            email: `annhorn@hotmail.com`,
            cellPhone: `8103342343`,
            vin: `XXXX`,
            vehicle: `2017 GMC Terrian`,
            mileage: `392421`,
            customerConcerns: [
                {
                    id: 1,
                    name: `[Primary]; OIL5QT - GM LUBE OIL FILTER - 5QT`,
                    serviceType: `XXX`,
                    hours: `XXX`,
                    labor: `XXX`,
                    parts: `XXX`,
                    total: `XXX`
                },
                {
                    id: 2,
                    name: `[Primary]; OIL5QT - GM LUBE OIL FILTER - 5QT`,
                    serviceType: `XXX`,
                    hours: `XXX`,
                    labor: `XXX`,
                    parts: `XXX`,
                    total: `XXX`
                },
                {
                    id: 3,
                    name: `[Primary]; OIL5QT - GM LUBE OIL FILTER - 5QT`,
                    serviceType: `XXX`,
                    hours: `XXX`,
                    labor: `XXX`,
                    parts: `XXX`,
                    total: `XXX`
                },
                {
                    id: 4,
                    name: `[Primary]; OIL5QT - GM LUBE OIL FILTER - 5QT`,
                    serviceType: `XXX`,
                    hours: `XXX`,
                    labor: `XXX`,
                    parts: `XXX`,
                    total: `XXX`
                }
            ],
            customerRecommendations: [
                {
                    id: 1,
                    name: `Fuel Induction Service - 3 pt`,
                    serviceType: `XXX`,
                    payType: `XXX`,
                    hours: `XXX`,
                    labor: `XXX`,
                    parts: `XXX`,
                    total: `XXX`,
                    addedBy: `XXX`
                },
            ]
        })
    }

    render(){
        const typeComponent = <InspectionTypeCheckboxes
            types={this.state.typeOptions}
            onChange={() => alert(`Type was chosen`)}
        ></InspectionTypeCheckboxes>
        
        const details = <InspectionDetails
            tagNumber={this.state.tagNumber}
        ></InspectionDetails>
        
        const information = <InspectionInfo
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            cellPhone={this.state.email}
            vin={this.state.vin}
            vehicle={this.state.vehicle}
            mileage={this.state.mileage}
        ></InspectionInfo>

        const services = <InspectionServices
            concernsArray={this.state.customerConcerns}
            recosArray={this.state.customerRecommendations}
        ></InspectionServices>
        
        const sections = this.state.schema.sections.map(section => 
            <BoxWithHeader
                key={section.id}
                titleText={section.title}
                content={
                    <InspectionBoxLayout
                        sectionID={section.id}
                        nodes={section.nodes}
                    ></InspectionBoxLayout>
                }
            ></BoxWithHeader>
        )

        return (
            <InspectionLayout
                poNumber={this.state.poNumber}
                tagNumber={this.state.tagNumber}
                typeComponent={typeComponent}
                detailsComponent={details}
                infoComponent={information}
                servicesComponent={services}
                mainContent={sections}
            ></InspectionLayout>
        )
    }
}

export default MPVIContainer
