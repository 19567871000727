import React from 'react'

import {
    BrowserRouter as Router
} from 'react-router-dom'

interface IBaseRouter {
    children: React.ReactNode
}

function BaseRouter({
    children
}: IBaseRouter) {
    return (
        <Router>{children}</Router>
    )
}

export default BaseRouter