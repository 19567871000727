import { Divider } from "@mui/material";
import LocalFilterStorage, { IRepairOrderFilterState } from "components/RepairOrderListView/helpers/filterLocalStorage";
import FilterChip from "componentsUI/TableView/components/FilterChip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnInstance } from "react-table";

export interface IWarrantyAlertFilter {
    column: ColumnInstance,
}

function WarrantyAlertFilter({
    column
}: IWarrantyAlertFilter){
    const [filters, setFilters] = useState<IRepairOrderFilterState>(LocalFilterStorage.readStorage())
    const setTableFilter = useMemo(() => column.setFilter, [column])

    const handleClick = useCallback(() => {

        const updatedFilters = {
            ...LocalFilterStorage.readStorage(),
            displayActiveWarrantyAlerts: !filters.displayActiveWarrantyAlerts
        }

        LocalFilterStorage.saveToStorage(updatedFilters)

        setFilters(updatedFilters)

    },[setFilters, filters])

    useEffect(() => {

        setTableFilter(filters.displayActiveWarrantyAlerts)

    }, [setTableFilter, filters.displayActiveWarrantyAlerts])

    return (
        <>
            <FilterChip
                key={`showActiveWarrantyAlertsChip`}
                text={`Show Warranty Alerts`}
                isActive={filters.displayActiveWarrantyAlerts}
                onClick={handleClick}
            ></FilterChip>
            <Divider orientation="vertical" variant="middle" flexItem />
        </>
    )
}

export default WarrantyAlertFilter