import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const XIcon = ({
    color,
    height,
    width,
    className
}) => {  
    const containerClassNames = classNames(
        `icon-container`,
        {[className]: className}
    )

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={containerClassNames} style={{
            height,
            width,
            transform: `translate(-50%, -50%)`
        }}>
            <path fill={color} d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
        </svg>
    )
}

XIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
}

XIcon.defaultProps = {
    color: `white`,
    height: 10,
    width: 10
}

export default XIcon