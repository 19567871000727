import React, { useEffect, useState } from "react";
import { FormControl, Grid } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { useAppSelector } from "store";
import addPreferredMake from "api/addPreferredMake";
import removePreferredMake from "api/removePreferredMake";

const DealerPreferredOEMSection = () => {
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);
  const { dealerPreferredMakes, dealer, allMakes } = useAppSelector(
    (state) => state.dealerForm
  );

  useEffect(()=>{
    setSelectedMakes(dealerPreferredMakes.map((item) => item.Make));
  },[dealerPreferredMakes]);

  const handleSelect = (selectedList: string[], selectedItem: string) => {
    setSelectedMakes(selectedList);
    addPreferredMake({ DealerID: dealer.ID, Make: selectedItem })
      .then(() => {
      })
      .catch((error) => {
        console.error("Error adding preferred make:", error);
      });
  };

  const handleRemove = (_,removedItem: string) => {
    removePreferredMake({DealerID: dealer.ID, Make: removedItem});
  };
  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Multiselect
          isObject={false}
          onRemove={handleRemove}
          onSelect={handleSelect}
          selectedValues={selectedMakes}
          options={allMakes}
          displayValue="name" 
        />
      </FormControl>
    </Grid>
  );
};

export default DealerPreferredOEMSection;
