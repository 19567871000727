import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.scss'

const InspectionDetailsGridLayout = ({
    children,
    className
}) => {  
    const containerClasses = classNames(
        styles.container,
        {[className]: className}
    )

    return (
        <div className={containerClasses}>
            {children}
        </div>
    )
}

InspectionDetailsGridLayout.propTypes = {
    children: PropTypes.array,
    className: PropTypes.string
}

export default InspectionDetailsGridLayout