import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const InspectionLayout = ({
    poNumber,
    tagNumber,
    typeComponent,
    detailsComponent,
    infoComponent,
    servicesComponent,
    mainContent
}) => {  
    return (
        <div className={styles.container}>
            <div className={styles.innerWrapper}>
                <div className={styles.headingWrapper}>
                    <div className={styles.headline}>PO#: {poNumber}</div>
                    <div className={styles.headline}>TAG#: {tagNumber}</div>
                    {typeComponent}
                </div>
                <div className={styles.infoWrapper}>
                    {detailsComponent}
                    {infoComponent}
                </div>
                {servicesComponent}
                <div className={styles.mainContentWrapper}>
                    {mainContent}
                </div>
            </div>
        </div>
    )
}

InspectionLayout.propTypes = {
    poNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    tagNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    typeComponent: PropTypes.object,
    detailsComponent: PropTypes.object,
    infoComponent: PropTypes.object,
    servicesComponent: PropTypes.object,
    mainContent: PropTypes.array
}

export default InspectionLayout