import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

export interface IEmptyRecipientListProps {
  open: boolean;
  toggleDialog: () => void;
}

const EmptyRecipientList = (props: IEmptyRecipientListProps) => {
  return (
    <Stack spacing={2} alignItems="center">
      <Typography>There's no recipients for this dealer yet.</Typography>
      <Button variant="contained" onClick={() => props.toggleDialog()}>
        Add New Recipient
        <Add />
      </Button>
    </Stack>
  );
};

export default EmptyRecipientList;
