import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { User } from 'api/types/user'
import authenticateUser from 'api/authenticateUser'
import getDealer from 'api/getDealer'
import getUser from 'api/getUser'
import { Dealer } from 'api/types/dealer'
import { Modes, modeSet } from 'modules/app'
import { setUserIsLoggedIn } from 'modules/auth'
import { setDealer, resetDealer } from 'modules/dealer'
import { reset as resetUser, userSet } from 'modules/user'
import { AppThunk } from 'store'
import moment from 'moment'
import getDealerFeatures from 'api/getDealerFeatures'

interface ILogin {
    isError: boolean
    infoText: string
    isLoading: boolean
}

let initialState: ILogin = {
    isLoading: false,
    isError: false,
    infoText: ``,
}

const slice = createSlice({
    name: `login`,
    initialState,
    reducers: {
        authStart(state) {
            state.isLoading = true
            state.isError = false
            state.infoText = ``
        },
        authSuccessful(state) {
            return initialState
        },
        authFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
        },
    }
})

const {
    authStart,
    authFailure,
    authSuccessful,
} = slice.actions

export default slice.reducer

const handleError = (error: any): AppThunk => async dispatch => {

    let errorMessage = error.Message !== undefined ? error.Message : error.message

    dispatch(modeSet(Modes.LOGIN))

    const getMessage = (msg: string): string => {
        switch (msg) {
            case `NetworkError when attempting to fetch resource.`:
                return `An error has occured while attempting to connect with the server. Please check your connection and contact support if the problem persists.`
            default:
                return msg
        }
    }

    dispatch(authFailure(getMessage(errorMessage)))
}

export const login = (email: string, password: string): AppThunk => async dispatch => {
    try {

        dispatch(authStart())

        const response: Response = await authenticateUser({ email, password })

        const json = await response.json();

        const access_token = json["access_token"]
        const expires_in_seconds = json["expires_in"]

        const expiration_datetime = moment.utc().add(expires_in_seconds, 'seconds')

        dispatch(initialize(access_token, expiration_datetime.toISOString()))

        dispatch(authSuccessful())

    } catch (error: any) {

        dispatch(handleError(error))

    }
}

export const checkAuth = (): AppThunk => async dispatch => {
    try {

        const token = localStorage.getItem(`crmToken`)
        const dealerId = parseInt(localStorage.getItem(`crmDealerId`) || `0`)
        const expiration_datetime = localStorage.getItem(`crmTokenExpiration`)

        if (token === null || 
            dealerId === 0 || 
            expiration_datetime == null ||
            moment.utc().isAfter(expiration_datetime)) { // check for token expiration

            // Clear local storage to start from a clean slate.
            dispatch(clearLocalStorage())

            // Redirect the user to the login page.
            dispatch(modeSet(Modes.LOGIN))

            return
        }

        dispatch(initialize(token, expiration_datetime))

    } catch (error: any) {

        dispatch(handleError(error))

    }
}

const initialize = (token: string, expiration_datetime: string): AppThunk => async dispatch => {

    try {
    
        localStorage.setItem(`crmToken`, token)
        localStorage.setItem(`crmTokenExpiration`, expiration_datetime)

        const user: User = await getUser()

        if (user.DealerUsers === null || user.DealerUsers.length === 0) {
            throw new Error("Authentication error. This user is not associated with a dealership.")
        }

        // Choose the first DealerID for now. In the future, we may need
        // a feature that lets the user change the dealership context
        // since a user can belong to more than one dealership.
        const savedDealerId = parseInt(
            localStorage.getItem(`crmDealerId`) || `0`
          );
          
        const dealerId = savedDealerId !== 0 && savedDealerId !== null
        ? savedDealerId 
        : user.DealerUsers[0].DealerID;

        localStorage.setItem(`crmDealerId`, dealerId.toString())

        dispatch(userSet(user))
    
        const dealer: Dealer = await getDealer(dealerId)

        // To improve performance, we will request the dealer features separately.
        // By requesting the DealerDTO instead of the DealerFlatDTO, we will improve
        // request time by not including all of the messages, repair orders, customers, etc.
        const dealerFeatures = await getDealerFeatures(dealerId)
        dealer.DealerFeatures = dealerFeatures
    
        dispatch(setDealer(dealer))
    
        dispatch(setUserIsLoggedIn(true))
    
        dispatch(modeSet(Modes.DEFAULT))

    } catch(error: any) {

        dispatch(handleError(error))

    }

}

const clearLocalStorage = (): AppThunk => dispatch => {
    localStorage.removeItem(`crmToken`)
    localStorage.removeItem(`crmTokenExpiration`)
    localStorage.removeItem(`crmDealerId`)
}

export const logOut = (): AppThunk => dispatch => {

    try {

        dispatch(modeSet(Modes.LOGIN))
    
        dispatch(clearLocalStorage())
    
        dispatch(resetDealer())
    
        dispatch(resetUser())
    
        dispatch(setUserIsLoggedIn(false))

    } catch(error: any) {

        dispatch(handleError(error))

    }

}