import { Grid } from "@mui/material"
import DateSelector from "components/Dashboard/components/DateSelector"
import React, { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "store"
import { ISetDates } from "types/ISetDates"
import useSubscribeToReportingOverview from "../../hooks/useSubscribeToReportingOverview"
import setDates from "../../thunks/setDates"
import VideoMpviOverview from "../VideoMpvi/VideoMpviOverview"
import WarrantyAlertOverview from "../WarrantyAlert/WarrantyAlertOverview"
import SmsOverview from "../SMS/SmsOverview"
import InteractiveMaintenanceOverview from "../iRM/InteractiveMaintenanceOverview"
import CsiAlertOverview from "../CSIAlert/CsiAlertOverview"
import styles from './styles.module.scss'
function ReportingDashboard(){
    const dispatch = useAppDispatch()
    useSubscribeToReportingOverview()

    const {
        loadingStates,
        startDate,
        endDate,
        reporting
    } = useAppSelector(state => state.reporting)

    const onDateRangeApplied = useCallback((args: ISetDates) => {
        dispatch(setDates(args))
    }, [dispatch])

    const isLoading = 
        loadingStates.isWarrantyAlertLoading ||
        loadingStates.isInteractiveMaintenanceLoading ||
        loadingStates.isCsiAlertLoading ||
        loadingStates.isSmsLoading ||
        loadingStates.isVideoMpviLoading

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                    <DateSelector
                        startDate={startDate}
                        endDate={endDate}
                        isLoading={isLoading}
                        onDateRangeApplied={onDateRangeApplied}/>
                </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
                <Grid container item spacing={2} sm={12} md={6} lg={4} className={styles.flexColumn}>
                    <Grid item xs={12}>
                        <WarrantyAlertOverview
                            data={reporting.WarrantyAlertReportingOverview}
                            isLoading={loadingStates.isWarrantyAlertLoading}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CsiAlertOverview
                            data={reporting.CSIAlertReportingOverview}
                            isLoading={loadingStates.isCsiAlertLoading}/>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} sm={12} md={6} lg={4}  className={styles.flexColumn}>
                    <Grid item xs={12}>
                        <SmsOverview
                            data={reporting.SmsReportingOverview}
                            isLoading={loadingStates.isSmsLoading}/>
                    </Grid>
                    <Grid item xs={12}>
                        <VideoMpviOverview
                            data={reporting.VideoMpviReportingOverview}
                            isLoading={loadingStates.isVideoMpviLoading}/>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} sm={12} md={6} lg={4}  className={styles.flexColumn}>
                    <Grid item xs={12}>
                        <InteractiveMaintenanceOverview
                            data={reporting.InteractiveMaintenanceReportingOverview}
                            isLoading={loadingStates.isInteractiveMaintenanceLoading}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ReportingDashboard