import { WarrantyAlertReportingByUserOverviewViewModel } from "api/types/reporting/WarrantyAlertReportingOverview";
import React, { useState } from "react";
import { Box } from "@mui/material";
import formatPrice from "utils/formatPrice";
import AvatarIcon from "../../AvatarIcon";
import { stringToColor } from "utils/stringToColor";
import UserRepairOrderTable from "./components/UserRepairOrderTable";
import { ColumnDefinition } from "../../../../Table/types";
import { CollasibleTableRow, TableHeader } from "components/Table";

export interface IWarrantyAlertUserStatsSectionProps {
  statsByUser: WarrantyAlertReportingByUserOverviewViewModel[]
}

export default function WarrantyAlertUserStatsSection({
  statsByUser
}: IWarrantyAlertUserStatsSectionProps){
    const [userExpanded, setUserExpanded] = useState<string>('')

    if(statsByUser.length === 0)
      return null

    const userColumnDefinitions: ColumnDefinition[] = [
        {
            content: ``,
            flex: .1,
            renderCell: (name: string) => {
              return <AvatarIcon name={name} color={stringToColor(name)}/>
            }
        },
        {
            content: `Advisor`,
            flex: .5
        },
        {
            content: `Total ROs`,
            flex: .25,
            isNumber: true
        },
        {
            content: `Cured`,
            flex: .25,
            isNumber: true
        },
        {
            content: `Uncured`,
            flex: .25,
            isNumber: true
        }
    ]

    const handleUserExpand = (panel: string) => {
      const newValue = userExpanded === panel ? '' : panel
      setUserExpanded(newValue)
    }

    return (
      <Box>
        <TableHeader columnDefinitions={userColumnDefinitions} offset={1}/>
        <Box>
          {statsByUser.map((user, index) => {
            const isExpanded = userExpanded === `user-${index}` 
                 
            const userRowData = {
              avatar: user.Name,
              advisor: user.Name,
              totalRepairOrders: user.TotalRepairOrders,
              totalCuredDollarAmount: formatPrice(user.TotalCuredDollarAmount),
              totalUncuredDollarAmount: formatPrice(user.TotalUncuredDollarAmount)
            }

            return (
              <CollasibleTableRow
                key={`collapsible-user-table-row-${index}`}
                isExpanded={isExpanded}
                columnDefinitions={userColumnDefinitions}
                rowData={userRowData}
                offset={1}
                onClick={() => handleUserExpand(`user-${index}`)}>
                  
                <UserRepairOrderTable 
                  isExpanded={isExpanded}
                  userRepairOrders={user.RepairOrders}/>
              </CollasibleTableRow>
            )
          })}
        </Box>
      </Box>
    )
}