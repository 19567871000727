import { BaseMessage } from 'api/types/message'
import { BaseMaintenanceSchedule } from './maintenanceSchedule';
import { BaseRepairOrder } from './repairOrder';
import formatPhone from 'utils/formatPhone';

export interface BaseCustomer {
    ID: number
    DealerID: number
    DmsID: string | null
    Address1: string | null
    Address2: string | null
    CellPhone: string | null
    City: string | null
    Country: string | null
    Email: string | null
    FirstName: string | null
    FullName: string
    HomePhone: string | null
    LastName: string | null
    Phone: string | null
    PostalCode: string | null
    PreferredChannel: number
    Region: string | null
    StreetAddress: string | null
    Status: number 
    WorkPhone: string | null
}

export interface Customer extends BaseCustomer {
    MaintenanceSchedules: BaseMaintenanceSchedule[] | null
    Messages: BaseMessage[] | null
    RepairOrders: BaseRepairOrder[] | null
}

// This function uses the same coalescing logic as the API
// to determine which of the customer phone numbers we should display.
// If none are available, only then should we return an empty string.
export const getDisplayPhone = (customer: BaseCustomer): string => {
    return getPhoneOrNah(customer.CellPhone) ??
        getPhoneOrNah(customer.HomePhone) ??
        getPhoneOrNah(customer.WorkPhone) ??
        '';
}

// Returns the phone when valid. Otherwise, returns null.
const getPhoneOrNah = (phone: string | null): string | null => {
    if (phone === null || phone.trim() === "") return null;
    
    return formatPhone(phone);
}