import React, { useCallback, useMemo } from 'react'
import { Box, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from 'store'
import { createChatConversation, gotoStepTwo, Modes, rosSelectors, subFilterValueSet, usersSelectors, wentBack } from 'components/ChatModule/modules'

type BasicListItem = {
    id: number
    text: string
}

function PanelListView() {
    const dispatch = useAppDispatch()
    const users = useAppSelector(usersSelectors.selectAll)
    const repairOrders = useAppSelector(rosSelectors.selectAll)

    const {
        mode,
        selectedUserID,
        subFilterValue: filterValue,
    } = useAppSelector(state => state.chat)

    const {
        ID: dealerID,
    } = useAppSelector(state => state.dealer)

    const userID = useAppSelector(state => state.user.ID) || 0

    const handleItemClick = useCallback((id: number) => {
        if (mode === Modes.SELECT_RECIPIENT) {
            dispatch(gotoStepTwo(dealerID, id))
        }
        if (mode === Modes.SELECT_REPAIR_ORDER) {
            dispatch(createChatConversation(dealerID, id, [userID, selectedUserID]))
        }
    }, [dispatch, mode, selectedUserID, dealerID, userID])

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(subFilterValueSet(event.target.value))
    }

    const text = `Start a new conversation - Select a ${mode === Modes.SELECT_RECIPIENT ? `recipient` : `repair order`}`

    const rawListItems: BasicListItem[] = useMemo(() => {
        let items: BasicListItem[] = []

        if (mode === Modes.SELECT_RECIPIENT) {
            items = users.map(user => ({
                id: user.id,
                text: user.email,
            }))
        }

        if (mode === Modes.SELECT_REPAIR_ORDER) {
            items = repairOrders.map(ro => ({
                id: ro.id,
                text: ro.number,
            }))
        }

        return items
    }, [repairOrders, users, mode])

    const listItems = useMemo(() => rawListItems.filter(item => item.text.toUpperCase().includes(filterValue.toUpperCase())).map(item => (
        <ListItem
            key={item.id}
            button
            onClick={() => handleItemClick(item.id)}
            selected={mode === Modes.SELECT_RECIPIENT && selectedUserID === item.id}
        >
            <ListItemText primary={item.text} />
        </ListItem>
    )), [filterValue, rawListItems, handleItemClick, selectedUserID, mode])

    return (
        <Box
            overflow={`hidden`}
            display={`grid`}
            gridTemplateRows={`min-content min-content 1fr`}
        >
            <Box p={1}>
                <Box
                    display={`grid`}
                    gridTemplateColumns={`min-content 1fr`}
                    gap={8}
                    alignItems={`center`}
                >
                    <Box
                        gridColumn={`1/-1`}
                    >
                        <Typography
                            variant={`caption`}
                        >{text}</Typography>
                    </Box>
                    <IconButton
                        size={`small`}
                        onClick={() => dispatch(wentBack())}
                    >
                        <ArrowBack />
                    </IconButton>
                    <TextField
                        label={`Search Username`}
                        size={`small`}
                        variant={`outlined`}
                        onChange={handleFilterChange}
                        InputProps={{
                            style: {
                                fontSize: `12px`,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: `12px`,
                            },
                        }}
                    ></TextField>
                </Box>
            </Box>
            <Divider />
            <List
                disablePadding
                style={{
                    overflowY: `auto`,
                }}
            >{listItems}</List>
        </Box>
    );
}

export default PanelListView