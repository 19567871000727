import { TIME_PICKER_DATE_FORMAT } from "constants/date.constants";
import { NO_VALID_TIMEZONE_MESSAGE } from "constants/messages.constants";
import { getTimezone } from "constants/timezones.constants";
import * as moment from "moment-timezone";

function convertUtcToTimezone(utcDate: Date, timezone: string) {
  const tz = getTimezone(timezone);

  if (!moment.tz.names().includes(tz)) {
    console.error(NO_VALID_TIMEZONE_MESSAGE);
    return null;
  }

  const utcMoment = moment.utc(utcDate);

  const timezoneMoment = utcMoment.tz(tz);

  return timezoneMoment.format(TIME_PICKER_DATE_FORMAT);
}

function convertStringToUtcFromTimezone(
  timezoneDate: string,
  timezone: string
) {
  const tz = getTimezone(timezone);

  if (!moment.tz.names().includes(tz)) {
    console.error(NO_VALID_TIMEZONE_MESSAGE);
    return null;
  }

  const timezoneMoment = moment.tz(timezoneDate, tz);

  const utcMoment = timezoneMoment.clone().tz("UTC");

  return utcMoment.format();
}

export { convertUtcToTimezone, convertStringToUtcFromTimezone };
