import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import useSubscribeToUnreadMessages from './hooks/useSubscribeToUnreadMessages'
import { customersWithUnreadMessagesSelectors, setDialogClosed, setDialogOpen } from './modules'
import { customerMessagesSelectors, updateCustomerID } from 'modules/customerMessages'
import useSubscribeToCustomerMessages from 'hooks/useSubscribeToCustomerMessages'
import DialogConversationContent from 'components/DialogConversationContent'
import { Customer, getDisplayPhone } from 'api/types/customer'

function UnreadConversationsDialog() {

    const [customer, setCustomer] = useState<Customer | undefined>(undefined)

    const dispatch = useAppDispatch()

    const {
        isOpenInToolbar
    } = useAppSelector(state => state.app)

    const {
        isOpen
    } = useAppSelector(state => state.customersWithUnreadMessages)

    const {
        isOpen: roAlertDialogIsOpen
    } = useAppSelector(state => state.roAlertDialog)

    const customersWithUnreadMessages = useAppSelector(customersWithUnreadMessagesSelectors.selectAll)
    const customerConversation = useAppSelector(customerMessagesSelectors.selectAll)

    useSubscribeToUnreadMessages()
    useSubscribeToCustomerMessages()

    const handleButtonClick = useCallback(() => {

        dispatch(setDialogClosed())

        setCustomer(undefined)

    }, [dispatch])

    useEffect(() => {

        const hasUnreadCustomerMessages = customersWithUnreadMessages.length > 0

        if(!isOpen && !roAlertDialogIsOpen && hasUnreadCustomerMessages && isOpenInToolbar) {

            dispatch(setDialogOpen())

            const customer = customersWithUnreadMessages[0]

            setCustomer(customer)

            updateCustomerID(customer.ID)

        }

    }, [isOpen, roAlertDialogIsOpen, isOpenInToolbar, customersWithUnreadMessages, dispatch])

    const content = useMemo(() => {
        if (isOpen && customer !== undefined && customer.Messages !== null) {
            
            // get the repair order id from the last unread message received.
            const unreadMessages = customer.Messages.filter(msg => !msg.HasAdvisorViewed).reverse()

            if(unreadMessages.length > 0) {

                const repairOrderId = unreadMessages[0].RepairOrderID

                const ro = customer.RepairOrders?.find(ro => ro.ID === repairOrderId)

                const roText = ro !== undefined ? `, RO#: ${ro.RONumber}` : ``

                const contentText = `${customer.FullName} ${getDisplayPhone(customer)}${roText}`
    
                return (                
                    <DialogConversationContent
                        contentText={contentText}
                        repairOrderId={repairOrderId}
                        customerId={customer.ID}
                        messages={customerConversation}
                    ></DialogConversationContent>
                )

            }

        }

        return null
    }, [customerConversation, isOpen, customer])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={isOpen}
        >
            <DialogTitle>New unread message</DialogTitle>
            {content}
            <DialogActions>
                <Button
                    onClick={handleButtonClick}
                >Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UnreadConversationsDialog