import { useEffect } from 'react'
import { proxy } from 'signalr'
import { Signal } from 'types/signalr'

// onSent must be memoized
function useSubscribeToSignal(signal: Signal, onSent: (...args: any[]) => any): void {
    useEffect(() => {
        proxy.on(signal, onSent)

        return () => {
            proxy.off(signal, onSent)
        }
    },[signal, onSent])
}

export default useSubscribeToSignal