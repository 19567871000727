import React, { useCallback, useEffect, useState } from "react"
import { useAppSelector } from "store"
import moment from "moment"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import theme from 'assets/theme'
import { US_DATE_FORMAT } from "constants/date.constants"
import { ISetDates } from "types/ISetDates"

export interface IDateRangeDialog {
    onClose(): void,
    onDateRangeApplied(setDatesPayload: ISetDates): void,
    startDate: string,
    endDate: string
}

interface IDateRange {
    startDate: Date,
    endDate: Date,
}

interface ISelectionRange extends IDateRange {
    key: `selection`
}

interface ISelectionRangeEvent {
    selection: IDateRange
}

function DateRangeDialog({
    onClose,
    onDateRangeApplied,
    startDate,
    endDate,
}: IDateRangeDialog) {
    const [dateRange, setDateRange] = useState<ISelectionRange>({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        });

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const onDateRangeChange = useCallback((event: ISelectionRangeEvent) => {

        setDateRange({
            startDate: event.selection.startDate,
            endDate: event.selection.endDate,
            key: `selection`
        })

    }, [])

    const onApply = useCallback(() => {

        const formatDate = date => moment(date).format(US_DATE_FORMAT)

        onDateRangeApplied({
            dealerID: dealerID,
            startDate: formatDate(dateRange.startDate),
            endDate: formatDate(dateRange.endDate)
        })

        onClose()

    }, [dealerID, dateRange.startDate, dateRange.endDate, onDateRangeApplied, onClose])

    useEffect(() => {

        const startingDate = new Date(startDate)
        const endingDate = new Date(endDate)

        setDateRange({
            startDate: new Date(startingDate.setDate(startingDate.getDate())),
            endDate: new Date(endingDate.setDate(endingDate.getDate())),
            key: `selection`
        })

    }, [startDate, endDate])

    return (
        <Dialog
            open
            maxWidth={'lg'}>

            <DialogContent>
                
                <DateRangePicker
                    ranges={[dateRange]}
                    onChange={onDateRangeChange}
                    maxDate={new Date()}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    direction={`horizontal`}
                    rangeColors={[theme.palette.primary.main]}/>

            </DialogContent>
                
            <DialogActions>
                <Button
                    onClick={onClose}
                >Cancel</Button>
                <Button
                    variant={'contained'}
                    onClick={onApply}
                    color={`primary`}
                >Apply</Button>

            </DialogActions>
        </Dialog>
    )
}

export default React.memo(DateRangeDialog)