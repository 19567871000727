import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import CheckBox from 'components/CheckBox'

const InspectionTypeCheckboxes = ({
    types,
    onChange
}) => {  
    const checkBoxes = types.map(type => 
        <CheckBox
            key={type.id}
            id={type.id}
            labelText={type.text}
            name={`inspectionTypes`}
            type={`radio`}
            symbolColor={`#223A78`}
            className={styles.checkbox}
            onChange={onChange}
        ></CheckBox>
    )

    return (
        <div className={styles.container}>
            {checkBoxes}
        </div>
    )
}

InspectionTypeCheckboxes.propTypes = {
    types: PropTypes.array,
    onChange: PropTypes.func
}

export default InspectionTypeCheckboxes