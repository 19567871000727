import { useMemo, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import getBdcTimelineData from 'utils/getBdcTimelineData'
import { 
    useAppSelector,
    useAppDispatch
} from 'store'
import {
    setCalendarData,
    setIsLoadingCalendarData,
    setIsErrorCalendarData
} from 'modules/bdcDashboard'

function useSubscribeToCalendarData() {
    const dispatch = useAppDispatch()
    const {
        selectedCalendarTab,
    } = useAppSelector(
        state => state.bdcDashboard
    )

    const unitOftime = useMemo(() => {
        switch (selectedCalendarTab) {
            case 0:
                return `day`
            case 1:
                return `week`
            case 2:
                return`month`
        }
    }, [selectedCalendarTab])

    const {
        isLoading,
        isError,
    } = useQuery([`bdcCalendarData`, unitOftime], getBdcTimelineData, {
        onSuccess: (data: any) => {
            const labels: string[] = []
            const series: any[][] = [
                [], 
                [], 
                []
            ]

            data.map(entry => {
                const dayFormat = moment.utc(entry.date).format(`MMM Do`)
                const monthFormat = moment.utc(entry.date).format(`MMM`)
                const formattedDate = unitOftime === `month` ? monthFormat : dayFormat
                labels.push(formattedDate)
                series[0].push(entry.opportunities)
                series[1].push(entry.calls)
                series[2].push(entry.appointments)
                return null
            })
            dispatch(setCalendarData({
                calendarLabels: labels,
                calendarSeries: series
            }))
        }
    })

    useEffect(() => {      
        dispatch(setIsErrorCalendarData(isError))
    }, [isError, dispatch])
    
    useEffect(() => {
        dispatch(setIsLoadingCalendarData(isLoading))
    }, [isLoading, dispatch])
}

export default useSubscribeToCalendarData