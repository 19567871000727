import { IListRepairOrder } from "components/RepairOrderListView/types/ListRepairOrder"

const name = `pinnedROsCrmListView`

export function getPinnedItems(): Array<any> {
    return JSON.parse(localStorage.getItem(name) || `[]`)
}

export function setPinnedItems(items: Array<any>): void {
    localStorage.setItem(name, JSON.stringify(items))
}

export function addPinnedItem(id: number): void {
    const items = getPinnedItems()

    items.push(id)
    setPinnedItems(items)
}

export function isItemPinned(id: number): boolean {
    const items = getPinnedItems()
    return items.includes(id)
}

export function removePinnedItem(id: number): void {
    const items = getPinnedItems()

    items.splice(items.indexOf(id), 1)

    setPinnedItems(items)
}

// orders the repair orders with the pinned items displaying at the top of the list.
export function orderRepairOrders(repairOrders: IListRepairOrder[]) : IListRepairOrder[] {
    return repairOrders.sort((a, b) => b.RONumber.localeCompare(a.RONumber, navigator.languages[0] || navigator.language, { numeric: true }))
        .sort((a, b) => b.isPinned ? 1 : a.isPinned ? -1 : 0)
}
