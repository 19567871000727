import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    useAppDispatch,
    useAppSelector
} from 'store'
import SearchField from '../SearchField'
import SearchByField from '../SearchByField'
import SearchIcon from '@mui/icons-material/Search';
import { searchForCustomer } from 'modules/customerSearchView'
import SubmitButton from 'componentsUI/SubmitButton'
import styles from './styles.module.scss'
import CustomerSearchTypes from 'enums/customerSearchTypes'

export interface ICustomerSearchFormProps {
    search: string
    searchBy: CustomerSearchTypes
  }

function SearchForm() {
    const [hasEmptyFields, setHasEmptyFields] = useState<boolean>(true)

    const dispatch = useAppDispatch()
    
    const {
        isSearching,
    } = useAppSelector(
        state => state.customerSearchView
    )

    const {register, watch, formState: { errors }, handleSubmit} = useForm<ICustomerSearchFormProps>({
        defaultValues: {
            search: ``,
            searchBy: CustomerSearchTypes.PHONE
        }
    })

    const searchValues = watch()

    const onSubmit: SubmitHandler<ICustomerSearchFormProps> = data => {

        console.log(data)
        
        dispatch(searchForCustomer({
            searchBy: data.searchBy,
            search: data.search
        }))
    }

    useEffect(() => {

        setHasEmptyFields(searchValues.search === ``)

    }, [searchValues])
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.container}>
                <SearchField register={register}></SearchField>
                <SearchByField register={register}></SearchByField>
                <SubmitButton
                    canSubmit={!hasEmptyFields && Object.keys(errors).length === 0}
                    isLoading={isSearching}
                    startIcon={<SearchIcon />}
                    text={`Search`}
                ></SubmitButton>
            </div>
        </form>
    )
}

export default SearchForm