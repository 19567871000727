import { CSIAlertReportingOverviewViewModel } from "api/types/reporting/CSIAlertReportingOverviewViewModel";
import React from "react";
import SummerySectionCell from "../../SummerySectionCell";
import SummerySectionContainer from "../../SummerySectionContainer";

export interface ICsiAlertSummerySectionProps {
    data: CSIAlertReportingOverviewViewModel
}

export default function CsiAlertSummerSection({
    data
}: ICsiAlertSummerySectionProps) {
    return (
        <SummerySectionContainer>
            <SummerySectionCell
                header={`Total CSI Alerts`}
                value={`${data.TotalAlerts}`}/>
            <SummerySectionCell
                header={`Total SMS Updates`}
                value={`${data.TotalSmsUpdates}`}/>
        </SummerySectionContainer>
    )
}