import React from 'react'
import TextLoader from 'componentsUI/TextLoader'

export interface ITextLine {
    isLoading: boolean
    label: string
    text: string
}

function TextLine({
    isLoading,
    label,
    text,
}: ITextLine) {
    return (
        <TextLoader
            isLoading={isLoading}
        ><span style={{
            fontWeight: 700,
        }}>{label}:</span> {text}</TextLoader>
    )
}

export default React.memo(TextLine)