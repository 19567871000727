import React from 'react'
import {
    TextField,
} from '@mui/material'
import { UseFormRegister } from 'react-hook-form'
import { ICustomerSearchFormProps } from '../SearchForm'

interface ISearchFieldProps {
    register: UseFormRegister<ICustomerSearchFormProps>
}

function SearchField({
    register
}: ISearchFieldProps) {
    return (
        <TextField
            {...register(`search`, { required: true})}
            label={`Search`}
            size={`small`}
        ></TextField>
    )
}

export default SearchField