import { Box } from "@mui/material";
import AppRoute from "components/AppRoute";
import SchedulingDashboard from "components/Scheduling/components/SchedulingDashboard";
import pages from "pages";
import React from "react";
import { Switch } from 'react-router-dom'

function SchedulingRouter() {
    return (
        <Box sx={{paddingTop: `20px`}}>
            <Switch>
                <AppRoute exact path={pages.SCHEDULING.route} page={pages.SCHEDULING}>
                    <SchedulingDashboard/>
                </AppRoute>
            </Switch>
        </Box>
    )
}

export default SchedulingRouter