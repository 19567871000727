import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Box, FormControl, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField,
} from '@mui/material'
// import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'
import { ColumnInstance, TableState } from 'react-table'
import FilterChip from '../FilterChip'
import { Clear } from '@mui/icons-material'
import { useDebouncedCallback } from 'use-debounce'

export enum filterTypes {
    GLOBAL,
    COLUMN,
}

export interface IFilterBar {
    filterType: filterTypes,
    globalFilter(value: string | undefined): void
    columns: ColumnInstance[]
    filters: TableState['filters']
}

function FilterBar({
    filterType = filterTypes.COLUMN,
    globalFilter,
    columns,
    filters,
}: IFilterBar) {
    const [filterValue, setFilterValue] = useState<string>(``)
    const [selectedID, setSelectedID] = useState<string>(``)

    const setColumnFilter = useCallback((value: string | undefined) => {
        if (filterType === filterTypes.GLOBAL) {
            globalFilter(value)
        } else if (filterType === filterTypes.COLUMN) {
            columns.find(column => column.id === selectedID)?.setFilter(value)
        } else {
            throw new Error(`Must define a valid filter type`)
        }
    }, [columns, selectedID, filterType, globalFilter])

    const handleSelect = (event: SelectChangeEvent<string>) => {
        setSelectedID(String(event.target.value))
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setFilterValue(value)
        handleChangeDebounce(value)
    }

     const handleChangeDebounce = useDebouncedCallback((value) => 
        setColumnFilter(value === `` ? undefined : value)
    ,500)
 
    const handleClear = () => {
        setColumnFilter(undefined)
        setFilterValue(``)
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setColumnFilter(filterValue)
        setFilterValue(filterValue)
        setSelectedID(``)
    }

    const isFiltering = useMemo(() => {
        return filterValue !== ``
    }, [filterValue])

    const [
        chipIDs,
        chips
    ] = useMemo(() => {
        const ids: string[] = []

        const baseChips = columns.filter(column => filters.some(filter => filter.id === column.id)).map(column => {
            const isCustomFilterChip = column.Filter !== undefined

            if ((isFiltering && column.id === selectedID) || isCustomFilterChip) {
                return null
            }
            
            ids.push(column.id)

            return (
                <FilterChip
                    key={column.id}
                    text={`${column.Header}: ${column.filterValue}`}
                    isActive
                    onClick={() => column.setFilter(undefined)}
                ></FilterChip>
            )
        })

        const customChips = columns.filter(column => column.Filter !== undefined).map(column => (
            <React.Fragment key={column.id}>
                {column.render('Filter')}
            </React.Fragment>
        ))

        return [
            ids,
            [customChips, baseChips],
        ]
    },[columns, filters, isFiltering, selectedID])

    const menuItems = useMemo(() => {
        return columns.filter(column => [
            column.canFilter,
            column.isVisible,
            column.showInFilterMenu,
            !chipIDs.includes(column.id)
        ].every(t => t)).map(column => (
            <MenuItem dense key={column.id} value={column.id} style={{
                fontSize: `12px`
            }}>{column.Header}</MenuItem>
        ))
    }, [columns, chipIDs])

    useEffect(() => {
        if (!isFiltering && selectedID === ``) {
            const columnID = columns.filter(column => column.canFilter).find(column => !chipIDs.includes(column.id))?.id
            if (columnID !== undefined) {
                setSelectedID(columnID)
            }
        }
    }, [isFiltering, selectedID, chipIDs, columns])

    return (
        <Box
            borderBottom={`1px solid #aaaaaa`}
            display={`grid`}
            gridAutoFlow={`column`}
            p={`5px`}
            columnGap={1}
            justifyContent={`flex-start`}
            alignItems={`center`}
        >
            <Box
                display={`grid`}
                gridTemplateColumns={filterType === filterTypes.GLOBAL ? `200px` : `100px 200px min-content`}
                columnGap={10}
                component={`form`}
                onSubmit={handleSubmit}
            >
                {filterType !== filterTypes.GLOBAL &&
                    <FormControl
                        size={`small`}
                        margin={`none`}
                    >
                        <Select
                            id={`filter-bar-select`}
                            style={{
                                fontSize: `12px`
                            }}
                            onChange={handleSelect}
                            value={selectedID}
                        >{menuItems}</Select>
                    </FormControl>
                }
                <TextField
                    size={`small`}
                    margin={`none`}
                    InputProps={{
                        style: {
                            fontSize: `12px`,
                        },
                        endAdornment: (
                            <InputAdornment
                                position={`end`}
                            >
                                <IconButton
                                    size={`small`}
                                    disabled={!isFiltering}
                                    onClick={handleClear}
                                >
                                    <Clear style={{
                                        fontSize: `14px`,
                                    }}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    value={filterValue}
                    onChange={handleChange}
                ></TextField>
                {filterType !== filterTypes.GLOBAL &&
                    <IconButton
                        size={`small`}
                        disabled={!isFiltering}
                        type={`submit`}
                    >
                        <FilterListIcon fontSize={`small`} />
                    </IconButton>
                }
            </Box>
            {chips}
        </Box>
    );
}

export default React.memo(FilterBar)