import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import Button from 'components/Button'
import InspectionCheckboxCell from 'components/InspectionCheckboxCell'
import InspectionRadioCell from 'components/InspectionRadioCell'
import InspectionStatusCheckboxGroup from 'components/InspectionStatusCheckboxGroup'
import InfoIcon from 'assets/iconsLibrary/InfoIcon'
import PictureIcon from 'assets/iconsLibrary/PictureIcon'
import PenBoxIcon from 'assets/iconsLibrary/PenBoxIcon'
import TrashIcon from 'assets/iconsLibrary/TrashIcon'

const InspectionServicesRecommendationsLine = ({
    id,
    name,
    serviceType,
    payType,
    hours,
    labor,
    parts,
    total,
    addedBy,
    onDMSChange,
    onValidChange,
    onDoneChange,
    onColorChange,
    onNoPAChange
}) => {  
    return (
        <>
            <InspectionCheckboxCell
                id={`dmsAdd-${id}`}
                onChange={onDMSChange}
            ></InspectionCheckboxCell>
            <InspectionRadioCell
                id={`validAdd-${id}`}
                onChange={onValidChange}
            ></InspectionRadioCell>
            <InspectionCheckboxCell
                id={`doneAdd-${id}`}
                onChange={onDoneChange}
            ></InspectionCheckboxCell>
            <div>
                <InspectionStatusCheckboxGroup
                    id={`colorAdd-${id}`}
                    onChange={onColorChange}
                ></InspectionStatusCheckboxGroup>
            </div>
            <div>{name}</div>
            <div className={styles.infoWrapper}>
                <Button
                    symbol={
                        <InfoIcon
                            height={14}
                            width={14}
                            color={`#AAB0B7`}
                            className={styles.icon}
                        ></InfoIcon>
                    }
                ></Button>
                <Button
                    symbol={
                        <PictureIcon
                            color={`#AAB0B7`}
                            className={styles.icon}
                        ></PictureIcon>
                    }
                ></Button>
            </div>
            <div>{serviceType}</div>
            <div>{payType}</div>
            <InspectionCheckboxCell
                id={`nopaAdd-${id}`}
                onChange={onNoPAChange}
            ></InspectionCheckboxCell>
            <div>{hours}</div>
            <div>{labor}</div>
            <div>{parts}</div>
            <div>{total}</div>
            <div>{addedBy}</div>
            <div className={styles.actionsWrapper}>
                <Button
                    className={styles.toggleButton}
                    symbol={
                        <PenBoxIcon
                            color={`#AAB0B7`}
                            className={styles.icon}
                        ></PenBoxIcon>
                    }
                ></Button>
                <Button
                    className={styles.toggleButton}
                    symbol={
                        <TrashIcon
                            color={`#AAB0B7`}
                            className={styles.icon}
                        ></TrashIcon>
                    }
                ></Button>
            </div>
        </>
    )
}

InspectionServicesRecommendationsLine.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    name: PropTypes.string,
    serviceType: PropTypes.string,
    payType: PropTypes.string,
    hours: PropTypes.string,
    labor: PropTypes.string,
    parts: PropTypes.string,
    total: PropTypes.string,
    addedBy: PropTypes.string,
    onDMSChange: PropTypes.func,
    onValidChange: PropTypes.func,
    onDoneChange: PropTypes.func,
    onNoPAChange: PropTypes.func
}

export default InspectionServicesRecommendationsLine