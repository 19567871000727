import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

interface IDataTasksOverview {
    tasksNew: number,
    tasksCompleted: number,
    tasksAppointments: number,
}

interface ICalendarData {
    calendarLabels: string[],
    calendarSeries: any[][]
}

interface IBdcDashboard extends IDataTasksOverview, ICalendarData {
    selectedTasksTab: number,
    selectedCalendarTab: number,
    isLoadingCalendarData: boolean,
    isErrorCalendarData: boolean,
    isLoadingCategoryData: boolean,
    isErrorCategoryData: boolean,
    isLoadingOverviewData: boolean,
    isErrorOverviewData: boolean,
}

let initialState: IBdcDashboard = {
    selectedTasksTab: 0,
    selectedCalendarTab: 0,
    isLoadingCalendarData: true,
    isErrorCalendarData: false,
    isLoadingCategoryData: true,
    isErrorCategoryData: false,
    isLoadingOverviewData: true,
    isErrorOverviewData: false,
    tasksNew: 0,
    tasksCompleted: 0,
    tasksAppointments: 0,
    calendarLabels: [],
    calendarSeries: [
        [],
        [],
        []
    ],
}

const slice = createSlice({
    name: `bdcDashboard`,
    initialState,
    reducers: {
        setSelectedTasksTab(state, action: PayloadAction<number>) {
            state.selectedTasksTab = action.payload
        },
        setSelectedCalendarTab(state, action: PayloadAction<number>) {
            state.selectedCalendarTab = action.payload
        },
        setIsLoadingCalendarData(state, action: PayloadAction<boolean>) {
            state.isLoadingCalendarData = action.payload
        },
        setIsErrorCalendarData(state, action: PayloadAction<boolean>) {
            state.isErrorCalendarData = action.payload
        },
        setIsLoadingCategoryData(state, action: PayloadAction<boolean>) {
            state.isLoadingCategoryData = action.payload
        },
        setIsErrorCategoryData(state, action: PayloadAction<boolean>) {
            state.isErrorCategoryData = action.payload
        },
        setIsLoadingOverviewData(state, action: PayloadAction<boolean>) {
            state.isLoadingOverviewData = action.payload
        },
        setIsErrorOverviewData(state, action: PayloadAction<boolean>) {
            state.isErrorOverviewData = action.payload
        },
        setDataTasksOverview(state, action: PayloadAction<IDataTasksOverview>) {
            state = {
                ...state,
                ...action.payload
            }
        },
        setCalendarData(state, action: PayloadAction<ICalendarData>) {
            const {
                calendarLabels,
                calendarSeries
            } = action.payload
            state.calendarLabels = calendarLabels
            state.calendarSeries = calendarSeries
        },
        reset: state => initialState,
    },
})

export const {
    setSelectedTasksTab,
    setSelectedCalendarTab,
    setIsLoadingCalendarData,
    setIsErrorCalendarData,
    setIsLoadingCategoryData,
    setIsErrorCategoryData,
    setDataTasksOverview,
    setIsLoadingOverviewData,
    setIsErrorOverviewData,
    setCalendarData,
    reset,
} = slice.actions

export default slice.reducer