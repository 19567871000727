import React from 'react'
import {
    Switch
} from 'react-router-dom'
import pages from 'pages'

//import components
import BdcPageWrapper from 'components/BdcPageWrapper'
import AppRoute from 'components/AppRoute'

//import containers
import BdcDataview from 'components/BdcDataview'
import BdcTableView from 'components/BdcTableView'

function BdcRouter() {
    return (
        <BdcPageWrapper>
            <Switch>
                <AppRoute exact path={pages.BDC.route} page={pages.BDC}>
                    <BdcDataview/>
                </AppRoute>
                <AppRoute path={pages.BDC_DETAIL.route} page={pages.BDC_DETAIL}>
                    <BdcTableView/>
                </AppRoute>
            </Switch>
        </BdcPageWrapper>
    )
}

export default BdcRouter