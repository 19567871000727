import {
    Hooks,
    makePropGetter,
    TableInstance,
    useGetLatest,
} from 'react-table'

export interface UseGridLayoutState {
    gridLayoutColumnWidths: string[]
}

export default function useGridLayout(hooks: Hooks): void {
    hooks.stateReducers.push(reducer)
    hooks.getTableProps.push(getTableProps)
    hooks.getHeaderProps.push(getHeaderProps)
    hooks.getTableContainerProps = []
    hooks.useInstance.push(useInstance)
}

const getTableProps = (props, { instance }) => [
    props,
    {
        style: {
            display: `grid`,
            gridTemplateColumns: instance.state.gridLayoutColumnWidths.map(w => w).join(` `),
        },
    },
]

const getHeaderProps = (props, { column }) => [
    props,
    {
        id: `header-cell-${column.id}`,
        style: {
            position: `sticky` //enables a scroll wrapper to be placed around the table and have sticky headers
        },
    }
]

function reducer(state, action, previousState, instance) {
    if (action.type === `init`) { 
        return {
            gridLayoutColumnWidths: instance.columns.map(column => {    
                const uniqueID = column.id === undefined ? column.accessor : column.id
                if (!state.hiddenColumns.includes(uniqueID)) {
                    return column.width ? `minmax(min-content, ${column.width}fr)` : `auto`
                } 
                return null
            }),
            ...state
        }
    }


    if (action.type === `columnStartResizing`) {
        const { columnId } = action
        const columns = instance.visibleColumns
        const columnIndex = columns.findIndex(col => col.id === columnId)

        const newColumnWidths = columns.map((col, index) => {
            if (index <= columnIndex) {
                return `${getElementWidth(col.id)}px`
            } else {
                return `minmax(auto, ${getElementWidth(col.id)}fr)`

            }
        })

        const elWidth = getElementWidth(columnId)

        if (elWidth !== undefined) {
            return {
                ...state,
                gridLayout: {
                    ...state.gridLayout,
                    columnId,
                    columnIndex,
                    startingWidth: elWidth,
                },
                gridLayoutColumnWidths: newColumnWidths,
            }
        } else {
            return state
        }
    }

    if (action.type === `columnResizing`) {
        const {
            columnIndex,
            startingWidth,
        } = state.gridLayout
        const columnWidths = state.gridLayoutColumnWidths

        const change = state.columnResizing.startX - action.clientX
        const newWidth = startingWidth - change
        const columnWidthsCopy = [...columnWidths]
        columnWidthsCopy[columnIndex] = `${newWidth}px`

        return {
            ...state,
            gridLayoutColumnWidths: columnWidthsCopy,
        }
    }

    if (action.type === `columnDoneResizing`) {
        const columns = instance.visibleColumns
        const newColumnWidths = columns.map(col => `${getElementWidth(col.id)}fr`)

        return {
            ...state,
            gridLayoutColumnWidths: newColumnWidths ,
        }
    }
}

function useInstance(instance: TableInstance) {
    const {
        getHooks,
    } = instance
    const getInstance = useGetLatest(instance)
    const getTableContainerProps = makePropGetter (
        getHooks().getTableContainerProps,
        { instance: getInstance() }
    )

    Object.assign(instance, {
        getTableContainerProps,
    })
}

function getElementWidth(columnID: string) {
    const width = document.getElementById(`header-cell-${columnID}`)?.offsetWidth

    if (width !== undefined) {
        return width
    }
}