import { initialize, updateChatConversation, updateUnreadChatMessageCounts } from 'components/ChatModule/modules'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'store'
import { Signal } from 'types/signalr'

function useInitializeChat() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(initialize())
    }, [ dispatch])

    const updateConversations = useCallback(() => {

        dispatch(updateChatConversation())

    },[dispatch])

    useSubscribeToSignal(Signal.CHAT_CONVERSATIONS_UPDATED, updateConversations)

    const updateCounts = useCallback(() => {

        dispatch(updateUnreadChatMessageCounts())

    },[dispatch])

    useSubscribeToSignal(Signal.USER_CHAT_CONVERSTATION_UPDATED, updateCounts)
}

export default useInitializeChat