import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { SmsReportingOverviewViewModel } from "api/types/reporting/SmsReportingOverviewViewModel"
import React from "react"
import DataTable from "components/DataTable";
import theme from "assets/theme";
import { Checkbox } from "@mui/material";

export interface ISmsOverviewDataGridProps{
    data: SmsReportingOverviewViewModel,
    checkedChangedCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function SmsOverviewDataGrid({
    data,
    checkedChangedCallback
}: ISmsOverviewDataGridProps){

    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'checkbox',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => 
            <Checkbox
                defaultChecked={true}
                name={params.formattedValue.name}
                onChange={checkedChangedCallback}
                sx={{
                  color: params.formattedValue.color,
                  '&.Mui-checked': {
                    color: params.formattedValue.color,
                  }
                }}
              />
        },
        {
          headerClassName: `header-cell`,
          field: 'name',
          sortable: false,
          editable: false,
          flex: 1,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'value',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .75,
          disableColumnMenu: true,
        },
      ];

    var rows = [
        {
            id: 0,
            checkbox: {
              color: theme.palette.primary.dark,
              name: "sentWelcomeMessages"
            },
            name: `SENT WELCOME MESSAGES`,
            value: data.TotalSentWelcomeMessages
        },
        {
            id: 1,
            checkbox: {
              color: theme.palette.primary.light,
              name: "sentMessages"
            },
            name: `SENT MESSAGES`,
            value: data.TotalSentMessages
        },
        {
            id: 2,
            checkbox: {
              color: theme.palette.secondary.dark,
              name: "receivedMessages"
            },
            name: `RECEIVED MESSAGES`,
            value: data.TotalReceivedMessages
        },
        {
            id: 3,
            checkbox: {
              color: theme.palette.secondary.light,
              name: "unansweredMessages"
            },
            name: `UNANSWERED MESSAGES`,
            value: data.TotalUnansweredMessages
        }
    ]

    return (
        <DataTable
            rows={rows}
            columns={columns}
            hideHeader={true}/>
    )
}