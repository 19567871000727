import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { InteractiveMaintenanceReportingOverviewViewModel } from 'api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel';
import TopSoldDataGrid from './components/TopSoldDataGrid';
import SoldByIntervalDataGrid from './components/SoldByIntervalDataGrid';
import AccordionSummaryHeader from './components/AccordianSummaryHeader';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: '#f2f2f2',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface IInteractiveMaintenanceDetailsAccordionProps {
    data: InteractiveMaintenanceReportingOverviewViewModel
}

export default function InteractiveMaintenanceDetailsAccordion({
    data
}: IInteractiveMaintenanceDetailsAccordionProps){
    const [expanded, setExpanded] = React.useState<string | false>('topSoldServices');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            <Accordion expanded={expanded === 'topSoldServices'} onChange={handleChange('topSoldServices')}>
                <AccordionSummary>
                    <AccordionSummaryHeader header={'TOP SOLD SERVICES'}/>
                </AccordionSummary>
                <AccordionDetails>
                    <TopSoldDataGrid
                        data={data.TopSoldServices}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'soldServiceByInterval'} onChange={handleChange('soldServiceByInterval')}>
                <AccordionSummary>
                    <AccordionSummaryHeader header={'TOTAL SOLD SERVICES BY INTERVAL'}/>
                </AccordionSummary>
                <AccordionDetails>
                    <SoldByIntervalDataGrid
                        data={data.SoldIntervalItems}/>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
