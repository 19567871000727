import React from 'react'
import AppMenu, { IMenuItem } from 'components/AppMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { useAppSelector } from 'store'
import pages from 'pages'
import { Role } from 'api/types/role'

function MainMenu() {
    const {
        DealerFeatures,
        EnableBDC,
        EnableMPVI,
    } = useAppSelector(
        state => state.dealer
    )

    const {
        Role: userRole
    } = useAppSelector(
        state => state.user
    )

    const isAdmin = userRole === Role.Admin

    const canDisplayDealer = isAdmin

    const SchedulingFeatures = DealerFeatures?.filter(f => f.FeatureID === 6.01);
    const canDisplayScheduling =
        SchedulingFeatures && SchedulingFeatures.length > 0 ?
        SchedulingFeatures[0].IsEnabled :
        false;

    const items: IMenuItem[] = [
        { 
            page: pages.REPAIR_ORDERS,
            visible: true
        },
        {
            page: pages.REPORTING,
            visible: true,
        },
        {
            page: pages.SCHEDULING,
            visible: canDisplayScheduling
        },
        { 
            page: pages.CUSTOMERS,
            visible: true
        },
        { 
            page: pages.BDC,
            visible: EnableBDC,
        },
        { 
            page: pages.MPVI,
            visible: EnableMPVI
        },
        {
            page: pages.DEALERS,
            visible: canDisplayDealer
        }
    ]

    return (
        <AppMenu
            icon={<MenuIcon/>}
            menuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }
            }}
            menuItems={items}
        ></AppMenu>
    )
}

export default MainMenu