import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useAppSelector } from "store";
import { useSubscribeToDealers } from "./hooks/useSuscribeToDealers";
import {
  setPageNumber,
  setPageSize,
  setSearchTerm,
} from "modules/dealersSearchView";
import { useDispatch } from "react-redux";
import { Add, Clear } from "@mui/icons-material";
import { isEmpty } from "voca";
import { useHistory } from "react-router-dom";
import pages from "pages";
import styles from './styles.module.scss'

function DealerListView() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { dealers, pageNumber, pageSize, totalRecords, searchTerm, isLoading } =
    useAppSelector((state) => state.dealerSearch);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useSubscribeToDealers(searchTerm, pageNumber, pageSize);

  const handleChangePage = (e, newPage: number) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPageSize(parseInt(event.target.value)));
    dispatch(setPageNumber(0));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(event.target.value));
    dispatch(setPageNumber(0));
  };

  const handleClearSearch = () => {
    dispatch(setSearchTerm(``));
    dispatch(setPageNumber(0));
  };

  const handleOpenForm = (id?: number) => {
    id
      ? history.push(`${pages.DEALERS_EDIT_FORM.routeBase}/${id}`)
      : history.push(`${pages.DEALERS_CREATE_FORM.routeBase}`);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.form}>
        <TextField
          size="small"
          label={`Search...`}
          onInput={handleSearch}
          InputProps={{
            endAdornment: !isEmpty(searchTerm) ? (
              <InputAdornment
                disableTypography
                position="end"
                onClick={handleClearSearch}
              >
                <Clear />
              </InputAdornment>
            ) : null,
          }}
          value={searchTerm}/>
        <Button
          variant="contained"
          sx={{ mx: 1 }}
          onClick={() => handleOpenForm()}>
          <Add />
        </Button>
        {loading && <CircularProgress />}
      </Box>
      <Paper className={styles.tableContainer}>
        <Box className={styles.table}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Activation Key</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dealers.map((data) => (
                  <TableRow
                    hover
                    key={data.ID}
                    onClick={() => handleOpenForm(data.ID)}
                  >
                    <TableCell component="th" scope="row">
                      {data.ExternalID}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.Name}
                    </TableCell>
                    <TableCell>
                      {data.Address1 !== "" && `${data.Address1}`}
                      {data.Address1 !== "" && data.City !== "" && ", "}
                      {data.City !== "" && `${data.City}`}
                      {data.City !== "" && data.Region !== "" && ", "}
                      {data.Region !== "" && `${data.Region}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={totalRecords}
            rowsPerPage={pageSize}
            page={pageNumber - 1 < 0 ? 0 : pageNumber - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Box>
      </Paper>
    </Box>
  );
}

export default DealerListView;
