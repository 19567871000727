import React, { useCallback, useState } from 'react'
import { Box, IconButton, Popover } from '@mui/material'
import ColorizeIcon from '@mui/icons-material/Colorize'
import ColorPickerButton from '../ColorPickerButton'
import { useAppDispatch } from 'store'
import { setRowColor } from 'components/RepairOrderListView/modules'
import { Color } from 'components/RepairOrderListView/types/ListRepairOrder'
import ClearColorPickerOption from '../ClearColorPickerOption'

export interface IColorPickerTool {
    id: number
    currentColor: Color
    onClick(): any
}

function ColorPickerTool({
    id,
    currentColor,
    onClick,
}: IColorPickerTool) {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = !!anchorEl

    const handleClick = useCallback(
        (e) => {
            setAnchorEl(e.currentTarget)
        },
        [],
    )

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    },[])

    const handleColorClick = useCallback(
        (color: Color) => {
            handleClose()
            onClick()
            dispatch(setRowColor({
                id,
                color: color === currentColor ? Color.NONE : color,
            }))
        },
        [id, currentColor, dispatch, onClick, handleClose],
    )

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                size={`small`}
            >
                <ColorizeIcon/>
            </IconButton>
            <Popover
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    padding={1.5}
                    display={`grid`}
                    gap={.2}
                    gridTemplateColumns={`repeat(4, 16px)`}
                    gridTemplateRows={`repeat(${currentColor === Color.NONE ? "2" : "3"}, 16px)`}
                >
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.PINK}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.RED}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.ORANGE}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.YELLOW}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.PURPLE}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.BLUE}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.TEAL}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    <ColorPickerButton
                        currentColor={currentColor}
                        color={Color.GREEN}
                        onClick={handleColorClick}
                    ></ColorPickerButton>
                    {currentColor !== Color.NONE && 
                        <ClearColorPickerOption onClick={handleColorClick}/>}
                </Box>
            </Popover>
        </Box>
    );
}

export default React.memo(ColorPickerTool)