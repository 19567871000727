import { Box, Typography } from "@mui/material"
import classnames from "classnames"
import React from "react"
import styles from './styles.module.scss'

export interface ISummerySectionCellProps {
    header: string
    value: string
    statusColor?: string
}

function SummerySectionCell({
    header,
    value,
    statusColor
}: ISummerySectionCellProps) {
    return (
        <Box className={styles.summerySectionCell}>
            <Box>
                <Box className={styles.summerySectionCellContent}>
                    <Typography variant={`overline`} className={styles.header}>{header}</Typography>
                    <Typography variant={`h6`} sx={{color: statusColor}} className={classnames(styles.value)}>{value}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default SummerySectionCell