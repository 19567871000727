import React, { useRef, useEffect } from 'react'

import styles from './styles.module.scss'

export interface IScrollWrapper {
    resetCount: number
    children: React.ReactNode
}

function ScrollWrapper({
    resetCount,
    children
}: IScrollWrapper) {
    const ref = useRef(document.createElement('div'))

    useEffect(() => {
        ref.current.scrollTop = 0
    }, [resetCount])

    return (
        <div id={`table-scroll-wrapper`} className={styles.container} ref={ref}>{children}</div>
    )
}

export default ScrollWrapper