import React, { useState } from 'react'
import ConvoIcon from '@mui/icons-material/Forum'
import ColumnIcon from 'componentsUI/TableView/components/ColumnIcon'
import ConversationDialog from 'components/ConversationDialog'
import { useAppSelector } from 'store'
import { customerMessagesSelectors } from 'modules/customerMessages'
import { Customer } from 'api/types/customer'

export interface IViewConversationCell {
    customer: Customer
    repairOrderID: number | null
}

const ViewConversationCell = ({
    customer,
    repairOrderID,
}: IViewConversationCell) => {
    const [isOpen, setIsOpen] = useState(false)

    const messages = useAppSelector(customerMessagesSelectors.selectAll)
    
    const handleClick = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <ColumnIcon
                tooltipText={`View Conversation`}
                Icon={ConvoIcon}
                isButton
                buttonProps={{
                    onClick: handleClick
                }}
            ></ColumnIcon>
            {isOpen &&
                <ConversationDialog
                    customer={customer}
                    repairOrderID={repairOrderID}
                    onClose={handleClose}
                    messages={messages}
                ></ConversationDialog> 
            }
        </>
    )
}

export default React.memo(ViewConversationCell)