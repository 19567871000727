import { BaseMessage } from 'api/types/message'
import { Color, IListRepairOrder, StatusTranslation } from 'components/RepairOrderListView/types/ListRepairOrder'
import AppError, { Errors } from 'errors'
import { EstimateStatus, Status } from 'types/repairOrder'
import { BaseCustomer } from './customer'
import { BaseMaintenanceSchedule } from './maintenanceSchedule'
import { BaseUser } from './user'
import { BaseVideo } from './video'

export interface BaseRepairOrder {
    ID: number
    CustomerID: number
    CustomerVehicleID: number
    DealerID: number
    MaintenanceScheduleID: string | null
    UserID: number | null

    AttentionNeeded: boolean
    Date: string | null
    FinalWarrantyAlertSent: boolean
    HasActiveWarrantyAlert: boolean
    HasWarrantyWork: boolean
    InitialWarrantyAlertSent: boolean
    MPVIStatus: number
    Notes: string | null
    Odometer: number | null
    PromiseTime: string | null
    RONumber: string
    Status: number
    Tag: string | null
    Transport: number
    VehicleDescription: string | null
    WarrantyClaimDeniable: boolean

    // Estimate data from GM
    WorkbenchUrl: string

    // Other estimate data
    EstimateKey: string | null
    EstimateStatus: EstimateStatus
}

export interface RepairOrder extends BaseRepairOrder {
    Customer: BaseCustomer
    MaintenanceSchedule: BaseMaintenanceSchedule | null
    Messages: BaseMessage[] | null
    User: BaseUser | null
    Videos: BaseVideo[] | null
}

export function mapToListRepairOrder(apiRepairOrder: RepairOrder): IListRepairOrder {
    try {
        const advisorFirstName = apiRepairOrder.User && apiRepairOrder.User.FirstName ? apiRepairOrder.User.FirstName : 'UNKNOWN'
        const advisorLastName = apiRepairOrder.User && apiRepairOrder.User.LastName ? apiRepairOrder.User.LastName : 'UNKNOWN'

        return {
            ...apiRepairOrder,
            isPinned: false,
            rowColor: Color.NONE,
            customerName: apiRepairOrder.Customer.FullName,
            advisorName: `${advisorLastName}, ${advisorFirstName}`,
            statusTranslation: apiRepairOrder.Status === Status.OPEN ? StatusTranslation.OPEN : StatusTranslation.CLOSED
        }
        
    } catch (error) {
        throw new AppError(error, Errors.CRM0020)
    }
}