import { createTheme } from '@mui/material/styles'

export default createTheme({
    palette: {
        primary: {
            main: `#26387A`
        },
        secondary: {
            main: `#54B74A`
        }
    },
});