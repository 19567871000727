import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { CSIAlertReportingOverviewViewModel } from 'api/types/reporting/CSIAlertReportingOverviewViewModel'
import { InteractiveMaintenanceReportingOverviewViewModel } from 'api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel'
import { ReportingOverviewViewModel } from 'api/types/reporting/ReportingOverviewViewModel'
import { SmsReportingOverviewViewModel } from 'api/types/reporting/SmsReportingOverviewViewModel'
import { VideoMpviReportingOverviewViewModel } from 'api/types/reporting/VideoMpviReportingOverviewViewModel'
import { WarrantyAlertReportingOverviewViewModel } from 'api/types/reporting/WarrantyAlertReportingOverview'
import getCsiAlertReportingOverviewData from 'components/Reporting/thunks/getCsiAlertReportingOverviewData'
import getInteractiveMaintenanceReportingOverviewData from 'components/Reporting/thunks/getInteractiveMaintenanceOverviewData'
import getSmsReportingOverviewData from 'components/Reporting/thunks/getSmsReportingOverviewData'
import getVideoMpviReportingOverviewData from 'components/Reporting/thunks/getVideoMpviReportingOverviewData'
import getWarrantyAlertReportingOverviewData from 'components/Reporting/thunks/getWarrantyAlertReportingOverviewData'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'
import { ISetDateRangeResponse } from 'types/ISetDateRangeResponse'
import setDates from '../../thunks/setDates'

export type LoadingStates = {
    isWarrantyAlertLoading: boolean,
    isInteractiveMaintenanceLoading: boolean,
    isCsiAlertLoading: boolean,
    isSmsLoading: boolean,
    isVideoMpviLoading: boolean
}

export interface IReportingOverviewState {
    loadingStates: LoadingStates,
    isError: boolean,
    startDate: string,
    endDate: string,
    reporting: ReportingOverviewViewModel,
}

let initialState: IReportingOverviewState = {
    loadingStates: {
        isWarrantyAlertLoading: false,
        isInteractiveMaintenanceLoading: false,
        isCsiAlertLoading: false,
        isSmsLoading: false,
        isVideoMpviLoading: false,
    },
    isError: false,
    startDate: moment().format(US_DATE_FORMAT),
    endDate: moment().format(US_DATE_FORMAT),
    reporting: {
        WarrantyAlertReportingOverview: {
            TotalRepairOrders: 0,
            TotalCuredDollarAmount: 0,
            TotalUncuredDollarAmount: 0,
            ByUser: []
        },
        InteractiveMaintenanceReportingOverview: {
            TotalPresentations: 0,
            TotalSubmissions: 0,
            TotalItemsSold: 0,
            TotalDollarsSold: 0,
            ByUser: [],
            TopSoldServices: [],
            SoldIntervalItems: []
        },
        CSIAlertReportingOverview: {
            TotalAlerts: 0,
            TotalSmsUpdates: 0,
            ByUser: []
        },
        SmsReportingOverview: {
            TotalSentWelcomeMessages: 0,
            TotalSentMessages: 0,
            TotalReceivedMessages: 0,
            TotalUnansweredMessages: 0
        },
        VideoMpviReportingOverview: {
            TotalRepairOrders: 0,
            TotalVideoMPVIs: 0,
            AverageASRsPerMPVI: 0,
            TotalASRs: 0,
            TotalDollarAmountOfASRs: 0,
            TotalApprovedASRs: 0,
            TotalApprovedDollarAmountOfASRs: 0,
            ByUser: []
        }
    },
}

const slice = createSlice({
    name: `reporting`,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getWarrantyAlertReportingOverviewData.pending, (state) => {
            state.loadingStates.isWarrantyAlertLoading = true
            state.isError = false
        })
        builder.addCase(getWarrantyAlertReportingOverviewData.fulfilled, (state, action: PayloadAction<WarrantyAlertReportingOverviewViewModel>) => {
            state.loadingStates.isWarrantyAlertLoading = false
            state.isError = false
            state.reporting.WarrantyAlertReportingOverview = action.payload
        })
        builder.addCase(getWarrantyAlertReportingOverviewData.rejected, (state) => {
            state.loadingStates.isWarrantyAlertLoading = false
            state.isError = true
        })
        builder.addCase(getInteractiveMaintenanceReportingOverviewData.pending, (state) => {
            state.loadingStates.isInteractiveMaintenanceLoading = true
            state.isError = false
        })
        builder.addCase(getInteractiveMaintenanceReportingOverviewData.fulfilled, (state, action: PayloadAction<InteractiveMaintenanceReportingOverviewViewModel>) => {
            state.loadingStates.isInteractiveMaintenanceLoading = false
            state.isError = false
            state.reporting.InteractiveMaintenanceReportingOverview = action.payload
        })
        builder.addCase(getInteractiveMaintenanceReportingOverviewData.rejected, (state) => {
            state.loadingStates.isInteractiveMaintenanceLoading = true
            state.isError = true
        })
        builder.addCase(getCsiAlertReportingOverviewData.pending, (state) => {
            state.loadingStates.isCsiAlertLoading = true
            state.isError = false
        })
        builder.addCase(getCsiAlertReportingOverviewData.fulfilled, (state, action: PayloadAction<CSIAlertReportingOverviewViewModel>) => {
            state.loadingStates.isCsiAlertLoading = false
            state.isError = false
            state.reporting.CSIAlertReportingOverview = action.payload
        })
        builder.addCase(getCsiAlertReportingOverviewData.rejected, (state) => {
            state.loadingStates.isCsiAlertLoading = false
            state.isError = true
        })
        builder.addCase(getSmsReportingOverviewData.pending, (state) => {
            state.loadingStates.isSmsLoading = true
            state.isError = false
        })
        builder.addCase(getSmsReportingOverviewData.fulfilled, (state, action: PayloadAction<SmsReportingOverviewViewModel>) => {
            state.loadingStates.isSmsLoading = false
            state.isError = false
            state.reporting.SmsReportingOverview = action.payload
        })
        builder.addCase(getSmsReportingOverviewData.rejected, (state) => {
            state.loadingStates.isSmsLoading = false
            state.isError = true
        })
        builder.addCase(getVideoMpviReportingOverviewData.pending, (state) => {
            state.loadingStates.isVideoMpviLoading = true
            state.isError = false
        })
        builder.addCase(getVideoMpviReportingOverviewData.fulfilled, (state, action: PayloadAction<VideoMpviReportingOverviewViewModel>) => {
            state.loadingStates.isVideoMpviLoading = false
            state.isError = false
            state.reporting.VideoMpviReportingOverview = action.payload
        })
        builder.addCase(getVideoMpviReportingOverviewData.rejected, (state) => {
            state.loadingStates.isVideoMpviLoading = false
            state.isError = true
        })
        builder.addCase(setDates.pending, (state) => {
            state.isError = false
        })
        builder.addCase(setDates.fulfilled, (state, action: PayloadAction<ISetDateRangeResponse>) => {
            const {
                startDate,
                endDate
            } = action.payload

            state.isError = false
            state.startDate = startDate
            state.endDate = endDate
        })
        builder.addCase(setDates.rejected, (state) => {
            state.isError = true
        })
    }
})

export default slice.reducer