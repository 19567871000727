import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import NavTab from './components/NavTab'
import Profile from './components/Profile'
import NavPanel from './components/NavPanel'

enum tabs {
    PROFILE = `1`,
    VEHICLES = `2`,
    RO_HISTORY = `3`,
    MESSAGES = `4`,
}

export interface ICustomerDetailDialog {
    id: number
    onClose(): void
}

function CustomerDetailDialog({
    id,
    onClose,
}: ICustomerDetailDialog) {
    const [tab, setTab] = useState<string>(tabs.PROFILE)
    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            maxWidth={`md`}
        >
            <DialogTitle
                style={{
                    paddingBottom: 0,
                }}
            >Customer Information</DialogTitle>
            <TabContext value={tab}>
                <DialogContent>
                    <TabList
                        indicatorColor={`primary`}
                        textColor={`primary`}
                        variant={`fullWidth`}
                        onChange={(event, value) => setTab(value)}
                    >
                        <NavTab 
                            label={`Profile`}
                            value={tabs.PROFILE} 
                        ></NavTab>
                        <NavTab
                            label={`Vehicles (Coming Soon)`}value={tabs.VEHICLES} 
                            disabled
                        ></NavTab>
                        <NavTab
                            label={`RO History (Coming Soon)`}
                            value={tabs.RO_HISTORY}
                            disabled
                        ></NavTab>
                        <NavTab
                            label={`Messages (Coming Soon)`} 
                            value={tabs.MESSAGES}
                            disabled
                        ></NavTab>
                    </TabList>
                </DialogContent>
                <DialogContent>
                    <NavPanel value={tabs.PROFILE}>
                        <Profile
                            id={id}
                        ></Profile>
                    </NavPanel>
                    <NavPanel value={tabs.VEHICLES}>
                    </NavPanel>
                    <NavPanel value={tabs.RO_HISTORY}>
                    </NavPanel>
                    <NavPanel value={tabs.MESSAGES}>
                    </NavPanel>
                </DialogContent>
            </TabContext>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color={`primary`}
                >Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(CustomerDetailDialog)