import apiFetch from 'api/utils/APIFetch'

const createChatMessage = (
    dealerID: number,
    chatConversationID: number, 
    userID: number, 
    message: string
): Promise<void> => apiFetch(`/api/chat/${dealerID}/messages`, {
    method: `POST`,
    body: JSON.stringify({
        ChatConversationID: chatConversationID,
        UserID: userID,
        Message: message
    })
}).catch(error => {
    throw new Error(error.Message)
})

export default createChatMessage