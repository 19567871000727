import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { getFirstInitialLast } from 'utils/getFirstInitialLast'

export interface IAdvisorCell {
    firstName: string,
    lastName: string
}

function AdvisorCell({
    firstName,
    lastName
}: IAdvisorCell) {

    const formattedName = useMemo(() => getFirstInitialLast(firstName || ``, lastName || ``),[firstName, lastName])
    
    return (
        <>
            <Box
                display={`grid`}
                gridTemplateColumns={`1fr min-content`}
                alignItems={`center`}
            >
                <Box>{formattedName}</Box>
            </Box>

        </>
    )
}

export default React.memo(AdvisorCell)