//import utils
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'
import getBdcCategoryByDate from 'utils/getBdcCategoryByDate'

function getBdcSchema() {

    const format = US_DATE_FORMAT
    
    const now = moment()
    
    const today = now.format(US_DATE_FORMAT)
    
    const tommorrow = now.add({ days: 1 }).format(format)
    
    const twoWeeksFromNow = now.add({ days: 14 }).format(format)

    const thirtyDaysAgo = now.subtract({ days: 30 }).format(format)

    const sixMonthsAgo = now.subtract({ months: 6 }).format(format)

    const nineMonthsAgo = now.subtract({ months: 9 }).format(format)

    const twelveMonthsAgo = now.subtract({ months: 12 }).format(format)

    const fifteenMonthsAgo = now.subtract({ months: 15 }).format(format)

    return [
        {
            id: 0,
            title: `No Show Appts`,
            desc: `(Last 30 Days)`,
            data: () => getBdcCategoryByDate(thirtyDaysAgo, today, 2),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: true
        },
        {
            id: 1,
            title: `Appointments For Tomorrow`,
            data: () => getBdcCategoryByDate(today, tommorrow, 1),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: false
        },
        {
            id: 2,
            title: `Appointments Upcoming`,
            desc: `in next 2 weeks`,
            data: () => getBdcCategoryByDate(today, twoWeeksFromNow, 1),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: false
        },
        {
            id: 3,
            title: `No R.O. in Last 6 Months`,
            desc: `estimated miles over 5500`,
            data: () => getBdcCategoryByDate(sixMonthsAgo, today, 3),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: true
        },
        {
            id: 4,
            title: `No R.O. in Last 6-9 Months`,
            data: () => getBdcCategoryByDate(nineMonthsAgo, sixMonthsAgo),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: true
        },
        {
            id: 5,
            title: `No R.O. in Last 9-12 Months`,
            data: () => getBdcCategoryByDate(twelveMonthsAgo, nineMonthsAgo),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: true
        },
        {
            id: 6,
            title: `No R.O. in Last 12-15 Months`,
            data: () => getBdcCategoryByDate(fifteenMonthsAgo, twelveMonthsAgo),
            total: 0,
            called: 0,
            appts: 0,
            showAppts: true
        }
    ]
}

export default getBdcSchema