import { Typography } from "@mui/material";
import React from "react";
import styles from './styles.module.scss'

export interface IAccordionSummeryHeaderProps {
    header: string
}

export default function AccordionSummaryHeader({
    header
}: IAccordionSummeryHeaderProps) {
    return (
        <Typography className={styles.summeryHeader} variant="overline">{header.toUpperCase()}</Typography>
    )
}