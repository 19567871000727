import React, { useCallback, useState } from 'react'
import { Box, ButtonBase } from '@mui/material'
import { Edit } from '@mui/icons-material'
import NotesDialog from '../NotesDialog'

export interface INotesCell {
    id: number
    text: string
}

function NotesCell({
    id,
    text,
}: INotesCell) {
    const [buttonOpacity, setButtonOpacity] = useState<number>(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleMouseEnter = useCallback(() => {
        setButtonOpacity(1)
    },[])

    const handleMouseLeave = useCallback(() => {
        setButtonOpacity(0)
    },[])

    const handleClick = useCallback(() => {
        setIsOpen(true)
        setButtonOpacity(0)
    },[])

    return (
        <>
            <Box
                position={`relative`}
                justifySelf={`stretch`}
                alignSelf={`stretch`}
            >
                <div
                    style={{
                        WebkitBoxOrient: `vertical`,//deprecated but works in chrome
                        display: `-webkit-box`, //deprecated but works in chrome
                        WebkitLineClamp: 2,
                        overflow: `hidden`,
                        textOverflow: `ellipsis`
                    }}
                >{text}</div>
                <ButtonBase
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        color: `white`,
                        width: `100%`,
                        opacity: buttonOpacity,
                        transition: `opacity .3s`,
                        display: `grid`,
                        justifyContent: `end`,
                        alignContent: `center`,
                    }}
                >
                    <Edit
                        color={`primary`}
                        fontSize={`small`}
                    ></Edit>
                </ButtonBase>
            </Box>
            {isOpen &&
                <NotesDialog
                    id={id}
                    text={text}
                    onClose={() => setIsOpen(false)}
                ></NotesDialog>
            }
        </>
    )
}

export default React.memo(NotesCell)