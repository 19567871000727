import React from "react";
import { IMaskInput } from "react-imask";

export enum TextMaskedInputType {
  State,
}

interface TextMaskedInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  regex: RegExp;
  type: TextMaskedInputType;
}

const TextMaskedInput = React.forwardRef<HTMLElement, TextMaskedInputProps>(
  (props, ref: any) => {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={props.mask}
        definitions={{
          "#": props.regex,
        }}
        inputRef={ref}
        onAccept={(value: string) => {
          switch (props.type) {
            case TextMaskedInputType.State: {
              value = value.toUpperCase();
              break;
            }
          }

          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  }
);

export { TextMaskedInput };
