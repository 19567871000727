import { Divider } from '@mui/material'
import LocalFilterStorage, { IRepairOrderFilterState } from 'components/RepairOrderListView/helpers/filterLocalStorage'
import { StatusTranslation } from 'components/RepairOrderListView/types/ListRepairOrder'
import FilterChip from 'componentsUI/TableView/components/FilterChip'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnInstance, TableInstance } from 'react-table'

export interface IStatusFilter {
    tableInstance: TableInstance,
    column: ColumnInstance,
}

function StatusFilter({
    column,
}: IStatusFilter) {
    const [filters, setFilters] = useState<IRepairOrderFilterState>(LocalFilterStorage.readStorage())
    const setTableFilter = useMemo(() => column.setFilter, [column])
    
    const getTableFilter = useCallback((newState: IRepairOrderFilterState) => {

        return newState.displayOpen && newState.displayClosed ?
            undefined :
            newState.displayOpen ?
                StatusTranslation.OPEN :
                StatusTranslation.CLOSED

    }, [])

    const updateFilters = (updatedFilters: IRepairOrderFilterState) => {
        
        setFilters(updatedFilters)

        LocalFilterStorage.saveToStorage(updatedFilters)

        setTableFilter(getTableFilter(updatedFilters))

    }

    const clickHandler = (value: StatusTranslation) => {

        const openChanged = value === StatusTranslation.OPEN

        const updatedFilters = {
            ...LocalFilterStorage.readStorage(),
            displayOpen: openChanged ? !filters.displayOpen : filters.displayOpen,
            displayClosed: !openChanged ? !filters.displayClosed : filters.displayClosed
        }

        // don't allow the user to uncheck both the open and closed filters.
        // force the user to have a least one checked.
        if(!updatedFilters.displayOpen && !updatedFilters.displayClosed)
            return

        updateFilters(updatedFilters)

    }

    useEffect(() => {

        setTableFilter(getTableFilter(filters))

    }, [setTableFilter, getTableFilter, filters])

    return (
        <>
            <FilterChip
                key={`openFilterChip`}
                text={`Open`}
                isActive={filters.displayOpen}
                onClick={() => clickHandler(StatusTranslation.OPEN)}
            ></FilterChip>
            <FilterChip
                key={`closedFilterChip`}
                text={`Closed`}
                isActive={filters.displayClosed}
                onClick={() => clickHandler(StatusTranslation.CLOSED)}
            ></FilterChip>
            <Divider orientation="vertical" variant="middle" flexItem />
        </>
    )
}

export default React.memo(StatusFilter)