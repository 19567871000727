import React from 'react'
import { useQuery } from 'react-query'
import { lowerCase, titleCase } from 'voca'
import getCustomer from 'api/getCustomer'
import { PreferredChannel } from 'types/customer'
import formatPhone from 'utils/formatPhone'
import { Box, List, Paper, Snackbar } from '@mui/material'
import ListLine from '../ListLine'
import theme from 'assets/theme'
import { Alert } from '@mui/material';
import updatePhoneNumber, { routes } from 'api/updatePhoneNumber'
import { Customer } from 'api/types/customer'

interface IProfile {
    id: number
}

function Profile({
    id,
}: IProfile) {
    const [customer, setCustomer] = React.useState<Customer>()
    const [isError, setIsError] = React.useState<boolean>(false)

    const getProfileData = async (id: number) => {
        return await getCustomer(id)
    }

    const {
        isLoading,
    } = useQuery([`customerDialog-profile`, id], () => getProfileData(id), {
        enabled: !!id,
        onSuccess: data => setCustomer(data),
        onError: () => setIsError(true)
    })

    const handlePhoneUpdate = async (value: string, type: routes) => {
        try {
            await updatePhoneNumber(value, id, type)
            if (customer !== undefined) {
                const customerCopy: Customer = { ...customer }
                switch (type) {
                    case routes.CELL_PHONE:
                        customerCopy.CellPhone = value
                        break
                    case routes.WORK_PHONE:
                        customerCopy.WorkPhone = value
                        break
                    case routes.HOME_PHONE:
                        customerCopy.HomePhone = value
                        break
                }
                setCustomer(customerCopy)
            }
        } catch (error) {
            setIsError(true)
        }
    }

    const preferrredChannel = () => {
        switch (customer?.PreferredChannel) {
            case PreferredChannel.EMAIL:
                return `Email`
            case PreferredChannel.SMS:
                return `SMS`
            default:
                return ``
        }
    }

    return <>
        <Box
            display={`grid`}
            gap={theme.spacing(2)}
            gridTemplateColumns={`1fr 1fr`}
        >
            <Paper variant={`outlined`}>
                <List dense>
                    <ListLine
                        isLoading={isLoading}
                        label={`Name`}
                        text={titleCase(customer?.FullName || ``)}
                    ></ListLine>
                    <ListLine
                        isLoading={isLoading}
                        label={`Email`}
                        text={lowerCase(customer?.Email || ``)}
                    ></ListLine>
                    <ListLine
                        editable
                        isLoading={isLoading}
                        label={`Cell Phone`}
                        text={formatPhone(customer?.CellPhone || ``)}
                        onEdit={value => handlePhoneUpdate(value, routes.CELL_PHONE)}
                    ></ListLine>
                    <ListLine
                        editable
                        isLoading={isLoading}
                        label={`Work Phone`}
                        text={formatPhone(customer?.WorkPhone || ``)}
                        onEdit={value => handlePhoneUpdate(value, routes.WORK_PHONE)}
                    ></ListLine>
                    <ListLine
                        editable
                        isLoading={isLoading}
                        label={`Home Phone`}
                        text={formatPhone(customer?.HomePhone || ``)}
                        onEdit={value => handlePhoneUpdate(value, routes.HOME_PHONE)}
                    ></ListLine>

                    {customer?.PreferredChannel !== PreferredChannel.NONE &&
                        <ListLine
                            isLoading={isLoading}
                            label={`PreferredChannel`}
                            text={preferrredChannel()}
                        ></ListLine>
                    }
                </List>
            </Paper>
            <Paper variant={`outlined`}>
                <List dense>
                    <ListLine
                        isLoading={isLoading}
                        label={`Primary Address`}
                        text={titleCase(customer?.Address1 || ``)}
                    ></ListLine>
                    {customer?.Address2 !== `` &&
                        <ListLine
                            isLoading={isLoading}
                            label={`Secondary Address`}
                            text={titleCase(customer?.Address2 || ``)}
                        ></ListLine>
                    }
                    <ListLine
                        isLoading={isLoading}
                        label={`City`}
                        text={titleCase(customer?.City || ``)}
                    ></ListLine>
                    <ListLine
                        isLoading={isLoading}
                        label={`State/Region`}
                        text={customer?.Region || ``}
                    ></ListLine>
                    <ListLine
                        isLoading={isLoading}
                        label={`Country`}
                        text={customer?.Country || ``}
                    ></ListLine>
                </List>
            </Paper>
        </Box>
        <Snackbar open={isError} onClose={() => setIsError(false)}>
            <Alert severity={`error`} onClose={() => setIsError(false)}>
                A server error has occured. Please contact support if the issue persists.
        </Alert>
        </Snackbar>
    </>;
}

export default React.memo(Profile)