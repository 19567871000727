import React, { useCallback } from 'react'
import { IconButton } from '@mui/material'
import PinIcon from '@mui/icons-material/Room'
import UnpinnedIcon from '@mui/icons-material/LocationOff'
import { useAppDispatch } from 'store'
import {
    IUpdateRepairOrderPinStatePayload,
    updateRepairOrderPinState,
} from 'components/RepairOrderListView/modules'

export interface IPinnedTool {
    id: number
    isPinned: boolean
    onClick(): any 
}

function PinnedTool({
    id,
    isPinned,
    onClick,
}: IPinnedTool) {
    const dispatch = useAppDispatch()

    const handleClick = useCallback(
        () => {
            onClick()
            
            const payload: IUpdateRepairOrderPinStatePayload = {
                id: id,
                isPinned: !isPinned
            }

            dispatch(updateRepairOrderPinState(payload))
        },
        [id, isPinned, dispatch, onClick],
    )

    const Icon = isPinned ? UnpinnedIcon : PinIcon

    return (
        <IconButton
            onClick={handleClick}
            size={`small`}
        >
            <Icon/>
        </IconButton>
    )
}

export default React.memo(PinnedTool)