import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import getDealer from 'api/getDealer'
import getDealerFeatures from 'api/getDealerFeatures'
import { Dealer } from 'api/types/dealer'
import { AppThunk } from 'store'

let initialState: Dealer = {
    ID: 0,
    RMDealerID: 0,
    ExternalID: ``,

    Address1: null,
    Address2: null,
    City: null,
    Country: null,
    Name: ``,
    Region: null,
    PostalCode: ``,
    SalesPhoneNumber:  null,
    ServicePhoneNumber: null,
    TimeZoneID: ``,
    AutoSendEstimateThresholdInMinutes: 0,

    EnableAdvisorPINs: false,
    EnableAppointments: false,
    EnableBDC: false,
    EnableCommunicationDashboard: false,
    EnableCRM: false,
    EnableEndUserAdvisorSelection: false,
    EnableMPVI: false,

    Users: null,
    Customers: null,
    MaintenanceSchedules: null,
    Messages: null,
    RepairOrders: null,
    DealerFeatures: null,
    DmsDealerSettings: null,
    WarrantyAlertRecipients: null,
    ROAlertTemplate: null
}

const slice = createSlice({
    name: `dealer`,
    initialState,
    reducers: {
        setDealer(state, action: PayloadAction<Dealer>) {
            return action.payload
        },
        resetDealer() {
            return initialState
        },
    }
})

export const {
    setDealer,
    resetDealer,
} = slice.actions

export default slice.reducer

export const retrieveDealer = (id: number): AppThunk => async dispatch => {
    try {
        const dealer = await getDealer(id)
        const dealerFeatures = await getDealerFeatures(id);
        dealer.DealerFeatures = dealerFeatures
        dispatch(setDealer(dealer))
    } catch (error) {
        throw new Error(`An error occured while attempting to retrieve dealer information: ${error}`)
    }
}