import React from 'react'
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material'
import CustomerSearchTypes from 'enums/customerSearchTypes'
import { UseFormRegister } from 'react-hook-form'
import { ICustomerSearchFormProps } from '../SearchForm'

interface ISearchByFieldProps {
    register: UseFormRegister<ICustomerSearchFormProps>
}

function SearchByField({
    register
}: ISearchByFieldProps) {
    return (
        <FormControl size="small">
            <InputLabel id={`search-by-label`}>Search By:</InputLabel>
            <Select
                {...register('searchBy', { required: true })}
                labelId={`search-by-label`}
                label={`Search By`}
                defaultValue={CustomerSearchTypes.PHONE}>
                <MenuItem value={CustomerSearchTypes.PHONE}>Phone</MenuItem>
                <MenuItem value={CustomerSearchTypes.EMAIL}>Email</MenuItem>
                <MenuItem value={CustomerSearchTypes.VIN}>VIN</MenuItem>
                <MenuItem value={CustomerSearchTypes.LICENSE}>License</MenuItem>
                <MenuItem value={CustomerSearchTypes.MODEL}>Model</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SearchByField