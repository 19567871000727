import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { setDmsSettings, updateValidation } from "modules/dealerForm";
import React, {
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "store";
import DmsSyncTaskSection from "./components/DmsSyncTaskSection";

export default function DmsDealerSettings() {
  const dispatch = useAppDispatch();

  const { dealer, dmsDealerSetting, isLoading: stateLoading, hasError, validation } = useAppSelector(
    (state) => state.dealerForm
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(stateLoading);
  }, [hasError, dealer, dmsDealerSetting, stateLoading, dispatch]);

  const DEALER_CODE_NAME = "DealerCode"
  const DMS_PROVIDER_ID_NAME = "DmsProviderID"

  const handleChange = (e) => {
    const currentSettings = {...dmsDealerSetting}
    const name = e.target.name
    const newValue = getValue(name, e)

    if (name === DMS_PROVIDER_ID_NAME) {
      toggleProviderSettings(newValue, currentSettings);
    }

    currentSettings[name] = newValue
    dispatch(setDmsSettings(currentSettings));

    updateStateValidation(name, newValue)
  }

  const updateStateValidation = (componentName: string, newValue: any) => {
    switch(componentName) {
      case DEALER_CODE_NAME:
        dispatch(updateValidation({
          ...validation,
          dmsDealerCodeHasError: !(newValue !== ``),
        }))
        break
      case DMS_PROVIDER_ID_NAME:
        dispatch(updateValidation({
          ...validation,
          dmsProviderHasError: !(newValue > 0),
        }))
        break
    }
  }

  const getValue = (componentName: string, e: any) => {
    const value = e.target.value
    const checked = e.target.checked

    switch(componentName) {
      case DEALER_CODE_NAME:
        return value
      case DMS_PROVIDER_ID_NAME:
        return value === `` ? 0 : value
      default:
        return checked
    }
  }

  const toggleProviderSettings = (value, object) => {
    switch (value) {
      case 1: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = true;
        break;
      }
      case 2: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = false;
        break;
      }
      case 3: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = true;
      }
    }

    return object;
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form>
          <h1>DMS Dealer Settings</h1>
          <Grid container spacing={2}>
            {dealer?.ID && (
              <Grid item xs={12}>
                <DmsSyncTaskSection
                  dmsDealerSettings={dmsDealerSetting}/>
              </Grid>
            )}
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={dmsDealerSetting.IsActive}
                      onChange={handleChange}
                    />
                  }
                  label="Active"
                  name="IsActive"
                />
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={dmsDealerSetting.IsCheckInSyncEnabled}
                      onChange={handleChange}
                    />
                  }
                  label="Enable Check In Sync"
                  name="IsCheckInSyncEnabled"
                />
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={dmsDealerSetting.IsIncrementalSyncEnabled}
                      onChange={handleChange}
                    />
                  }
                  label="Enable Incremental Sync"
                  name="IsIncrementalSyncEnabled"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    required
                    id="outlined-required"
                    label="Dealer Code"
                    name={DEALER_CODE_NAME}
                    value={dmsDealerSetting.DealerCode}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={validation.dmsDealerCodeHasError}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <FormControl fullWidth required>
                    <InputLabel id="dms-provider-select-label">
                      DMS Provider
                    </InputLabel>
                    <Select
                      id="outlined-required"
                      label="DMS Provider"
                      name={DMS_PROVIDER_ID_NAME}
                      value={dmsDealerSetting.DmsProviderID === 0 ? `` : dmsDealerSetting.DmsProviderID}
                      onChange={handleChange}
                      onBlur={handleChange}
                      error={validation.dmsProviderHasError}
                    >
                      <MenuItem value={``}>
                        <em>DMS Provider ID</em>
                      </MenuItem>
                      <MenuItem value={1}>CDK</MenuItem>
                      <MenuItem value={2}>Reynolds</MenuItem>
                      <MenuItem value={3}>Dealertrack</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};