import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import deleteRepairOrderAlert from 'api/deleteRepairOrderAlert'
import getRepairOrderByID from 'api/getRepairOrderByID'
import { roAlertSelectors } from 'modules/roAlerts'
import moment from 'moment'
import RepairOrderAlert, { createROAlert, updateROAlert } from 'services/RepairOrderAlert'
import { AppThunk } from 'store'
import { Modes } from '../Modes'
import { IError } from 'types/IError'

interface EnterAddModePayload {
    time: string
    text: string
}

interface EnterUpdateModePayload {
    alert: RepairOrderAlert | undefined
    mode: Modes
}

interface Props extends IError {
    mode: Modes
    isOpen: boolean
    timeValue: string
    textValue: string
    alert: RepairOrderAlert | undefined
}

let initialState: Props = {
    hasError: false,
    error: ``,
    mode: Modes.INITIALIZING,
    isOpen: false,
    timeValue: moment().toISOString(),
    textValue: ``,
    alert: undefined,
}

const slice = createSlice({
    name: `roAlertAddEditDialog`,
    initialState,
    reducers: {
        openSuccessful(state) {
            state.isOpen = true
            state.hasError = false
            state.error = ``
        },
        enterAddModeStarting(state) {
            state.mode = Modes.LOADING
            state.hasError = false
            state.error = ``
        },
        enterAddModeSuccessful(state, action: PayloadAction<EnterAddModePayload>) {
            const {
                text,
                time,
            } = action.payload

            state.mode = Modes.ADD
            state.textValue = text
            state.timeValue = time
            state.hasError = false
            state.error = ``
        },
        enterEditModeSuccessful(state, action: PayloadAction<RepairOrderAlert>) {
            const alert = action.payload
            state.alert = alert
            state.textValue = alert.text
            state.timeValue = alert.time
            state.mode = Modes.EDIT
            state.hasError = false
            state.error = ``
        },
        enterSnoozeModeSuccessful(state, action: PayloadAction<RepairOrderAlert>) {
            
            const alert = action.payload
            state.alert = alert
            state.textValue = alert.text
            state.timeValue = alert.time
            state.mode = Modes.SNOOZE
            state.hasError = false
            state.error = ``

        },
        enterDefaultModeSuccessful(state) {
            state.mode = Modes.DEFAULT
        },
        addNewAlertSuccessful() {
            return initialState
        },
        updateAlertSuccessful() {
            return initialState
        },
        deleteSuccessful(state) {
            return initialState
        },
        errorAcknowledged() {
            return initialState
        },
        textValueUpdated(state, action: PayloadAction<string>) {

            state.textValue = action.payload

        },
        timeValueUpdated(state, action: PayloadAction<string>) {

            state.timeValue = action.payload

        },
        setError(state, action: PayloadAction<IError>) {

            const {
                hasError,
                error
            } = action.payload

            state.mode = Modes.NONE

            state.hasError = hasError
            state.error = error

        },
        closed() {
            return initialState
        }
    }
})

export const {
    openSuccessful,
    enterAddModeStarting,
    enterAddModeSuccessful,
    enterDefaultModeSuccessful,
    enterEditModeSuccessful,
    enterSnoozeModeSuccessful,
    addNewAlertSuccessful,
    updateAlertSuccessful,
    deleteSuccessful,
    errorAcknowledged,
    textValueUpdated,
    timeValueUpdated,
    setError,
    closed,
} = slice.actions

export default slice.reducer

export const open = (repairOrderID: number): AppThunk => async (dispatch, getState) => {

    try {

        const alerts = roAlertSelectors.selectAll(getState()).filter(alert => alert.repairOrderID === repairOrderID)

        alerts.length === 0 ?
            dispatch(enterAddMode(repairOrderID)) :
            dispatch(enterDefaultModeSuccessful())

        dispatch(openSuccessful())

    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error occured while attempting to open the Dialog: ${error}`
        }))

    }

}

export const enterAddMode = (repairOrderID: number): AppThunk => async dispatch => {

    try {

        dispatch(enterAddModeStarting())

        const repairOrder = await getRepairOrderByID(repairOrderID)
        const text = `Contact RO#: ${repairOrder.RONumber}, Customer: ${repairOrder.Customer.FullName}, Tag: ${repairOrder.Tag}`

        const time = moment().add(2, 'hours').toISOString()

        dispatch(enterAddModeSuccessful({
            text,
            time,
        }))

    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error occured while attempting to add a new Alert: ${error}`
        }))

    }

}

export const enterUpdateMode = (payload: EnterUpdateModePayload): AppThunk => async dispatch => {

    try {

        const {
            alert,
            mode
        } = payload

        if(alert === undefined) 
            throw new Error('An attempt was made to edit an undefined repair order alert.')

        if(mode === Modes.EDIT) {

            dispatch(enterEditModeSuccessful(alert))

        } else if (mode === Modes.SNOOZE) {

            // If an alert from the previous day comes in, we must
            // make sure we update the date to today so it passes
            // validation and can be snoozed until some time today.
            const oldDate = moment(alert.time)
            const newDate = moment()

            newDate.hours(oldDate.hours())
            newDate.minutes(oldDate.minutes())

            const validAlert: RepairOrderAlert = {
                ...alert,
                time: newDate.toISOString()
            }

            dispatch(enterSnoozeModeSuccessful(validAlert))

        }            

    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error occurred while attempting to enter alert edit mode: ${error}`
        }))

    }
    
}

export const addNewAlert = (repairOrderID: number): AppThunk => async (dispatch, getState) => {

    try {

        const userID = getState().user.ID

        if (userID === null) throw new Error(`UserID is null!`)

        const {
            textValue: text,
            timeValue: time,
        } = getState().roAlertAddEditDialog

        await createROAlert({
            id: 0,
            userID,
            repairOrderID,
            text,
            time: time,
            acknowledged: false,
        })

        dispatch(addNewAlertSuccessful())
    
    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error has occured while attempting to create a new RO Alert: ${error}`
        }))

    }

}

export const updateAlert = (): AppThunk => async (dispatch, getState) => {

    try {

        const alert = getState().roAlertAddEditDialog.alert

        if (alert === undefined) throw new Error(`There is no alert in store!`)

        const {
            textValue: text,
            timeValue: time,
        } = getState().roAlertAddEditDialog

        await updateROAlert({
            ...alert,
            text,
            time: time,
        })

        dispatch(updateAlertSuccessful())
    
    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error has occured while attempting to update an existing RO Alert: ${error}`
        }))

    }

}

export const deleteAlert = (id: number): AppThunk => async dispatch => {

    try {

        await deleteRepairOrderAlert(id)
        
        dispatch(deleteSuccessful())
    
    } catch (error) {

        dispatch(setError({
            hasError: true,
            error: `An error has occured while attempting to delete the RO Alert: ${error}`
        }))
    
    }

}