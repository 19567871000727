import { IChatUser } from 'types/chatUser'

export default interface ChatUser {
    ID: number
    Email: string
}

export function mapToChatUser({
    ID,
    Email
}: ChatUser): IChatUser {
    return {
        id: ID,
        email: Email
    }
}