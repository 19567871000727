export enum Hub {
    CRM = 'CRMHub'
}

//messages the hub manager can emit
export enum Status {
    CONNECTED = 'connected',
    CONNECTING = 'connecting',
    DISCONNECTED = 'disconnected',
}

//signals coming from the server. Same as the method name on the server
export enum Signal {
    CUSTOMER_OPT_IN = 'customerOptIn',
    CUSTOMER_OPT_OUT = 'customerOptOut',
    MESSAGE_CREATED = 'messageCreated',
    MESSAGE_RECEIVED = 'messageReceived',
    MESSAGES_UPDATED = 'messagesUpdated',
    REPAIR_ORDER_ALERT_UPDATED = 'repairOrderAlertUpdated',
    REPAIR_ORDER_CREATED = 'repairOrderCreated',
    REPAIR_ORDER_UPDATED = 'repairOrderUpdated',
    REPAIR_ORDER_MPVI_STATUS_UPDATED ='repairOrderMpviStatusUpdated',
    REPAIR_ORDER_AUTO_ALERT_CREATED = 'repairOrderAutoAlertCreated',
    REPAIR_ORDER_SYNC_COMPLETED = 'repairOrderSyncCompleted',
    VIDEO_CREATED = 'videoCreated',
    VIDEO_UPDATED = 'videoUpdated',
    CHAT_CONVERSATIONS_UPDATED = 'chatConversationsUpdated',
    CHAT_MESSAGE_CREATED = 'chatMessageCreated',
    USER_CHAT_CONVERSTATION_UPDATED = 'userChatConversationUpdated',
}