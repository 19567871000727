import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import CheckBox from 'components/CheckBox'

const InspectionCheckboxCell = ({
    id,
    onChange
}) => {  
    return (
        <div>
            <CheckBox
                id={id}
                onChange={onChange}
                className={styles.container}
            ></CheckBox>
        </div>
    )
}

InspectionCheckboxCell.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onChange: PropTypes.func
}

export default InspectionCheckboxCell