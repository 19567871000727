import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { useAppDispatch } from 'store'
import { updateRepairOrder } from 'components/RepairOrderListView/modules'
import setRepairOrderNotes from 'api/setRepairOrderNotes'

export interface INotesDialog {
    id: number
    text: string
    onClose(): void
}

function NotesDialog({
    id,
    text,
    onClose,
}: INotesDialog) {
    const dispatch = useAppDispatch()
    const [canSave, setCanSave] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [textValue, setTextValue] = useState<string>(text)

    const handleClose = () => {
        onClose()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || ``

        if (value !== text) {
            setCanSave(true)
            setTextValue(value)
        } else {
            setCanSave(false)
        }
    }

    const handleSave = () => {
        setIsSaving(true)
        setRepairOrderNotes(id, textValue).then(() => {
            dispatch(updateRepairOrder({
                id,
                changes: {
                    Notes: textValue
                },
            }))
        }).catch(() => {
            setIsError(true)
        }).finally(() => {
            setIsSaving(false)
            handleClose()
        })
    }

    const content = !isError ? <TextField
        color={`primary`}
        defaultValue={text}
        fullWidth
        onChange={handleChange}
        disabled={isSaving}
        autoFocus
    ></TextField> : <Typography color={`error`}>An error has occured while attempting to update. Please contact Support if the problem persists.</Typography>

    return (  
        <Dialog
            open
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Notes</DialogTitle>
            <form>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color={`primary`}
                        disabled={isSaving}
                    >Cancel</Button>
                    <Button
                        type={`submit`}
                        color={`primary`}
                        disabled={!canSave || isSaving || isError}
                        onClick={handleSave}
                    >{isSaving ? `Saving...` : `Save`}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default React.memo(NotesDialog)