import { Tooltip } from "@mui/material"
import React from "react"
import PanToolIcon from '@mui/icons-material/PanTool';

interface IWarrantyAlertIcon{
    HasActiveWarrantyAlert: boolean
    FinalWarrantyAlertSent: boolean
    WarrantyClaimDeniable: boolean
}

function WarrantyAlertIcon({
    HasActiveWarrantyAlert,
    FinalWarrantyAlertSent,
    WarrantyClaimDeniable
}: IWarrantyAlertIcon){

    var fillColor = FinalWarrantyAlertSent || WarrantyClaimDeniable ? '#ff3333' : '#ff9900'

    var tooltipMessage = WarrantyClaimDeniable ? 
        `Warranty claims for this repair order are deniable because the vehicle was not submitted to SAVI three days (before or after) the repair order was created. A new repair order and SAVI submission are required.` :
        `This vehicle has not been reported to SAVI. Do not begin warranty work on this vehicle until it has been submitted to SAVI.`

    return  HasActiveWarrantyAlert ? (
        <Tooltip
            title={tooltipMessage}>
            {WarrantyClaimDeniable ? 
                <PanToolIcon style={{ color: fillColor}}/> :
                <svg width="24" height="24">
                    <circle cx={12} cy={12} r={12} fill={fillColor}/>
                    <text cursor='context-menu' fontSize={10} fontWeight='bold' fill='white' y={15.5} x={3}>WA!</text>
                </svg>}
        </Tooltip>
    ) : null

}

export default WarrantyAlertIcon