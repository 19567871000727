import { useEffect } from 'react'
import getBdcDashboardData from 'utils/getBdcDashboardData'
import {
    useAppDispatch
} from 'store'
import { useQuery } from 'react-query'
import {
    setIsLoadingCategoryData,
    setIsErrorCategoryData
} from 'modules/bdcDashboard'

function useSubscribeToCategoryData(categoriesData, setCategoriesData) {
    const dispatch = useAppDispatch()
    const {
        isLoading,
        isError
    } = useQuery(`bdcCategoryData`, getBdcDashboardData, {
        onSuccess: (data: any) => setCategoriesData([
            {
                ...categoriesData[0],
                ...data.noShowAppointments
            },
            {
                ...categoriesData[1],
                ...data.tomorrowsAppoinements
            },
            {
                ...categoriesData[2],
                ...data.upcomingAppointments
            },
            {
                ...categoriesData[3],
                ...data.noRepairOrdersLastSixMonths
            },
            {
                ...categoriesData[4],
                ...data.noRepairOrdersLastSixToNineMonths
            },
            {
                ...categoriesData[5],
                ...data.noRepairOrdersLastNineToTwelveMonths
            },
            {
                ...categoriesData[6],
                ...data.noRepairOrdersLastTwelveToFifteenMonths
            }
        ])
    })

    useEffect(() => {      
        dispatch(setIsErrorCategoryData(isError))
    }, [isError, dispatch])
    
    useEffect(() => {
        dispatch(setIsLoadingCategoryData(isLoading))
    }, [isLoading, dispatch])
}

export default useSubscribeToCategoryData