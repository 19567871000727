import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { useEffect } from 'react'
import { useAppSelector } from 'store'

function useSentry() {
    const user = useAppSelector(state => state.user)

    useEffect(() => {
        if (process.env.REACT_APP_SENTRY_ENABLED === `true`) {
            Sentry.init({
                dsn: "https://b7096182017f4890bc853a26a225968a@o967782.ingest.sentry.io/5919138",
                integrations: [new Integrations.BrowserTracing()],
                beforeSend(event, hint) {
                    // Check if it is an exception, and if so, show the report dialog
                    if (event.exception) {
                        if (process.env.REACT_APP_SENTRY_FEEDBACK_FORM_ENABLED === `true`) Sentry.showReportDialog({ eventId: event.event_id })
                    }
                    return event;
                },
                environment: process.env.NODE_ENV,
                release: process.env.REACT_APP_VERSION,
                initialScope: {
                    user: {
                        ...user,
                        id: `${user.ID}`,
                    },
                },
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.5,
              });
        }
    }, [user])
}

export default useSentry