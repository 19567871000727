import { TextField } from "@mui/material"
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styles from './styles.module.scss'
import React, { useCallback, useEffect } from "react"
import moment, { Moment } from "moment"
import { timeValueUpdated } from "components/RepairOrderAlertAddEditDialog/modules"
import { useAppDispatch } from "store"

export interface ITimeSelect {
    time: Moment
}

const TimeSelect = ({
    time
}: ITimeSelect) => {
    const dispatch = useAppDispatch()

    const hoursInputFieldId = `hours-input-field`
    const minutesInputFieldId = `minutes-input-field`

    const constants = {
        minHours: 0,
        maxHours: 12,
        minMinutes: 0,
        maxMinutes: 59
    }

    const getCleanHoursMinutes = (value: string, min: number, max: number) : string => {
        
        if (value.length > 2)
        {
            value = value.slice(0, 2)
        }

        if (/^\d+$/.test(value)) // checks that the input string is all digits
        {
            const integer = parseInt(value)

            const newValue =  integer > max ? max.toString() : integer < min ? min.toString() : value

            return newValue
        }
        else // anything that is not a number
        {
            return "0"
        }
    }

    const setTime = (time: Moment) => {

        time.seconds(0)

        dispatch(timeValueUpdated(time.toISOString()))

    }

    const handleOnHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
        const currentTime = time
        
        const newTime = moment(currentTime)

        if (e.target.value.trim() !== "")
        {
            let newHours = getCleanHoursMinutes(e.target.value, constants.minHours, constants.maxHours)

            e.target.value = newHours

            const newHoursNumber = parseInt(newHours)
    
            if(parseInt(currentTime.format('H')) >= 12 && newHoursNumber < 12) {
    
                newHours += 12
    
            }
    
            newTime.hours(newHoursNumber)

            setTime(newTime)
        }
    }

    const handleOnMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const newTime = moment(time)

        if (e.target.value.trim() !== "")
        {
            let newMinutes = getCleanHoursMinutes(e.target.value, constants.minMinutes, constants.maxMinutes)

            e.target.value = newMinutes.toString()

            const newMinutesNumber = parseInt(newMinutes)

            newTime.minutes(newMinutesNumber)

            setTime(newTime)
        }
    }

    const onMeridiemChanged = () => {

        let newTime = moment(time)
        
        let hours = parseInt(newTime.format('H'))
        
        if(hours < 12) {
            
            newTime.add(12, 'hours')

        } else {

            newTime.subtract(12, 'hours')

        }

        setTime(newTime)

    }

    const selectInputField = useCallback((selector: string) => {

        (document.getElementById(selector) as HTMLInputElement).select();

    }, [])

    useEffect(() => {

        selectInputField(hoursInputFieldId)

    }, [hoursInputFieldId, selectInputField])


    return (
        <div className={styles.root}>
            <TextField
                id={`hours-input-field`}
                size={`small`}
                label={`Hours`}
                type={`text`}
                InputLabelProps={{ shrink: true, }}
                InputProps={{ inputProps: { min: 1, max: 12 } }}
                variant={`outlined`}
                className={styles.timeField}
                defaultValue={time.format('h')}
                onChange={handleOnHoursChange}
                onFocus={() => selectInputField(hoursInputFieldId)}
            ></TextField>
            <span style={{'fontSize': `30px`}}>:</span>
            <TextField
                id={`minutes-input-field`}
                size={`small`}
                label={`Minutes`}
                type={`text`}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0, max: 59 } }}
                variant={`outlined`}
                className={styles.timeField}
                defaultValue={time.format('mm')}
                onChange={handleOnMinutesChange}
                onFocus={() => selectInputField(minutesInputFieldId)}
            ></TextField>

            <ToggleButtonGroup
                size={`small`}>

                <ToggleButton
                    selected={parseInt(time.format('H')) < 12}
                    onClick={onMeridiemChanged}
                    value={`AM`}
                    aria-label={`left-aligned`}
                >AM</ToggleButton>
                <ToggleButton
                    selected={parseInt(time.format('H')) >= 12}
                    onClick={onMeridiemChanged}
                    value={`PM`}
                    aria-label={`centered`}
                >PM</ToggleButton>

            </ToggleButtonGroup>
        </div>
    )
}

export default TimeSelect