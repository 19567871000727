import { createEntityAdapter, createSlice, current, EntityState, PayloadAction } from "@reduxjs/toolkit";
import getDealerMessagesByCustomer from "api/getDealerMessagesByCustomer";
import { Message } from "api/types/message";
import { RootState } from "rootReducer";
import { AppThunk } from "store";

const customerMessages = createEntityAdapter<Message>({
    selectId: (message) => message.ID
})

interface ICustomerMessages {
    isLoading: boolean,
    isError: boolean,
    infoText: string,
    customerID: number | undefined
    customerMessages: EntityState<Message>
}

let initialState: ICustomerMessages = {
    isLoading: true,
    isError: false,
    infoText: `Initializing...`,
    customerID: undefined,
    customerMessages: customerMessages.getInitialState()
}

const slice = createSlice({
    name: `customerMessages`,
    initialState: initialState,
    reducers: {
        getDataStart(state) {
            state.isLoading = true
            state.isError = false
            state.infoText = [...current(state.customerMessages.ids)].length === 0 ? `Retrieving customer messages...` : `Refreshing Data...`
        },
        getDataSuccess(state, action: PayloadAction<Message[]>){
            const messages = action.payload
            
            customerMessages.removeAll(state.customerMessages)
            customerMessages.upsertMany(state.customerMessages, messages)

            state.isLoading = false
            state.isError = false
            state.infoText = ``
        },
        getDataFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
        },
        messageUpserted(state, action: PayloadAction<Message>) {
            customerMessages.upsertOne(state.customerMessages, action.payload)
        },
        updateCustomerID(state, action: PayloadAction<number>){
            state.customerID = action.payload
        },
        reset(state){
            customerMessages.removeAll(state.customerMessages)
            state.customerID = undefined
            state.isLoading = initialState.isLoading
            state.isError = initialState.isError
            state.infoText = initialState.infoText
        }
    }
})

export const {
    getDataStart,
    getDataSuccess,
    getDataFailure,
    messageUpserted,
    updateCustomerID,
    reset
} = slice.actions

export const customerMessagesSelectors = customerMessages.getSelectors<RootState>(state => state.customerMessages.customerMessages)

export default slice.reducer

export const getData = (customerID: number): AppThunk => async dispatch => {
    try {

        dispatch(getDataStart())

        const messages = await getDealerMessagesByCustomer(customerID)

        dispatch(getDataSuccess(messages))

    } catch(error){

        dispatch(getDataFailure(`There was an error retrieving customer messages: ${error}`))
    
    }
}