import React, { useState } from 'react'
import {
    IconButton,
    Menu,
    MenuProps,
} from '@mui/material'
import MenuItem from './components/MenuItem'
import { IPage } from 'pages'

export interface IMenuItem {
    page: IPage,
    visible?: boolean,
}

export interface IAppMenu {
    icon: React.ReactNode
    menuProps: Omit<MenuProps, 'open'>
    menuItems: IMenuItem[]
}

function AppMenu({
    icon,
    menuProps,
    menuItems,
}: IAppMenu) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const generateMenuItems = (items: IMenuItem[]) => items.map((item: IMenuItem) => {
        const route = item.page.route
        const title = item.page.title
        return (
            item.visible ? 
            <MenuItem 
                key={route}
                route={route}>

                    {title}
            </MenuItem> : null
        )
    })

    return <>
        <IconButton onClick={handleButtonClick} color="inherit" size="large">
            {icon}
        </IconButton>
        <Menu
            {...menuProps}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            anchorEl={anchorEl}>

            {generateMenuItems(menuItems)}
        </Menu>
    </>;
}

export default AppMenu