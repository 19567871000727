import React from "react";
import formatPrice from "utils/formatPrice";
import SummerySectionCell from "../../SummerySectionCell";
import { green, red } from "@mui/material/colors";
import SummerySectionContainer from "../../SummerySectionContainer";

export interface IWarrantyAlertDashboardSummeryStatsProps {
    totalRepairOrderCount: number,
    totalDollarAmount: number,
    totalLostDollarAmount: number
}

function WarrantyAlertSummerySection({
    totalRepairOrderCount,
    totalDollarAmount,
    totalLostDollarAmount
}: IWarrantyAlertDashboardSummeryStatsProps) {
    return (
        <SummerySectionContainer>
            <SummerySectionCell
                header={`Total Alerted ROs`}
                value={`${totalRepairOrderCount}`}/>
            <SummerySectionCell
                statusColor={green[500]}
                header={`Total Cured`}
                value={`${formatPrice(totalDollarAmount)}`}/>
            <SummerySectionCell
                statusColor={red[500]}
                header={`Total Uncured`}
                value={`${formatPrice(totalLostDollarAmount)}`}/>
        </SummerySectionContainer>
    )
}

export default WarrantyAlertSummerySection