import apiFetch from 'api/utils/APIFetch'

export type UnreadMessageCounts = {
    Total: number,
    ConversationIDsWithUnreadMessages: {
        ID: number,
        Count: number,
    }[],
}

const getUnreadMessageCounts = (): Promise<UnreadMessageCounts> => apiFetch(`/api/chat/unread-messages`)

export default getUnreadMessageCounts