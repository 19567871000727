import { ICollasibleTableRowProps, ITableHeaderProps, ITableRowProps } from "./interfaces"
import { Box, IconButton, Typography } from "@mui/material"
import React from "react"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import theme from 'assets/theme'
import styles from './styles.module.scss'

export function TableHeader({
    columnDefinitions,
    offset
}: ITableHeaderProps) {
    return (
        <Box 
            className={styles.header} 
            sx={{color: theme.palette.primary.light}}>

            {/* Tab like spacing before column headers. */}
            {Array(offset).fill(0).map((_, index) => 
                <Box 
                    key={`offset-table-header-cell-${index}`} 
                    className={styles.cell} 
                    flex={.08}/>)}
            
            {/* The column headers */}
            {columnDefinitions.map((column, index) => {
                return (
                    <Box
                        key={`table-header-cell-${index}`}
                        className={styles.cell}
                        style={{justifyContent: column.isNumber ? 'flex-end' : 'flex-start'}}
                        flex={column.flex}>{column.content}</Box>
                )
            })}
        </Box>
    )
}

export function TableRow({
    columnDefinitions,
    rowData,
    offset,
    startIcon = null,
}: ITableRowProps){
    return (
        <Box className={styles.row}>
            {/* Tab like spacing before row cell data. */}
            {Array(offset-1).fill(0).map((_, index) => 
                <Box 
                    key={`offset-table-row-cell-${index}`}
                    className={styles.cell}
                    flex={.08}/>)}

            {/* Collapsible icon if the component can expand. */}
            {startIcon && startIcon}

            {/* The row data */}
            {Object.keys(rowData).map((key, index) => {
                const columnDef = columnDefinitions[index]
                return (
                    <Box 
                        key={`table-row-cell-${index}`} 
                        className={styles.cell}
                        style={{justifyContent: columnDef.isNumber ? 'flex-end' : 'flex-start'}} 
                        flex={columnDef.flex}>
                            
                            {columnDef.renderCell !== undefined ? 
                                columnDef.renderCell(rowData[key]) : 
                                <Typography noWrap sx={{fontSize: '13px'}}>{rowData[key]}</Typography>}
                    </Box>
                )
            })}
        </Box>
    )
}

export function CollasibleTableRow({
    isExpanded,
    columnDefinitions,
    rowData,
    offset,
    onClick,
    children
}: ICollasibleTableRowProps) {
    return (
        <Box>
            <TableRow
                columnDefinitions={columnDefinitions}
                rowData={rowData}
                offset={offset}
                startIcon={(
                    <Box className={styles.cell} flex={.08}>
                        <IconButton sx={{padding: 0}} onClick={() => onClick !== undefined ? onClick() : null}>
                            {!isExpanded ? <KeyboardArrowRightIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </Box>
                )}/>

            {children}
        </Box>
    )
}