import React from 'react'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import SubmitButton from 'componentsUI/SubmitButton'
import { sendEstimateLink } from 'modules/estimateDialog'
import styles from './styles.module.scss'

export interface ITextEstimateContent {
    onClose(): any
}

function TextEstimateContent({
    onClose,
}: ITextEstimateContent) {
    const dispatch = useAppDispatch()
    const {
        isSending,
        repairOrderID,
        estimateKey,
    } = useAppSelector(state => state.estimateDialog)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const handleSendTextClick = () => {
        if (repairOrderID !== undefined) {
            dispatch(sendEstimateLink(repairOrderID, dealerID))
        } else {
            throw new Error(`Missing repairOrderID.`)
        }
    }

    return (
        <>
            <DialogContent>
                <DialogContentText>Review the estimate below; send a text to the customer with a link to the estimate so they can approve/decline services.</DialogContentText>
                <iframe src={`${process.env.REACT_APP_MPVI_URL}/${estimateKey}?m=ro`} title={`mpvi-iframe`} className={styles.iframe}></iframe>
            </DialogContent>
            <DialogActions>
                <SubmitButton
                    canSubmit
                    isLoading={isSending}
                    onClick={handleSendTextClick}
                    autoFocus
                    text={`Text Estimate`}
                ></SubmitButton>
                <Button  
                    onClick={onClose}
                    color={`primary`}
                >Send Later</Button>
            </DialogActions>
        </>
    )
}

export default TextEstimateContent