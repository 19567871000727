import React from 'react'
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { deleteAlert } from 'components/RepairOrderAlertAddEditDialog/modules'

export interface IDeleteDialog {
    id: number
    onClose(): void
    onCancel(): void
    isOpen: boolean
}

function DeleteDialog({
    id,
    onClose,
    onCancel,
    isOpen,
}: IDeleteDialog) {
    const dispatch = useDispatch()

    const handleDelete = () => {
        dispatch(deleteAlert(id))
        
        onClose()
    }

    return (
        <Dialog
            open={isOpen}
        >
            <DialogContent>
                <DialogContentText>Are you sure you would like to delete this alert?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color={`primary`}
                    onClick={onCancel}
                >No</Button>
                <Button
                    color={`primary`}
                    onClick={handleDelete}
                    autoFocus
                >Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog