import React, { useEffect, useState } from "react";
import FeaturesListItem from "../FeatureListItem";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store";
import { useSubscribeToFeatures } from "components/DealerForm/hooks/useSuscribeToFeatures";
import LoadingIndicator from "components/LoadingIndicator";
import { upsertFeatures } from "modules/dealerForm";

export interface IFeatureListItem {
  ID: number;
  DealerID: number;
  FeatureID: number;
  Name: string;
  Description: string;
  IsEnabled: boolean;
}

const FeaturesList = () => {
  useSubscribeToFeatures();
  const dispatch = useAppDispatch();
  const { dealerFeatures, isLoading: dealerFeaturesLoading } = useAppSelector(
    (state) => state.dealerForm
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(dealerFeaturesLoading);
  }, [dealerFeaturesLoading]);

  const [currentFeatures, setCurrentFeatures] = useState(
    [] as IFeatureListItem[]
  );

  useEffect(() => {
    setCurrentFeatures(dealerFeatures);
  }, [dealerFeatures]);

  const handleFeatureChange = (id: number, value: boolean) => {
    const updatedFeatures = currentFeatures.map((feature) =>
      feature.FeatureID === id ? { ...feature, IsEnabled: value } : feature
    );

    dispatch(upsertFeatures(updatedFeatures));
  };

  return (
    <>
      <h1>Features</h1>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Box component={"div"}>
          {currentFeatures &&
            currentFeatures.map((feature) => (
              <FeaturesListItem
                key={feature.FeatureID}
                feature={feature}
                onFeatureChange={handleFeatureChange}
              />
            ))}
        </Box>
      )}
    </>
  );
};

export default FeaturesList;
