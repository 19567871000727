import React from 'react'

import styles from './styles.module.scss'

function NoResults() {
    return (
        <div className={styles.container}>
            <div>No Results</div>
        </div>
    )
}

export default NoResults