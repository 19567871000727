import React from 'react'
import {
    Redirect,
    Route,
} from 'react-router-dom'
import { IPage } from 'pages'
import useAppPage from 'hooks/useAppPage'

//TODO: Need to extend the Route type
export interface IAppRoute {
    page: IPage
    children: any
    exact?: boolean
    path: string
    isBaseRoute: boolean
}

AppRoute.defaultProps = {
    exact: false,
    isBaseRoute: false
}

function AppRoute({
    page,
    children,
    isBaseRoute,
    ...rest
}: IAppRoute) {
    const {
        isAccessible
    } = useAppPage(page, !isBaseRoute)

    const content = isAccessible ? 
        <Route {...rest}>
            {children}
        </Route> : <Redirect to={`/`}/>

    return (content)
}

export default AppRoute