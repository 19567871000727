import { IChatRepairOrder } from 'types/chatRepairOrder'

export default interface ChatRepairOrder {
    ID: number
    RONumber: string
}

export function mapToChatRepairOrder({
    ID,
    RONumber,
}: ChatRepairOrder): IChatRepairOrder {
    return {
        id: ID,
        number: RONumber,
    }
}