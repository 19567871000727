import React from "react";
import { VideoMpviReportingOverviewViewModel } from "api/types/reporting/VideoMpviReportingOverviewViewModel";
import ReportingContainer from "../../ReportingContainer";
import VideoMpviSummerySection from "../VideoMpviSummerySection";
import { ILoading } from "../../Interfaces/ILoading";
import VideoMpviUserDataGrid from "../VideoMpviUserDataGrid";
import PerformanceSectionContainer from "../../PerformanceSectionContainer";
import theme from "assets/theme";
import PerformanceSection from "../../PerformanceSection";
import formatPrice from "utils/formatPrice";

export interface IVideoMpviOverviewProps extends ILoading {
    data: VideoMpviReportingOverviewViewModel
}

function VideoMpviOverview({
    data,
    isLoading
}: IVideoMpviOverviewProps) {

    const {
        TotalRepairOrders,
        TotalVideoMPVIs,
        AverageASRsPerMPVI,
        TotalASRs,
        TotalApprovedASRs,
        TotalDollarAmountOfASRs,
        TotalApprovedDollarAmountOfASRs,
        ByUser
    } = data

    return (
        <ReportingContainer
            title={`Video MPVIs`}
            isLoading={isLoading}
            viewDetails={true}
            viewDetailsEnabled={false}>

            <VideoMpviSummerySection
                totalRepairOrderCount={TotalRepairOrders}
                totalVideoMpvisRecordedCount={TotalVideoMPVIs}
                averageASRsPerMpviCount={AverageASRsPerMPVI}/>

            <PerformanceSectionContainer>
                <PerformanceSection
                    maxValue={TotalASRs}
                    actualValue={TotalApprovedASRs}
                    label={`Approved ASRs`}
                    content={`${TotalApprovedASRs} / ${TotalASRs}`}
                    color={theme.palette.primary.light}/>

                <PerformanceSection
                    maxValue={TotalDollarAmountOfASRs}
                    actualValue={TotalApprovedDollarAmountOfASRs}
                    label={`Accepted $ of ASRs`}
                    content={`${formatPrice(TotalApprovedDollarAmountOfASRs)} / ${formatPrice(TotalDollarAmountOfASRs)}`}
                    color={theme.palette.secondary.light}/>
            </PerformanceSectionContainer>

            {TotalVideoMPVIs > 0 &&
                <VideoMpviUserDataGrid
                    data={ByUser}/>}
            
        </ReportingContainer>
    )
}

export default VideoMpviOverview