import {
  Clear,
  Badge,
  HighlightOff,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  TextField,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material";
import { Dealer } from "api/types/dealer";
import { setPageNumber, setSearchTerm } from "modules/dealerPicker";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import DealerListItem from "../DealerListItem";
import { isEmpty } from "voca";
import { useSubscribeToPickerDealers } from "components/UserMenu/hooks/useSuscribeToPickerDealers";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 5,
  pt: 2,
  px: 4,
  pb: 3,
};

interface IDealerPickerProps {
  data: Dealer;
  closeMenu: () => void;
}

function DealerPicker(props: IDealerPickerProps) {
  const { data: dealer, closeMenu } = props;

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const { dealers, pageNumber, pageSize, searchTerm, totalPages } =
    useAppSelector((state) => state.dealerPickerSearch);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOnSubmit = () => {
    handleClearSearch();
    setOpen(false);
    closeMenu();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(event.target.value));
    dispatch(setPageNumber(0));
  };

  const handleClearSearch = () => {
    dispatch(setSearchTerm(``));
    dispatch(setPageNumber(0));
  };

  const handleChangePage = (e, newPage: number) => {
    dispatch(setPageNumber(newPage - 1));
  };

  useSubscribeToPickerDealers(searchTerm, pageNumber, pageSize);

  return (
    <React.Fragment>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <Badge fontSize="small" />
        </ListItemIcon>
        Change Dealer
      </MenuItem>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 id="child-modal-title">Choose Active Dealer</h2>
            <HighlightOff onClick={handleClose} sx={{ color: "gray" }} />
          </Box>
          <Box
            sx={{
              pb: 1,
            }}
          >
            <TextField
              fullWidth
              size="small"
              label={`Search...`}
              onInput={handleSearch}
              InputProps={{
                endAdornment: !isEmpty(searchTerm) ? (
                  <InputAdornment
                    disableTypography
                    position="end"
                    onClick={handleClearSearch}
                  >
                    <Clear />
                  </InputAdornment>
                ) : null,
              }}
              value={searchTerm}
            />
          </Box>
          <Box
            sx={{
              height: 500,
              overflow: "auto",
            }}
          >
            {dealers.map((data) => (
              <DealerListItem
                key={data.ID}
                data={data}
                default={dealer.ID === data.ID}
                toggleMain={handleCloseOnSubmit}
              />
            ))}
          </Box>
          <Stack sx={{ alignItems: "center", mt: 1 }}>
            <Pagination
              count={totalPages}
              onChange={(e, value) => handleChangePage(e, value)}
              color="primary"
            />
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default DealerPicker;
