import React, { useCallback, useEffect, useState } from 'react'
import { Box, Divider, Paper, Stack, styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useHistory } from 'react-router'
import pages from 'pages'
import { useAppSelector } from 'store'
import { Views } from 'components/Dashboard/modules'
import ReceivedIcon from '@mui/icons-material/CallReceived'
import SentIcon from '@mui/icons-material/CallMade'
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import DashboardIcon from '@mui/icons-material/Dashboard';

interface INavButtons {
    type: Views
}

function NavButtons({
    type
}: INavButtons) {
    const [currentView, setCurrentView] = useState<Views>(type)
    const history = useHistory()

    const {
        unansweredCount,
        isLoading: isLoadingCommDashboard,
    } = useAppSelector(
        state => state.communicationDashboard
    )

    const {
        Role: userRole
    } = useAppSelector(
        state => state.user
    )
    
    const isLoadingROListView = useAppSelector(state => state.repairOrderListView.isLoading)
    
    const isLoading = isLoadingCommDashboard || isLoadingROListView

    const handleClick = useCallback((location: string, view: Views) => {
        if (!isLoading) {
            history.push(location)
            setCurrentView(view)
        }
    }, [history, isLoading])

    useEffect(() => {
        if(!isLoading && currentView === Views.NONE){
            handleClick(pages.REPAIR_ORDERS.route, Views.REPAIR_ORDERS)
        }
    }, [isLoading, userRole, handleClick, currentView])

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
          margin: theme.spacing(0.5),
          border: 0,
          '&.Mui-disabled': {
            border: 0,
          },
          '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
          },
          '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
          },
        },
      }));

    return (
        <Box
            display={`grid`}
            gap={3}
            gridTemplateColumns={`repeat(2, min-content)`}
        >

            <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  flexWrap: 'wrap',
                }}>
                <Stack direction={`row`}>
                    <StyledToggleButtonGroup
                        size={`small`}
                        color={`primary`}
                        exclusive
                        value={currentView}
                        style={{
                            whiteSpace: `nowrap`
                        }}>
                        <ToggleButton 
                            value={Views.REPAIR_ORDERS}
                            onClick={() => handleClick(pages.REPAIR_ORDERS.route, Views.REPAIR_ORDERS)}>
                            <DashboardIcon/>
                            Repair Orders
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                    <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                    <StyledToggleButtonGroup
                        size={`small`}
                        color={`primary`}
                        exclusive
                        value={currentView}
                        style={{
                            whiteSpace: `nowrap`
                        }}>
                        <ToggleButton 
                            value={Views.SENT}
                            onClick={() => handleClick(pages.SENT_MESSAGES.route, Views.SENT)}>
                            <SentIcon/>
                            Sent
                        </ToggleButton>
                        <ToggleButton 
                            value={Views.RECEIVED}
                            onClick={() => handleClick(pages.RECIEVED_MESSAGES.route, Views.RECEIVED)}>
                            <ReceivedIcon/>
                            Received
                        </ToggleButton>
                        <ToggleButton 
                            value={Views.AUTOMATED}
                            onClick={() => handleClick(pages.AUTOMATED_MESSAGES.route, Views.AUTOMATED)}>
                            <QuickreplyIcon/>
                            Automated
                        </ToggleButton>
                        <ToggleButton 
                            value={Views.UNANSWERED}
                            onClick={() => handleClick(pages.UNANSWERED_MESSAGES.route, Views.UNANSWERED)}>
                            <span>{unansweredCount} Unanswered</span>
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Stack>
            </Paper>
            
        </Box>
    );
}

export default NavButtons
