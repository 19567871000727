import React, { useEffect, useState } from 'react'
import { MenuItem, OutlinedInput, Select } from '@mui/material'
import { IStockMessage } from 'types/stockMessage'
import getStockMessages from 'api/getStockMessages'
import { mapToStockMessage } from 'api/types/stockMessage'
import { replaceAll, titleCase } from 'voca'
import store, { useAppSelector } from 'store'
import { customerSelectors } from 'components/RepairOrderListView/modules'
import styles from './styles.module.scss'
import getRepairOrderByID from 'api/getRepairOrderByID'
import { RepairOrder } from 'api/types/repairOrder'

enum MessageVars {
    ADVISOR_NAME = `{ADVISOR_NAME}`,
    CUSTOMER_NAME = `{CUSTOMER_NAME}`,
    DEALER_NAME = `{DEALER_NAME}`,
    VEHICLE_NAME = `{VEHICLE_NAME}`,
}

type IStockMessageSelect = {
    disabled?: boolean
    onChange(message: IStockMessage): void
    repairOrderID: number
}

function StockMessageSelect({
    disabled = false,
    onChange,
    repairOrderID,
}: IStockMessageSelect) {
    const [stockMessages, setStockMessages] = useState<IStockMessage[]>([])
    const [repairOrder, setRepairOrder] = useState<RepairOrder | null>(null)

    const advisorFirstName = useAppSelector(state => state.user.FirstName)
    const dealerName = useAppSelector(state => state.dealer.Name)

    const handleChange = (id: unknown) => {
        const message: IStockMessage | undefined = stockMessages.find(message => message.id === id)
        if (message === undefined) throw new Error(`Could not find stock message with id of ${id}`)
        if (repairOrder === null) throw new Error(`RepairOrder with id of ${repairOrderID} is null`)
        const customer = customerSelectors.selectById(store.getState(), repairOrder.CustomerID)

        message.description = replaceAll(message.description, MessageVars.ADVISOR_NAME, titleCase(advisorFirstName || ``))
        message.description = replaceAll(message.description, MessageVars.VEHICLE_NAME, titleCase(repairOrder?.VehicleDescription || ``))
        message.description = replaceAll(message.description, MessageVars.DEALER_NAME, dealerName)

        const customerFirstName = customer !== undefined ? 
            (customer.FirstName !== null) ? 
                customer.FirstName :
                    `` : // case if customer first name is null
                    ``   // case if customer is undefined

        message.description = replaceAll(message.description, MessageVars.CUSTOMER_NAME, titleCase(customerFirstName))

        onChange(message)
    }

    useEffect(() => {

        getRepairOrderByID(repairOrderID).then(resp => setRepairOrder(resp))

        getStockMessages().then(res => setStockMessages(res.map(mapToStockMessage)))

    }, [repairOrderID])

    const stockMessageLineItems = stockMessages.map(message => (
        <MenuItem
            key={message.id}
            value={message.id}
            dense
        >{message.name}</MenuItem>
    ))

    return (
        <Select
            className={styles.stockMessageContainer}
            onChange={(e) => handleChange(e.target.value)}
            value={`placeholder`}
            input={<OutlinedInput margin={`dense`} />}
            inputProps={{
                size: `small`,
            }}
            disabled={disabled}
            variant={`outlined`}
        >
            <MenuItem
                disabled
                key={`placeholder`}
                value={`placeholder`}
                dense
            >Start with a stock message</MenuItem>
            {stockMessageLineItems}
        </Select>
    )
}

export default StockMessageSelect