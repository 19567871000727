import React from 'react'
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import PasswordIcon from '@mui/icons-material/Password';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { ILoginFormProps } from 'components/Login';

interface IPasswordFieldProps {
    register: UseFormRegister<ILoginFormProps>
    error: FieldError | undefined
}

function PasswordField({
    register,
    error
}: IPasswordFieldProps) {

    return (
        <FormControl>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
                {...register(`password`, { required: true })}
                size={`small`}
                error={!!error}
                startAdornment={
                    <InputAdornment position="start">
                        <PasswordIcon/>
                    </InputAdornment>
                }
                label={`Password`}
                type="password"
                placeholder='Password'
            ></OutlinedInput>
        </FormControl>
    )
}

export default PasswordField