import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import { dismissAlert } from './module'
import getRepairOrderByID from 'api/getRepairOrderByID'
import { customerMessagesSelectors } from 'modules/customerMessages'
import DialogConversationContent from 'components/DialogConversationContent/index'
import RepairOrderAlertAddEditDialog from 'components/RepairOrderAlertAddEditDialog'
import { closed, open, enterUpdateMode } from 'components/RepairOrderAlertAddEditDialog/modules'
import { Modes } from 'components/RepairOrderAlertAddEditDialog/Modes'

function RepairOrderAlertDialog() {
    const dispatch = useAppDispatch()
    const [snoozeIsOpen, setSnoozeIsOpen] = useState<boolean>(false)
    const [customerID, setCustomerID] = useState<number>(-1)
    const [repairOrderID, setRepairOrderID] = useState<number>(-1)
    const [repairOrderAlertID, setRepairOrderAlertID] = useState<number | null>(null)
    const messages = useAppSelector(customerMessagesSelectors.selectAll)

    const {
        alert,
        isOpen: roAlertDialogIsOpen,
    } = useAppSelector(state => state.roAlertDialog)

    const {
        isOpen: unreadConvoDialogIsOpen
    } = useAppSelector(state => state.customersWithUnreadMessages)

    const time = alert?.time || new Date()
    const text = alert?.text || ``

    const timeFormatted = new Date(time).toLocaleTimeString([], {
        timeStyle: 'short'
    })

    const handleDismissClick = () => {

        dispatch(dismissAlert())

    }

    const handleSnoozeClick = () => {

        dispatch(open(repairOrderID))

        dispatch(enterUpdateMode({
            alert: alert,
            mode: Modes.SNOOZE
        }))

        setSnoozeIsOpen(true)

    }

    const handleSnoozeClose = () => {
        
        setSnoozeIsOpen(false)
        
        dispatch(closed())

    }

    const getRepairOrder = useCallback(async (repairOrderID: number) => {

        return await getRepairOrderByID(repairOrderID)

    }, [])

    useEffect(() => {
        
        if(roAlertDialogIsOpen && !unreadConvoDialogIsOpen){

            const repairOrderID = alert?.repairOrderID
            const roAlertID = alert?.id

            if(roAlertID !== undefined)
                setRepairOrderAlertID(roAlertID)

            if(repairOrderID !== undefined) {

                getRepairOrder(repairOrderID)
                .then((resp) => {
                    setCustomerID(resp.CustomerID)
                    setRepairOrderID(repairOrderID)
                })

            }
        }
        
    }, [dispatch, roAlertDialogIsOpen, alert, getRepairOrder, unreadConvoDialogIsOpen])

    return (
        <>
            <Dialog
                open={roAlertDialogIsOpen && !unreadConvoDialogIsOpen}
                fullWidth
            >
                <DialogTitle>Alert Reminder - {timeFormatted}</DialogTitle>
                <DialogConversationContent
                    contentText={text}
                    repairOrderId={repairOrderID}
                    customerId={customerID}
                    messages={messages}
                    repairOrderAlertID={repairOrderAlertID}
                ></DialogConversationContent>
                <DialogActions>
                    <Button
                        onClick={handleSnoozeClick}
                        color={`primary`}
                    >Snooze</Button>
                    <Button
                        onClick={handleDismissClick}
                        color={`primary`}
                    >Dismiss</Button>
                </DialogActions>
            </Dialog>

            {snoozeIsOpen && <RepairOrderAlertAddEditDialog
                repairOrderID={repairOrderID}
                onClose={handleSnoozeClose}
            ></RepairOrderAlertAddEditDialog>}
        </>
    )
}

export default RepairOrderAlertDialog