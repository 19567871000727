import { Box } from "@mui/material"
import { WarrantyAlertLineItemResponse } from "api/types/reporting/WarrantyAlertReportingOverview"
import { TableHeader, TableRow } from "components/Table"
import { ColumnDefinition } from "components/Table/types"
import React from "react"
import formatPrice from "utils/formatPrice"

export interface IUserRepairOrderLineItemTableProps {
    lineItems: WarrantyAlertLineItemResponse[],
    expanded: boolean
}

export default function UserRepairOrderLineItemTable({
    lineItems,
    expanded
}: IUserRepairOrderLineItemTableProps) {
    const lineItemColumnDefinitions: ColumnDefinition[] = [
        {
            content: `Description`,
            flex: .5
        },
        {
            content: `OpCode`,
            flex: .25,
        },
        {
            content: `Price`,
            flex: .25,
            isNumber: true
        },
      ]

    return (
        <Box style={{display: !expanded ? 'none' : ''}}>
            <TableHeader columnDefinitions={lineItemColumnDefinitions} offset={3}/>
            {lineItems.map((li, index) => {
                const lineItemRowData = {
                    description: li.Description,
                    opCode: li.OpCode,
                    price: formatPrice(li.Price)
                }

                return (
                    <TableRow
                        key={`user-repair-order-line-item-row-${index}`}
                        columnDefinitions={lineItemColumnDefinitions}
                        rowData={lineItemRowData}
                        offset={4} />
                )
            })}
        </Box>
    )
}