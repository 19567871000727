import React from 'react'
import Chartist from 'chartist'
import PropTypes from 'prop-types'

import './styles.scss'

export class CalendarLineChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            options: {
                fullWidth: true,
                chartPadding: {
                    right: 20
                },
                lineSmooth: false,
                showPoint: false,
                axisY: {
                    showLabel: false
                },
                axisX: {
                    labelOffset: {
                        x: -12,
                        y: 5
                    },
                }
            },
            calendar: {}
        }
    }

    //initialize the calendar
    componentDidMount() {
        const calendar = new Chartist.Line('.calendarLineChart-chart', this.state.data, this.state.options)

        this.setState({
            calendar
        })
    }

    componentDidUpdate(prevProps, prevState) {
        //update state if data from props changes
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data
            })
        }

        //update the Chartist module if state changes
        if (prevState.data !== this.state.data) {
            this.state.calendar.update(this.state.data)
        }
    }
    
    
    render(){
        return (
            <div>
                <div className="calendarLineChart-chart"></div>
            </div>
        )
    }
}

CalendarLineChart.propTypes = {
    data: PropTypes.object
}

CalendarLineChart.defaultProps = {
    data: {
        labels: [],
        series: []
    }
}

export default CalendarLineChart