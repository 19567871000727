import React from 'react'
import styles from './styles.module.scss'

export interface INumberWithWord {
    number: number
    text: string
    color: string
}

const NumberWithWord = ({
    number,
    text,
    color
}: INumberWithWord) => {
    return (
        <div className={styles.container}>
            <div style={{color}} className={styles.number}
            
            >{number}</div>
            <div style={{color}} className={styles.text}
            
            >{text}</div>
        </div>
    )
}

export default NumberWithWord
