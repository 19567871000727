import { checkAuth } from 'modules/login'
import React from 'react'
import { useAppDispatch } from 'store'

function useInitialization() {
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        dispatch(checkAuth())
    }, [dispatch])
}

export default useInitialization