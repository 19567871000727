import getChatConversation from 'api/getChatConversation'
import { mapToChatConversation } from 'api/types/chatConversation'
import { errored } from 'components/ChatModule/modules'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { IChatConversation } from 'types/chatConversation'
import { Signal } from 'types/signalr'

function useSubscribeToChatConversation(id: number): IChatConversation | undefined {
    const [conversation, setConversation] = useState<IChatConversation | undefined>()
    const dispatch = useAppDispatch()

    const fetchConversation = useCallback(async () => {
        if (id) {
            try {
                const res = await getChatConversation(id)
                setConversation(mapToChatConversation(res))
            } catch (error) {
                dispatch(errored())
            }
        }
    }, [id, dispatch])

    useEffect(() => {
        fetchConversation()
    }, [fetchConversation])

    useSubscribeToSignal(Signal.CHAT_MESSAGE_CREATED, fetchConversation)

    return conversation

}

export default useSubscribeToChatConversation