import React, { useCallback, useState } from 'react'
import { Box, InputAdornment, TextField } from '@mui/material'
import DateRangeDialog from '../DateRangeDialog'
import { Today as CalendarIcon } from '@mui/icons-material'
import moment from 'moment'
import { US_DATE_FORMAT } from 'constants/date.constants'
import { ISetDates } from 'types/ISetDates'

export interface IDateSelectorProps {
    startDate: string,
    endDate: string,
    isLoading: boolean
    onDateRangeApplied(setDatesPayload: ISetDates): void
}

function DateSelector({
    startDate,
    endDate,
    isLoading = false,
    onDateRangeApplied
}: IDateSelectorProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const toggle = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen])

    const formatDate = (date: string) : string => moment(date, US_DATE_FORMAT).format(US_DATE_FORMAT) 

    return (
        <Box
            width={`275px`}
        >

            <TextField
                size='small'
                fullWidth={true}
                disabled={isLoading}
                onClick={toggle}
                id="outlined-read-only-input"
                InputProps={{
                    readOnly: true,
                    startAdornment:(
                        <InputAdornment position="start">
                            <CalendarIcon style={{color: `#aaaaaa`}}/>
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                value={`${formatDate(startDate)} to ${formatDate(endDate)}`}/>  
            
            { isOpen && <DateRangeDialog
                onClose={toggle}
                onDateRangeApplied={onDateRangeApplied}
                startDate={startDate}
                endDate={endDate}
            ></DateRangeDialog>}
        </Box>
    )
}

export default DateSelector