import React from 'react'
import {
    DialogContent,
    CircularProgress,
} from '@mui/material'

import styles from './styles.module.scss'

function LoadingContent() {
    return (
        <DialogContent className={styles.container}>
            <CircularProgress></CircularProgress>
        </DialogContent>
    )
}

export default LoadingContent