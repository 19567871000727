import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { Status } from 'types/signalr'

export enum Modes {
    INITIALIZING,
    LOGIN,
    DEFAULT,
}

interface IApp {
    mode: Modes
    isOpenInToolbar: boolean
    isToolbarSvsEnabled: boolean
    signalrStatus: Status
}

let initialState: IApp = {
    mode: Modes.INITIALIZING,
    isOpenInToolbar: false,
    isToolbarSvsEnabled: false,
    signalrStatus: Status.DISCONNECTED
}

const slice = createSlice({
    name: `app`,
    initialState,
    reducers: {
        modeSet(state, action: PayloadAction<Modes>) {
            state.mode = action.payload
        },
        setIsOpenInToolbar(state, action: PayloadAction<boolean>) {
            state.isOpenInToolbar = action.payload
        },
        setIsToolbarSvsEnabled(state, action: PayloadAction<boolean>) {
            state.isToolbarSvsEnabled = action.payload
        },
        setSignalrStatus(state, action: PayloadAction<Status>) {
            state.signalrStatus = action.payload
        },
    },
})

export const {
    modeSet,
    setIsOpenInToolbar,
    setIsToolbarSvsEnabled,
    setSignalrStatus,
} = slice.actions

export default slice.reducer