import { RepairOrder } from 'api/types/repairOrder';

export enum Color {
    NONE,
    RED,
    PURPLE,
    BLUE,
    GREEN,
    YELLOW,
    ORANGE,
    PINK,
    TEAL,
}

export enum StatusTranslation {
    OPEN = `Open`,
    CLOSED = `Closed`
}

export interface IListRepairOrder extends RepairOrder {
    customerName: string
    advisorName: string
    isPinned: boolean
    rowColor: Color
    statusTranslation: StatusTranslation
}