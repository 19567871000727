import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import Tab from 'components/Tab'

const TabBar = ({
    tabsArray,
    activeTabIndex,
    height,
    width,
    onClick
}) => {
    const tabs = tabsArray.map((obj, index) => 
        <Tab
            key={index}
            index={index}
            text={obj.text}
            height={height - 2}
            active={index === activeTabIndex}
            onClick={() => onClick(index)}
            isClickable
        ></Tab>
    )

    return (
        <div 
            style={{height, width}} 
            className={styles.container}
        >
            {tabs}
        </div>
    )
}

TabBar.propTypes = {
    tabsArray: PropTypes.array,
    activeTabIndex: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    onClick: PropTypes.func
}

TabBar.defaultProps = {
    tabsArray: [],
    height: 32
}

export default TabBar
