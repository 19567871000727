import React from 'react'
import moment from 'moment'
import { Box } from '@mui/material'

export interface IDateTimeCell {
    timestamp: string
}

function DateTimeCell({
    timestamp,
}: IDateTimeCell) {
    const formattedValue = moment(timestamp.slice(0, -1)).format(`M/D h:mma`)

    return (
        <Box
            whiteSpace={`nowrap`}
        >
            {formattedValue}
        </Box>
    )
}

export default React.memo(DateTimeCell)