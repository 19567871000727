import React from 'react'
import { Typography, TypographyProps } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'


export interface ITextLoader extends TypographyProps {
    isLoading: boolean
    children: React.ReactNode
}

function TextLoader({
    isLoading,
    children,
    ...props
}: ITextLoader) {
    return (
        <Typography
            {...props}
        >{isLoading ? <Skeleton/> : children}</Typography>
    )
}

export default React.memo(TextLoader)