import { Hooks } from 'react-table'


export interface UseScrollResetState {
    scrollResetCount: number
}

export default function useScrollReset(hooks: Hooks) {
    hooks.stateReducers.push(reducer)
}

function reducer(state, action) {
    if (action.type === `init`) {
        return {
            scrollResetCount: 0,
            ...state
        }
    }

    if (action.type === `gotoPage`) {
        return {
            ...state,
            scrollResetCount: state.scrollResetCount + 1
        }
    }
}