import React, { useEffect, useRef } from 'react'
import { CircularProgress, Fade, FadeProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import styles from './styles.module.scss'

export interface IOverlay {
    text?: string,
    show: boolean,
    isLoading: boolean,
    isError: boolean,
    timeout?: FadeProps['timeout'],
}

Overlay.defaultProps = {
    timeout: 500
}

const useStyles = makeStyles({
    root: {
        fontSize: `60px`,
        color: `white`,
    }
})

function Overlay({
    text,
    show,
    isLoading,
    isError,
    timeout,
}: IOverlay) {
    const mStyles = useStyles()
    const timeoutRef = useRef<FadeProps['timeout']>(0)
    let symbol

    if (isLoading) {
        symbol = <CircularProgress></CircularProgress>
    }

    if (isError) {
        symbol = <ErrorOutlineIcon
            classes={{
                root: mStyles.root
            }}
        ></ErrorOutlineIcon>
    }
    
    useEffect(() => {
        if (timeoutRef.current !== timeout) {
            timeoutRef.current = timeout
        }
    })

    return (
        <Fade in={show} timeout={timeoutRef.current}>
            <div className={styles.container}>
                {symbol}
                {text &&
                    <div className={styles.text}>{text}</div>
                }
            </div>
        </Fade>
    )
}

export default Overlay