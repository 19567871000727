import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import Button from 'components/Button'
import CheckBoxScale from 'components/CheckBoxScale'
import InspectionStatusCheckboxGroup from 'components/InspectionStatusCheckboxGroup'

const InspectionLine = ({
    id,
    label,
    onChange,
    onInputChange,
    showButton,
    showInput,
    showScale
}) => {  
    return (
        <div className={styles.container}>
            <InspectionStatusCheckboxGroup
                id={id}
                onChange={onChange}
            ></InspectionStatusCheckboxGroup>
            <div className={styles.label}>{label}</div>
            {showButton &&
                <Button 
                    text={`Yes`}
                    type={`plain`}
                    className={styles.button}
                ></Button>
            }
            {showInput &&
                <Fragment>
                    <input type="text" className={styles.input} onChange={onInputChange}/>
                    {showScale &&
                        <CheckBoxScale
                        ></CheckBoxScale>
                    }
                    <Button 
                        text={`Clear`}
                        type={`plain`}
                        className={styles.buttonClear}
                    >Clear</Button>
                </Fragment>
            }
        </div>
    )
}

InspectionLine.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    showButton: PropTypes.bool,
    showInput: PropTypes.bool,
    showScale: PropTypes.bool
}

export default InspectionLine