import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import timeFromMinutes from "utils/timeFromMinutes";
import timeInMinutes from "utils/timeInMinutes";

interface ITimeInMinutesProps {
  label: string;
  name: string;
  changeHandler: (e) => void;
  minutes?: number;
  error?: boolean | false;
  errorMessage?: string | ``;
}

const TimeInMinutesPicker = (props: ITimeInMinutesProps) => {
  var initialValue = timeFromMinutes(props.minutes);

  const [state, setState] = useState<{ hours; minutes }>(
    initialValue ?? { hours: "", minutes: "" }
  );

  const onHandleChange = (e) => {
    let value;

    switch (e.target.name) {
      case "hours":
        if (e.target.value >= 0 && e.target.value <= 24) {
          value = e.target.value;
        }
        break;
      case "minutes":
        if (e.target.value >= 0 && e.target.value <= 59) {
          value = e.target.value;
        }
        break;
      default:
        break;
    }

    const currentState = {
      ...state,
      [e.target.name]: value,
    };

    setState({ ...currentState } as { hours; minutes });

    let minutes =
      currentState.hours === "" && currentState.minutes === ""
        ? undefined
        : timeInMinutes(currentState?.hours, currentState?.minutes);

    props.changeHandler({ target: { name: props.name, value: minutes } });
  };

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          border: props.error ? "solid" : "",
          borderColor: "red",
          p: props.error ? 1 : 0,
          borderWidth: "2px",
        }}
      >
        <Typography
          fontWeight={"bold"}
          sx={{ color: props.error ? "red" : "" }}
        >
          {props.label}
        </Typography>
        <Stack direction={"row"} spacing={1} width={"100%"}>
          <NumberFormat
            mask={``}
            fullWidth
            size="small"
            format={"##"}
            InputProps={{
              name: "hours",
            }}
            variant="filled"
            name={props.name}
            autoComplete="off"
            error={props.error}
            label={"Hours"}
            sx={{ width: "50%" }}
            customInput={TextField}
            onChange={onHandleChange}
            value={state.hours ?? ``}
          />
          <NumberFormat
            mask={``}
            fullWidth
            size="small"
            format={"##"}
            InputProps={{
              name: "minutes",
            }}
            variant="filled"
            name={props.name}
            autoComplete="off"
            error={props.error}
            label={"Minutes"}
            sx={{ width: "50%" }}
            customInput={TextField}
            onChange={onHandleChange}
            value={state.minutes ?? ``}
          />
        </Stack>
        {props.error && (
          <Typography sx={{ color: "red", fontSize: "15px" }}>
            {props.errorMessage}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default TimeInMinutesPicker;
