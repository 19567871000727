import { createAsyncThunk } from '@reduxjs/toolkit'
import getCommunicationDashboardStats from 'api/getCommunicationDashboardStats'
import { IDashboardDataPayload } from 'components/CommunicationDashboard/modules'

interface IPayload {
    dealerID: number
    startDate: string
    endDate: string
}

const getDashboardData = createAsyncThunk(
    `communicationDashboard/getDashboardData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<IDashboardDataPayload> => {
        const res = await getCommunicationDashboardStats(dealerID, startDate, endDate)
        
        return {
            receivedCount: res.ReceivedCount,
            sentCount: res.SentCount,
            unansweredCount: res.UnansweredCount,
            automatedCount: res.AutomatedCount
        }
    }
)

export default getDashboardData