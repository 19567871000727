import apiFetch from 'api/utils/APIFetch'

type RepairOrderAlert = {
    id: number
    userID: number
    repairOrderID: number
    text: string
    time: string
    acknowledged: boolean
}

export function mapFromAPI(raw): RepairOrderAlert {
    return {
        id: raw.ID,
        userID: raw.UserID,
        repairOrderID: raw.RepairOrderID,
        text: raw.Reminder,
        time: raw.TargetTime,
        acknowledged: raw.Acknowledged,
    }
}

export async function getROAlerts(userID: number): Promise<RepairOrderAlert[]> {
    const res = await apiFetch(`/api/ro-alerts/user/${userID}/pending`)
    return res.map(alert => mapFromAPI(alert))
}

export async function createROAlert(alert: RepairOrderAlert) {
    await apiFetch(`/api/ro-alerts`, {
        method: `POST`,
        body: JSON.stringify({
            UserID: alert.userID,
            RepairOrderID: alert.repairOrderID,
            Reminder: alert.text,
            TargetTime: alert.time,
        }),
    })
}

export async function updateROAlert(alert: RepairOrderAlert) {
    await apiFetch(`/api/ro-alerts/${alert.id}`, {
        method: `PUT`,
        body: JSON.stringify({
            ID: alert.id,
            UserID: alert.userID,
            RepairOrderID: alert.repairOrderID,
            Reminder: alert.text,
            TargetTime: alert.time,
            Acknowledged: alert.acknowledged,
        }),
    })
}

export default RepairOrderAlert