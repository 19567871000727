import React from 'react'
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
} from 'react-router-dom'
import { MenuItem as MaterialMenuItem, MenuItemProps } from '@mui/material'

export interface IMenuItem {
    route: string
    children: React.ReactNode
}

const MenuItem = React.forwardRef<MenuItemProps, IMenuItem>(({
    route,
    children
}, ref) => {
    const renderLink = React.useMemo(
        () =>
          React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, linkRef) => (
            <RouterLink to={route} {...itemProps} />
          )),
        [route],
      )

    return (
        <MaterialMenuItem component={renderLink}>{children}</MaterialMenuItem>
    )
})

export default MenuItem