import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

type IEditDialog = {
    label: string
    value: string
    onSave(value: string): void
    onClose(): void
}

const checkValid = (value: string): boolean => {
    return value.length === 10
}

function EditDialog({
    label,
    value,
    onSave,
    onClose,
}: IEditDialog) {
    const [textFieldValue, setTextFieldValue] = React.useState<string>(value)
    const [isValid, setIsValid] = React.useState<boolean>(() =>  checkValid(value))

    const handleValueChange = (values: NumberFormatValues) => {
        const value = values.value

        setIsValid(checkValid(value))
        setTextFieldValue(value)
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        onClose()
        onSave(textFieldValue)
    }

    return (
        <Dialog 
            open
            fullWidth
            onClose={onClose}
        >
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <NumberFormat
                        format={`(###) ###-####`}
                        mask={`_`}
                        customInput={TextField}
                        label={label}
                        value={textFieldValue}
                        onValueChange={handleValueChange}
                        fullWidth
                        margin={`dense`}
                        autoFocus
                    ></NumberFormat>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color={`primary`}
                    >Cancel</Button>
                    <Button
                        type={`submit`}
                        color={`primary`}
                        disabled={!isValid}
                    >Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default React.memo(EditDialog)