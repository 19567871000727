
const storageName = `repairOrderFilters`

export interface IRepairOrderFilterState {
    displayOpen: boolean,
    displayClosed: boolean,
    displayAdvisor: boolean,
    displayActiveWarrantyAlerts: boolean,
    displaySubmittedMaintenanceSchedules: boolean
}

const initialState: IRepairOrderFilterState = {
    displayOpen: true,
    displayClosed: false,
    displayAdvisor: true,
    displayActiveWarrantyAlerts: false,
    displaySubmittedMaintenanceSchedules: false
}


export default class LocalFilterStorage {

    static readStorage(): IRepairOrderFilterState {

        const storage = localStorage.getItem(storageName)
    
        return storage ? JSON.parse(storage) : initialState
    
    }

    static saveToStorage(filters: IRepairOrderFilterState) {

        localStorage.setItem(storageName, JSON.stringify(filters))

    }
}