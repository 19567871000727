import React, { useMemo } from 'react'
import { withRouter } from 'react-router'
import {
    useAppSelector,
} from 'store'
import TableView from 'componentsUI/TableView'
import { Collapse } from '@mui/material'

import styles from './styles.module.scss'

export interface IResultsView {
    history: any
}

function ResultsView({
    history
}: IResultsView) {
    //const dispatch = useAppDispatch()
    const {
        isSearching,
        results
    } = useAppSelector(
        state => state.customerSearchView
    )

    // const gotoCustomerDetail = useCallback(
    //     (id: number) => {
    //         history.push(`/customer-detail/${id}`)
    //         dispatch(reset())
    //     },
    //     [history, dispatch],
    // )

    const columns = useMemo(
        () => [
            {
                Header: `First Name`,
                accessor: `FirstName`,
                width: 10,
            },
            {
                Header: `Last Name`,
                accessor: `LastName`,
                width: 10,
            },
            {
                Header: `Email`,
                accessor: `Email`,
                width: 20,
            },
            {
                Header: `Cell Phone`,
                accessor: `CellPhone`,
                width: 10,
            },
            {
                Header: `Home Phone`,
                accessor: `HomePhone`,
                width: 10,
            },
            {
                Header: `Work Phone`,
                accessor: `WorkPhone`,
                width: 10,
            }
            // {
            //     Header: `Vehicle Info`,
            //     accessor: `vehiclesStringified`,
            //     width: 40,
            //     Cell: ({ row }) => <VehicleInfoCell
            //         vehicles={row.original.vehicles}
            //     ></VehicleInfoCell>
            // },
            // {
            //     accessor: `id`,
            //     width: 1,
            //     disableFilters: true,
            //     disableSortBy: true,
            //     Cell: ({ value }) => <ViewCell
            //         id={value}
            //         onClick={gotoCustomerDetail}
            //     ></ViewCell>
            // },
        ],
    [])
    
    // useEffect(() => {
    //     if (results.length === 1) {
    //         if (results[0].ID !== null) {
    //             gotoCustomerDetail(results[0].ID)   
    //         }
    //     }
    // }, [results, gotoCustomerDetail])

    return (
        <Collapse
            in={!isSearching && results.length > 0} 
            classes={{
            root: styles.container
        }}>
            <TableView
                data={results}
                columns={columns}
                className={styles.table}
            ></TableView>
        </Collapse>
    );
}

export default withRouter(ResultsView)