import React, { useEffect, useRef } from 'react'
import Chartist, { IChartistPieChart, IPieChartOptions } from 'chartist'
import styles from './styles.module.scss'

export interface ISlice {
    amount: number
}

export interface IPieChart {
    slices: ISlice[]
    options?: IPieChartOptions
}

function PieChart({
    slices = [],
    options,
}: IPieChart) {
    const chartDomRef = useRef(null)
    const chart = useRef<IChartistPieChart>()

    useEffect(() => {
        const series = slices.map(slice => slice.amount)

        // add a blank placeholder if there is no data
        if (series.every(i => i === 0)) {
            series.splice(0, series.length, ...[0, 0, 0, 1])
        }

        if (chart.current !== undefined) {
            chart.current.update({
                series,
            },
            options)
        } else {
            chart.current = new Chartist.Pie(chartDomRef.current, {series}, options)
        }

    }, [slices, options])
    
    return (
        <div className={styles.container}>
            <div ref={chartDomRef} className={styles.chart}></div>
        </div>
    )
}

export default PieChart