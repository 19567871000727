import React from 'react'
import { useAppDispatch } from 'store'
import CallGroup from 'classes/CallGroup'
import { updateCreateCallGroup } from 'modules/bdcListView'

import styles from './styles.module.scss'

export interface INotesCell {
    text: string,
    callGroup: CallGroup
}

function NotesCell({
    text,
    callGroup
}: INotesCell) {
    const dispatch = useAppDispatch()

    function handleBlur(e) {
        const val = e.target.value

        //prevent uneccessary update
        if (val === text) return  

        const newCallGroup = new CallGroup(callGroup)
        newCallGroup.notes = val
        
        dispatch(updateCreateCallGroup(newCallGroup))
    }

    return (
        <textarea
            className={styles.input}
            defaultValue={text}
            onBlur={handleBlur}
        ></textarea>
    )
}

export default NotesCell