import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import {
    getRepairOrders, messagesUpserted, messageUpserted, reorder, repairOrdersSelectors, reset, updateMpviStatus, videoUpserted,
} from 'components/RepairOrderListView/modules'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { Signal } from 'types/signalr'
import { Video } from 'api/types/video'
import { RepairOrder } from 'api/types/repairOrder'
import { Message } from 'api/types/message'
import _ from 'lodash'
import { IListRepairOrder } from 'components/RepairOrderListView/types/ListRepairOrder'
import { Update } from '@reduxjs/toolkit'
import { getRepairOrderAlerts } from 'modules/roAlerts'

function useSubscribeToRepairOrders() {
    const dispatch = useAppDispatch()
    const {
        startDate,
        endDate,
    } = useAppSelector(
        state => state.dashboard
    )

    const {
        pinStateChanged
    } = useAppSelector(state => state.repairOrderListView)

    const {
        ID: userID
    } = useAppSelector(state => state.user)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const ids = useAppSelector(repairOrdersSelectors.selectIds)
    const repairOrders = useAppSelector(repairOrdersSelectors.selectAll)

    useEffect(() => {
        dispatch(getRepairOrders(startDate, endDate, dealerID))

        return () => {
            dispatch(reset())
        }
    }, [startDate, endDate, dispatch, dealerID])

    useEffect(() => {
        if(pinStateChanged){
            dispatch(reorder())
        }
    }, [dispatch, pinStateChanged])

    const addVideo = useCallback((video: Video) => dispatch(videoUpserted(video)),[dispatch])
    useSubscribeToSignal(Signal.VIDEO_CREATED, addVideo)
    useSubscribeToSignal(Signal.VIDEO_UPDATED, addVideo)

    const handleEstimateNotification = useCallback((updatedRO: RepairOrder) => {
        if (ids.includes(updatedRO.ID)) {
            const ro = repairOrders.find(ro => ro.ID === updatedRO.ID)

            if (
                ro !== undefined &&
                ro.MPVIStatus !== updatedRO.MPVIStatus
            ) {
                dispatch(updateMpviStatus({
                    id: updatedRO.ID,
                    mpviStatus: updatedRO.MPVIStatus,
                    attentionNeeded: updatedRO.AttentionNeeded,
                }))
            }
        }
    }, [dispatch, repairOrders, ids])
    useSubscribeToSignal(Signal.REPAIR_ORDER_MPVI_STATUS_UPDATED, handleEstimateNotification)

    const addMessage = useCallback((message: Message) => {

        if(ids.includes(message.RepairOrderID || -1)){

            const ro = repairOrders.find(ro => ro.ID === message.RepairOrderID)

            if(ro !== undefined){

                dispatch(messageUpserted({
                    repairOrder: ro,
                    message: message
                }))

            }
        }

    }, [dispatch, repairOrders, ids])
    useSubscribeToSignal(Signal.MESSAGE_CREATED, addMessage)
    useSubscribeToSignal(Signal.MESSAGE_RECEIVED, addMessage)

    const updateMessages = useCallback((messages: Message[]) => {
        const updates: Update<IListRepairOrder>[] = []

        const groupedMessagesByRepairOrder = _.groupBy(messages, (m: Message) => m.RepairOrderID)

        const parseRepairOrderID = (id: string) => parseInt(id)

        for(const key in groupedMessagesByRepairOrder) {

            const repairOrderID = parseRepairOrderID(key)

            const repairOrder = repairOrders.find(ro => ro.ID === repairOrderID)

            if(repairOrder !== undefined) {

                const updatedMessages: Message[] = []
                const currentMessages: Message[] = Object.assign([], repairOrder.Messages) || []

                currentMessages.forEach(message => updatedMessages.push({...message, HasAdvisorViewed: true}))

                updates.push({
                    id: repairOrderID,
                    changes: {
                        Messages: [...updatedMessages]
                    }
                })

            }

        }

        if(updates.length > 0) {

            dispatch(messagesUpserted(updates))

        }
    }, [dispatch, repairOrders])

    useSubscribeToSignal(Signal.MESSAGES_UPDATED, updateMessages)

    const handleSyncSignal = useCallback(() => {

        dispatch(getRepairOrders(startDate, endDate, dealerID))

        dispatch(getRepairOrderAlerts(userID))

    },[startDate, endDate, dispatch, userID, dealerID])

    useSubscribeToSignal(Signal.REPAIR_ORDER_SYNC_COMPLETED, handleSyncSignal)
    useSubscribeToSignal(Signal.REPAIR_ORDER_UPDATED, handleSyncSignal)
}

export default useSubscribeToRepairOrders