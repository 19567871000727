import { createEntityAdapter, createSlice, current, EntityState, PayloadAction } from "@reduxjs/toolkit";
import getCustomersWithUnreadMessages from "api/getCustomersWithUnreadMessages";
import { Customer } from "api/types/customer";
import { RootState } from "rootReducer";
import { AppThunk } from "store";

const customersWithUnreadMessagesAdapter = createEntityAdapter<Customer>({
    selectId: (customer) => customer.ID
})

interface ICustomersWithUnreadMessages {
    isLoading: boolean,
    isError: boolean,
    infoText: string,
    isOpen: boolean,
    customers: EntityState<Customer>
}

let initialState: ICustomersWithUnreadMessages = {
    isLoading: false,
    isError: false,
    infoText: `Initializing...`,
    isOpen: false,
    customers: customersWithUnreadMessagesAdapter.getInitialState()
}

const slice = createSlice({
    name: `customersWithUnreadMessages`,
    initialState: initialState,
    reducers: {
        getDataStart(state) {
            state.isLoading = true
            state.isError = false
            state.infoText = [...current(state.customers.ids)].length === 0 ? `Retrieving unread messages...` : `Refreshing Data...`
        },
        getDataSuccess(state, action: PayloadAction<Customer[]>){

            customersWithUnreadMessagesAdapter.removeAll(state.customers)

            customersWithUnreadMessagesAdapter.addMany(state.customers, action.payload)

            state.isLoading = false
            state.isError = false
            state.infoText = ``
        },
        getDataFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
            customersWithUnreadMessagesAdapter.removeAll(state.customers)
        },
        setDialogOpen(state) {
            state.isOpen = true
        },
        setDialogClosed(state) {
            state.isOpen = false
        }
    }
})

export const {
    getDataStart,
    getDataSuccess,
    getDataFailure,
    setDialogOpen,
    setDialogClosed
} = slice.actions

export const customersWithUnreadMessagesSelectors = customersWithUnreadMessagesAdapter.getSelectors<RootState>(state => state.customersWithUnreadMessages.customers)

export default slice.reducer

export const getCustomerData = (dealerID: number): AppThunk => async dispatch => {
    
    try {
        
        dispatch(getDataStart())

        const customers = await getCustomersWithUnreadMessages(dealerID)

        dispatch(getDataSuccess(customers))

    } catch(error){
    
        dispatch(getDataFailure(`There was an error unread customer messages: ${error}`))
    
    }

}