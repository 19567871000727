import { Fab, List, ListItem, ListItemText, Paper, Popper } from '@mui/material'
import React from 'react'
import { proxyMock } from 'signalr'
import SignalWifi3BarLockIcon from '@mui/icons-material/SignalWifi3BarLock'
import { Signal } from 'types/signalr'

function SignalrMockTool() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const open = Boolean(anchorEl)

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_SIGNALR) {
        const signals: Signal[] = []
        proxyMock.events.forEach(entry => {
            if (!signals.includes(entry.signal)) {
                signals.push(entry.signal)
            }
        })
        const listItems = signals.map(signal => (
            <ListItem
                key={signal}
                dense
                button
                onClick={() => {
                    proxyMock.invoke(signal)
                    setAnchorEl(null)
                }}
            >
                <ListItemText>{signal}</ListItemText>
            </ListItem>
        ))

        return (
            <>
                <Fab
                    onClick={handleButtonClick}
                    size={`small`}
                    style={{
                        position: 'fixed',
                        bottom: '10px',
                        left: '60px',
                        zIndex: 99998,

                    }}
                >
                    <SignalWifi3BarLockIcon />
                </Fab>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={'right-end'}
                    style={{
                        zIndex: 99998,
                        marginLeft: '10px',
                    }}
                >
                    <Paper>
                        <List dense>{listItems}</List>
                    </Paper>
                </Popper>
            </>
        )
    }

    return null

}

export default SignalrMockTool