import { createAsyncThunk } from '@reduxjs/toolkit'
import { ISetSchedulingDateRange } from '../interfaces/ISetSchedulingDateRange'

const setDates = createAsyncThunk(
    `scheduling/setDates`,
    async ({
        startDate, 
        endDate,
    }: ISetSchedulingDateRange, _) => {

        return {
            startDate,
            endDate,
        }
    }
)

export default setDates