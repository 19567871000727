import React from 'react'
import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material'

// import styles from './styles.module.scss'

export interface IErrorContent {
    onClose(): void
}

function ErrorContent({
    onClose
}: IErrorContent) {
    return (
        <>
            <DialogContent>
                <DialogContentText>An error has occured! Please contact support if the issue persists.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color={`primary`}
                >Close</Button>
            </DialogActions>
        </>
    )
}

export default ErrorContent