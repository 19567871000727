import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import postDealerSchedule from "api/postDealerSchedule";
import { useAppSelector } from "store";

const DealerScheduleSection = () => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [closeTime, setCloseTime] = useState<Date | null>(null);
  const [selectedCapacity, setSelectedCapacity] = useState<number>(0);
  const { dealer, dealerSchedule } = useAppSelector(
    (state) => state.dealerForm
  );
  const capacity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    if (dealerSchedule && dealerSchedule.Capacity !== undefined && dealerSchedule.Capacity !== 0) {
      setSelectedCapacity(dealerSchedule.Capacity);
      setStartTime(reformatTime(dealerSchedule.StartTime));
      setCloseTime(reformatTime(dealerSchedule.CloseTime));
    }
  }, [dealerSchedule]);

  const formatTime = (date: Date | null) => {
    if (!date) return "";
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return formattedTime;
  };
  const reformatTime = (date: string | "") => {
    const now = new Date();
    const [hours, minutes, seconds] = date.split(":").map(Number);
    const dateWithTime = new Date(now.setHours(hours, minutes, seconds, 0));
    return dateWithTime;
  };

  const handleSubmit = () => {
    const openingTime = formatTime(startTime);
    const closingTime = formatTime(closeTime);
    const dealerId = dealer.ID;
    const capacity = selectedCapacity;

    postDealerSchedule({
      DealerId: dealerId,
      Capacity: capacity,
      StartTime: openingTime,
      CloseTime: closingTime,
    })
      .then(() => {
        console.log("Dealer Schedule Created");
      })
      .catch((error) => {
        console.error("Error adding Dealer Appointment Schedule: ", error);
      });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="start-time"></InputLabel>
            <TimePicker
              value={startTime}
              onChange={(newTime) => setStartTime(newTime)}
              renderInput={(params) => (
                <TextField {...params} label="Start Time" fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="end-time"></InputLabel>
            <TimePicker
              value={closeTime}
              onChange={(newTime) => setCloseTime(newTime)}
              renderInput={(params) => (
                <TextField {...params} label="End Time" fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="capacity-label">Capacity</InputLabel>
            <Select
              id="capacity-select"
              labelId="capacity-label"
              value={selectedCapacity}
              onChange={(e) => setSelectedCapacity(Number(e.target.value))}
              displayEmpty
            >
              <MenuItem value="" disabled></MenuItem>
              {capacity.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select the number of vehicles every 15 minutes
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DealerScheduleSection;
