import { createAsyncThunk } from '@reduxjs/toolkit'
import getDashboardData from 'components/CommunicationDashboard/thunks/getDashboardData'
import { ISetDates } from 'types/ISetDates'

const setDates = createAsyncThunk(
    `dashboard/setDates`,
    async ({
        dealerID,
        startDate, 
        endDate,
    }: ISetDates, { dispatch }) => {
        await dispatch(getDashboardData({dealerID, startDate, endDate}))

        return {
            startDate,
            endDate,
        }
    }
)

export default setDates