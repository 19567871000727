import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import styles from './styles.module.scss'

export interface ISummerySectionContainerProps {
    children: ReactNode
}

export default function SummerySectionContainer({
    children
}: ISummerySectionContainerProps){
    return (
        <Box className={styles.summerySection}>
            {children}
        </Box>
    )
}