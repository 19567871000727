import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { resetMaintenanceScheduleDialog } from "modules/maintenanceScheduleDialog"
import React from "react"
import { useAppDispatch, useAppSelector } from "store"
import styles from './styles.module.scss'

function MaintenanceScheduleDialog() {
    const dispatch = useAppDispatch()

    const {
        isOpen,
        maintenanceScheduleId
    } = useAppSelector(state => state.maintenanceScheduleDialog)

    const handleClose = () => {
        dispatch(resetMaintenanceScheduleDialog())
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth>
                <DialogTitle>{`Maintenance Schedule`}</DialogTitle>
            <DialogContent dividers>
                <iframe className={styles.iframe} src={`${process.env.REACT_APP_MAINT_URL}/${maintenanceScheduleId}`} title={`mpvi-iframe`}></iframe>
            </DialogContent>
            <DialogActions>
                <Button 
                    autoFocus 
                    onClick={handleClose}
                    color={`primary`}
                >Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MaintenanceScheduleDialog