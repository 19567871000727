import React, { useState } from 'react'
import { Box, CircularProgress, Divider, IconButton, TextField, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store'
import theme from 'assets/theme'
import { errored } from 'components/ChatModule/modules'
import { Send } from '@mui/icons-material'
import MessageBox from '../MessageBox'
import createChatMessage from 'api/createChatMessage'
import useSubscribeToChatConversation from 'components/ChatModule/hooks/useSubscribeToChatConversation'

function ConversationPanel() {
    const dispatch = useAppDispatch()
    const [value, setValue] = useState<string>(``)

    const {
        selectedID,
    } = useAppSelector(state => state.chat)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const currentUserID = useAppSelector(state => state.user.ID) || 0

    const conversation = useSubscribeToChatConversation(selectedID)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setValue(value)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault()
        if (value !== ``) {
            try {
                await createChatMessage(dealerID, selectedID, currentUserID, value)
                setValue(``)
            } catch (error) {
                dispatch(errored())
                throw new Error(`An error occured whil attempting to send new message: ${error}`)
            }
        }
    }

    if (conversation === undefined) {
        return (
            <Box
                display={`grid`}
                justifyItems={`center`}
                alignItems={`center`}
            >
                <CircularProgress />
            </Box>
        )
    }

    const usernames = conversation.users.filter(user => user.id !== currentUserID).map(user => user.email)

    const messages = conversation.messages.map(message => (
        <MessageBox
            key={message.id}
            text={message.text}
            time={message.ageText}
            alignRight={currentUserID === message.userID}
        ></MessageBox>
    ))

    return (
        <Box
            display={`grid`}
            gridTemplateRows={`min-content 1fr min-content min-content`}
            overflow={`hidden`}
        >
            <Box
                bgcolor={theme.palette.grey[200]}
                p={1}
                minHeight={`100%`}
            >
                <Typography>{conversation.repairOrderNumber}</Typography>
                <Typography variant={`body2`}>{usernames.join(` ,`)}</Typography>
            </Box>
            <Box
                display={`flex`}
                flexDirection={`column-reverse`}
                overflow={` hidden auto`}
                p={2}
            >
                <Box
                    display={`grid`}
                    rowGap={theme.spacing(1)}
                >{messages}</Box>
            </Box>
            <Divider />
            <Box
                p={1}
                display={`grid`}
                gridTemplateColumns={`1fr min-content`}
                alignItems={`center`}
                columnGap={theme.spacing(1)}
                component={`form`}
                onSubmit={handleSubmit}
            >
                <TextField
                    size={`small`}
                    variant={`outlined`}
                    margin={`none`}
                    onChange={handleChange}
                    value={value}
                ></TextField>
                <IconButton
                    size={`small`}
                    color={`primary`}
                    type={`submit`}
                >
                    <Send />
                </IconButton>
            </Box>
        </Box>
    );
}

export default ConversationPanel