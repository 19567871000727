import React from 'react'
import { TabPanel, TabPanelProps } from '@mui/lab'

interface INavPanel extends TabPanelProps { }

function NavPanel({ ...props }: INavPanel) {
    return (
        <TabPanel 
            style={{
                padding: 0,
            }}
            {...props}
        ></TabPanel>

    )
}

export default React.memo(NavPanel)