import APIFetch from 'api/utils/APIFetch'
import BdcLineItem from 'classes/BdcLineItem'

function getBdcCategoryDataByDate(fromDate, toDate, type = 0) {
    const types = [
        `no-repair-orders/${fromDate}/${toDate}`,
        `appointments/${fromDate}/${toDate}`,
        `appointments/${fromDate}/${toDate}/no-show`,
        `no-repair-orders/${fromDate}/${toDate}/5500`
    ]
    const url = types[type]
    return APIFetch(`/api/bdc/customers/${url}`)
        .then(res => res.map(i => new BdcLineItem().mapFromAPI(i))
    )
}

export default getBdcCategoryDataByDate