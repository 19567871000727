import { Box, Tooltip } from "@mui/material"
import React from "react"

interface IHeaderIcon{
    tooltip: string,
    children: React.ReactNode
}

function HeaderIcon({
    tooltip,
    children
}: IHeaderIcon){

    return (
        <Tooltip
            title={tooltip}>
            <Box>{children}</Box>
        </Tooltip>
    )

}

export default HeaderIcon