import { Dealer } from "api/types/dealer";
import apiFetch from "api/utils/APIFetch";
import { IPaginatedResponse } from "types/paginatedResponse";

const getDealersBySearch = async (
  searchCriteria: string,
  pageNumber: number,
  pageSize: number
): Promise<IPaginatedResponse<Dealer>> =>
  await apiFetch(`/api/dealers/search?searchCriteria=${searchCriteria}&pageNumber=${pageNumber}&pageSize=${pageSize}`).catch((error) => {
    throw new Error(error.Message);
  });

export default getDealersBySearch;
