import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "api/types/customer";
import { AppThunk } from "store";
import CustomerSearchTypes from "enums/customerSearchTypes";
import getCustomerByPhone from "api/customers/getCustomerByPhone";
import getCustomerByEmail from "api/customers/getCustomerByEmail";
import getCustomerByVin from "api/customers/getCustomerByVin";
import getCustomerByLicense from "api/customers/getCustomerByLicense";
import getCustomerByModel from "api/customers/getCustomerByModel";
import { ICustomerSearchRequest } from "types/customer";

interface ICustomerSearchView {
  isSearching: boolean;
  isError: boolean;
  results: Customer[];
  helperText: string;
}

let initialState: ICustomerSearchView = {
  isSearching: false,
  isError: false,
  results: [],
  helperText: ``,
};

const slice = createSlice({
  name: `customerSearchView`,
  initialState,
  reducers: {
    searchStart(state) {
      state.isSearching = true;
      state.isError = false;
      state.helperText = initialState.helperText;
    },
    searchSuccess(state, action: PayloadAction<Customer[]>) {
      state.isSearching = false;
      state.isError = false;
      state.helperText = initialState.helperText;
      state.results = action.payload;
    },
    searchFailure(state, action: PayloadAction<string>) {
      state.isSearching = false;
      state.isError = true;
      state.helperText = action.payload;
      state.results = [];
    },
    reset: (state) => initialState,
  },
});

export const { searchStart, searchSuccess, searchFailure, reset } =
  slice.actions;

export default slice.reducer;

export interface ICustomerSearchPayload {
  searchBy: string;
  search: string;
}

export const searchForCustomer =
  (payload: ICustomerSearchPayload): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(searchStart());

      const { ID: dealerID } = getState().dealer;

      const getSearchService = (searchBy) => {
        switch (searchBy) {
          case CustomerSearchTypes.PHONE:
            return getCustomerByPhone;
          case CustomerSearchTypes.EMAIL:
            return getCustomerByEmail;
          case CustomerSearchTypes.VIN:
            return getCustomerByVin;
          case CustomerSearchTypes.LICENSE:
            return getCustomerByLicense;
          case CustomerSearchTypes.MODEL:
            return getCustomerByModel;
          default:
            return undefined;
        }
      };

      const searchService = getSearchService(payload.searchBy);

      const results = searchService
        ? await searchService({
            SearchTerm: payload.search,
            DealerID: dealerID,
          } as ICustomerSearchRequest)
        : [];

      dispatch(searchSuccess(results));
    } catch (err: any) {
      dispatch(searchFailure(err.Message));
    }
  };
