import React, { useMemo } from 'react'
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { first } from 'voca'
import theme from 'assets/theme'
import { useAppSelector } from 'store'
import { SortByTypes, unreadMessageCountSelectors } from 'components/ChatModule/modules'
import { IChatUser } from 'types/chatUser'
import { AvatarGroup } from '@mui/material';

interface Props {
    id: number
    users: IChatUser[]
    roNumber: string
    lastReadText: string
    selected?: boolean
    onClick(): void
}

function ConversationListItem({
    id,
    users,
    roNumber,
    lastReadText,
    selected = false,
    onClick,
}: Props) {
    const sortBy = useAppSelector(state => state.chat.sortBy)
    const {
        ID: userID,
    } = useAppSelector(state => state.user)
    const unreadMessageCount = useAppSelector(state => unreadMessageCountSelectors.selectById(state, id))?.count || 0


    const [
        primaryText,
        secondaryText,
        avatars,
    ] = useMemo(() => {
        const otherUsers = users.filter(user => user.id !== userID)
        const usernameText = otherUsers.map(user => user.email).join(` ,`)
        const numberText = roNumber
        const avatars = otherUsers.map(user => (
            <Avatar
                key={user.id}
                style={{
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                    fontSize: `12px`,
                }}
            >{first(user.email, 2).toUpperCase()}</Avatar>
        ))

        if (sortBy === SortByTypes.RO ) {
            return [
                numberText,
                usernameText,
            ]
        }

        return [
            usernameText,
            numberText,
            avatars,
        ]
    }, [sortBy, users, roNumber, userID])

    const unreadMessageText = () => {
        if (unreadMessageCount === 0) {
            return null
        }

        return (
            <>
                <span
                    style={{
                        fontWeight: 700,
                    }}
                >{unreadMessageCount} unread message{unreadMessageCount > 1 ? `s` : ``}</span><br />
            </>
        )
    }

    return (
        <ListItem 
            alignItems={`flex-start`}
            button
            onClick={onClick}
            selected={selected}
        >
            <ListItemAvatar
                style={{
                    minWidth: theme.spacing(4),
                }}
            >
                <AvatarGroup max={2}>{avatars}</AvatarGroup>
            </ListItemAvatar>
            <ListItemText
                primary={primaryText}
                secondary={<>
                    {secondaryText}
                    <Typography
                        variant={`caption`}
                        style={{
                            fontSize: `10px`,
                        }}
                        display={`block`}
                    >
                        {unreadMessageText()}
                        <span>{lastReadText}</span>
                    </Typography>
                </>}
            />
        </ListItem>
    )
}

export default ConversationListItem