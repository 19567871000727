export const REQUIRED_FIELD_MESSAGE = `This field is required.`

//ROAutoAlertTemplate
export const AFTER_CUTOFF_TIME_DESC = `This setting specifies what will happen if the timing of the alert occurs after the cutoff time.`
export const ACTIVE_ALERT_INTERVAL_DESC = `These are the hours between which repair order alerts can be automatically created.`
export const NO_VALID_FIRST_INTERVAL_MESSAGE = `First interval must be lower than Second interval.`
export const NO_VALID_SECOND_INTERVAL_MESSAGE = `Second interval must be higher than First interval.`
export const NO_VALID_START_TIME_MESSAGE = `Start time must be lower than Cutoff time.`
export const NO_VALID_CUTOFF_TIME_MESSAGE = `Start time must be lower than Cutoff time.`
export const NO_VALID_DATE_MESSAGE = `Invalid date`
export const NO_VALID_TIMEZONE_MESSAGE = `Invalid time zone.`