import React from 'react'
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    RadioProps,
    TextField
} from '@mui/material'
import theme from 'assets/theme'
import { useAppDispatch, useAppSelector } from 'store'
import { filterValueSet, sortBySet, SortByTypes } from 'components/ChatModule/modules'

function Header() {
    const dispatch = useAppDispatch()
    const {
        sortBy,
        filterValue,
    } = useAppSelector(state => state.chat)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        dispatch(sortBySet(value))
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(filterValueSet(event.target.value))
    }

    const radioProps: RadioProps = {
        size: `small`,
        color: `primary`,
        style: {
            padding: `2px`,
        },
    }

    const labelStyles: React.CSSProperties = {
        marginBottom: 0,
    }

    return (
        <Box
            padding={`10px`}
            display={`grid`}
            gridTemplateColumns={`auto auto`}
            justifyContent={`spaceBetween`}
            alignItems={`center`}
        >
            <FormControl
                component={Box}
                style={{
                    display: `grid`,
                    gridTemplateColumns: `min-content 1fr`,
                    alignItems: `center`,
                    gridColumnGap: theme.spacing(2),
                }}
            >
                <FormLabel
                    style={{
                        ...labelStyles,
                        whiteSpace: `nowrap`,
                    }}
                >View By:</FormLabel>
                <RadioGroup
                    onChange={handleChange}
                    value={sortBy}
                    row
                    name={`sort-by-radio-group`}
                >
                    <FormControlLabel
                        value={SortByTypes.RECENT}
                        control={<Radio {...radioProps} />}
                        label={`Recent`}
                        style={{
                            ...labelStyles,
                        }}
                    ></FormControlLabel>
                    <FormControlLabel
                        value={SortByTypes.RO}
                        control={<Radio {...radioProps} />}
                        label={`RO#`}
                        style={{
                            ...labelStyles,
                        }}
                    ></FormControlLabel>
                </RadioGroup>
            </FormControl>
            <TextField
                label={`Search By RO#`}
                size={`small`}
                variant={`outlined`}
                value={filterValue}
                onChange={handleFilterChange}
                InputProps={{
                    style: {
                        fontSize: `12px`,
                    }
                }}
                InputLabelProps={{
                    style: {
                        fontSize: `12px`,
                    }
                }}
            />
        </Box>
    )
}

export default Header