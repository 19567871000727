import APIFetch from 'api/utils/APIFetch'

function getBdcOverviewData(key, startDate, endDate) {
    return APIFetch(`/api/bdc/overview/${startDate}/${endDate}`).then(res => {
        const payload = {
            tasksNew: res.Total,
            tasksCompleted: res.Called,
            tasksAppointments: res.Appointments
        }
        return Promise.resolve(payload)
    })
}

export default getBdcOverviewData