import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { US_DATE_FORMAT } from 'constants/date.constants'
import moment from 'moment'
import { ISetDateRangeResponse } from 'types/ISetDateRangeResponse'
import setDates from '../thunks/setDates'

export enum Views {
    REPAIR_ORDERS,
    SENT,
    RECEIVED,
    UNANSWERED,
    AUTOMATED,
    NONE,
}

interface IState {
    startDate: string
    endDate: string
    isLoading: boolean
    isError: boolean
    view: Views
    
}

const initialState: IState = {
    startDate: moment().format(US_DATE_FORMAT),
    endDate: moment().format(US_DATE_FORMAT),
    isLoading: false,
    isError: false,
    view: Views.NONE
}

const slice = createSlice({
    name: `dashboard`,
    initialState,
    reducers: {
        viewSet(state, action: PayloadAction<Views>) {
            state.view = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setDates.pending, (state) => {
            state.isError = false
            state.isLoading = true
        })
        builder.addCase(setDates.fulfilled, (state, action: PayloadAction<ISetDateRangeResponse>) => {
            const {
                startDate,
                endDate,
            } = action.payload

            state.isLoading = false
            state.startDate = startDate
            state.endDate = endDate
        })
        builder.addCase(setDates.rejected, (state) => {
            state.isLoading = false
            state.isError = true
        })
    }
})

export const {
    viewSet,
} = slice.actions

export default slice.reducer