import React, { useState } from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideoPopup from '../VideoPopup'
import { videoSelectors } from 'components/RepairOrderListView/modules'
import { useAppSelector } from 'store'

export interface IVideoCell {
    repairOrderID: number
}

function VideoCell({
    repairOrderID,
}: IVideoCell) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const videos = useAppSelector(state => videoSelectors.selectAll(state)).filter(video => video.RepairOrderID === repairOrderID)
    const hasNonTranscoded = videos.some(video => !video.Transcoded)

    const handleClick = () => {
        setIsOpen(true)
    }
    
    const handleDialogClose = () => {
        setIsOpen(false)
    }

    if (!!videos.length) {
        return (
            <>
                <IconButton
                    size={`small`}
                    onClick={handleClick}
                    style={{
                        position: `relative`,

                    }}
                >
                    {hasNonTranscoded &&
                        <CircularProgress size={30} style={{
                            position: `absolute`,
                            top: `50%`,
                            left: `50%`,
                            marginTop: `-50%`,
                            marginLeft: `-50%`,
                        }} />
                    }
                    <VideocamIcon />
                </IconButton>
                {isOpen &&
                    <VideoPopup
                        onClose={handleDialogClose}
                        videos={videos}
                    ></VideoPopup>
                }
            </>
        )
    }

    return null
}

export default React.memo(VideoCell)