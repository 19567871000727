import { useEffect } from 'react'
import { useAppDispatch } from 'store'
import { fetchData } from 'modules/bdcListView'

function useSubscribeToData(categoryID) {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchData(categoryID))
    }, [categoryID, dispatch])
}

export default useSubscribeToData