import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import { Modes } from './Modes'
import AddEditCard from './components/AddEditCard'
import AlertCard from './components/AlertCard'
import { useAppDispatch, useAppSelector } from 'store'
import { roAlertSelectors } from 'modules/roAlerts'
import { enterAddMode, errorAcknowledged } from './modules'
import { addNewAlert, enterDefaultModeSuccessful, updateAlert } from 'components/RepairOrderAlertAddEditDialog/modules'
import ErrorSnackbar from 'components/ErrorSnackbar'
import { Moment } from 'moment'
import moment from 'moment'
import { snoozeAlert } from 'components/RepairOrderAlertDialog/module'

export interface IRepairOrderAlertAddEditDialog {
    repairOrderID: number
    onClose(): void
}

function RepairOrderAlertAddEditDialog({
    repairOrderID,
    onClose,
}: IRepairOrderAlertAddEditDialog) {
    const [currentTime, setCurrentTime] = useState<Moment>(moment())
    
    const dispatch = useAppDispatch()

    const {
        isOpen,
        mode,
        hasError,
        error,
        timeValue,
        textValue,
    } = useAppSelector(state => state.roAlertAddEditDialog)

    useEffect(() => {
        
        const timer = setInterval(() => setCurrentTime(moment()), 1000)

        return () => {
           clearInterval(timer) 
        }

    }, [currentTime])
    
    const alerts = useAppSelector(state => roAlertSelectors.selectAll(state).filter(alert => alert.repairOrderID === repairOrderID))

    const handleEditClick = () => {

        dispatch(enterAddMode(repairOrderID))
    
    }

    const handleSaveClick = () => {

        if(mode === Modes.ADD) {

            dispatch(addNewAlert(repairOrderID))

        } else {

            dispatch(updateAlert())

            if(mode === Modes.SNOOZE) {

                dispatch(snoozeAlert(timeValue))

            }
        }

        onClose()

    }

    const handleCancelClick = () => {

        if(mode === Modes.ADD || mode === Modes.SNOOZE) {

            onClose()

        } else {

            dispatch(enterDefaultModeSuccessful())

        }

    }

    const getContent = () => {
        switch (mode) {
            case Modes.INITIALIZING:
            case Modes.LOADING:
                return <Box
                    display={`grid`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    p={1}
                >
                    <CircularProgress/>
                </Box>
            case Modes.ADD:
            case Modes.EDIT:
            case Modes.SNOOZE:
                return <AddEditCard
                        time={timeValue}
                        text={textValue}/>
            default:
                return (
                    <Box
                        display={`grid`}
                        rowGap={10}
                    >
                        {alerts.map(alert => <AlertCard
                            key={alert.id}
                            alert={alert}
                            onClose={onClose}
                        ></AlertCard>)}
                    </Box>
                );
        }
    }

    const getFooterButtons = () => {
        switch (mode) {
            case Modes.ADD:
            case Modes.EDIT:
            case Modes.SNOOZE:
                return <>
                    <Button
                        color={`primary`}
                        onClick={handleCancelClick}
                    >Cancel</Button>
                    <Button
                        color={`primary`}
                        onClick={handleSaveClick}
                        disabled={moment(timeValue) <= moment() || textValue === ``}
                    >Save</Button>
                </>
            default:
                return <Button
                    color={`primary`}
                    onClick={onClose}
                >Close</Button>
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth>
                <DialogTitle>
                    <Box
                        display={`grid`}
                        justifyItems={`space-between`}
                        gridTemplateColumns={`1fr min-content`}
                        alignItems={`center`}
                    >
                        <span>Alerts</span>
                        <Tooltip
                            title={`Add Alert`}
                        >
                            <span>
                                <IconButton
                                    size={`small`}
                                    onClick={handleEditClick}
                                    disabled={mode === Modes.ADD || mode === Modes.EDIT || mode === Modes.SNOOZE}
                                >
                                    <AddAlertIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Current Time: ${currentTime.format('h:mm')} ${parseInt(currentTime.format('H')) < 12 ? 'AM' : 'PM'}`}
                    </DialogContentText>
                    {getContent()}
                </DialogContent>
                <DialogActions>
                    {getFooterButtons()}
                </DialogActions>
            </Dialog>
            <ErrorSnackbar 
                open={hasError} 
                message={error} 
                onClose={() => dispatch(errorAcknowledged())}
            ></ErrorSnackbar>
        </React.Fragment>
    )
}

export default React.memo(RepairOrderAlertAddEditDialog)