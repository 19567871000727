import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import CheckBox from 'components/CheckBox'

const InspectionStatusCheckboxGroup = ({
    id,
    onChange
}) => {  
    return (
        <div className={styles.container}>
            <CheckBox
                id={`statusGroup1-${id}`}
                backgroundColor={`#53A73E`}
                name={`group-${id}`}
                type={`radio`}
                onChange={onChange}
                defaultChecked
            ></CheckBox>
            <CheckBox
                id={`statusGroup2-${id}`}
                backgroundColor={`#DDB92E`}
                name={`group-${id}`}
                type={`radio`}
                onChange={onChange}
            ></CheckBox>
            <CheckBox
                id={`statusGroup3-${id}`}
                backgroundColor={`#D2452E`}
                name={`group-${id}`}
                type={`radio`}
                onChange={onChange}
            ></CheckBox>
        </div>
    )
}

InspectionStatusCheckboxGroup.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onChange: PropTypes.func
}

export default InspectionStatusCheckboxGroup