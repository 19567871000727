import React from 'react'
import { useAppSelector } from 'store'
import VideocamIcon from '@mui/icons-material/Videocam'
import useSubscribeToRepairOrders from './hooks/useSubscribeToRepairOrders'
import TableView from 'componentsUI/TableView'
import PromiseTimeCell from './components/PromiseTimeCell'
import MPVIStatusCell from './components/MPVIStatusCell'
import TransportTypeCell from './components/TransportTypeCell'
import MessageIcon from '@mui/icons-material/Message'
import VinCell from './components/VinCell'
import EstimateDialog from 'components/EstimateDialog'
import EstimateIcon from '@mui/icons-material/AssignmentTurnedIn'
import TransportIcon from '@mui/icons-material/Commute'
import StatusCell from './components/StatusCell'
import usePinnedRows from 'componentsUI/TableView/hooks/usePinnedRows'
import ToolSelectCell from './components/ToolSelectCell'
import DateTimeCell from './components/DateTimeCell'
import NotesCell from './components/NotesCell'
import MessagesCell from './components/MessagesCell'
import VideoCell from './components/VideoCell'
import CustomerCell from './components/CustomerCell'
import { repairOrdersSelectors } from 'components/RepairOrderListView/modules'
import StatusFilter from './components/StatusFilter'
import AdvisorFilter from './components/AdvisorFilter'
import VehicleDescriptionCell from './components/VehicleDescriptionCell'
import InfoScreen from './components/InfoScreen'
import WarrantyAlertFilter from './components/WarrantyAlertFilter'
import CarCrashIcon from '@mui/icons-material/CarCrash';
import WarrantyAlertIcon from './components/WarrantyAlertIcon/Index'
import HeaderIcon from './components/HeaderIcon'
import RuleIcon from '@mui/icons-material/Rule';
import MaintenanceScheduleCell from './components/MaintenanceScheduleCell'
import MaintenanceScheduleDialog from 'components/MaintenanceScheduleDialog'
import SubmittedMaintenanceScheduleFilter from './components/SubmittedMaintenanceScheduleFilter'

function RepairOrderListView() {
    const data = useAppSelector(repairOrdersSelectors.selectAll)

    useSubscribeToRepairOrders()

    const {
        DealerFeatures: features,
    } = useAppSelector((state) => state.dealer);

    const interactiveIsEnabled = features?.some(feature => feature.FeatureID === 5.01 && feature.IsEnabled)

    const schema = React.useMemo(
        () => [
            {
                Header: ``,
                accessor: `ID`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: null,
                },
                Cell: ({ value, row }) => <ToolSelectCell
                    id={value}
                    rowID={row.id}
                    currentColor={row.values.rowColor}
                    isPinned={row.values.isPinned}
                ></ToolSelectCell>
            },
            {
                Header: `RO#`,
                accessor: `RONumber`,
                width: 1,
            },
            {
                Header: `Tag`,
                accessor: `Tag`,
                width: 1,
            },
            {
                Header: `Promise Time`,
                accessor: `PromiseTime`,
                width: 1,
                disableFilters: true,
                disableGlobalFilter: true,
                Cell: ({ value, row }) => <PromiseTimeCell
                    dateTime={value}
                    roStatus={row.original.Status }
                ></PromiseTimeCell>,
            },
            {
                Header: `Customer`,
                accessor: `customerName`,
                width: 2,
                Cell: ({ row }) => <CustomerCell
                    id={row.original.CustomerID}
                ></CustomerCell>
            },
            {
                Header: `Vehicle`,
                accessor: `VehicleDescription`,
                width: 3,
                Cell: ({ value, row }) => <VehicleDescriptionCell
                    description={value}
                    odometer={row.original.Odometer}
                ></VehicleDescriptionCell>,
            },
            {
                Header: `Vin`,
                accessor: `Vin`,
                width: 1,
                Cell: ({ value }) => <VinCell
                    vin={value}
                ></VinCell>,
            },
            {
                Header: `Advisor`,
                accessor: `advisorName`,
                width: 1,
            },
            {
                Header: `Notes`,
                accessor: `Notes`,
                width: 2,
                Cell: ({ value, row }) => <NotesCell
                    id={row.original.ID}
                    text={value}
                ></NotesCell>
            },
            {
                Header: `Date/Time`,
                accessor: `Date`,
                width: 1,
                disableFilters: true,
                disableGlobalFilter: true,
                Cell: ({ value }) => <DateTimeCell
                    timestamp={value}
                ></DateTimeCell>
            },
            {
                Header: `Warranty Alert`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`Has Active Warranty Alert`}>
                            
                        <CarCrashIcon/>
                    </HeaderIcon>
                },
                Cell: ({value, row}) => 
                    <WarrantyAlertIcon
                        HasActiveWarrantyAlert={row.original.HasActiveWarrantyAlert}
                        FinalWarrantyAlertSent={row.original.FinalWarrantyAlertSent}
                        WarrantyClaimDeniable={row.original.WarrantyClaimDeniable}/>
            },
            {
                id: `message`,
                Header: `Message`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`Messages`}>
                            
                        <MessageIcon/>
                    </HeaderIcon>
                },
                Cell: ({ row }) => <MessagesCell
                    repairOrderID={row.original.ID}
                    customer={row.original.Customer}
                    messages={row.original.Messages}
                ></MessagesCell>
            },
            ...(interactiveIsEnabled ? [{
                Header: `Maintenance Schedule`,
                accessor: `MaintenanceSchedule`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`Maintenance Schedule Available to View`}>
                            
                        <RuleIcon/>
                    </HeaderIcon>
                },
                Cell: ({ value }) => <MaintenanceScheduleCell maintenanceSchedule={value}/>
            }] : []),
            {
                Header: `Video`,
                accessor: `Videos`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`Video Available to View`}>
                            
                        <VideocamIcon/>
                    </HeaderIcon>
                },
                Cell: ({ row }) => <VideoCell
                    repairOrderID={row.original.ID}
                ></VideoCell>
            },
            {
                Header: `MPVI Status`,
                accessor: `MPVIStatus`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`MPVI Status`}>
                            
                        <EstimateIcon/>
                    </HeaderIcon>
                },
                Cell: ({ value, row }) => <MPVIStatusCell
                    status={value}
                    isAttentionNeeded={row.original.AttentionNeeded}
                    repairOrderID={row.original.ID}
                ></MPVIStatusCell>
            },
            {
                Header: `Transport`,
                accessor: `Transport`,
                disableFilters: true,
                disableGlobalFilter: true,
                iconColumn: {
                    Icon: 
                    <HeaderIcon
                        tooltip={`Customer Transport`}>
                            
                        <TransportIcon/>
                    </HeaderIcon>
                },
                Cell: ({ value }) => <TransportTypeCell
                    type={value}
                ></TransportTypeCell>
            },
            {
                Header: ``,
                accessor: `statusTranslation`,
                iconColumn: {
                    Icon: null,
                },
                Filter: StatusFilter,
                showInFilterMenu: false,
                disableGlobalFilter: true,
                Cell: ({ value }) => <StatusCell
                    status={value}
                ></StatusCell>,
            },
            {
                Header: `Pinned`,
                accessor: `isPinned`,
                showInFilterMenu: false,
                disableGlobalFilter: true,
            },
            {
                Header: `Color`,
                accessor: `rowColor`,
                showInFilterMenu: false,
                disableGlobalFilter: true,
            },
            {
                Header: `Show My ROs`,
                id: `showMyRos`,
                accessor: row => row.UserID,
                Filter: AdvisorFilter,
                showInFilterMenu: false,
                disableGlobalFilter: true,
            },
            {
                Header: `Show Warranty Alerts`,
                id: `showWarrantyAlertFilter`,
                accessor: row => row.HasActiveWarrantyAlert,
                Filter: WarrantyAlertFilter,
                showInFilterMenu: false,
                disableGlobalFilter: true,
            },
            ...(interactiveIsEnabled ? [{
                Header: `Show submitted iRMs`,
                id: `showSubmittedInteractiveMaintenanceSchedules`,
                accessor: row => row.MaintenanceScheduleID !== null && row.MaintenanceSchedule.IsSubmitted,
                Filter: SubmittedMaintenanceScheduleFilter,
                showInFilterMenu: false,
                disableGlobalFilter: true,
            }] : [])
        ], [interactiveIsEnabled])

    const initialState = React.useMemo(() => ({
        hiddenColumns: [`isPinned`, `rowColor`, `showMyRos`, `showWarrantyAlertFilter`, ...(interactiveIsEnabled ? [`showSubmittedInteractiveMaintenanceSchedules`] : [])]
    }),[interactiveIsEnabled])

    const options = React.useMemo(() => ({
        autoResetFilters: false,
        autoResetSortBy: false,
        disableSortBy: true,
        defaultColumn: {
            showInFilterMenu: true,
        },
    }), [])

    const hooks = React.useMemo(() => ([
        usePinnedRows,
    ]), [])

    return (
        <>
            <TableView
                data={data}
                columns={schema}
                initialState={initialState}
                options={options}
                hooks={hooks}/>
            <InfoScreen/>
            <EstimateDialog/>
            <MaintenanceScheduleDialog/>
        </>
    )
}

export default React.memo(RepairOrderListView)