import React from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { Box, Divider, ListItemIcon, MenuItem, MenuList, Popover} from '@mui/material'
import { logOut } from 'modules/login'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.scss'
import { Logout } from '@mui/icons-material'
import { User } from 'api/types/user'
import { Dealer } from 'api/types/dealer'
import { Role } from 'api/types/role'
import DealerPicker from './components/DealerPicker'

function UserMenu() {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const user: User = useAppSelector(state => state.user)
    const dealer: Dealer = useAppSelector(state => state.dealer)
    
    const handleSignOutClick = () => {

        dispatch(logOut())
    
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {

        setAnchorEl(event.currentTarget);

    }

    const handleMenuClose = () => {

        setAnchorEl(null)
        window.location.reload()

    }

    return (
        
        <React.Fragment>
            <Box className={styles.accountContainer}>
                <Box className={styles.accountInfo}>
                    <span>{user.FirstName} {user.LastName}</span>
                    <span>{dealer.Name}</span>
                </Box>
                <Box 
                    className={styles.accountMenu}
                    onClick={handleMenuOpen}>

                    <AccountCircle fontSize={`large`}/>
                    <ExpandMoreIcon/>
                </Box>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>

                <MenuList
                    dense>
                    <Box className={styles.accountDetailsContainer}>
                        <Box>
                            <AccountCircle/>
                        </Box>
                        <Box>
                            <span>{user.FirstName} {user.LastName}</span>
                            <span>{user.Email}</span>
                            <span>{dealer.Name}</span>
                        </Box>
                    </Box>
                    <Divider/>
                    {user.Role === Role.Admin && (
                        <DealerPicker data={dealer} closeMenu={() => handleMenuClose()} />
                    )}
                    <MenuItem
                        onClick={handleSignOutClick}>

                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </MenuList>
            </Popover>
            
        </React.Fragment>
    )
}

export default UserMenu