import {
    createEntityAdapter,
    createSlice,
    current,
    PayloadAction,
} from '@reduxjs/toolkit'
import { Message } from 'api/types/message'
import getCommunicationDashboardMessages from 'api/getCommunicationDashboardMessages'
import Modes from 'components/CommunicationDashboard/modes'
import moment from 'moment'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import getDashboardData from '../thunks/getDashboardData'

const messagesAdapter = createEntityAdapter<Message>({
    selectId: (message) => message.ID,
    sortComparer: (a: Message, b: Message) => moment(b.SentAt).diff(moment(a.SentAt))
})

export interface IDashboardDataPayload {
    receivedCount: number
    sentCount: number
    unansweredCount: number
    automatedCount: number
}

interface ICommuncationDashboard extends IDashboardDataPayload {
    mode: Modes
    isLoading: boolean
    isError: boolean
    infoText: string
    globalFilterValue: string
    dateFilterMaxValue: string
    dateFilterMinValue: string
}

let initialState: ICommuncationDashboard = {
    mode: Modes.NONE,
    isLoading: false,
    isError: false,
    infoText: ``,
    globalFilterValue: ``,
    receivedCount: 0,
    sentCount: 0,
    unansweredCount: 0,
    automatedCount: 0,
    dateFilterMaxValue: ``,
    dateFilterMinValue: ``,
}

const slice = createSlice({
    name: `communicationDashboard`,
    initialState: messagesAdapter.getInitialState(initialState),
    reducers: {
        getDataStart(state) {
            state.isLoading = [...current(state.ids)].length === 0
            state.isError = false
            state.infoText = `Gathering data...`
        },
        getDataSuccess(state, action: PayloadAction<Message[]>) {
            messagesAdapter.setAll(state, action.payload)

            state.isLoading = false
            state.isError = false
            state.infoText = ``
        },
        getDataFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
        },
        messageUpserted(state, action: PayloadAction<Message>) {

            const message = action.payload

            if(state.mode === message.Direction || (state.mode === Modes.UNANSWERED && message.Direction === Modes.RECEIVED)) {

                messagesAdapter.addOne(state, message)

            }

        },
        setMode(state, action: PayloadAction<Modes>) {
            state.mode = action.payload
        },
        setDateFilterMaxValue(state, action: PayloadAction<string>) {
            state.dateFilterMaxValue = action.payload
        },
        setDateFilterMinValue(state, action: PayloadAction<string>) {
            state.dateFilterMinValue = action.payload
        },
        toggleLoading(state) {
            state.isLoading = !state.isLoading
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardData.pending, (state) => {
            state.isError = false
            state.infoText = initialState.infoText
            state.isLoading = true
        })
        builder.addCase(getDashboardData.fulfilled, (state, action: PayloadAction<IDashboardDataPayload>) => {
            const {
                receivedCount,
                sentCount,
                unansweredCount,
                automatedCount
            } = action.payload
            
            state.isLoading = false
            state.receivedCount = receivedCount
            state.sentCount = sentCount
            state.unansweredCount = unansweredCount
            state.automatedCount = automatedCount
        })
        builder.addCase(getDashboardData.rejected, state => {
            state.isLoading = false
            state.isError = true
            state.infoText = `An unexpected error has occurred while attempting to retrieve dashboard data. Contact support if the issue persists.`
        })
    },
})

export const {
    getDataStart,
    getDataSuccess,
    getDataFailure,
    messageUpserted,
    setMode,
    setDateFilterMaxValue,
    setDateFilterMinValue,
    toggleLoading,
} = slice.actions

export const messagesSelectors = messagesAdapter.getSelectors<RootState>(state => state.communicationDashboard)

export default slice.reducer

export const getData = (mode: Modes): AppThunk => async (dispatch, getState) => {
    try {

        dispatch(getDataStart())

        const {
            startDate,
            endDate,
        } = getState().dashboard

        const {
            ID: dealerID
        } = getState().dealer

        const res = await getCommunicationDashboardMessages(dealerID, mode, startDate, endDate)

        dispatch(getDataSuccess(res))
    } catch (error) {
        dispatch(getDataFailure(`There was an error retrieving messages: ${error}`))
    }
}