import LocalFilterStorage, { IRepairOrderFilterState } from "components/RepairOrderListView/helpers/filterLocalStorage";
import FilterChip from "componentsUI/TableView/components/FilterChip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnInstance } from "react-table";

export interface IWarrantyAlertFilter {
    column: ColumnInstance,
}

function SubmittedMaintenanceScheduleFilter({
    column
}: IWarrantyAlertFilter){
    const [filters, setFilters] = useState<IRepairOrderFilterState>(LocalFilterStorage.readStorage())
    const setTableFilter = useMemo(() => column.setFilter, [column])

    const handleClick = useCallback(() => {

        const updatedFilters = {
            ...LocalFilterStorage.readStorage(),
            displaySubmittedMaintenanceSchedules: !filters.displaySubmittedMaintenanceSchedules
        }

        LocalFilterStorage.saveToStorage(updatedFilters)

        setFilters(updatedFilters)

    },[setFilters, filters])

    useEffect(() => {

        setTableFilter(filters.displaySubmittedMaintenanceSchedules)

    }, [setTableFilter, filters.displaySubmittedMaintenanceSchedules])

    return (
        <FilterChip
            key={`showSubmittedMaintenanceSchedulesChip`}
            text={`Show Submitted iRMs`}
            isActive={filters.displaySubmittedMaintenanceSchedules}
            onClick={handleClick}
        ></FilterChip>
    )
}

export default SubmittedMaintenanceScheduleFilter