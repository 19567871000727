import React, { useCallback, useEffect, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message'
import { Badge, IconButton, Tooltip } from '@mui/material'
import ConversationDialog from 'components/ConversationDialog'
import { BaseMessage } from 'api/types/message'
import { useAppSelector } from 'store'
import { customerMessagesSelectors } from 'modules/customerMessages'
import { Customer } from 'api/types/customer'

export interface IMessagesCell {
    repairOrderID: number
    customer: Customer
    messages: BaseMessage[]
}

function MessagesCell({ 
    repairOrderID,
    customer,
    messages,
}: IMessagesCell) {
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
    const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false)

    const getMessages = useCallback(() => messages ? messages : [], [messages])

    const getUnreadMessages = useCallback(() => {
        return getMessages().filter(message => !message.HasAdvisorViewed)
    }, [getMessages])

    useEffect(() => {
        setHasUnreadMessages(getUnreadMessages().length > 0)
    }, [messages, getUnreadMessages])

    const customerMessages = useAppSelector(customerMessagesSelectors.selectAll)

    const handleDialogClick = () => {
        setDialogIsOpen(!dialogIsOpen)
    }

    return (
        <>
            <Tooltip

                title={`Send/View Messages`}
            >
                <IconButton
                    onClick={handleDialogClick}
                    size={`small`}
                >
                    <Badge variant={`dot`} color={`error`} invisible={!hasUnreadMessages}>
                        <MessageIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>
            {dialogIsOpen &&
                <ConversationDialog
                    customer={customer}
                    repairOrderID={repairOrderID}
                    onClose={handleDialogClick}
                    messages={customerMessages}
                ></ConversationDialog>
            }
        </>
    )
}

export default React.memo(MessagesCell)