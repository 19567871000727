import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import TimeInMinutesPicker from "./components/timeInMinutesPicker/TimeInMinutesPicker";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AfterCutoffTimeBehavior,
  RepairOrderAutoAlertTemplate,
} from "api/types/repairOrderAutoAlertTemplate";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { setHasError, updateROAutoAlertTemplate } from "modules/dealerForm";
import { InfoOutlined } from "@mui/icons-material";
import moment from "moment";
import {
  convertStringToUtcFromTimezone,
  convertUtcToTimezone,
} from "utils/formatDates";
import {
  ACTIVE_ALERT_INTERVAL_DESC,
  AFTER_CUTOFF_TIME_DESC,
  NO_VALID_CUTOFF_TIME_MESSAGE,
  NO_VALID_DATE_MESSAGE,
  NO_VALID_FIRST_INTERVAL_MESSAGE,
  NO_VALID_SECOND_INTERVAL_MESSAGE,
  NO_VALID_START_TIME_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "constants/messages.constants";
import { TIME_FORMAT } from "constants/date.constants";

const BASE_DATE = `2000-01-01T`;

const ROAutoAlertTemplate = () => {
  const { dealer } = useAppSelector((state) => state.dealerForm);

  const [state, setState] = useState<RepairOrderAutoAlertTemplate>(
    dealer.ROAlertTemplate ??
      ({
        AfterCutoffTimeBehavior: 0,
      } as RepairOrderAutoAlertTemplate)
  );

  const [errors, setErrors] = useState({
    FirstDeltaInMinutes: ``,
    SecondDeltaInMinutes: ``,
    StartTime: ``,
    CutoffTime: ``,
  });

  const dispatch = useDispatch();

  const validate = (currentState) => {
    let valError = { ...errors };

    if (
      !currentState.FirstDeltaInMinutes ||
      currentState.FirstDeltaInMinutes === 0
    ) {
      valError.FirstDeltaInMinutes = REQUIRED_FIELD_MESSAGE;
      valError.SecondDeltaInMinutes = ``;
    } else if (
      (currentState.SecondDeltaInMinutes &&
        currentState.SecondDeltaInMinutes === 0) ||
      currentState.FirstDeltaInMinutes >= currentState.SecondDeltaInMinutes
    ) {
      valError.FirstDeltaInMinutes = NO_VALID_FIRST_INTERVAL_MESSAGE;
      valError.SecondDeltaInMinutes = NO_VALID_SECOND_INTERVAL_MESSAGE;
    } else {
      valError.FirstDeltaInMinutes = ``;
      valError.SecondDeltaInMinutes = ``;
    }

    var convertedStartTime = convertUtcToTimezone(
      new Date(currentState.StartTime),
      dealer.TimeZoneID ?? ""
    );

    var convertedCutoffTime = convertUtcToTimezone(
      new Date(currentState.CutoffTime),
      dealer.TimeZoneID ?? ""
    );

    if (
      !currentState.StartTime ||
      currentState.StartTime === NO_VALID_DATE_MESSAGE
    ) {
      valError.StartTime = REQUIRED_FIELD_MESSAGE;
      valError.CutoffTime = ``;
    } else if (
      moment(
        new Date(
          `${BASE_DATE}${moment(convertedStartTime).format(TIME_FORMAT)}`
        )
      ).isSameOrAfter(
        moment(`${BASE_DATE}${moment(convertedCutoffTime).format(TIME_FORMAT)}`)
      )
    ) {
      valError.StartTime = NO_VALID_START_TIME_MESSAGE;
      valError.CutoffTime = NO_VALID_CUTOFF_TIME_MESSAGE;
    } else {
      valError.StartTime = "";
    }

    if (
      !currentState.CutoffTime ||
      currentState.CutoffTime === NO_VALID_DATE_MESSAGE
    ) {
      valError.CutoffTime = REQUIRED_FIELD_MESSAGE;
    } else if (
      moment(
        new Date(
          `${BASE_DATE}${moment(convertedStartTime).format(TIME_FORMAT)}`
        )
      ).isSameOrAfter(
        moment(`${BASE_DATE}${moment(convertedCutoffTime).format(TIME_FORMAT)}`)
      )
    ) {
      valError.StartTime = NO_VALID_START_TIME_MESSAGE;
      valError.CutoffTime = NO_VALID_CUTOFF_TIME_MESSAGE;
    } else {
      valError.StartTime = "";
      valError.CutoffTime = ``;
    }

    setErrors(valError);
  };

  useEffect(() => {
    let currentState = {
      ...dealer.ROAlertTemplate,
      DealerID: dealer.ID || 0,
      AfterCutoffTimeBehavior:
        dealer.ROAlertTemplate?.AfterCutoffTimeBehavior ?? 0,
      StartTime: dealer.ROAlertTemplate?.StartTime ?? "",
      CutoffTime: dealer.ROAlertTemplate?.CutoffTime ?? "",
    } as RepairOrderAutoAlertTemplate;

    setState(currentState);
  }, [dealer]);

  const changeHandler = (e) => {
    const currentState = {
      ...state,
      DealerID: dealer.ID ?? 0,
      [e.target.name]: e.target.value,
    } as RepairOrderAutoAlertTemplate;

    setState(currentState);

    dispatch(updateROAutoAlertTemplate(currentState));

    validate(currentState);
  };

  useEffect(() => {
    if (Object.values(errors).some((prop) => prop.trim() !== "")) {
      dispatch(setHasError(true));
    } else dispatch(setHasError(false));
  }, [errors, dispatch]);

  const handleTimeChange = (e) => {
    var value = e.target.value
      ? convertStringToUtcFromTimezone(
          e.target.value ? `${BASE_DATE}${e.target.value}` : ``,
          dealer.TimeZoneID ?? ""
        )
      : null;

    const currentState = {
      ...state,
      DealerID: dealer.ID ?? 0,
      [e.target.name]: value,
    } as RepairOrderAutoAlertTemplate;

    setState(currentState);

    dispatch(updateROAutoAlertTemplate(currentState));

    validate(currentState);
  };

  return (
    <>
      <h1>Repair Order Auto Alert Template</h1>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <TimeInMinutesPicker
            name="FirstDeltaInMinutes"
            label="First Interval"
            changeHandler={changeHandler}
            minutes={dealer.ROAlertTemplate?.FirstDeltaInMinutes}
            error={!!errors.FirstDeltaInMinutes}
            errorMessage={errors.FirstDeltaInMinutes}
          />
          <TimeInMinutesPicker
            name="SecondDeltaInMinutes"
            label="Second Interval"
            changeHandler={changeHandler}
            minutes={dealer.ROAlertTemplate?.SecondDeltaInMinutes ?? undefined}
            error={!!errors.SecondDeltaInMinutes}
            errorMessage={errors.SecondDeltaInMinutes}
          />
        </Stack>
        {dealer.TimeZoneID && (
          <Box sx={{ display: "flex" }}>
            <Typography fontWeight={"bold"} sx={{ marginRight: 1 }}>
              Active Alert Interval ({dealer.TimeZoneID})
            </Typography>
            <Tooltip title={ACTIVE_ALERT_INTERVAL_DESC}>
              <InfoOutlined />
            </Tooltip>
          </Box>
        )}
        <Stack direction={"row"} spacing={2} width={"100%"}>
          <TextField
            fullWidth
            type="time"
            name="StartTime"
            variant="filled"
            label="Start Time"
            inputProps={{
              step: 300,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.StartTime}
            onChange={handleTimeChange}
            helperText={errors.StartTime}
            value={
              state.StartTime
                ? convertUtcToTimezone(
                    new Date(state.StartTime),
                    dealer.TimeZoneID ?? ""
                  )?.slice(11, 16)
                : ""
            }
          />
          <TextField
            fullWidth
            type="time"
            variant="filled"
            name="CutoffTime"
            label="Cutoff Time"
            inputProps={{
              step: 300,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleTimeChange}
            error={!!errors.CutoffTime}
            helperText={errors.CutoffTime}
            value={
              state.CutoffTime
                ? convertUtcToTimezone(
                    new Date(state.CutoffTime),
                    dealer.TimeZoneID ?? ""
                  )?.slice(11, 16)
                : ""
            }
          />
        </Stack>
        <Box sx={{ display: "flex" }}>
          <Typography fontWeight={"bold"} sx={{ marginRight: 1 }}>
            After Cutoff Time Behavior
          </Typography>
          <Tooltip title={AFTER_CUTOFF_TIME_DESC}>
            <InfoOutlined />
          </Tooltip>
        </Box>
        <Select
          name={"AfterCutoffTimeBehavior"}
          value={dealer.ROAlertTemplate?.AfterCutoffTimeBehavior || 0}
          onChange={changeHandler}
        >
          <MenuItem value={AfterCutoffTimeBehavior.SetForTomorrowAtStartTime}>
            Set For Tomorrow At Start Time
          </MenuItem>
          <MenuItem value={AfterCutoffTimeBehavior.Ignore}>Ignore</MenuItem>
        </Select>
      </Stack>
    </>
  );
};

export default ROAutoAlertTemplate;
