import { Connection, hubConnection, Proxy, ProxyMock } from 'signalr-no-jquery'
import { Signal, Hub } from 'types/signalr'
import { mocks } from './mocks'

export const connection: Connection = hubConnection(`${process.env.REACT_APP_API_URL}/api/signalr/hubs`, {
    logging: process.env.NODE_ENV === 'development'
}) 

export const proxy: Proxy | ProxyMock = connection.createHubProxy(Hub.CRM)

export const proxyMock: ProxyMock = {
    events: [],
    on (signal, callback)  {
        this.events.push({
            signal,
            func: callback,
        })
    },
    off (signal, callback) {
        this.events = this.events.filter(s => s.func !== callback)
    },
    invoke (signal, args) {
        const entries = this.events.filter(s => s.signal === signal)

        if (entries.length === 0) {
            throw new Error(`There is no mock registered for ${signal}`)
        }

        entries.forEach(entry => {
            if (entry.func !== undefined) {                
                entry.func(mocks[signal](args))
            } else {
                throw new Error(`Mock ${signal} has no callback`)
            }
        })

    }
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_SIGNALR) {
    proxy.on = (signal: Signal, callback) => {
        if (mocks[signal] !== undefined) {
            console.log(`${signal} is being mocked`)
            return proxyMock.on(signal, callback)  
        } else {
            return () => proxy.on(signal, callback)
        }
    }
    
    proxy.off = (signal: Signal, callback) => {
        if (mocks[signal] !== undefined) {
            console.log(`${signal} mock has been removed`)
            return proxyMock.off(signal, callback)
        } else {
            return () => proxy.off(signal, callback)
        }
    }
}

connection.starting(() => {
    console.log('HUB STARTING!')
})
// connection.received((...d) => console.log('HUB RECEIVED!', d))
connection.connectionSlow(() => console.log('HUB CONNECTION SLOW!'))
connection.reconnecting(() => {
    console.log('HUB RECONNECTING!')
})
connection.reconnected(() => {
    console.log('HUB RECONNECTED!')
})
connection.stateChanged((o, n) => console.log('HUB STATE CHANGED!', JSON.stringify(o), JSON.stringify(n)))

//IMPORTANT: All client-side methods exposed to the hubs must be declared BEFORE connecting to the server!!!
//However, I want potentially multiple entities within the client to be able to subscribe to these broadcasts, so using HubAccessor as a facade over all that mess..
//UPDATE: Only one method needs to be defined before connecting. Use the "useSubscribeToSignalrMessage" hook moving forward.
Object.values(Signal).forEach(signal => proxy.on(signal, () => console.log(`Signalr message ${signal} received`)))

export function connect(token: string) {
    connection.qs = { Bearer: token }
    return connection.start()
}