import React, { useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import ViewIcon from '@mui/icons-material/Visibility'
import CustomerDetailDialog from 'components/CustomerDetailDialog'
import { useAppSelector } from 'store'
import { customerSelectors } from 'components/RepairOrderListView/modules'

export interface ICustomerCell {
    id: number
}

function CustomerCell({
    id,
}: ICustomerCell) {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const {
        FullName
    } = useAppSelector(state => customerSelectors.selectById(state, id)) || { FullName: `Unknown`}

    return (
        <>
            <Box
                display={`grid`}
                gridTemplateColumns={`1fr min-content`}
                alignItems={`center`}
            >
                <Box>{FullName}</Box>
                <Tooltip
                    title={`View Customer`}
                >
                    <Box>
                        <IconButton
                            size={`small`}
                            onClick={() => setDialogIsOpen(true)}
                        >
                            <ViewIcon/>
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
            {dialogIsOpen &&
                <CustomerDetailDialog
                    id={id}
                    onClose={() => setDialogIsOpen(false)}
                ></CustomerDetailDialog>
            }
        </>
    )
}

export default React.memo(CustomerCell)