import { BaseMaintenanceSchedule } from "api/types/maintenanceSchedule"
import ColumnIcon from "componentsUI/TableView/components/ColumnIcon";
import React from "react"
import { useAppDispatch } from "store";
import { maintenanceScheduleDialogOpened } from "modules/maintenanceScheduleDialog";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { green, red } from "@mui/material/colors";

interface IMaintenanceScheduleCellProps {
    maintenanceSchedule: BaseMaintenanceSchedule
}

function MaintenanceScheduleCell({ 
    maintenanceSchedule
}: IMaintenanceScheduleCellProps){
    const dispatch = useAppDispatch()

    if(maintenanceSchedule === null || !maintenanceSchedule.IsSubmitted)
        return null

    const handleClick = () => {
        dispatch(maintenanceScheduleDialogOpened({
            maintenanceScheduleId: maintenanceSchedule.ID
        }))
    }

    const isAccepted = maintenanceSchedule.IsAccepted

    return (
        <>
            <ColumnIcon
                tooltipText={`View Maintenance Schedule`}
                Icon={isAccepted ? AttachMoneyIcon : MoneyOffIcon}
                color={isAccepted ? green[700] : red[700]}
                buttonProps={{
                    onClick: handleClick
                }}
                isButton={true}/>
        </>
    )
}

export default MaintenanceScheduleCell