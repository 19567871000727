import React from 'react'
import Overlay from 'components/Overlay'
import { useAppSelector } from 'store'

function InfoScreen() {
    const {
        isLoading,
        isError,
        infoText
    } = useAppSelector(
        state => state.repairOrderListView
    )

    return (
        <Overlay
            show={isLoading || isError}
            isLoading={isLoading}
            isError={isError}
            text={infoText}
        ></Overlay>
    )
}

export default InfoScreen