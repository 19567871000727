export enum AfterCutoffTimeBehavior {
    SetForTomorrowAtStartTime,
    Ignore,
}

export interface BaseRepairOrderAutoAlertTemplate {
    DealerID: number
    FirstDeltaInMinutes: number
    SecondDeltaInMinutes: number | null
    StartTime: string  
    CutoffTime: string
    AfterCutoffTimeBehavior: AfterCutoffTimeBehavior
}

export interface RepairOrderAutoAlertTemplate extends BaseRepairOrderAutoAlertTemplate {
    
}