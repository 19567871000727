import React from 'react'
import moment from 'moment'
import CallGroup, { StatusTypes } from 'classes/CallGroup'
import CallStatusInput from 'components/CallStatusInput'
import { useAppDispatch, useAppSelector } from 'store'
import { updateCreateCallGroup } from 'modules/bdcListView'

import styles from './styles.module.scss'
import { ISO_DATE_FORMAT } from 'constants/date.constants'

export interface ICallStatusCell {
    callGroup: CallGroup
}

function CallStatusCell({
    callGroup
}: ICallStatusCell) {
    const dispatch = useAppDispatch()
    const {
        EnableAppointments,
    } = useAppSelector(state => state.dealer)

    const onCheckChange = (statusType: StatusTypes) => {
        const newCallGroup = new CallGroup(callGroup)
        let result: boolean = false

        switch (statusType) {
            case StatusTypes.NO_ANSWER:
                result = !newCallGroup.noAnswer
                newCallGroup.noAnswer = result
                break
            case StatusTypes.LEFT_MESSAGE:
                result = !newCallGroup.leftMessage
                newCallGroup.leftMessage = result
                break
            case StatusTypes.LEFT_VOICEMAIL:
                result = !newCallGroup.leftVoicemail
                newCallGroup.leftVoicemail = result
                break
            case StatusTypes.CUSTOMER_REQ_CALL:
                result = !newCallGroup.custReqCall
                newCallGroup.custReqCall = result
                break
            case StatusTypes.APPOINTMENT_MADE:
                result = !newCallGroup.apptMade
                newCallGroup.apptMade = result
                break
            default:
        }

        const callAttemptsPreUpdate = newCallGroup.numberOfCallAttempts
        newCallGroup.calculateNumberOfCallAttempts()
        const callAttemptsPostUpdate = newCallGroup.numberOfCallAttempts

        if(callAttemptsPreUpdate < callAttemptsPostUpdate) {
            newCallGroup.timeStamp = moment().format(`${ISO_DATE_FORMAT}THH:mm:ss`)
            newCallGroup.lastCall = newCallGroup.timeStamp
        }

        dispatch(updateCreateCallGroup(newCallGroup))
    }

    return (
        <div className={styles.container}>
            <CallStatusInput
                id={`callStatusInputsBox-noAnswer-${callGroup.id}`}
                label={`No Answer`}
                value={StatusTypes.NO_ANSWER}
                isChecked={callGroup.noAnswer}
                onClick={onCheckChange}
            ></CallStatusInput>
            <CallStatusInput
                id={`callStatusInputsBox-custReqCall-${callGroup.id}`}
                label={`Cust Req Call`}
                value={StatusTypes.CUSTOMER_REQ_CALL}
                isChecked={callGroup.custReqCall}
                onClick={onCheckChange}
            ></CallStatusInput>
            <CallStatusInput
                id={`callStatusInputsBox-leftMessage-${callGroup.id}`}
                label={`Left Message`}
                value={StatusTypes.LEFT_MESSAGE}
                isChecked={callGroup.leftMessage}
                onClick={onCheckChange}
            ></CallStatusInput>
            {EnableAppointments &&
                <CallStatusInput
                    id={`callStatusInputsBox-apptMade-${callGroup.id}`}
                    label={`Appt Made`}
                    value={StatusTypes.APPOINTMENT_MADE}
                    isChecked={callGroup.apptMade}
                    onClick={onCheckChange}
                ></CallStatusInput>
            }
            <CallStatusInput
                id={`callStatusInputsBox-leftVoicemail-${callGroup.id}`}
                label={`Left VM`}
                value={StatusTypes.LEFT_VOICEMAIL}
                isChecked={callGroup.leftVoicemail}
                onClick={onCheckChange}
            ></CallStatusInput>
        </div>
    )
}

export default CallStatusCell