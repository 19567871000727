import { useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import getBdcOverviewData from 'utils/getBdcOverviewData'
import {
    useAppSelector,
    useAppDispatch
} from 'store'
import {
    setDataTasksOverview, setIsLoadingOverviewData, setIsErrorOverviewData,
} from 'modules/bdcDashboard'
import { ISO_DATE_FORMAT } from 'constants/date.constants'

function useSubscribeToOverviewData() {
    const dispatch = useAppDispatch()
    const {
        selectedTasksTab,
    } = useAppSelector(
        state => state.bdcDashboard
    )

    const {
        startDate,
        endDate
    } = useMemo(
        () => {
            switch (selectedTasksTab) {
                case 0: //today
                    return {
                        startDate: moment(),
                        endDate: moment()
                    }
                case 1: //yesterday
                    return {
                        startDate: moment().subtract(1, `days`),
                        endDate: moment()
                    }   
                case 2: //This Week
                    return {
                        startDate: moment().subtract(7, `days`),
                        endDate: moment()
                    }
                default:
                    return {
                        startDate: moment(),
                        endDate: moment()
                    }
            }
        }, [selectedTasksTab]
    )

    const {
        isLoading,
        isError
    } = useQuery([`bdcOverviewData`, startDate.format(ISO_DATE_FORMAT), endDate.format(ISO_DATE_FORMAT)], getBdcOverviewData, {
        onSuccess: (data: any) => {
            dispatch(setDataTasksOverview(data))
        }
    })
    
    useEffect(() => {      
        dispatch(setIsErrorOverviewData(isError))
    }, [isError, dispatch])
    
    useEffect(() => {
        dispatch(setIsLoadingOverviewData(isLoading))
    }, [isLoading, dispatch])
}

export default useSubscribeToOverviewData