import { Box } from '@mui/material'
import React from 'react'
import ToggleSort from './components/ToggleSort'

import styles from './styles.module.scss'

export interface IHeaderCell {
    column: any
    numColumns: number
    index: number
}

function HeaderCell({
    column,
    numColumns,
    index,
}: IHeaderCell) {
    const isIconColumn = !!column.iconColumn

    const baseView = <>
        <ToggleSort
            canSort={column.canSort}
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            toggleSortBy={column.toggleSortBy}
            clearSortBy={column.clearSortBy}
        ></ToggleSort>
        <div className={styles.contentWrapper}>
            <Box
                className={styles.text}
                whiteSpace={column.canFilter ? `nowrap` : `normal`}
            >
                {column.render('Header')}
            </Box>
        </div>
    </>

    const content = isIconColumn ?
        <div className={styles.iconCell}>
            {column.iconColumn.Icon}
        </div> : baseView

    return (
        <div
            {...column.getHeaderProps()}
            className={styles.container}
            style={{
                ...column.getHeaderProps().style,
                zIndex: `${numColumns - index + 1}`,
            }}
        >
            {numColumns !== index &&
                <div
                    {...column.getResizerProps()}
                    className={`${styles.resizer} ${column.isResizing ? 'isResizing' : ''}`}
                >
                    <div draggable='false'></div>
                </div>
            }
            {content}
        </div>
    )
}

export default React.memo(HeaderCell)