import React, { useCallback, useMemo, useState } from 'react'
import { Box, Popover } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PinIcon from '@mui/icons-material/Room'
import ColumnIcon from 'componentsUI/TableView/components/ColumnIcon'
import PinnedTool from '../PinnedTool'
import ColorPickerTool from '../ColorPickerTool'
import AlertTool from '../AlertTool'
import { Color } from 'components/RepairOrderListView/types/ListRepairOrder'

export interface IToolSelectCell {
    id: number
    rowID: number
    currentColor: Color
    isPinned: boolean
}

function ToolSelectCell({
    id,
    rowID,
    currentColor,
    isPinned,
}: IToolSelectCell) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    let tooltipText = `Click for tools.`
    const isOpen = !!anchorEl

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget)
        },
        [],
    )

    const handleClose = useCallback(
        () => {
            setAnchorEl(null)
        },
        [],
    )

    const buttonProps = useMemo(() => ({
        onClick: handleClick
    }), [handleClick])

    return (
        <Box
            alignSelf={`stretch`}
            display={`grid`}
            alignContent={`stretch`}
        >
            <ColumnIcon
                tooltipText={tooltipText}
                Icon={isPinned ? PinIcon : MenuIcon}
                isButton
                buttonProps={buttonProps}
            ></ColumnIcon>
            <Popover
                open={isOpen}
                onClose={(handleClose)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    padding={1}
                    display={`grid`}
                    gap={1}
                    gridAutoFlow={`columns`}
                >
                    <PinnedTool
                        id={id}
                        isPinned={isPinned}
                        onClick={handleClose}
                    ></PinnedTool>
                    <ColorPickerTool
                        id={id}
                        currentColor={currentColor}
                        onClick={handleClose}
                    ></ColorPickerTool>
                    <AlertTool
                        roID={id}
                        onClick={handleClose}
                    ></AlertTool>
                </Box>
            </Popover>
        </Box>
    );
}

export default React.memo(ToolSelectCell)