import { DealerAppointmentSchedule } from "api/types/dealerAppointmentSchedule";
import apiFetch from "api/utils/APIFetch";

const postDealerSchedule = (DealerSchedule: DealerAppointmentSchedule) : Promise<void> => apiFetch(`/api/web-scheduler/dealerschedule`, {
    method: `POST`,
    body: JSON.stringify(DealerSchedule)
}).catch(error => {
    throw new Error(error.Message)
})

export default postDealerSchedule;
