import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useAppSelector } from 'store'

function BackButton() {
    const {
        goBack,
        push,
    } = useHistory()
    const {
        backRoute,
    } = useAppSelector(
        state  => state.appNavBar
    )

    const handleClick = backRoute === `` ? () => goBack() : () => push(backRoute)

    return (
        <IconButton onClick={handleClick} color={`inherit`} size="large">
            <ArrowBackIcon/>
        </IconButton>
    );
}

export default BackButton