import { Clear } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useSubscribeToUsersSearch } from "components/DealerForm/hooks/useSuscribeToUsersSearch";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { isEmpty } from "voca";
import { User } from "api/types/user";
import UserCard from "./components/UserCard";
import { getDealerUsersData } from "modules/dealerForm";

interface IUsersTabProps {
  searchTerm: string;
  users?: User[];
}

const UsersTab = ({ setDialog }) => {
  const dispatch = useAppDispatch();

  const { dealer, users } = useAppSelector((state) => state.dealerForm);

  const [propState, setPropState] = useState<IUsersTabProps>();

  useSubscribeToUsersSearch(dealer.ID, propState?.searchTerm || ``);

  useEffect(() => {
    setPropState((p) => {
      return { ...p, users: users } as IUsersTabProps;
    });
  }, [users]);

  const handleClearSearch = () =>
    setPropState({ ...propState, searchTerm: `` });

  const handleSearch = (e) => {
    setPropState({ ...propState, searchTerm: e.target.value });
    dispatch(getDealerUsersData(dealer.ID, e.target.value || ``));
  };

  const handleRefresh = () => {
    dispatch(getDealerUsersData(dealer.ID, propState?.searchTerm || ``));
  };

  return (
    <>
      <h1>Users</h1>
      <TextField
        size="small"
        label={`Search user...`}
        onInput={handleSearch}
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              disableTypography
              position="end"
              onClick={handleClearSearch}
              disablePointerEvents={isEmpty(propState?.searchTerm)}
            >
              <Clear />
            </InputAdornment>
          ),
        }}
        value={propState?.searchTerm || ``}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
        }}
      >
        {propState?.users?.map((user) => (
          <UserCard
            key={user.ID}
            dealerID={dealer.ID}
            user={user}
            setDialog={setDialog}
            refresh={handleRefresh}
          />
        ))}
      </Box>
    </>
  );
};

export default UsersTab;
