import { createAsyncThunk } from '@reduxjs/toolkit'
import getInteractiveMaintenanceReportingOverview from 'api/reporting/interactiveMaintenance/getInteractiveMaintenanceReportingOverview'
import { InteractiveMaintenanceReportingOverviewViewModel } from 'api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel'
import { IPayload } from '../interfaces/IPayload'

const getInteractiveMaintenanceReportingOverviewData = createAsyncThunk(
    `reporting/getInteractiveMaintenanceReportingOverviewData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<InteractiveMaintenanceReportingOverviewViewModel> => {
        return await getInteractiveMaintenanceReportingOverview(dealerID, startDate, endDate)
    }
)

export default getInteractiveMaintenanceReportingOverviewData