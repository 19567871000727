import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { Role } from 'api/types/role'
import { User } from 'api/types/user'

let initialState: User = {
    ID: 0,
    Email: ``,
    FirstName: ``,
    FullName: ``,
    LastName: ``,
    PIN: 0,
    Role: Role.Advisor,
    DealerUsers: []
}

const slice = createSlice({
    name: `user`,
    initialState,
    reducers: {
        userSet(state, action: PayloadAction<User>) {
            return action.payload
        },
        reset() {
            return initialState
        },
    }
})

export const {
    userSet,
    reset,
} = slice.actions

export default slice.reducer