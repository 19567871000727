import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import styles from './styles.module.scss'

export interface IPerformanceSectionContainerProps {
    children: ReactNode,
}

export default function PerformanceSectionContainer({
    children
}: IPerformanceSectionContainerProps){
    return (
        <Box className={styles.container}>
            {children}
        </Box>   
    )
}