import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { Signal } from 'types/signalr'
import getDashboardData from 'components/CommunicationDashboard/thunks/getDashboardData'

function useSubscribeToData() {
    const dispatch = useAppDispatch()
    const {
        startDate,
        endDate,
    } = useAppSelector(state => state.dashboard)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const getData = useCallback(() => {
        dispatch(getDashboardData({dealerID, startDate, endDate}))
    }, [dispatch, dealerID, startDate, endDate])

    useEffect(() => {
        getData()
    }, [getData])

    useSubscribeToSignal(Signal.MESSAGE_CREATED, getData)
    useSubscribeToSignal(Signal.MESSAGE_RECEIVED, getData)
    useSubscribeToSignal(Signal.MESSAGES_UPDATED, getData)
}

export default useSubscribeToData