import APIFetch from 'api/utils/APIFetch'

function getBdcDashboardData() {
    return APIFetch(`/api/bdc`).then(res => {
        //map the data we need
        const noShowAppointmentsObj = res.NoShowAppointments
        const tomorrowsAppoinementsObj = res.TomorrowsAppointments
        const upcomingAppointmentsObj = res.UpcomingAppointments
        const noRepairOrdersLastSixMonthsObj = res.NoRepairOrdersLastSixMonths
        const noRepairOrdersLastSixToNineMonthsObj = res.NoRepairOrdersLastSixToNineMonths
        const noRepairOrdersLastNineToTwelveMonthsObj = res.NoRepairOrdersLastNineToTwelveMonths
        const noRepairOrdersLastTwelveToFifteenMonthsObj = res.NoRepairOrdersLastTwelveToFifteenMonths

        return {
            noShowAppointments: createCategory(noShowAppointmentsObj),
            tomorrowsAppoinements: createCategory(tomorrowsAppoinementsObj),
            upcomingAppointments: createCategory(upcomingAppointmentsObj),
            noRepairOrdersLastSixMonths: createCategory(noRepairOrdersLastSixMonthsObj),
            noRepairOrdersLastSixToNineMonths: createCategory(noRepairOrdersLastSixToNineMonthsObj),
            noRepairOrdersLastNineToTwelveMonths: createCategory(noRepairOrdersLastNineToTwelveMonthsObj),
            noRepairOrdersLastTwelveToFifteenMonths: createCategory(noRepairOrdersLastTwelveToFifteenMonthsObj),
        }
    })
}

//helper function to cut down on code
function createCategory(obj){
    return {
        total: obj.Total,
        called: obj.Called,
        appts: obj.Appointments
    }
}

export default getBdcDashboardData