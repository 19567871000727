import React from 'react'
import { Tab, TabProps } from '@mui/material'

interface INavTab extends TabProps { }

function NavTab({ ...props }: INavTab) {
    return (
        <Tab 
            style={{
                fontSize: `12px`,
                minWidth: `min-content`,
            }}
            {...props}
        ></Tab>

    )
}

export default React.memo(NavTab)