import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { 
    useSelector,
    TypedUseSelectorHook,
    useDispatch,
} from 'react-redux'

import rootReducer, { RootState } from 'rootReducer'

const store = configureStore({
    reducer: rootReducer
})

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer)
    })
}

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
