import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { CSIAlertReportingOverviewViewModel } from "api/types/reporting/CSIAlertReportingOverviewViewModel"
import React from "react";
import { stringToColor } from "utils/stringToColor";
import AvatarIcon from "../../AvatarIcon";
import DataTable from "components/DataTable";

export interface ICsiAlertDataGrid {
    data: CSIAlertReportingOverviewViewModel
}

export default function CsiAlertDataGrid({
    data
}: ICsiAlertDataGrid) {

    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'avatar',
          headerName: '',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            const columnData = params.formattedValue
            return (
                <AvatarIcon 
                    name={columnData.name} 
                    color={columnData.color}/>
            )
          }
        },
        {
          headerClassName: `header-cell`,
          field: 'advisor',
          headerName: 'Advisor',
          type: 'string',
          sortable: false,
          editable: false,
          flex: 1,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'totalSmsUpdates',
          headerName: 'Total SMS Updates',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .75,
          disableColumnMenu: true,
        },
        {
          headerClassName: `header-cell`,
          field: 'totalAlerts',
          headerName: 'Total Alerts',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .5,
          disableColumnMenu: true,
        },
      ];

    const rows = data.ByUser.map((userAlert, index) => {
        const advisorName = userAlert.Name
        const color = stringToColor(advisorName)
        
        return {
            id: index,
            avatar: {
                name: advisorName,
                color: color
            },
            advisor: advisorName,
            totalSmsUpdates: userAlert.TotalSmsUpdates,
            totalAlerts: userAlert.TotalAlerts,
        }
    })

    return (
        <DataTable
            rows={rows}
            columns={columns}/>
    )
}