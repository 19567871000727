import { Box } from "@mui/material";
import AppRoute from "components/AppRoute";
import ReportingDashboard from "components/Reporting/components/ReportingDashboard";
import pages from "pages";
import React from "react";
import { Switch } from 'react-router-dom'

function ReportingRouter() {
    return (
        <Box sx={{paddingTop: `20px`}}>
            <Switch>
                <AppRoute exact path={pages.REPORTING.route} page={pages.REPORTING}>
                    <ReportingDashboard/>
                </AppRoute>
            </Switch>
        </Box>
    )
}

export default ReportingRouter