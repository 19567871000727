import { IChatConversation } from 'types/chatConversation'
import ChatMessage, { mapToChatMessage } from './chatMessage'
import ChatUser, { mapToChatUser } from './chatUser'

export default interface ChatConversation {
    ID: number
    Description: string
    RepairOrderID: number
    RepairOrderName: string
    Users: ChatUser[]
    ChatMessages: ChatMessage[]
}

export function mapToChatConversation({
    ID,
    Description,
    RepairOrderID,
    RepairOrderName,
    Users,
    ChatMessages,
}: ChatConversation): IChatConversation {
    return {
        id: ID,
        description: Description,
        repairOrderID: RepairOrderID,
        repairOrderNumber: RepairOrderName,
        users: Users.map(mapToChatUser),
        messages: ChatMessages.map(mapToChatMessage)
    }
}