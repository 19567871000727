import { AlternateEmail, Edit, Save } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  TextField,
  InputAdornment,
  Box,
} from "@mui/material";
import putDealerUser from "api/putDealerUser";
import { User } from "api/types/user";
import React, { useState } from "react";
import validateEmail from "utils/validateEmail";

interface IUserCardProps {
  dealerID: number;
  user: User;
  setDialog: (e) => void;
  refresh: () => void;
}

const UserCard = ({ dealerID, user, setDialog, refresh }: IUserCardProps) => {
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState<string>(user.Email);
  const [error, setError] = useState(false);

  const handleEdit = () => setEdit(!edit);

  const handleSave = async () => {
    if (!validateEmail(email) && email !== ``) return;

    const userPayload = { ...user, email: email };

    await putDealerUser(dealerID, userPayload)
      .then(refresh)
      .catch((error) => {
        setDialog({
          title: "Error",
          message:
            error.Message ??
            `Something went wrong. Please try again or contact your administrator.`,
          open: true,
          toggleDialog: () =>
            setDialog((d) => {
              return {
                ...d,
                open: false,
              };
            }),
        });
      });

    setEdit(!edit);
  };

  const handleEmailChange = (e) => {
    if (!validateEmail(e.target.value)) setError(true);
    else setError(false);
    setEmail(e.target.value);
  };

  return (
    <Box sx={{ width: "270px", mr: 1, mb: 1 }}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#26387A" }}>
              {`${user.FirstName.charAt(0)}${user.LastName.charAt(0)}`}
            </Avatar>
          }
          action={
            <IconButton onClick={edit ? handleSave : handleEdit}>
              {edit ? <Save /> : <Edit />}
            </IconButton>
          }
          title={user.FullName}
          subheader={!edit && (user.Email || "No email assigned.")}
          titleTypographyProps={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "155px",
            noWrap: true,
          }}
          subheaderTypographyProps={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "155px",
          }}
        />
        {edit && (
          <CardContent>
            <TextField
              size="small"
              variant="standard"
              placeholder="Email"
              fullWidth
              error={error}
              helperText={error && `Please provide a valid email address`}
              autoComplete="off"
              onChange={handleEmailChange}
              value={email || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmail fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default UserCard;
