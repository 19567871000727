import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import BoxWithHeader from 'components/BoxWithHeader'
import CheckIcon from 'assets/iconsLibrary/CheckIcon'
import XIcon from 'assets/iconsLibrary/XIcon'
import InspectionServicesLine from 'components/InspectionServicesLine'
import InspectionServicesRecommendationsLine from 'components/InspectionServicesRecommendationsLine'

const InspectionServices = ({
    concernsArray,
    recosArray
}) => {  
    const concerns = concernsArray.map(concern => 
        <InspectionServicesLine
            key={concern.id}
            {...concern}
        ></InspectionServicesLine>
    )

    const recos = recosArray.map(reco => 
        <InspectionServicesRecommendationsLine
            key={reco.id}
            {...reco}
        ></InspectionServicesRecommendationsLine>
    )

    const content = <div>
        <div className={styles.subTitle}>Customer Initial Concerns</div>
        <div className={`${styles.grid} ${styles.topGrid}`}>
            <div className={styles.gridHeader}>DMS</div>
            <div className={`${styles.gridHeader} ${styles.iconsWrapper}`}>
                <div className={styles.checkIconWrapper}>
                    <CheckIcon></CheckIcon>
                </div>
                <div className={styles.xIconWrapper}>
                    <XIcon></XIcon>
                </div>
            </div>
            <div className={styles.gridHeader}>Done</div>
            <div className={styles.gridHeader}>Name</div>
            <div className={styles.gridHeader}>SVC Type</div>
            <div className={styles.gridHeader}>Hours</div>
            <div className={styles.gridHeader}>Labor</div>
            <div className={styles.gridHeader}>Parts</div>
            <div className={styles.gridHeader}>Total</div>
            {concerns}
        </div>
        <div className={styles.subTitle}>Additional Service Recommendations</div>
        <div className={`${styles.grid} ${styles.bottomGrid}`}>
            <div className={styles.gridHeader}>DMS</div>
            <div className={`${styles.gridHeader} ${styles.iconsWrapper}`}>
                <div className={styles.checkIconWrapper}>
                    <CheckIcon></CheckIcon>
                </div>
                <div className={styles.xIconWrapper}>
                    <XIcon></XIcon>
                </div>
            </div>
            <div className={styles.gridHeader}>Done</div>
            <div className={styles.gridHeader}>Color</div>
            <div className={styles.gridHeader}>Name</div>
            <div className={styles.gridHeader}>Info</div>
            <div className={styles.gridHeader}>SVC Type</div>
            <div className={styles.gridHeader}>Pay Type</div>
            <div className={styles.gridHeader}>No P&A</div>
            <div className={styles.gridHeader}>Hours</div>
            <div className={styles.gridHeader}>Labor</div>
            <div className={styles.gridHeader}>Parts</div>
            <div className={styles.gridHeader}>Total</div>
            <div className={styles.gridHeader}>Added By</div>
            <div className={styles.gridHeader}>Actions</div>
            {recos}
        </div>
    </div>

    return (
        <BoxWithHeader
            titleText={`Services`}
            content={content}
            containerClasses={styles.container}
        ></BoxWithHeader>
    )
}

InspectionServices.propTypes = {
    concernsArray: PropTypes.array,
    recosArray: PropTypes.array
}

export default InspectionServices