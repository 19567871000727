import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from 'rootReducer'
import RepairOrderAlert, { getROAlerts, updateROAlert } from 'services/RepairOrderAlert'
import { AppThunk } from 'store'
import { IError } from 'types/IError'

const roAlertsAdapter = createEntityAdapter<RepairOrderAlert>()

interface IRoAlertState extends IError { }

const initialState: IRoAlertState = {
    hasError: false,
    error: ``
}

const slice = createSlice({
    name: `roAlerts`,
    initialState: roAlertsAdapter.getInitialState(initialState),
    reducers: {
        roAlertsReceived: roAlertsAdapter.setAll,
        setError(state, action: PayloadAction<IError>) {

            const {
                hasError,
                error
            } = action.payload

            state.hasError = hasError
            state.error = error

        },
        acknowledgeRoAlertError(state) {

            state.hasError = false
            state.error = ``

        }
    }
})

export const {
    roAlertsReceived,
    setError,
    acknowledgeRoAlertError
} = slice.actions

export const roAlertSelectors = roAlertsAdapter.getSelectors<RootState>(state => state.roAlerts)

export default slice.reducer

export const getRepairOrderAlerts = (userID: number): AppThunk => async (dispatch, getState) => {
    
    try {
        
        if(userID > 0) {
            
            const res = await getROAlerts(userID)
            
            const alerts = res.filter(alert => !alert.acknowledged)
            
            dispatch(roAlertsReceived(alerts))

        }
    
    } catch (error) {
    
        dispatch(setError({
            hasError: true,
            error: `An error has occurred while attempting to get repair order alerts: ${error}.`
        }))
        
    }

}

export const updateRepairOrderAlert = (id: number, payload: Partial<RepairOrderAlert>): AppThunk => async (dispatch, getState) => {

    try {
    
        const alert = getState().roAlerts.entities[id]
    
        if (alert === undefined) {
    
            throw new Error(`Alert is undefined!`)
    
        }
    
        await updateROAlert({
            ...alert,
            ...payload,
        })
    
    } catch (error) {
    
        dispatch(setError({
            hasError: true,
            error: `An error occurred while attempting to update the alert: ${error}`
        }))
    
    }

}