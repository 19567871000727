import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const CheckIcon = ({
    color,
    height,
    width,
    rotation,
    className
}) => {  
    const containerClassNames = classNames(
        `icon-container`,
        {[className]: className}
    )

    return (
        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={containerClassNames} style={{
            height,
            width,
            transform: `translate(-50%, -50%) rotate(${rotation}deg)`
        }}>
            <path fill={color} d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path>
        </svg>
    )
}

CheckIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    rotation: PropTypes.number,
    className: PropTypes.string
}

CheckIcon.defaultProps = {
    color: `white`,
    height: 13,
    width: 13,
    rotation: -8
}

export default CheckIcon