import React, { useMemo } from 'react'
import {
    Badge,
    Fab,
    Modal,
} from '@mui/material'
import { Close, Edit } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from 'store'
import { chatClosed, chatOpened, unreadMessageCountSelectors } from './modules'
import BaseView from './components/BaseView'
import useInitializeChat from './hooks/useInitializeChat'

function ChatModule() {
    const dispatch = useAppDispatch()
    const {
        isOpen,
    } = useAppSelector(state => state.chat)
    const unreadCounts = useAppSelector(unreadMessageCountSelectors.selectAll)

    const handleClick = () => {
        dispatch(isOpen ? chatClosed() : chatOpened())
    }

    useInitializeChat()

    const totalUnreadMessagesCount = useMemo(() => {
        return unreadCounts.reduce((currentTotal, item) => currentTotal + item.count, 0)
    },[unreadCounts])

    return (
        <>
            <Fab
                onClick={handleClick}
                color={`primary`}
                style={{
                    position: `fixed`,
                    bottom: `23px`,
                    right: `30px`,
                }}
            >
                <Badge
                    badgeContent={totalUnreadMessagesCount}
                    max={99}
                    color={`error`}
                    overlap={`circular`}
                    style={{
                        display: 'contents'
                    }}>
                    
                    {isOpen ? <Close /> : <Edit />}
                </Badge>
            </Fab>
            
            <Modal
                open={isOpen}
                hideBackdrop
                style={{
                    height: 0,
                    width: 0,
                }}
            >
                <div>
                    <BaseView/>
                </div>
            </Modal>
        </>
    )
}

export default ChatModule