import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnInstance, TableInstance } from 'react-table'
import { useAppSelector } from 'store'
import FilterChip from 'componentsUI/TableView/components/FilterChip'
import LocalFilterStorage, { IRepairOrderFilterState } from 'components/RepairOrderListView/helpers/filterLocalStorage'
import { Divider } from '@mui/material'

export interface IAdvisorFilter {
    tableInstance: TableInstance,
    column: ColumnInstance,
}

function AdvisorFilter({
    column,
}: IAdvisorFilter) {
    const userID: number = useAppSelector(state => state.user.ID)
    const [filters, setFilters] = useState<IRepairOrderFilterState>(LocalFilterStorage.readStorage())
    const setTableFilter = useMemo(() => column.setFilter,[column])

    const handleClick = useCallback(() => {

        const updatedFilters = {
            ...LocalFilterStorage.readStorage(),
            displayAdvisor: !filters.displayAdvisor
        }

        LocalFilterStorage.saveToStorage(updatedFilters)

        setFilters(updatedFilters)

    },[setFilters, filters])

    useEffect(() => {

        setTableFilter(!filters.displayAdvisor ? 0 : userID)

    }, [setTableFilter, userID, filters.displayAdvisor])

    return (
        (userID !== 0 ? 
            <>
                <FilterChip
                    key={`showMyFilterChip`}
                    text={`Show my ROs`}
                    isActive={filters.displayAdvisor}
                    onClick={handleClick}
                ></FilterChip>
                <Divider orientation="vertical" variant="middle" flexItem />
            </> : null)
    )
}

export default React.memo(AdvisorFilter)