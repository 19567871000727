import React from 'react'
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { ILoginFormProps } from 'components/Login'
import { FieldError, UseFormRegister } from 'react-hook-form'

interface IUsernameFieldProps {
    register: UseFormRegister<ILoginFormProps>
    error: FieldError | undefined
}

function UsernameField({
    register,
    error
}: IUsernameFieldProps) {
    
    return (
        <FormControl>
            <InputLabel>Email</InputLabel>
            <OutlinedInput
                {...register(`email`, { required: true})}
                size={`small`}
                error={!!error}
                startAdornment={
                    <InputAdornment position="start">
                        <AccountCircle />
                    </InputAdornment>
                }
                label={`Email`}
                placeholder='Email'
            ></OutlinedInput>
        </FormControl>
        
    )
}

export default UsernameField