import React from 'react'
import { IconButton } from '@mui/material'
import AlarmIcon from '@mui/icons-material/Alarm'
import RepairOrderAlertAddEditDialog from 'components/RepairOrderAlertAddEditDialog'
import { useAppDispatch } from 'store'
import { closed, open } from 'components/RepairOrderAlertAddEditDialog/modules'

export interface IAlertTool {
    roID: number
    onClick(): any 
}

function AlertTool({
    roID,
    onClick,
}: IAlertTool) {

    const dispatch = useAppDispatch()

    const handleClick = () => {

        dispatch(open(roID))

    }
    
    const handleClose = () => {

        dispatch(closed())

        onClick()

    }
    
    return (
        <>
            <IconButton
                onClick={handleClick}
                size={`small`}
            >
                <AlarmIcon
                ></AlarmIcon>
            </IconButton>

            <RepairOrderAlertAddEditDialog
                repairOrderID={roID}
                onClose={handleClose}
            ></RepairOrderAlertAddEditDialog>
        </>
    )
}

export default React.memo(AlertTool)