import { IStockMessage } from 'types/stockMessage'

export interface StockMessage {
    ID: number
    Name: string
    Description: string
}

export function mapToStockMessage({
    ID,
    Name,
    Description,
}: StockMessage): IStockMessage {
    return {
        id: ID,
        name: Name,
        description: Description,
    }
}