import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import * as signalr from 'signalr'
import { setSignalrStatus } from 'modules/app'
import { Status } from 'types/signalr'
import AppError, { Errors } from 'errors'

function useSignalr() {
    const dispatch = useAppDispatch()
    const {
        userIsLoggedIn: isAuthenticated,
    } = useAppSelector(state => state.auth)

    const token = localStorage.getItem(`crmToken`) || ``

    useEffect(() => {
        if (isAuthenticated) {
            const connect = (token: string) => {
                signalr.connection.qs = { Bearer: token }

                signalr.connection.start()
                    .done(() => {
                        console.log(`Signalr Connected`)
                        dispatch(setSignalrStatus(Status.CONNECTED))
                    })
                    .fail(err => {
                        throw new AppError(err, Errors.CRM0002)
                    })
            }

            connect(token)

            signalr.connection.starting(() => dispatch(setSignalrStatus(Status.CONNECTING)))

            signalr.connection.disconnected(() => {
                console.warn('HUB DISCONNECTED!')
                dispatch(setSignalrStatus(Status.DISCONNECTED))
                setTimeout(() => {
                    connect(token)
                }, 5000)
            })
        } else {
            signalr.connection.stop()
        }
    }, [isAuthenticated, token, dispatch])
}

export default useSignalr