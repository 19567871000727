import { CSIAlertReportingOverviewViewModel } from "api/types/reporting/CSIAlertReportingOverviewViewModel";
import React from "react";
import { ILoading } from "../../Interfaces/ILoading";
import ReportingContainer from "../../ReportingContainer";
import CsiAlertDataGrid from "../CsiAlertDataGrid";
import CsiAlertSummerSection from "../CsiAlertSummerySection";

export interface ICsiAlertOverviewProps extends ILoading {
    data: CSIAlertReportingOverviewViewModel
}

export default function CsiAlertOverview({
    data,
    isLoading,
}: ICsiAlertOverviewProps) {
    return (
        <ReportingContainer
            title={`Advisor Contact Reminder`}
            isLoading={isLoading}
            viewDetails={true}
            viewDetailsEnabled={false}>

                <CsiAlertSummerSection
                    data={data}/>
                
                <CsiAlertDataGrid
                    data={data}/>

        </ReportingContainer>
    )
}