import React from 'react'
import { Box, Divider, Paper, Typography } from '@mui/material'
import theme from 'assets/theme'

interface Props {
    text: string
    time: string
    alignRight?: boolean
}

function MessageBox({
    text,
    time,
    alignRight,
}: Props) {
    const colors = {
        blue: theme.palette.primary.light,
        white: theme.palette.primary.contrastText,
        grey: theme.palette.grey[100],
    }

    return (
        <Paper
            variant={`outlined`}
            style={{
                width: `80%`,
                backgroundColor: alignRight ? colors.blue : colors.grey,
                padding: theme.spacing(1),
                justifySelf: alignRight ? `end` : `start`,
            }}
        >
            <Box color={alignRight ? colors.white : colors.blue}>
                <Typography 
                    variant={`body2`} 
                    gutterBottom
                >{text}</Typography>
                <Divider style={{
                    backgroundColor: alignRight ? colors.white : colors.blue,
                }} />
                <Typography 
                    variant={`caption`}
                    display={`block`}
                    align={alignRight ? `right` : `left`}
                >{time}</Typography>
            </Box>
        </Paper>
    )
}

export default MessageBox