import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles.module.scss'
import classnames from 'classnames';

export interface IPerformanceSectionProps {
    maxValue: number,
    actualValue: number,
    label: string,
    content: string,
    color: string
}

export default function PerformanceSection({
    maxValue,
    actualValue,
    label,
    content,
    color
}: IPerformanceSectionProps){

    const containsData = maxValue > 0

    if (!containsData) {
        return null;
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color
        },
    }));
    
    const ratioOfApproved = (actualValue / maxValue) * 100

    return (
        <Box className={classnames(styles.flexContainer, styles.wrapper)}>
            <Box className={classnames(styles.flexContainer, styles.labelSection)}>
                <Typography variant='overline'>{label}</Typography>
                <Typography variant='overline'>{content}</Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={ratioOfApproved} />
            </Box>
        </Box>
    );
}