import React, { useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import ReactPlayer from 'react-player'
import theme from 'assets/theme'
import { BaseVideo } from 'api/types/video'

export interface IVideoPopup {
    onClose(): void
    videos: BaseVideo[]
}

function VideoPopup({
    onClose,
    videos,
}: IVideoPopup) {
    const [index, setIndex] = useState<number>(0)
    const video = videos[index]

    const handleContinue = () => {
        if (index + 1 < videos.length) {
            setIndex(index + 1)
        } else {
            onClose()
        }
    }

    const player = <ReactPlayer
        controls
        url={[
            {src: `${process.env.REACT_APP_API_URL}/api/videos/${video.ID}/play?viewKey=${video.ViewKey}&format=.mp4`, type: `video/mp4`},
            {src: `${process.env.REACT_APP_API_URL}/api/videos/${video.ID}/play?viewKey=${video.ViewKey}&format=.webm`, type: `video/webm`}
        ]}
        width={`100%`}
        height={`100%`}
        style={{
            position: `absolute`,
            top: 0,
            left: 0,
        }}
    ></ReactPlayer>

    const loadingContent = <Box
        display={`grid`}
        justifyItems={`center`}
        alignContent={`center`}
        gap={1}
        position={`absolute`}
        top={0}
        left={0}
        bottom={0}
        right= {0}
        bgcolor={theme.palette.grey[200]}
    >
        <CircularProgress/>
        <Typography>Processing...</Typography>
    </Box>

    const buttonText = index + 1 === videos.length ? `Close` : `View Next Video`

    return (
        <Dialog
            onClose={onClose}
            open
            fullWidth
        >
            <DialogTitle>Video ({index + 1} of {videos.length})</DialogTitle>
            <DialogContent>
                <Box
                    position={`relative`}
                    pt={`56.25%`}
                >{video.Transcoded ? player : loadingContent}</Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={`contained`}
                    color={`primary`}
                    onClick={handleContinue}
                >{buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(VideoPopup)