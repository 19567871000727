import { Dealer } from "api/types/dealer";
import apiFetch from "api/utils/APIFetch";

const postDealer = (dealer: Dealer): Promise<void> =>
  apiFetch(
    dealer.ID === undefined || dealer.ID === 0
      ? `/api/dealers`
      : `/api/dealers/${dealer.ID}`,
    {
      method: dealer.ID === undefined || dealer.ID === 0 ? `POST` : `PUT`,
      body: JSON.stringify(dealer),
    }
  );

export default postDealer;
