export default function RMAPIFetch({ urlTail = ``, options = {} }: { urlTail?: string, options?: object } = {}): Promise<any> {
    const url: string = `${process.env.REACT_APP_RM_API_URL}${urlTail}`;
    
    return fetch(url, {
        headers: {
            'Content-Type': `application/json`
        },
        ...options
    })
    .then(res => {
        let contentType: string = res.headers.get(`content-type`) || ``;
        if (res.status === 204 && contentType === `` ) {
            return true;
        } else if (contentType.includes(`application/json`)) {
            if (res.status === 400 || res.status === 404 || res.status === 500) {
                return res.json().then(payload => {
                    return Promise.reject(payload.Message);
                });
            } else {
                return res.json();
            }
        }
    })
    .catch(error => {
        //this will only fire on network failure or if anything prevented the request from completing
        return Promise.reject(error); 
    });
}