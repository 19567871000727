import React from 'react'
import {
    Badge,
    BadgeProps,
    Box,
    IconButton,
    IconButtonProps,
    SvgIconProps,
    Tooltip,
} from '@mui/material'

export interface IColumnIcon {
    tooltipText: string
    badgeProps?: BadgeProps
    Icon?(props: SvgIconProps): React.ReactElement<SvgIconProps>
    isButton?: boolean
    buttonProps?: IconButtonProps
    color?: string
    disabled?: boolean
}

function ColumnIcon({
    tooltipText,
    badgeProps = { invisible: true },
    Icon,
    isButton = false,
    buttonProps,
    color = `#636363`,
    disabled = false,
}: IColumnIcon) {
    const iconColor = disabled ? `#e0e0e0` : color
    let icon = Icon !== undefined ? <Badge {...badgeProps}><Icon htmlColor={iconColor} /></Badge> : null
    const button = <IconButton size={`small`} {...buttonProps}>{icon}</IconButton>
    const content = isButton ? button : icon

    if (disabled) {
        return content
    }

    return (
        <Tooltip
            title={tooltipText}
            style={{
                display: `grid`,
                justifyContent: `center`,
                alignItems: `center`,
            }}
        >
            <Box
                display={`grid`}
                justifyContent={`center`}
            >{content}</Box>
        </Tooltip>
    )
}

export default React.memo(ColumnIcon)