import { Views, viewSet } from 'components/Dashboard/modules'
import pages from 'pages'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store'

function useManageViews() {
    const dispatch = useAppDispatch()
    const pathname = useLocation().pathname
    const view = useAppSelector(state => state.dashboard.view)
    
    useEffect(() => {
        const getNextView = () => {
            switch (pathname) {
                case pages.SENT_MESSAGES.route:
                    return Views.SENT

                case pages.RECIEVED_MESSAGES.route:
                    return Views.RECEIVED

                case pages.UNANSWERED_MESSAGES.route:
                    return Views.UNANSWERED

                case pages.AUTOMATED_MESSAGES.route:
                    return Views.AUTOMATED

                case pages.REPAIR_ORDERS.route:
                    return Views.REPAIR_ORDERS
            
                default:
                    return Views.NONE
            }
        }

        dispatch(viewSet(getNextView()))
    }, [pathname, view, dispatch])
}

export default useManageViews