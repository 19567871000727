import moment from 'moment'
import Base from 'classes/Base'
import APIFetch from 'api/utils/APIFetch'

export enum StatusTypes {
    NO_ANSWER,
    LEFT_MESSAGE,
    LEFT_VOICEMAIL,
    CUSTOMER_REQ_CALL,
    APPOINTMENT_MADE,
}

export enum SourceTypes {
    APPOINTMENT,
    REPAIR_ORDER,
    NONE
}

export default class CallGroup extends Base {
    id: number | null = null
    customerID: number | null = null
    sourceType: SourceTypes = SourceTypes.NONE
    sourceTypeID: number | null = null
    noAnswer: boolean  = false
    leftMessage: boolean = false
    leftVoicemail: boolean = false
    custReqCall: boolean = false
    apptMade: boolean = false
    lastCall: string = ``
    notes: string = ``
    timeStamp: string = ``
    numberOfCallAttempts: number = 0

    constructor(init?: Partial<CallGroup>) {
        super()
        Object.assign(this, init)
        this.calculateNumberOfCallAttempts()
    }

    getStatuses() {
        return {
            noAnswer: this.noAnswer,
            leftMessage: this.leftMessage,
            leftVoicemail: this.leftVoicemail,
            custReqCall: this.custReqCall,
            apptMade: this.apptMade
        }
    }

    mapFromAPI(raw) {      
        return new CallGroup({
            id: raw.CallGroupID,
            customerID: raw.CustomerID,
            sourceType: raw.SourceType,
            sourceTypeID: raw.ID,
            lastCall: raw.LastCall === null ? `` : raw.LastCall,
            noAnswer: raw.IsNoAnswer,
            leftMessage: raw.IsLeftMessage,
            leftVoicemail: raw.IsLeftVoicemail,
            custReqCall: raw.IsCustomerRequiredCall,
            apptMade: raw.IsAppointmentMade,
            notes: raw.Notes === null ? `` : raw.Notes,
        }).toPlainObj()
    }

    calculateNumberOfCallAttempts() {
        const status = this.getStatuses()
        let count = 0
        
        Object.values(status).map(val => {
            if(val) count ++
            return null
        })

        this.numberOfCallAttempts = count
    }

    updateCreateViaAPI() {
        if(this.id) {
            return this.updateViaAPI()
        } else {
            return this.createNewViaAPI()
        } 
    }

    async updateViaAPI() {
        return await APIFetch(`/api/call-group/update`, {
            method: `POST`,
            body: JSON.stringify({
                ID: this.id,
                CustomerID: this.customerID,
                IsNoAnswer: this.noAnswer,
                IsCustomerRequiredCall: this.custReqCall,
                IsLeftMessage: this.leftMessage,
                IsAppointmentMade: this.apptMade,
                IsLeftVoicemail: this.leftVoicemail,
                Notes: this.notes,
                TimeStamp: moment(this.timeStamp).utc().format()
            })
        })
    }

    async createNewViaAPI() {
        return await APIFetch(`/api/bdc/call-group`, {
            method: `POST`,
            body: JSON.stringify({
                CallGroup: {
                    ID: this.id,
                    CustomerID: this.customerID,
                    IsNoAnswer: this.noAnswer,
                    IsCustomerRequiredCall: this.custReqCall,
                    IsLeftMessage: this.leftMessage,
                    IsAppointmentMade: this.apptMade,
                    IsLeftVoicemail: this.leftVoicemail,
                    Notes: this.notes,
                    TimeStamp: moment(this.timeStamp).utc().format()
                },
                SourceType: this.sourceType,
                TypeID: this.sourceTypeID
            })
        })
    }

    formatLastCall(dateTime: string) {
        if (dateTime === ``) {
            return ``
        } else {
            return moment(dateTime).format(`MM/DD/YYYY h:mm a`)
        }
    }
}