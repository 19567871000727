import apiFetch from 'api/utils/APIFetch'

const createChatConversation = (
    dealerID: number,
    repairOrderID: number, 
    userIDs: number[],
): Promise<number> => apiFetch(`/api/chat/${dealerID}/conversations`, {
    method: `POST`,
    body: JSON.stringify({
        RepairOrderID: repairOrderID,
        UserIDs: userIDs,
    })
}).catch(error => {
    throw new Error(error.Message)
})

export default createChatConversation