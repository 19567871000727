import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import getRepairOrderByID from 'api/getRepairOrderByID'
import setRepairOrderAttentionNeeded from 'api/setRepairOrderAttentionNeeded'
import { BaseCustomer } from 'api/types/customer'
import apiFetch from 'api/utils/APIFetch'
import { updateRepairOrder } from 'components/RepairOrderListView/modules'
import { AppThunk } from 'store'
import { EstimateStatus, MpviStatus } from 'types/repairOrder'
import { titleCase } from 'voca'

export interface IOpenPayload {
    repairOrderID: number
    roNumber: string
    mpviStatus: MpviStatus
    workbenchURL: string
    customer: BaseCustomer
    estimateKey: string | null
    estimateStatus: EstimateStatus
}

export interface IEstimateDialog {
    repairOrderID: number | undefined
    roNumber: string
    mpviStatus: MpviStatus
    customerName: string
    workbenchURL: string
    isLoading: boolean
    isSending: boolean
    isOpen: boolean
    isError: boolean
    infoText: string
    estimateKey: string | null
    estimateStatus: EstimateStatus
}

let initialState: IEstimateDialog = {
    isLoading: false,
    isSending: false,
    isOpen: false,
    isError: false,
    infoText: ``,
    repairOrderID: undefined,
    roNumber: ``,
    customerName: ``,
    mpviStatus: MpviStatus.UNKNOWN,
    workbenchURL: ``,
    estimateKey: ``,
    estimateStatus: EstimateStatus.PENDING_ESTIMATE
}

const slice = createSlice({
    name: `estimateDialog`,
    initialState,
    reducers: {
        estimateDialogOpened(state, action: PayloadAction<IOpenPayload>) {
            const {
                repairOrderID,
                roNumber,
                mpviStatus,
                workbenchURL,
                customer,
                estimateKey,
                estimateStatus
            } = action.payload

            state.estimateStatus = estimateStatus
            state.estimateKey = estimateKey
            state.customerName = titleCase(`${customer.FullName}`)
            state.repairOrderID = repairOrderID
            state.roNumber = roNumber
            state.mpviStatus = mpviStatus
            state.workbenchURL = workbenchURL
            state.isOpen = true
        },
        sendTextStart(state) {
            state.isSending = true
        },
        sendTextFinish: () => initialState,
        sendTextFailure(state, action: PayloadAction<string>) {
            state.isSending = false
            state.isError = true
            state.infoText = action.payload
        },
        resetEstimateDialog: () => initialState,
    }
})

export const {
    estimateDialogOpened,
    sendTextStart,
    sendTextFinish,
    sendTextFailure,
    resetEstimateDialog,
} = slice.actions

export default slice.reducer

export const openEstimateDialog = (repairOrderID: number): AppThunk => async dispatch => {
    try {
        const repairOrder = await getRepairOrderByID(repairOrderID)

        if(repairOrder === null)
            throw new Error("RO $`{repairOrderID}` could not be found.")

        dispatch(estimateDialogOpened({
            repairOrderID,
            roNumber: repairOrder.RONumber,
            mpviStatus: repairOrder.MPVIStatus,
            workbenchURL: repairOrder.WorkbenchUrl,
            customer: repairOrder.Customer,
            estimateKey: repairOrder.EstimateKey,
            estimateStatus: repairOrder.EstimateStatus,
        }))
    } catch (error) {
        throw new Error(`The following error occured while trying to open EstimateDialog: ${error}`)
    }
}

export const sendEstimateLink = (repairOrderID: number, dealerID: number): AppThunk => async dispatch => {
    try {
        dispatch(sendTextStart())
        await apiFetch(`/api/messages/send-estimate-text`, {
            method: `POST`,
            body: JSON.stringify({
                RepairOrderID: repairOrderID,
                DealerID: dealerID
            })
        })
        await setRepairOrderAttentionNeeded(repairOrderID, false)
        dispatch(updateRepairOrder({
            id: repairOrderID,
            changes: {
                AttentionNeeded: false,
            },
        }))
        dispatch(sendTextFinish())
    } catch (err) {
        let errorMessage = `Unexpected Error!`

        if (typeof err === `object` && err) {
            if (err.hasOwnProperty(`Message`)) {
                errorMessage = err[`Message`]
            }
        }

        dispatch(sendTextFailure(errorMessage))
    }
}