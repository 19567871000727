import { createAsyncThunk } from '@reduxjs/toolkit'
import getCsiAlertReportingOverview from 'api/reporting/csiAlert/getCsiAlertReportingOverview'
import { CSIAlertReportingOverviewViewModel } from 'api/types/reporting/CSIAlertReportingOverviewViewModel'
import { IPayload } from '../interfaces/IPayload'

const getCsiAlertReportingOverviewData = createAsyncThunk(
    `reporting/getCsiAlertReportingOverviewData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<CSIAlertReportingOverviewViewModel> => {
        return await getCsiAlertReportingOverview(dealerID, startDate, endDate)
    }
)

export default getCsiAlertReportingOverviewData