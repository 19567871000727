import React, { useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardHeader,
} from '@mui/material'
import DeleteDialog from '../DeleteDialog'
import RepairOrderAlert from 'services/RepairOrderAlert'
import { useAppDispatch } from 'store'
import { enterUpdateMode } from 'components/RepairOrderAlertAddEditDialog/modules'
import { Modes } from 'components/RepairOrderAlertAddEditDialog/Modes'

export interface IAlertCard {
    alert: RepairOrderAlert
    onClose(): void
}

function AlertCard({
    alert,
    onClose,
}: IAlertCard) {
    const dispatch = useAppDispatch()
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)

    const timeFormatted = new Date(alert.time).toLocaleTimeString([], { hour: `numeric`, minute: `2-digit` })

    const handleEditClick = () => {

        dispatch(enterUpdateMode({
            alert: alert,
            mode: Modes.EDIT
        }))
    
    }

    return (
        <>
            <Card>
                <Box>
                    <CardHeader
                        title={alert.text}
                        titleTypographyProps={{
                            style: {
                                fontSize: 18
                            }
                        }}
                        subheader={timeFormatted}
                        style={{
                            paddingBottom: 0,
                        }}
                    ></CardHeader>
                    <CardActions>
                        <Button
                            size={`small`}
                            onClick={handleEditClick}
                        >Edit</Button>
                        <Button
                            size={`small`}
                            onClick={() => setDeleteDialogIsOpen(true)}
                        >Delete</Button>
                    </CardActions>
                </Box>
            </Card>
            <DeleteDialog
                id={alert.id}
                onClose={onClose}
                onCancel={() => setDeleteDialogIsOpen(false)}
                isOpen={deleteDialogIsOpen}
            ></DeleteDialog>
        </>
    )
}

export default AlertCard