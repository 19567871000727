import { InteractiveMaintenanceReportingOverviewViewModel } from "api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel";
import React from "react";
import { ILoading } from "../../Interfaces/ILoading";
import ReportingContainer from "../../ReportingContainer";
import InteractiveMaintenanceDetailsAccordion from "../InteractiveMaintenanceDetailsAccordian";
import PerformanceSection from "../../PerformanceSection";
import theme from "assets/theme";
import InteractiveMaintenanceSummerySection from "../InteractiveMaintenanceSummerySection";
import PerformanceSectionContainer from "../../PerformanceSectionContainer";
import InteractiveMaintenanceUserStatsDataGrid from "../InteractiveMaintenanceUserStatsDataGrid";

export interface IInteractiveMaintenanceOverviewProps extends ILoading{
    data: InteractiveMaintenanceReportingOverviewViewModel
}

export default function InteractiveMaintenanceOverview({
    data,
    isLoading
}: IInteractiveMaintenanceOverviewProps) {
    return (
        <ReportingContainer
            title={`Interactive Maintenance`}
            isLoading={isLoading}
            viewDetails={true}
            viewDetailsEnabled={false}>
                
            <InteractiveMaintenanceSummerySection
                data={data}/>

            <PerformanceSectionContainer>
                <PerformanceSection
                    maxValue={data.TotalPresentations}
                    actualValue={data.TotalSubmissions}
                    label={`Presentations vs Check-Ins`}
                    content={`${data.TotalSubmissions} / ${data.TotalPresentations}`}
                    color={theme.palette.primary.light}/>
            </PerformanceSectionContainer>

            <InteractiveMaintenanceUserStatsDataGrid
                data={data.ByUser}/>
            
            <InteractiveMaintenanceDetailsAccordion
                data={data}/>

        </ReportingContainer>
    )
}