import React, { useState, useEffect } from 'react'
import { Box, IconButton, InputAdornment, Snackbar, SnackbarCloseReason, TextField, useMediaQuery } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { Alert } from '@mui/material';
import StockMessageSelect from '../StockMessageSelect'
import { IStockMessage } from 'types/stockMessage'
import { Clear } from '@mui/icons-material'
import { sendDefault } from 'api/types/message'
import styles from './styles.module.scss'

type ISendForm = {
    customerID: number
    dealerID: number
    repairOrderID: number | null
    repairOrderAlertID: number | null
}

function SendForm({
    customerID,
    dealerID,
    repairOrderID,
    repairOrderAlertID
}: ISendForm) {
    const [value, setValue] = useState<string>(``)
    const [hasError, setHasError] = useState<boolean>(false)
    const [isSending, setIsSending] = useState<boolean>(false)
    const [rowCount, setRowCount] = useState<number>(3)

    const matches = useMediaQuery(`(min-height: 467px)`)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setHasError(false)
        setIsSending(true)

        try {
           await sendDefault({
               CustomerID: customerID,
               MessageBody: value,
               RepairOrderID: repairOrderID,
               DealerID: dealerID,
               RepairOrderAlertID: repairOrderAlertID
           })

            setValue(``)
        } catch (error) {
            setHasError(true)
        } finally {
            setIsSending(false)
        }
    }

    const handleStockMessageSelect = (message: IStockMessage) => {
        setValue(message.description)
    }

    const handleClose = (_, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return
        }
        setHasError(false)
    }

    useEffect(() => {

        setRowCount(!matches ? 2  : 3)
        
    }, [matches])

    const stockMessagingContent = !!repairOrderID && repairOrderID > 0 ?
        <StockMessageSelect
            disabled={value !== ``}
            onChange={handleStockMessageSelect}
            repairOrderID={repairOrderID}
        ></StockMessageSelect> : <div></div>

    return (
        <React.Fragment>
            <Box className={styles.messageSendFormContainer}>
                <Box className={styles.messageSendFormContent}>

                    {stockMessagingContent}

                    <TextField
                        id="standard-multiline-flexible"
                        className={styles.messageInput}
                        value={value}
                        size={`small`}
                        onChange={(e) => setValue(e.target.value)}
                        multiline
                        maxRows={rowCount}
                        variant={`outlined`}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <InputAdornment position={`end`}>
                                        <IconButton
                                            size={`small`}
                                            onClick={() => setValue(``)}
                                        >
                                            <Clear fontSize={`small`} />
                                        </IconButton>
                                    </InputAdornment>
                                    <InputAdornment position={`end`}>
                                        <IconButton
                                            size={`small`}
                                            onClick={handleSubmit}
                                            disabled={value === `` || isSending}
                                            color={`primary`}>
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                </React.Fragment>
                            ),
                            style: {
                                height: `100%`,
                            }
                        }}
                    ></TextField>
                </Box>
            </Box>
            <Snackbar 
                open={hasError}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert 
                    severity={`error`}
                    onClose={handleClose}
                    variant={`filled`}
                >Unable to send message. A server error has occured.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default SendForm