import { createAsyncThunk } from '@reduxjs/toolkit'
import getDealerAppointmentsByDateRange from 'api/scheduling/getDealerAppointmentsByDateRange'
import { Appointment } from 'api/types/appointment'
import { IGetAppointmentsPayload } from '../interfaces/IGetAppointmentsPayload'

const getDealerAppointmentsByDateRangeData = createAsyncThunk(
    `scheduling/getDealerAppointmentsByDateRange`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IGetAppointmentsPayload): Promise<Appointment[]> => {
        return await getDealerAppointmentsByDateRange(dealerID, startDate, endDate)
    }
)

export default getDealerAppointmentsByDateRangeData