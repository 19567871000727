import {
    createEntityAdapter,
    createSlice,
    current,
    EntityState,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { RootState } from 'rootReducer'
import {
    addPinnedItem,
    isItemPinned,
    orderRepairOrders,
    removePinnedItem
} from 'components/RepairOrderListView/helpers/pinnedItems'
import {
    getColorRow,
    RowColorEntry,
    setColorRow,
} from 'components/RepairOrderListView/helpers/rowColors'
import { BaseVideo } from 'api/types/video'
import { AppThunk } from 'store'
import { BaseCustomer } from 'api/types/customer'
import getRepairOrdersByDateRange from 'api/getRepairOrdersByDateRange'
import { mapToListRepairOrder, RepairOrder } from 'api/types/repairOrder'
import { IListRepairOrder } from 'components/RepairOrderListView/types/ListRepairOrder'
import { MpviStatus } from 'types/repairOrder'
import { BaseMessage, Message } from 'api/types/message'

const repairOrdersAdapter = createEntityAdapter<IListRepairOrder>({
    selectId: (listRepairOrder) => listRepairOrder.ID,
})
const customersAdapter = createEntityAdapter<BaseCustomer>({
    selectId: (customer) => customer.ID
})
const videosAdapter = createEntityAdapter<BaseVideo>({
    selectId: (video) => video.ID
})

export interface IUpdateMpviPayload {
    id: number
    mpviStatus: MpviStatus,
    attentionNeeded: boolean,
}

export interface IUpdateMessagesPayload {
    repairOrder: IListRepairOrder,
    message: Message
}

export interface IUpdateRepairOrderPinStatePayload {
    id: number,
    isPinned: boolean
}

interface IRepairOrderListView {
    isLoading: boolean
    isError: boolean
    infoText: string
    customers: EntityState<BaseCustomer>
    videos: EntityState<BaseVideo>
    pinStateChanged: boolean
    repairOrders: EntityState<IListRepairOrder>
}

let initialState: IRepairOrderListView = {
    isLoading: false,
    isError: false,
    infoText: `Initializing...`,
    customers: customersAdapter.getInitialState(),
    videos: videosAdapter.getInitialState(),
    pinStateChanged: false,
    repairOrders: repairOrdersAdapter.getInitialState()
}

const slice = createSlice({
    name: `repairOrderListView`,
    initialState: initialState,
    reducers: {
        getDataStart(state) {
            state.isLoading = true
            state.isError = false
            state.infoText = [...current(state.repairOrders.ids)].length === 0 ? `Retrieving repair orders...` : `Refreshing Data...`
            state.pinStateChanged = false
        },
        getDataSuccess(state, action: PayloadAction<RepairOrder[]>) {
            const rawRepairOrders = action.payload

            customersAdapter.upsertMany(state.customers, rawRepairOrders.map(ro => ro.Customer))
            videosAdapter.upsertMany(state.videos, rawRepairOrders.map(ro => ro.Videos ? ro.Videos : []).flat())

            const data = rawRepairOrders.map(mapToListRepairOrder)

            const modifiedData = data.map(ro => {
                ro.isPinned = isItemPinned(ro.ID)
                ro.rowColor = getColorRow(ro.ID)

                return ro
            })

            // clear all repair orders currently in the list.
            repairOrdersAdapter.removeAll(state.repairOrders)

            // get the desired display order
            const orderedRepairOrders = orderRepairOrders(modifiedData)

            //add the ROs not in state
            repairOrdersAdapter.upsertMany(state.repairOrders, orderedRepairOrders)

            state.isLoading = false
            state.isError = false
            state.infoText = ``
        },
        getDataFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        updateRepairOrderPinState(state, action: PayloadAction<IUpdateRepairOrderPinStatePayload>) {
            const {
                id,
                isPinned
            } = action.payload

            if(isPinned){
                addPinnedItem(id)
            } else {
                removePinnedItem(id)
            }

            repairOrdersAdapter.updateOne(state.repairOrders, {
                id,
                changes: {
                    isPinned
                }
            })

            state.pinStateChanged = true
        },
        setRowColor(state, action: PayloadAction<RowColorEntry>) {
            const entry = action.payload

            setColorRow(entry)

            repairOrdersAdapter.updateOne(state.repairOrders, {
                id: entry.id,
                changes: {
                    rowColor: entry.color
                }
            })
        },
        updateMpviStatus(state, action: PayloadAction<IUpdateMpviPayload>) {
            const {
                id,
                mpviStatus,
                attentionNeeded,
            } = action.payload

            repairOrdersAdapter.updateOne(state.repairOrders, {
                id,
                changes: {
                    MPVIStatus: mpviStatus,
                    AttentionNeeded: attentionNeeded,
                }
            })
        },
        updateRepairOrder(state, action: PayloadAction<Update<IListRepairOrder>>){

            repairOrdersAdapter.updateOne(state.repairOrders, action.payload)

        },
        videoUpserted(state, action: PayloadAction<BaseVideo>) {
            videosAdapter.upsertOne(state.videos, action.payload)
        },
        messageUpserted(state, action: PayloadAction<IUpdateMessagesPayload>){
            const {
                repairOrder,
                message
            } = action.payload

            const id = repairOrder.ID
            repairOrdersAdapter.updateOne(state.repairOrders, {
                id,
                changes:{
                    Messages: [
                        ...(repairOrder.Messages || []),
                        message as BaseMessage
                    ]
                }
            })
        },
        messagesUpserted(state, action: PayloadAction<Update<IListRepairOrder>[]>) {

            repairOrdersAdapter.updateMany(state.repairOrders, action.payload)

        },
        reorder(state){

            const repairOrders = sliceRepairOrderSelectors.selectAll(state.repairOrders)

            state.pinStateChanged = false

            repairOrdersAdapter.removeAll(state.repairOrders)

            const orderedRepairOrders = orderRepairOrders(repairOrders)

            repairOrdersAdapter.upsertMany(state.repairOrders, orderedRepairOrders)

        },
        reset(state) {
            repairOrdersAdapter.removeAll(state.repairOrders)
            customersAdapter.removeAll(state.customers)
            videosAdapter.removeAll(state.videos)

            state.isLoading = initialState.isLoading
            state.isError = initialState.isError
            state.infoText = initialState.infoText
            state.pinStateChanged = initialState.pinStateChanged
        },
    },
})

export const {
    getDataStart,
    getDataSuccess,
    getDataFailure,
    setIsLoading,
    updateRepairOrderPinState,
    setRowColor,
    updateRepairOrder,
    updateMpviStatus,
    videoUpserted,
    messageUpserted,
    messagesUpserted,
    reorder,
    reset,
} = slice.actions

const sliceRepairOrderSelectors = repairOrdersAdapter.getSelectors<EntityState<IListRepairOrder>>(state => state)

export const repairOrdersSelectors = repairOrdersAdapter.getSelectors<RootState>(state => state.repairOrderListView.repairOrders)
export const customerSelectors = customersAdapter.getSelectors<RootState>(state => state.repairOrderListView.customers)
export const videoSelectors = videosAdapter.getSelectors<RootState>(state => state.repairOrderListView.videos)

export default slice.reducer

export const getRepairOrders = (startDate: string, endDate: string, dealerID: number): AppThunk => async dispatch => {
    try {
        dispatch(getDataStart())
        const ros = await getRepairOrdersByDateRange(startDate, endDate, dealerID)

        dispatch(getDataSuccess(ros))
    } catch (error) {
        dispatch(getDataFailure(`There was an error retrieving repair orders: ${error}`))
    }
}