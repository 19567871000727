import { Grid } from "@mui/material"
import React, { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "store"
import setDates from "../../thunks/setDates"
import useSubscribeToSchedulingOverview from "components/Scheduling/hooks/useSubscribeToSchedulingOverview"
import moment from 'moment'
import { US_DATE_FORMAT } from "constants/date.constants"
import setTimePeriod from "components/Scheduling/thunks/setTimePeriod"
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

function SchedulingDashboard(){
    const dispatch = useAppDispatch()
    useSubscribeToSchedulingOverview()

    const {
        startDate,
        endDate,
        appointments,
        timePeriod
    } = useAppSelector(state => state.scheduling)

    const onTimeRangeApplied = useCallback((time) => {
        dispatch(setTimePeriod({ startDate: startDate, endDate: endDate, timePeriod: time}))
    }, [dispatch, startDate, endDate])

    const onDateRangeApplied = useCallback((date) => {
        var startMoment = moment(date)
        var endMoment = moment(date)

        if (timePeriod === "week") {
            startMoment = moment(date).day(0)
            endMoment = moment(date).day(6)
        }
        if (timePeriod === "month") {
            startMoment = moment(date).date(1)
            endMoment = moment(date).add(1, 'month').date(1).subtract(1, 'day')
        }

        dispatch(setDates({ startDate: startMoment.format(US_DATE_FORMAT), endDate: endMoment.format(US_DATE_FORMAT) }))
    }, [dispatch, timePeriod])

    const events = appointments.map(appt => {
        return {
            start: moment(appt.Date).toDate(),
            end: moment(appt.Date).add(15, "minutes").toDate(),
            title: `${appt.Lead.FirstName} ${appt.Lead.LastName} ${appt.Lead.Phone}`
        }
    })

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                <Calendar
                    localizer={localizer}
                    date={startDate}
                    min={new Date(1972, 0, 0, 7, 0, 0)}
                    max={new Date(2072, 0, 0, 19, 0, 0)}
                    step={15}
                    timeslots={2}
                    view={timePeriod}
                    events={events}
                    style={{ height: "100vh" }}
                    onNavigate={onDateRangeApplied}
                    onView={onTimeRangeApplied}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SchedulingDashboard