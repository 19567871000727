import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

interface IAuth {
    userIsLoggedIn: boolean
}

const initialState: IAuth = {
    userIsLoggedIn: false
}

const slice = createSlice({
    name: `auth`,
    initialState,
    reducers: {
        setUserIsLoggedIn(state, action: PayloadAction<boolean>) {
            state.userIsLoggedIn = action.payload
        },
    }
})

export const {
    setUserIsLoggedIn,
} = slice.actions

export default slice.reducer