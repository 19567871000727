import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import useSubscribeToMessages from 'components/CommunicationDashboard/hooks/useSubscribeToMessages'
import TableView from 'componentsUI/TableView'
import modes from 'components/CommunicationDashboard/modes'
import { messagesSelectors, setMode } from 'components/CommunicationDashboard/modules'
import ViewConversationCell from './components/ViewConversationCell'
import formatTime from './helpers/formatTime'
import AdvisorCell from './components/AdvisorCell'
import { getDisplayPhone } from 'api/types/customer'

interface ICommunicationDashboard {
    mode: modes
}

function CommunicationDashboard({
    mode
}: ICommunicationDashboard) {

    const dispatch = useAppDispatch()
    
    const messages = useAppSelector(messagesSelectors.selectAll)
    
    const {
        isLoading,
    } = useAppSelector(state => state.communicationDashboard)

    useSubscribeToMessages()

    useEffect(() => {

        dispatch(setMode(mode))

        return () => {

            dispatch(setMode(modes.NONE))

        }

    }, [mode, messages, dispatch])

    const schema = useMemo(
        () => [
            {
                Header: ``,
                accessor: `Customer.ID`,
                iconColumn: {
                    Icon: null,
                },
                disableGlobalFilter: true,
                Cell: ({ row }) => <ViewConversationCell
                    customer={row.original.Customer}
                    repairOrderID={row.original.RepairOrderID}
                ></ViewConversationCell>
            },
            {
                Header: 'Advisor Name',
                width: 1,
                Cell: ({row}) => <AdvisorCell
                    firstName={row.original.User.FirstName}
                    lastName={row.original.User.LastName}
                ></AdvisorCell>
            },
            {
                Header: 'Customer Name',
                accessor: 'Customer.FullName',
                width: 1,
            },
            {
                Header: 'Customer Contact',
                width: 1,
                Cell: ({row}) => getDisplayPhone(row.original.Customer)
            },
            {
                Header: 'Text',
                accessor: 'Body',
                width: 3,
                disableSortBy: true,
            },
            {
                Header: 'Sent At Raw',
                accessor: 'SentAt',
                disableGlobalFilter: true,
            },
            {
                Header: () => mode === modes.RECEIVED ? `Received At` : `Sent At`,
                id: 'sentAtFormatted',
                accessor: row => new Date(row.SentAt),
                Cell: ({ value }) => formatTime(value),
                sortType: `datetime`,
                width: 1,
                disableGlobalFilter: true,
            },
        ], [mode])

    const options = useMemo(() => ({
        autoResetFilters: false,
        autoResetSortBy: false,
        disableSortBy: true,
    }), [])

    const initialState = useMemo(() => ({
        hiddenColumns: [`SentAt`]
    }), [])

    return (
        <TableView
            data={messages}
            columns={schema}
            isLoading={isLoading}
            options={options}
            initialState={initialState}
            responsive
        ></TableView>
    )
}

export default React.memo(CommunicationDashboard)