import { Message } from 'api/types/message'
import Modes from 'components/CommunicationDashboard/modes'
import { getData, messageUpserted } from 'components/CommunicationDashboard/modules'
import useSubscribeToSignal from 'hooks/useSubscribeToSignal'
import { useCallback, useEffect } from 'react'
import { 
    useAppDispatch,
    useAppSelector
} from 'store'
import { Signal } from 'types/signalr'

function useSubscribeToMessages() {
    const dispatch = useAppDispatch()
    const {
        mode
    } = useAppSelector(state => state.communicationDashboard)

    const {
        startDate,
        endDate
    } = useAppSelector(state => state.dashboard)

    useEffect(() => {
        if (mode !== Modes.NONE) {
            dispatch(getData(mode))
        }
    }, [dispatch, mode, startDate, endDate])

    const upsertMessage = useCallback((message: Message) => {
        
        dispatch(messageUpserted(message))

    }, [dispatch])
    useSubscribeToSignal(Signal.MESSAGE_CREATED, upsertMessage)
    useSubscribeToSignal(Signal.MESSAGE_RECEIVED, upsertMessage)
}

export default useSubscribeToMessages