import { blue, green, orange, pink, purple, red, teal, yellow } from '@mui/material/colors'
import { Color } from 'components/RepairOrderListView/types/ListRepairOrder'

const name = `rowColorsCrmListView`

export const colors = {
    [Color.RED]: red[200],
    [Color.PURPLE]: purple[100],
    [Color.BLUE]: blue[100],
    [Color.GREEN]: green[100],
    [Color.YELLOW]: yellow[200],
    [Color.ORANGE]: orange[100],
    [Color.PINK]: pink[100],
    [Color.TEAL]: teal[100],
}

 export type RowColorEntry = {
    id: number,
    color: Color,
}

export function getColorRows(): Array<RowColorEntry> {
    return JSON.parse(localStorage.getItem(name) || `[]`)
}

export function setColorRows(items: Array<RowColorEntry>): void {
    localStorage.setItem(name, JSON.stringify(items))
}

export function getColorRow(id: number): Color {
    const items = getColorRows()
    const result = items.find(entry => entry.id === id)

    if (result !== undefined) {
        return result.color
    } else {
        return Color.NONE
    }
}

export function setColorRow(entry: RowColorEntry): void {
    if (entry.color === Color.NONE) {
        removeColorRow(entry.id)
    } else {
        addColorRow(entry)
    }
}

export function addColorRow(entry: RowColorEntry): void {
    const items = getColorRows()

    items.push(entry)

    setColorRows(items)
}

export function removeColorRow(id: number): void {
    const items = getColorRows()
    const index = items.findIndex(entry => entry.id === id)

    if (index !== undefined) {
        items.splice(index, 1)
        setColorRows(items)
    }
}
