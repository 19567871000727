import apiFetch from 'api/utils/APIFetch'

export enum routes {
    CELL_PHONE = `cell-phone`,
    HOME_PHONE = `home-phone`,
    WORK_PHONE = `work-phone`,
}

const updatePhoneNumber = (value: string, customerID: number,  type: routes): Promise<void> => apiFetch(`/api/customers/${type}`, {
    method: `PATCH`,
    body: JSON.stringify({
        CustomerId: customerID,
        PhoneNumber: value,
    }),
})

export default updatePhoneNumber