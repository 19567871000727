import React from 'react'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material'
import { useAppSelector } from 'store'
import setRepairOrderAttentionNeeded from 'api/setRepairOrderAttentionNeeded'

declare global {
    interface Window {
        finishEstimates(workbenchUrl: string): any 
    }
}

export interface IWaitingOnEstimateContent {
    repairOrderID: number | null
    number: string
    workbenchUrl: string
    onClose(): any 
}

function WaitingOnEstimateContent({
    repairOrderID,
    number,
    workbenchUrl,
    onClose,
}: IWaitingOnEstimateContent) {
    const {
        isToolbarSvsEnabled
    } = useAppSelector(state => state.app)
    
    const handleFinishEstimateClick = async () => {
        if (repairOrderID !== null) {
            await setRepairOrderAttentionNeeded(repairOrderID, false)
    
            if (isToolbarSvsEnabled) {
                // When we're in the toolbar, we want to open Workbench in the
                // SVS tab (with the Cef Sharp browser). We use injected Toolbar JS.
                window.finishEstimates(workbenchUrl);
            } else {
                // When we're not in the toolbar or SVS is not enabled, open Workbench in a new tab.
                const popup = window.open(workbenchUrl);
    
                if (popup !== null) {
                    if(popup.focus) popup.focus()
                }
            }
            onClose()
        }
    }

    return (
        <>
            <DialogContent>
                <DialogContentText>RO# {number} is waiting on parts/labor estimate.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    autoFocus 
                    onClick={handleFinishEstimateClick}
                    color={`primary`}
                >Finish Estimate</Button>
            </DialogActions>
        </>
    )
}

export default WaitingOnEstimateContent