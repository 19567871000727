
import { getData } from "modules/dealerForm";
import { useEffect } from "react";
import { useAppDispatch } from "store";

export const useSubscribeFormData = (id) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getData(id));
  }, [id, dispatch]);
};
