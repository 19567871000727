import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { IPage } from 'pages'

interface IAppNavBar {
    title: string
    isPrimaryRoute: boolean
    backRoute: string
}

let initialState: IAppNavBar = {
    title: `eSS`,
    isPrimaryRoute: true,
    backRoute: `/`,
}

const slice = createSlice({
    name: `appNavBar`,
    initialState,
    reducers: {
        setAppNavBar(state, action: PayloadAction<IAppNavBar>) {
            return action.payload
        },
        setViaPage(state, action: PayloadAction<IPage>) {
            const {
                title,
                isPrimaryRoute,
                backRoute,
            } = action.payload
            state.title = title ? title : initialState.title
            state.isPrimaryRoute = isPrimaryRoute
            state.backRoute = backRoute ? backRoute : initialState.backRoute
        },
        reset() {
            return initialState
        }
    }
})

export const {
    setAppNavBar,
    setViaPage,
    reset,
} = slice.actions

export default slice.reducer