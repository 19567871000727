import { createAsyncThunk } from '@reduxjs/toolkit'
import getSmsReportingOverview from 'api/reporting/sms/getSmsReportingOverview'
import { SmsReportingOverviewViewModel } from 'api/types/reporting/SmsReportingOverviewViewModel'
import { IPayload } from '../interfaces/IPayload'

const getSmsReportingOverviewData = createAsyncThunk(
    `reporting/getSmsReportingOverviewData`,
    async ({
        dealerID,
        startDate,
        endDate,
    }: IPayload): Promise<SmsReportingOverviewViewModel> => {
        return await getSmsReportingOverview(dealerID, startDate, endDate)
    }
)

export default getSmsReportingOverviewData