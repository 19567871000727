import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getFeatures from "api/getFeatures";
import { Feature } from "api/types/feature";
import { AppThunk } from "store";

interface IFeatures {
  isLoading: boolean;
  isError: boolean;
  infoText: string;
  features: Feature[];
}

let initialState: IFeatures = {
  isLoading: true,
  isError: false,
  infoText: `Initializing...`,
  features: [],
};

const slice = createSlice({
  name: `features`,
  initialState,
  reducers: {
    getDataStart(state) {
      state.isLoading = true;
      state.isError = false;
      state.infoText =
        state.features === undefined
          ? `Retrieving features...`
          : `Refreshing Data...`;
    },
    getDataSuccess(state, action: PayloadAction<Feature[]>) {
      state.features = [];
      state.features = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.infoText = ``;
    },
    getDataFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.infoText = action.payload;
    },
    reset(state) {
      state.features = initialState.features;
      state.isLoading = initialState.isLoading;
      state.isError = initialState.isError;
      state.infoText = initialState.infoText;
    },
  },
});

export const { getDataStart, getDataSuccess, getDataFailure, reset } =
  slice.actions;

export default slice.reducer;

export const getData = (): AppThunk => async (dispatch) => {
  try {
    try {
      dispatch(getDataStart());
      const data = await getFeatures();
      dispatch(getDataSuccess(data));
    } catch (err: any) {
      dispatch(getDataFailure(err));
    }
  } catch (error) {
    dispatch(getDataFailure(`There was an error retrieving dealers: ${error}`));
  }
};
