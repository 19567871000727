import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import BaseProvider from 'providers/BaseProvider'

const render = () => {
    ReactDOM.render(
        <BaseProvider>
            <App></App>
        </BaseProvider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development') {
    if(process.env.REACT_APP_MOCK_API) {
        import('api/mocks/server').then(({makeServer}) => {
            makeServer({ environment: 'development' })
        })
    }

    if (module.hot) {
        module.hot.accept('components/App', render)
    }
}
