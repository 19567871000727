import React from 'react'
import {
    ensurePluginOrder,
} from 'react-table'

const pluginName = 'usePinnedRows'

export default function usePinnedRows(hooks) {
    hooks.useInstance.push(useInstance)
}

usePinnedRows.pluginName = pluginName

function useInstance(instance) {
    const {
        rows,
        plugins,
    } = instance

    ensurePluginOrder(
        plugins,
        ['useFilters', 'useGlobalFilter', 'useGroupBy', 'usePivotColumns', 'useSortBy'],
        'usePinnedRows'
    )

    const orderedRows = React.useMemo(() => {
        const sortRows = (rows: any[]) => {
            return [
                ...rows.filter(row => row.values.isPinned),
                ...rows.filter(row => !row.values.isPinned)
            ]  
        }
        
        return sortRows(rows)
    }, [
        rows,
    ])

    Object.assign(instance, {
        rows: orderedRows,
    })
}