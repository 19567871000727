import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import Overlay from 'components/Overlay'
import { Link } from 'react-router-dom'

export interface ITasksInfoBox {
    id: number,
    tab: any,
    infoArray: any[],
    isLoading: boolean,
    isError: boolean,
}

function TasksInfoBox({
    id,
    tab,
    infoArray,
    isLoading,
    isError,
}:ITasksInfoBox) {
    //get the first item
    const topItem = infoArray[0]

    //get the rest
    const bottomItems = infoArray.slice(1)

    return (
        <div className={styles.container}>
            <Link className={styles.link} to={`/bdc/detail/${id}`}>
                <div className={styles.contentWrapper}>
                    <div className={styles.tabWrapper}>{tab}</div>
                    <div className={styles.bottomWrapper}>
                        <div className={styles.topRow}>{topItem}</div>
                        <div className={styles.bottomRow}>{bottomItems}</div>
                    </div>
                </div>
            </Link>
            <Overlay
                show={isLoading || isError}
                isLoading={isLoading}
                isError={isError}
                text={isError ? `An error has occured while retrieving categrory data!` : `Retrieving category data...`}
            ></Overlay>
        </div>
    )
}

TasksInfoBox.propTypes = {
    id: PropTypes.number,
    tab: PropTypes.object,
    infoArray: PropTypes.array
}

export default TasksInfoBox
