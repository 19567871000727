import moment from 'moment'
import parsePhoneNumberFromString from 'libphonenumber-js'

export default class Base {
    toPlainObj() {
        return Object.assign({}, this)
    }

    formatTime(rawTime: string) {        
        return rawTime !== null ? moment(rawTime).format(`MM/DD/YYYY h:mm a`) : ``
    }

    formatRawDateTime(dateTime: string) {
        if (dateTime === ``) {
            return ``
        } else {
            return moment(dateTime).utc().format()
        }
    }

    formatPhone(number: string = ``) {
        return parsePhoneNumberFromString(number, `US`)?.formatNational() || ``
    }
}