import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

export interface IOpenPayload {
    maintenanceScheduleId: string
}

export interface IMaintenanceScheduleDialogState {
    isOpen: boolean,
    maintenanceScheduleId: string | null
}

let initialState: IMaintenanceScheduleDialogState = {
    isOpen: false,
    maintenanceScheduleId: null
}

const slice = createSlice({
    name: `maintenanceScheduleDialog`,
    initialState,
    reducers: {
        maintenanceScheduleDialogOpened(state, action: PayloadAction<IOpenPayload>) {
            const {
                maintenanceScheduleId
            } = action.payload

            state.maintenanceScheduleId = maintenanceScheduleId
            state.isOpen = true
        },
        resetMaintenanceScheduleDialog: () => initialState,
    }
})

export const {
    maintenanceScheduleDialogOpened,
    resetMaintenanceScheduleDialog,
} = slice.actions

export default slice.reducer