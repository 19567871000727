import React from 'react'
import SearchForm from './components/SearchForm'
import ResultsView from './components/ResultsView'

import styles from './styles.module.scss'

function CustomerSearchView() {
    return (
        <div className={styles.container}>
            <SearchForm/>
            <ResultsView/>
        </div>
    )
}

export default CustomerSearchView