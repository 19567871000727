import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TrashIcon = ({
    color,
    height,
    width,
    className
}) => {  
    const containerClassNames = classNames(
        `icon-container`,
        {[className]: className}
    )

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={containerClassNames} style={{
            height,
            width
        }}>
            <path fill={color} d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z"></path>
        </svg>
    )
}

TrashIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
}

TrashIcon.defaultProps = {
    color: `white`,
    height: 12,
    width: 12,
}

export default TrashIcon