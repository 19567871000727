import {
    createEntityAdapter,
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import BdcLineItem from 'classes/BdcLineItem'
import CallGroup from 'classes/CallGroup'
import generateBdcCategorySchema from 'utils/generateBdcCategorySchema'
import { RootState } from 'rootReducer'

const bdcListViewLineItemsAdapter = createEntityAdapter<BdcLineItem>()

interface IBdcListView {
    isLoading: boolean,
    isError: boolean,
    infoText: string
}

let initialState: IBdcListView = {
    isLoading: true,
    isError: false,
    infoText: ``
}

const slice = createSlice({
    name: `bdcListView`,
    initialState: bdcListViewLineItemsAdapter.getInitialState(initialState),
    reducers: {
        getDataStart(state) {
            state.isLoading = true
            state.isError = false
            state.infoText = `Retrieving data...`
        },
        getDataSuccess(state, action: PayloadAction<BdcLineItem[]>) {
            bdcListViewLineItemsAdapter.setAll(state, action.payload)
            state.isLoading = false
            state.isError = false
            state.infoText = ``
        },
        getDataFailure(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.isError = true
            state.infoText = action.payload
        },
        modifyCallGroupSuccess(state, action: PayloadAction<CallGroup>) {
            const callGroup = action.payload

            if (callGroup.sourceTypeID !== null) {
                bdcListViewLineItemsAdapter.updateOne(state, {
                    id: callGroup.sourceTypeID,
                    changes: {
                        callGroup,
                    }
                })
            } else {
                state.isError = true
                state.infoText = `An error has occured while updating.`
            }
        },
        modifyCallGroupFailure(state, action: PayloadAction<string>) {
            state.isError = true
            state.infoText = action.payload
        },
        reset(state) {
            bdcListViewLineItemsAdapter.removeAll(state)
            state.infoText = initialState.infoText
            state.isError = initialState.isError
            state.isLoading = initialState.isLoading
        }
    }
})

export const {
    getDataStart,
    getDataSuccess,
    getDataFailure,
    modifyCallGroupSuccess,
    modifyCallGroupFailure,
    reset
} = slice.actions

export const bdcListViewLineItemsSelectors = bdcListViewLineItemsAdapter.getSelectors<RootState>(state => state.bdcListView)

export default slice.reducer

export const fetchData = (categoryID: number): AppThunk => async dispatch => {
    try {
        dispatch(getDataStart())
        const getData = generateBdcCategorySchema()[categoryID].data
        const data = await getData()
        dispatch(getDataSuccess(data))
    } catch (err: any) {
        dispatch(getDataFailure(err))
    }
}

export const updateCreateCallGroup = (callGroup: CallGroup): AppThunk => async dispatch => {
    try {
        const res = await callGroup.updateCreateViaAPI()
        if(res.ID !== undefined) callGroup.id = res.ID   
        dispatch(modifyCallGroupSuccess(callGroup.toPlainObj()))
    } catch (err: any) {
        dispatch(modifyCallGroupFailure(err))
    }
}