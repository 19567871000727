import { Message } from "api/types/message";
import useSubscribeToSignal from "hooks/useSubscribeToSignal";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { Signal } from "types/signalr";
import { getData, messageUpserted } from 'modules/customerMessages'


const useSubscribeToCustomerMessages = () => {
    const dispatch = useAppDispatch()

    const {
        customerID
    } = useAppSelector(state => state.customerMessages)

    useEffect(() => {

        if(customerID !== undefined && customerID >= 0){

            dispatch(getData(customerID))

        }
    }, [dispatch, customerID])


    const addMessage = useCallback((message: Message) => {
        
        dispatch(messageUpserted(message))

    }, [dispatch])

    useSubscribeToSignal(Signal.MESSAGE_CREATED, addMessage)
    useSubscribeToSignal(Signal.MESSAGE_RECEIVED, addMessage)
}

export default useSubscribeToCustomerMessages