import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

//import components
import BoxWithHeader from 'components/BoxWithHeader'
import InspectionDetailsGridLayout from 'components/InspectionDetailsGridLayout'
import Button from 'components/Button'
import InfoIcon from 'assets/iconsLibrary/InfoIcon'

const InspectionInfo = ({
    firstName,
    lastName,
    email,
    cellPhone,
    vin,
    vehicle,
    mileage,
    onCustomerInfoClick,
    onVehicleHistoryClick
}) => {  
    const buttonSymbol = <InfoIcon
        color={`#223A78`}
        className={styles.buttonSymbol}
    ></InfoIcon>

    return (
        <BoxWithHeader
            titleText={`Customer & Vehicle Info`}
            content={
                <div className={styles.container}>
                    <div className={styles.vehicleWrapper}>
                        <div className={styles.vehicleImageWrapper}></div>
                        <Button
                            className={styles.button}
                            text={`Customer Info`}
                            symbol={buttonSymbol}
                            onClick={onCustomerInfoClick}
                        ></Button>
                        <Button
                            className={styles.button}
                            text={`Vehicle History`}
                            symbol={buttonSymbol}
                            onClick={onVehicleHistoryClick}
                        ></Button>
                    </div>
                    <InspectionDetailsGridLayout
                        className={styles.grid}
                    >
                        <div className={`labelClean`}>First Name</div>
                        <div>{firstName}</div>
                        <div className={`labelClean`}>Last Name</div>
                        <div>{lastName}</div>
                        <div className={`labelClean`}>Email</div>
                        <div>{email}</div>
                        <div className={`labelClean`}>Cell Phone</div>
                        <div>{cellPhone}</div>
                        <div className={`labelClean`}>Vin</div>
                        <div>{vin}</div>
                        <div className={`labelClean`}>Vehicle</div>
                        <div>{vehicle}</div>
                        <div className={`labelClean`}>Mileage</div>
                        <div>{mileage}</div>
                    </InspectionDetailsGridLayout>
                </div>
            }
        ></BoxWithHeader>
    )
}

InspectionInfo.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    cellPhone: PropTypes.string,
    vin: PropTypes.string,
    vehicle: PropTypes.string,
    mileage: PropTypes.string
}

export default InspectionInfo