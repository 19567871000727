import {
  Box,
  Card,
  CardContent,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IFeatureListItem } from "../FeaturesList";

interface IFeatureListItemProps {
  feature: IFeatureListItem,
  onFeatureChange: (id: number, value: boolean) => any;
}

const FeaturesListItem = ({feature, onFeatureChange}: IFeatureListItemProps) => {

  const [controlValue, setControlValue] = useState(false);

  const handleChange = (e) => {
    setControlValue(e.target.checked);
    onFeatureChange(feature.FeatureID, e.target.checked);
  };

  useEffect(() => {
    setControlValue(feature.IsEnabled);
  }, [feature]);

  return (
    <Card
      sx={{
        display: "flex",
        width: "100%",
        mb: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            fontWeight={"bold"}
            component="div"
          >
            {feature.Name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {feature.Description}
          </Typography>
        </CardContent>
      </Box>
      <Switch checked={controlValue} onChange={handleChange} />
    </Card>
  );
};

export default FeaturesListItem;
