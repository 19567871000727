import { useEffect } from "react";
import { useAppDispatch } from "store";
import { getData } from "modules/dealerPicker";

export const useSubscribeToPickerDealers = (
  searchCriteria: string,
  pageNumber: number,
  pageSize: number
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getData(searchCriteria, pageNumber, pageSize));
  }, [dispatch, pageNumber, pageSize, searchCriteria]);
};
