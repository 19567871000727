import AppError, { Errors } from 'errors'
import apiFetch from './APIFetch'

export namespace ApiUtils {
    export type Endpoint = ((args?: string | number) => string)

    export async function send<T>(url: Endpoint, payload, errorType: Errors, method: `POST` | `PATCH` = `POST`): Promise<T> {
        return apiFetch(url(), {
            method,
            body: JSON.stringify(payload),
        }).catch(error => {
            throw new AppError(error.Message, errorType)
        })
    }

    export async function get<T>(url: Endpoint, errorType: Errors): Promise<T> {
        return apiFetch(url()).catch(error => {
            throw new AppError(error, errorType)
        })
    }
}