let tokenRefreshPromise = null,
    isRefreshingToken = false;

export default function apiFetch(url, request = {}) {
    const token = localStorage.getItem(`crmToken`)

    if (isRefreshingToken) {
        return new Promise(res => {
            let tries = 0,
                intv = window.setInterval(() => {
                    //whichever comes first - either the token gets refreshed, or we've been waiting 5 seconds...
                    if (!isRefreshingToken || tries > 50) {
                        window.clearInterval(intv);
                        isRefreshingToken = false;
                        res(apiFetch(`${process.env.REACT_APP_API_URL}${url}`, request));
                    }
                    tries++;
                }, 100);
        });
    }

    let defaultHeaders = {
            'Content-Type': 'application/json'
        },
        headersExisted = !!request.headers,
        isActualHeadersObject;

    //Fetch will determine appropriate Content-Type header for FormData
    if (request.body && request.body instanceof FormData) {
        delete defaultHeaders['Content-Type'];
    }

    if (token) {
        defaultHeaders.Authorization = `Bearer ${token}`;
    }

    request.method = request.method || 'GET';
    request.headers = request.headers || new Headers(defaultHeaders);

    // if headersExisted, then we are modifying headers the consumer sent
    // otherwise, we've already set the request.headers with defaultHeaders above
    if (headersExisted) {
        isActualHeadersObject = typeof request.headers.has === 'function';

        Object.keys(defaultHeaders).forEach(key => {
            if (isActualHeadersObject) {
                if (!request.headers.has(key)) {
                    request.headers.set(key, defaultHeaders[key]);
                }
            } else {
                request.headers[key] = request.headers[key] || defaultHeaders[key];
            }
        });
    }

    return fetch(`${process.env.REACT_APP_API_URL}${url}`, request)
        .then(response => {
            let contentType = response.headers.get('content-type');
            let newResponse = /application\/json/.test(contentType) ? response.json() : response;

            if (response.ok) {
                return newResponse;
            } else {
                //Reject on non 2xx status codes to simplify error handling for the caller
                if (newResponse.then && typeof (newResponse.then === 'function')) {
                    //we don't want to reject with the promise returned by the response.json() call (otherwise the caller gets a promise rather than the actual
                    //json object that represents the error).  Thus, reject after the parse json promise is resolved.
                    return newResponse.then((errJson) => {
                        return Promise.reject(errJson);
                    });
                } else {
                    return Promise.reject(newResponse);
                }
            }
        }).catch(err => {
            if (!err || !(err.message || err.Message || err.ModelState)) {
                err = new Error('An unexpected server error occurred');
            } else if (err.message === 'Failed to fetch') {
                // convert "Failed to fetch" into a more user friendly error.
                err = new Error('Error connecting to server');
            }

            return Promise.reject(err);
        });
}

export function refreshToken() {
    if (isRefreshingToken && tokenRefreshPromise) {
        return tokenRefreshPromise;
    }

    tokenRefreshPromise = apiFetch(`${process.env.REACT_APP_API_URL}api/login/token/refresh`)
        .then(resp => {
            isRefreshingToken = false;
            return resp;
        });

    isRefreshingToken = true;
    return tokenRefreshPromise;
}