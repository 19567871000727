import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import styles from './styles.module.scss'
import theme from 'assets/theme'

export interface ILoadingIndicatorProps {
    message?: string
}

function LoadingIndicator({
    message = `Loading...`
}: ILoadingIndicatorProps) {
    return (
        <div className={styles.container}>
            <div className={styles.centerContent}>
                <Box>
                    <div className={styles.centerContent}>
                        <CircularProgress sx={{'> svg': {color: `${theme.palette.primary.light} !important`}}}/>
                    </div>
                    <Typography>{message}</Typography>
                </Box>
            </div>
        </div>
    )
}

export default LoadingIndicator