import React, { useCallback, useEffect } from 'react'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import MessageBox from './components/MessageBox'
import SendForm from './components/SendForm'
import { markRead, Message } from 'api/types/message'
import { useAppDispatch, useAppSelector } from 'store'
import { reset, updateCustomerID } from 'modules/customerMessages'
import styles from './styles.module.scss'

type IConversationPane = {
    messages: Message[]
    customerID: number
    repairOrderID: number | null
    repairOrderAlertID: number | null
}

function ConversationPane({
    customerID,
    repairOrderID,
    messages,
    repairOrderAlertID
}: IConversationPane) {
    const dispatch = useAppDispatch()

    const {
        isLoading
    } = useAppSelector(state => state.customerMessages)

    const {
        ID: dealerID
    } = useAppSelector(state => state.dealer)

    const markMessagesAsRead = useCallback(async () => {
        const unreadMessageIds = messages.filter(message => !message.HasAdvisorViewed).map(message => message.ID)

        if(unreadMessageIds.length > 0) {

            await markRead(unreadMessageIds, dealerID)

        }
    }, [messages, dealerID])

    useEffect(()=> {

        dispatch(updateCustomerID(customerID))

        return () => {

            dispatch(reset())
        
        }

    }, [dispatch, customerID])

    useEffect(() => {
        return () => {

            markMessagesAsRead()
        
        }
    }, [markMessagesAsRead])

    const messageList = messages.map(message => (
        <MessageBox
            key={message.ID}
            message={message}
        ></MessageBox>
    ))

    const loadingIndicator = <Box
        justifySelf={`center`}
        display={`grid`}
        gridTemplateColumns={`auto auto`}
        columnGap={5}
        alignItems={`center`}
    >
        <Typography variant={`subtitle2`}>Retrieving Messages...</Typography>
        <CircularProgress size={10} />
    </Box>

    const noMessagesIndicator = <Box
        justifySelf={`center`}
        alignItems={`center`}
    >
        <Typography variant={`subtitle2`}>No messages to display.</Typography>
    </Box>

    const content = () => {
        if (isLoading) {
            return loadingIndicator
        }

        if (messages.length === 0) {
            return noMessagesIndicator
        }
        
        return messageList
    }

    const sendForm = () => {
        if (customerID === undefined) {
            throw new Error(`CustomerID cannot be undefined if send form is enabled.`)
        }

        return <SendForm
            customerID={customerID}
            repairOrderID={repairOrderID}
            dealerID={dealerID}
            repairOrderAlertID={repairOrderAlertID}
        ></SendForm>
    }

    return (
        <Box className={styles.container}>
            <Paper
                className={styles.messagingContainer} 
                variant={`outlined`} 
                square>
                <Box
                    className={styles.messageHistory}
                    display={`flex`}
                    flexDirection={`column-reverse`}
                    overflow={` hidden auto`}
                >
                    <Box
                        display={`grid`}
                        gap={1}
                        padding={2}>

                        {content()}
                    </Box>
                </Box>
            </Paper>
            {sendForm()}
        </Box>
    );
}

export default ConversationPane