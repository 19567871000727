import React from 'react'

import styles from './styles.module.scss'
import { IconButton } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'

export interface IPagination {
    canNextPage: boolean
    canPreviousPage: boolean
    onNextPage(): void
    onPreviousPage(): void
    currentPageIndex: number
    pageCount: number
    onGoToPage(pageIndex: number): void 
}

function Pagination({
    canNextPage,
    canPreviousPage,
    onNextPage,
    onPreviousPage,
    currentPageIndex,
    pageCount,
    onGoToPage,
}: IPagination) {
    return (
        <div className={styles.container}>
            <IconButton
                disabled={currentPageIndex === 0}
                onClick={() => onGoToPage(0)}
                size="large">
                <FirstPageIcon fontSize={`small`}/>
            </IconButton>
            <IconButton disabled={!canPreviousPage} onClick={onPreviousPage} size="large">
                <NavigateBeforeIcon fontSize={`small`}/>
            </IconButton>
            <div className={styles.progressWrapper}>{currentPageIndex + 1}/{pageCount}</div>
            <IconButton disabled={!canNextPage} onClick={onNextPage} size="large">
                <NavigateNextIcon fontSize={`small`}/>
            </IconButton>
            <IconButton
                disabled={currentPageIndex === pageCount - 1}
                onClick={() => onGoToPage(pageCount - 1)}
                size="large">
                <LastPageIcon fontSize={`small`}/>
            </IconButton>
        </div>
    );
}

export default React.memo(Pagination)