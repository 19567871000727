import React, { useMemo } from 'react'
import OpenIcon from '@mui/icons-material/CheckCircleOutline'
import ClosedIcon from '@mui/icons-material/Block'
import UnknownIcon from '@mui/icons-material/Help'
import { green, red } from '@mui/material/colors'
import { Box } from '@mui/material'
import ColumnIcon from 'componentsUI/TableView/components/ColumnIcon'
import { StatusTranslation } from 'components/RepairOrderListView/types/ListRepairOrder'

export interface IStatusCell {
    status: StatusTranslation
}

function StatusCell({
    status,
}: IStatusCell) {

    const [Icon, color, text = ``] = useMemo(() => {
        switch(status) {
            case StatusTranslation.CLOSED:
                return [
                    ClosedIcon,
                    red[700],
                    `Closed`,
                ]
            case StatusTranslation.OPEN:
                return [
                    OpenIcon,
                    green[700],
                    `Open`,
                ]
            default:
                return [
                    UnknownIcon,
                    undefined,
                    `Unknown`,
                ]
        }
    },[status])
    

    return (
        <Box
            display={`grid`}
            alignItems={`center`}
        >
            <ColumnIcon
                tooltipText={text}
                Icon={Icon}
                color={color}
            ></ColumnIcon>
        </Box>
    )
}

export default React.memo(StatusCell)