import { getDealerUsersData } from "modules/dealerForm";
import { useEffect } from "react";
import { useAppDispatch } from "store";

export const useSubscribeToUsersSearch = (
  dealerID: number,
  searchTerm: string
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDealerUsersData(dealerID, searchTerm));
  }, [dispatch, dealerID, searchTerm]);
};
