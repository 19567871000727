import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from 'assets/theme'


function MaterialUiProvider({children}) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default MaterialUiProvider