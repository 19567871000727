import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from 'store'
import { openEstimateDialog } from 'modules/estimateDialog'
import WaitingOnEstimateIcon from '@mui/icons-material/MonetizationOn'
import WaitingOnCustomerIcon from '@mui/icons-material/AccountCircle'
import CompleteIcon from '@mui/icons-material/CheckCircle'
import UnknownIcon from '@mui/icons-material/HelpOutline'
import ColumnIcon from 'componentsUI/TableView/components/ColumnIcon'
import { MpviStatus } from 'types/repairOrder'
import PendingIcon from 'assets/iconsLibrary/PendingIcon'
import MessageIcon from '@mui/icons-material/Mail'
import MessageReadIcon from '@mui/icons-material/Drafts'
import ProfitIcon from '@mui/icons-material/AttachMoney'
import { green, red } from '@mui/material/colors'
import { BadgeProps } from '@mui/material'

export interface IMPVIStatusCell {
    status: MpviStatus
    isAttentionNeeded: boolean
    repairOrderID: number
}

function MPVIStatusCell({
    status,
    isAttentionNeeded,
    repairOrderID,
}: IMPVIStatusCell) {
    
    const dispatch = useAppDispatch()

    const handleClick = useCallback(() => {

        dispatch(openEstimateDialog(repairOrderID))

    }, [repairOrderID, dispatch])

    const [
        text,
        Icon,
        color,
        isButton = true,
    ] = useMemo(() => {
        const colorRed = red[700]
        const colorGreen = green[700]

        switch (status) {
            case MpviStatus.NOT_STARTED:
                return [
                    `Not Started`,
                    undefined,
                    undefined,
                    false,
                ]
            case MpviStatus.IN_PROGRESS:
                return [
                    `In Progress`,
                    PendingIcon,
                    undefined,
                    false,
                ]
            case MpviStatus.WAITING_ON_ESTIMATE:
                return [
                    `Waiting On Estimate`,
                    WaitingOnEstimateIcon,
                ]
            case MpviStatus.WAITING_ON_APPROVAL:
                return [
                    `Waiting On Customer Approval`,
                    WaitingOnCustomerIcon,
                ]
            case MpviStatus.MPVI_SENT:
                return [
                    `Sent`,
                    MessageIcon,
                ]
            case MpviStatus.MPVI_OPENED:
                return [
                    `Opened`,
                    MessageReadIcon,
                ]
            case MpviStatus.WAITING_ON_PARTS:
                return [
                    `Waiting On Parts`,
                    ProfitIcon,
                    colorGreen,
                ]
            case MpviStatus.SERVICE_DECLINED:
                return [
                    `Declined`,
                    ProfitIcon,
                    colorRed,
                ]
            case MpviStatus.VEHICLE_IN_SERVICE:
                return [
                    `In Service`,
                    ProfitIcon,
                    colorGreen,
                ]
            case MpviStatus.COMPLETE:
                return [
                    `Complete`,
                    CompleteIcon,
                    ``,
                ]
            default:
                return [
                    `Unknown`,
                    UnknownIcon,
                    ``,
                ]
        }
    }, [status])

    const buttonProps = useMemo(() => ({
        onClick: handleClick
    }), [handleClick])

    const badgeProps: BadgeProps = useMemo(() => ({
        variant: 'dot',
        color: 'error',
        invisible: !isAttentionNeeded,
        overlap: 'circular',
    }), [isAttentionNeeded])

    return (
        <ColumnIcon
            tooltipText={text}
            badgeProps={badgeProps}
            Icon={Icon}
            isButton={isButton}
            buttonProps={buttonProps}
            color={color}
        />
    )
}

export default React.memo(MPVIStatusCell)