import { setAlert } from 'components/RepairOrderAlertDialog/module'
import { roAlertSelectors } from 'modules/roAlerts'
import moment from 'moment'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'

function useRepairOrderAlertManager() {
    
    const dispatch = useAppDispatch()

    const alerts = useAppSelector(state => roAlertSelectors.selectAll(state))
    
    const {
        isOpen,
    } = useAppSelector(state => state.roAlertDialog)

    const {
        isOpen: unreadMessageDialogOpen
    } = useAppSelector(state => state.customersWithUnreadMessages)
    
    useEffect(() => {

        const timer = setInterval(() => {
            
            const pastDueAlerts = alerts.filter(alert => moment(alert.time) <= moment())

            if(pastDueAlerts.length > 0 && !isOpen && !unreadMessageDialogOpen) {

                const alert = pastDueAlerts[0]

                dispatch(setAlert(alert))

            }

        }, 1000)


        return () => {

            clearInterval(timer)

        }

    }, [alerts, dispatch, isOpen, unreadMessageDialogOpen])
}

export default useRepairOrderAlertManager