import React from 'react'
import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import TextLine from '../TextLine'
import EditDialog from '../EditDialog'

type IListLine = {
    isLoading: boolean
    label: string
    text: string
} & ({
    editable: true
    onEdit(value: string): void
} | {
    editable?: undefined
    onEdit?: never
})

function ListLine({
    editable,
    isLoading,
    label,
    text,
    onEdit = () => {},
}: IListLine) {
    const [isEditing, setIsEditing] = React.useState<boolean>(false)

    const handleSave = (value: string) => {
        onEdit(value)
    }

    const content = <>
        <ListItemText
            primary={<TextLine
                isLoading={isLoading}
                label={label}
                text={text}
            ></TextLine>}
        ></ListItemText>
        {editable && !isLoading ?
            <ListItemSecondaryAction>
                <EditIcon fontSize={`small`} color={`action`} />
            </ListItemSecondaryAction>
        : null}
    </>

    return (
        <>
            {editable ? <ListItem 
                dense 
                button
                onClick={() => setIsEditing(true)}
            >{content}</ListItem> : <ListItem dense>{content}</ListItem>}
            {isEditing && <EditDialog
                label={label}
                value={text}
                onSave={handleSave}
                onClose={() => setIsEditing(false)}
            ></EditDialog>}
        </>
    )
}

export default React.memo(ListLine)